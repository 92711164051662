import React, { useEffect, useContext, useState } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiDataTableSupply from './MiDataTableSupply';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Kpi from '../../componentes/ui/Kpi';
import Button from '../../componentes/ui/Button';
import { Spinner } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';


import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, getPantDocFromNivel, getTitle, getDecodedCookie } from '../../helpers/helperApp';

import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';


$.DataTable = DataTable;

function Supply(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();
    const source5 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ tableAprov, saveTableAprov ] = useState([]);
    const [ calculateData, saveCalculateData ] = useState(null);
    const [ docAprov, saveDocAprov ] = useState(null);
    const [ loadingCalculate, saveLoadingCalculate ] = useState(false);
    const [ solicitandoMat, saveSolicitandoMat ] = useState(false);
    const [ primeraCarga, savePrimeraCarga ] = useState(true);
    const [ txtBtnSolicitando, saveTxtBtnSolicitando ] = useState("");
    const [ verAprov, saveVerAprov ] = useState(false);
    const [ showModal, saveShowModal ] = useState(false);
    const [ docsori, saveDocsOri ] = useState([]);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                     
                    document.title = getTitle(confPantalla.config.trad, confPantalla.supply.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);                                                                                                        
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
                source4.cancel();
                source5.cancel();
        } 

    },[confPantalla, formularioHijo.id])

    const handleSearchStock = async() => {        

        saveVerAprov(false);

        saveLoadingCalculate(true);
        
        await getDocArprov();

        let table = $("#supply").DataTable();
        const rows = table.rows( { selected: true } ).data();
        if( rows.length > 0 ){

            savePrimeraCarga(false);

            const ids = [];
            rows.map( row => {                
                ids.push(row.id);
            })
            const data = new FormData();      
            data.append("validacion", apiKey());
            data.append("ids", JSON.stringify(ids));
            // const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.supply.controles.datatableres.getdata,data);
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.supply.controles.datatableres.getdata, data, source.token);
            
            if( typeof respuesta.data !== "string" ){
                saveCalculateData(respuesta.data);
            }else if( typeof respuesta.data === "string" ){            
                // Reseteamos la tabla de aprovisionamiento
                resetTAbAprov();
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
            } 

        }else{
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.supply.controles.emptyclick.title}`,{autoClose: true});
            // Reseteamos la tabla de aprovisionamiento
            resetTAbAprov();
        }  
        
        saveLoadingCalculate(false);
        saveVerAprov(true);
    }

    const getDocArprov = async () =>{

        const data = new FormData();      
        data.append("validacion", apiKey());
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+"/supply/getDocArprov",data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+"/supply/getDocArprov", data, source3.token);

        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else if( respuesta.data.length == 0 ){
            // No existe documento de aprovisonamiento
            saveDocAprov(null);
        }else if( respuesta.data.length ){
            // Existe documento de aprovisionamiento     
            saveDocAprov(respuesta.data);
        }

    }

    const solicitarMateriales = async () => {

        saveSolicitandoMat(true);        

        let table = $('#calcsupply').DataTable();
        const rows = table.rows().data()

        let tableDocs = $('#supply').DataTable();
        const rowsDocs = tableDocs.rows({ selected: true }).data()

        const materiales = [];
        const documentos = [];

        // Eliminamos la columna con la clase style del row de la tabla
        rows.map(row => {
            delete row.style;
            materiales.push(row);
        })

        rowsDocs.map(row => {
            delete row.style;
            documentos.push(row);
        })

        const data = new FormData(); 
        data.append("validacion", apiKey());
        data.append("datos", JSON.stringify(materiales));
        data.append("datos2", JSON.stringify(documentos));

        // Crear cabecera/linea
        saveTxtBtnSolicitando(confPantalla.supply.controles.btnsolicitudsendingreg.title);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.supply.controles.sendmaterialesreg.senddata, data, source2.token);
        if( typeof respuesta.data.ok === "string"  ){
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.supply.controles.sendmaterialesreg.success.title}`, {autoClose: true});

            // Recargamos la información del documento de aprovisionamiento
            await getDocArprov();

            // Reseteamos la tabla de aprovisionamiento
            resetTAbAprov();

        }else if( respuesta.data === false ){            
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.supply.controles.sendmaterialesreg.error.title}`, {autoClose: true});            
        }else if( typeof respuesta.data === "string" ){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});  
        }


        // Envío de correo
        // const respuestamail = await axios.post(getApiUrl(window.location.origin)+confPantalla.supply.controles.sendmateriales.senddata,data);
        saveTxtBtnSolicitando(confPantalla.supply.controles.btnsolicitudsendingmail.title);
        const respuestamail = await axiosPost(getApiUrl(window.location.origin)+confPantalla.supply.controles.sendmaterialesmail.senddata, data, source4.token);
        if( typeof respuestamail.data.ok === "string"  ){
            alertService.success(`${respuestamail.data.ok}`, {autoClose: false});
        }else if( respuestamail.data === false ){            
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.supply.controles.sendmaterialesmail.error.title}`, {autoClose: true});            
        }else if( typeof respuestamail.data === "string" ){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuestamail.data}`, {autoClose: true});  
        } 

        saveSolicitandoMat(false);
    }


    // Función para resetear la tabla de aprovisionamiento
    const resetTAbAprov = () => {
        saveLoadingCalculate(true);
        saveVerAprov(false); 
        saveCalculateData([])
        saveVerAprov(true);
        saveLoadingCalculate(false);
    }
    
    // Función para ver los documentos de orígen de las líneas de la tabla de aprovisionamiento
    const verOrigenes = async (e, rowData) => {

        const data = new FormData(); 
        data.append("validacion", apiKey());
        data.append("iddocs", rowData.iddocs);
        data.append("iduni", rowData.id);

        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.supply.controles.getdocsaprov, data, source5.token);
        if( typeof respuesta.data === "string" ){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});  
        }else{
            saveDocsOri(respuesta.data)
            saveShowModal(true);
        }


    }

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Alert fondo={true} />
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">                
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <Section titulo={ confPantalla.supply.section.titulo } icono={ confPantalla.supply.section.icono } col={ confPantalla.supply.section.col } >

                                <Kpi
                                    conf={ confPantalla.supply.controles.kpi }
                                    getDataTable1 = { confPantalla.supply.controles.datatable.getdata }
                                    saveDataTable1 = { saveTableAprov }                           
                                    saveLoadingTable = { saveLoadingTable }
                                    filtro={ confPantalla.supply.controles.tipdoc }
                                    idFiltro={ 0 }
                                    resetCalcsupply={ true }
                                    callBackLoaded={ resetTAbAprov }
                                    initLoading={ resetTAbAprov }
                                />                            
                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"supply"}
                                    columnas={confPantalla.supply.controles.datatable.columns}
                                    data={tableAprov}
                                    buttons={confPantalla.supply.controles.datatable.buttons}
                                    ordering={confPantalla.supply.controles.datatable.ordering}
                                    order={confPantalla.supply.controles.datatable.order}
                                    searching={confPantalla.supply.controles.datatable.searching}
                                    paging={confPantalla.supply.controles.datatable.paging}
                                    select={confPantalla.supply.controles.datatable.select}
                                    pagelength={confPantalla.supply.controles.datatable.pagelength}
                                    // saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={confPantalla.supply.controles.datatable.columnactions.title}
                                    linkColumns={confPantalla.supply.controles.datatable.linkColumns}
                                    traduction={confPantalla.config.trad.components.datatable}                                        
                                />
                                {
                                    tableAprov.length > 0
                                    ?
                                        <Fragment>
                                            <Button 
                                                clases={ "col-12 mt-2" }
                                                color={ "success" }  
                                                type="button" 
                                                icono={ "" }   
                                                id="btncalcaprov"     
                                                texto={ confPantalla.supply.controles.btncalcular.title }   
                                                accion={ handleSearchStock } 
                                                disabled={ false }
                                            />                            
                                            <p className="font-italic text-center">{ confPantalla.supply.controles.texthelpcalcular.title }</p>
                                        </Fragment>
                                    :
                                        null
                                }
                                {   loadingCalculate ? 
                                        <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                            <Spinner animation="border" />
                                        </div>
                                    :
                                    null
                                }
                                {
                                    calculateData != null && primeraCarga == false
                                    ?
                                        <Fragment>
                                            <Tabs defaultActiveKey="tab1" id="tab1">
                                                <Tab eventKey="tab1" title={ confPantalla.supply.tabs.tab1.titulo }>
                                                    {
                                                        verAprov                                                         
                                                        ?
                                                            <MiDataTableSupply 
                                                                estadoForm= { "" }
                                                                formulario={ {id:0} }
                                                                id={"calcsupply"}
                                                                columnas={confPantalla.supply.controles.datatableres.columns}
                                                                data={calculateData}
                                                                saveData={saveCalculateData}
                                                                buttons={confPantalla.supply.controles.datatableres.buttons}
                                                                ordering={confPantalla.supply.controles.datatableres.ordering}
                                                                order={confPantalla.supply.controles.datatableres.order}
                                                                searching={confPantalla.supply.controles.datatableres.searching}
                                                                paging={confPantalla.supply.controles.datatableres.paging}
                                                                select={confPantalla.supply.controles.datatableres.select}
                                                                pagelength={confPantalla.supply.controles.datatableres.pagelength}
                                                                saveLoadingTable={ saveLoadingCalculate }
                                                                loadingTable={loadingCalculate}
                                                                columnactions={confPantalla.supply.controles.datatableres.columnactions.title}
                                                                linkColumns={confPantalla.supply.controles.datatableres.linkColumns}
                                                                traduction={confPantalla.config.trad.components.datatable}
                                                                functionClickTd={verOrigenes}                                        
                                                            />                                           
                                                        :
                                                            null
                                                    }
                                                </Tab>
                                                <Tab eventKey="tab" title={ confPantalla.supply.tabs.tab2.titulo }>
                                                    {
                                                        docAprov == null
                                                        ?
                                                            <div className='text-center mt-4 mb-4'>
                                                                <i className="fal fa-empty-set fa-6x" style={{color: "#dc3545"}}></i>                                                                
                                                            </div>
                                                        :
                                                            <Fragment>
                                                                
                                                                <NavLink to={getPantDocFromNivel(getDecodedCookie('nivel'))+"/"+docAprov[0]["iddocaprov"]}>
                                                                    <hr data-content={docAprov[0]["descripcion"]+" - "+docAprov[0]["fecini"]+" - "+docAprov[0]["esttab"]  }
                                                                        className="hr-titleinfwhite">                                                                    
                                                                    </hr>
                                                                </NavLink>
                                                                <MiDataTable 
                                                                    estadoForm= { "" }
                                                                    formulario={ {id:0} }
                                                                    id={"docaprov"}
                                                                    columnas={confPantalla.supply.controles.datatableaprov.columns}
                                                                    data={docAprov}
                                                                    buttons={confPantalla.supply.controles.datatableaprov.buttons}
                                                                    ordering={confPantalla.supply.controles.datatableaprov.ordering}
                                                                    order={confPantalla.supply.controles.datatableaprov.order}
                                                                    searching={confPantalla.supply.controles.datatableaprov.searching}
                                                                    paging={confPantalla.supply.controles.datatableaprov.paging}
                                                                    select={confPantalla.supply.controles.datatableaprov.select}
                                                                    pagelength={confPantalla.supply.controles.datatableaprov.pagelength}
                                                                    // saveLoadingTable={ saveLoadingCalculate }
                                                                    // loadingTable={loadingCalculate}
                                                                    columnactions={confPantalla.supply.controles.datatableaprov.columnactions.title}
                                                                    linkColumns={confPantalla.supply.controles.datatableaprov.linkColumns}
                                                                    traduction={confPantalla.config.trad.components.datatable}                                        
                                                                />
                                                            </Fragment>
                                                    }

                                                </Tab>
                                            </Tabs>
                                            <button 
                                                type="button" 
                                                onClick={ solicitarMateriales } 
                                                className="btn btn-danger col-12 mt-2 mb-4" 
                                                disabled={ calculateData.length == 0 || !calculateData || solicitandoMat || loadingCalculate? true : false } 
                                            >
                                                {
                                                    solicitandoMat 
                                                    ?                                                         
                                                        txtBtnSolicitando 
                                                    :
                                                        confPantalla.supply.controles.btnsolicitud.title 
                                                }                                                
                                                {   
                                                    solicitandoMat
                                                    ? 
                                                    <Spinner as="span" style={{marginLeft: "10px"}} animation="border" size="sm" />
                                                    :
                                                    null
                                                }      
                                            </button>
                                        </Fragment>
                                    :
                                        null
                                }
                                <Modal
                                    show={showModal}
                                    onHide={ () => saveShowModal(false)}                            
                                    aria-labelledby="example-custom-modal-styling-title"
                                    centered={true}                    
                                    dialogClassName="modal-90w"                                             
                                > 
                                    <Modal.Header closeButton>
                                    </Modal.Header>                         
                                    <Modal.Body>
                                        <MiDataTable 
                                            estadoForm= { "" }
                                            formulario={ {id:0} }
                                            id={"docsori"}
                                            columnas={confPantalla.supply.controles.datatabledocsaprov.columns}
                                            data={docsori}
                                            buttons={confPantalla.supply.controles.datatabledocsaprov.buttons}
                                            ordering={confPantalla.supply.controles.datatabledocsaprov.ordering}
                                            order={confPantalla.supply.controles.datatabledocsaprov.order}
                                            searching={confPantalla.supply.controles.datatabledocsaprov.searching}
                                            paging={confPantalla.supply.controles.datatabledocsaprov.paging}
                                            select={confPantalla.supply.controles.datatabledocsaprov.select}
                                            pagelength={confPantalla.supply.controles.datatabledocsaprov.pagelength}
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.supply.controles.datatabledocsaprov.columnactions.title}
                                            linkColumns={confPantalla.supply.controles.datatabledocsaprov.linkColumns}
                                            traduction={confPantalla.config.trad.components.datatable}                                        
                                        />
                                    </Modal.Body>                            
                                </Modal>    
                            </Section>
                            
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>                                    
        </div>
        <Footer />        
        </div>

    );

}
export default Supply