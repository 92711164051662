import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';


function MiModal(props){

    const {titulo, actionhide, centered, btnclosetext, iconoBtnDanger, footer} = props;


    return(
                <Modal
                    show={true}
                    onHide={() => actionhide(false)}
                    // dialogClassName={"modal-width"}
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={centered}                    
                >
                    {
                        titulo != ""
                        ?
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title" >
                                {titulo}                            
                            </Modal.Title>
                        </Modal.Header>
                        :
                        null
                    }
                    <Modal.Body>
                        { props.children }                       
                    </Modal.Body>
                    {
                        footer != false
                        ?
                            <Modal.Footer>
                                <button className="btn btn-danger" onClick={() => actionhide(false)}>
                                    {btnclosetext}
                                    {
                                    iconoBtnDanger != null && iconoBtnDanger != ""
                                    ?
                                        <i class={iconoBtnDanger}></i>
                                    :
                                        null 
                                    }
                                    </button>
                            </Modal.Footer>
                        :
                                null
                    }
                </Modal>
    )
    
}

export default MiModal;