import React, { useState, useEffect, useRef, useContext } from 'react';
import ReactDOM from 'react-dom'
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from '../ui/Button';
import MiSelect from './MiSelect';
import FormInput from './FormInput';
import { Spinner } from 'react-bootstrap';
import { Fragment } from 'react';
import CheckIos from '../../componentes/ui/CheckIos';

import { axiosPost, getApiUrl, MiReplaceAll, getDecodedCookie } from '../../helpers/helperApp';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

function Firma(props){

    const { cabecera, controles, show, saveShow, traduction, functionAfter, crewList, numFirmasProp, rangoAgentesProp, dataAgentes, defaultFirmante } = props;
    let { lineasFirmas } = props;

    // Ponemos al final las firmas infinitas ya que si salen antes de las de cantidad fija no saldrían nunca, ya que las infinitas al pulsar el botón terminar para parar de firmar, la aplicación nos redirige al inicio.

    if(crewList != true){
        const sortByNum1 = (arr) => {                  
            return arr.sort((a, b) => b.linea.num1 - a.linea.num1);    
        };    
        lineasFirmas = sortByNum1(lineasFirmas);
    }

    const sigCanvas = useRef({});

    const [ showModal, saveShowModal ] = useState(show);
    const [ agente, saveAgente ] = useState(defaultFirmante ? defaultFirmante : "");
    const [ textoLibre, saveTextoLibre ] = useState("");
    const [ numFirmaActual, saveNumFirmaActual ] = useState(1);
    const [ errorAgente, saveErrorAgente ] = useState("");
    const [ errorTexto, saveErrorTexto ] = useState("");
    const [ firmando, saveFirmando ] = useState(false); 
    const [ agentesFirmaron, saveAgentesFirmaron ] = useState([]);
    const [ indexLineaActual, saveIndexLineaActual ] = useState(0);
    const [ lineaActual, saveLineaActual ] = useState(!crewList ? lineasFirmas[indexLineaActual].linea : lineasFirmas[indexLineaActual]);
    const [ numFirmas, saveNumfirmas ] = useState(!numFirmasProp ? lineasFirmas[indexLineaActual].linea.num1 : numFirmasProp);
    const [ rangoAgentes, saveRangoAgentes ] = useState(!rangoAgentesProp ? lineasFirmas[indexLineaActual].respuesta.data[0].val.split(";")[1] : rangoAgentesProp);
    const [ firmaSinAgente, saveFirmaSinAgente ] = useState(false)
    
    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { apiKey, menuMode } = useContext(LoginContext);

    useEffect( () => {            
                    
        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 
    },[])


    const nextFirma = async () => {
        let resetNumfirma = false;
       
        if(sigCanvas.current.isEmpty()){
            // Firma vacía
            saveErrorTexto(controles.firma.error.emptyfirma.title);
            return;
        }
        if(rangoAgentes == "0"){
            if(textoLibre == ""){
                // Texto libre vacio
                saveErrorTexto(controles.firma.error.emptytexto.title);
                return;
            }
        }
        if(rangoAgentes != "0" && !firmaSinAgente){
            if(agente == ""){
                // Agente vacio
                saveErrorAgente(controles.firma.error.emptyagente.title);
                return;
            }
        }
        saveErrorTexto("");
        saveErrorAgente("");

        // Almacenamos los agentes que ya firmaron para deshabilitarlos del combo
        if(agente != ""){
            saveAgentesFirmaron(agentesFirmaron => [...agentesFirmaron, agente]);
        }

        // LLamada para enviar la firma
        saveFirmando(true);
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("agente", firmaSinAgente || rangoAgentes == "0" ? 0 : agente);
        data.append("textolibre", !crewList ? textoLibre : controles.firma.txthelp.title);
        data.append("latitud", "");
        data.append("longitud", "");
        data.append("iddoc", cabecera.id);
        data.append("tabrel", controles.firma.tabrel);        
        data.append("firma", dataURLtoFile(sigCanvas.current.toDataURL(), 'signature.png'));
        data.append("idlineafirma", !crewList ? lineaActual.id : lineaActual.idlinea);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+controles.firma.savefirma, data, source.token);        
      
        if(typeof respuesta.data == "string"){
            // Si error, mostrar mensaje y que vuelva a probar
            saveErrorTexto(controles.firma.error.errorfirmando.title);
            saveFirmando(false);
        }else{
            // Si correcto             
            if( numFirmaActual == numFirmas ){
                if(indexLineaActual == lineasFirmas.length-1 || numFirmaActual == numFirmasProp){
                    //  Ultima firma. Cerrar y continuar
                    saveShowModal(false);
                    saveShow(false);
                    if(functionAfter){
                        functionAfter();  
                    }
                    return;
                }
                // Siguiente linea de firmas
                saveNumfirmas(!numFirmasProp ? lineasFirmas[indexLineaActual+1].linea.num1: numFirmasProp );
                saveRangoAgentes(!rangoAgentesProp ? lineasFirmas[indexLineaActual+1].respuesta.data[0].val.split(";")[1] : rangoAgentesProp);
                saveIndexLineaActual(indexLineaActual + 1);
                saveLineaActual(!crewList ? lineasFirmas[indexLineaActual+1].linea : lineasFirmas[indexLineaActual+1]);
                saveAgentesFirmaron([]);               
                resetNumfirma = true;
            }else{
                // Aun continuamos con las firmas
            }
            resetNumfirma ? saveNumFirmaActual(1): saveNumFirmaActual(numFirmaActual + 1);
            saveTextoLibre("");
            saveAgente("");
            saveFirmando(false);
            sigCanvas.current.clear();
        }
        saveFirmaSinAgente(false);
    }

    // Función para convertir datos base64 a objeto File
    const dataURLtoFile = (dataURL, fileName) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    };
    
    const finFirma = () => {
        saveShowModal(false);
        saveShow(false);
        if(functionAfter){
            functionAfter();  
        }
    };
    
    const closeModal = () => {
        saveErrorTexto("");
        saveErrorAgente("");
        saveShowModal(false);
        saveShow(false);
    }
    
    const limpiarFirma = () =>{
        saveErrorTexto("");
        saveErrorAgente("");
        sigCanvas.current.clear();
    }

    const handleSelectAgente = (selectedItem, nameOfComponent) => {  
        
        if( selectedItem ){
            saveErrorAgente("");
            saveAgente(selectedItem.value);
            // if(textoLibre == ""){
            //     saveTextoLibre(selectedItem.label)
            // }
        }else{
            saveErrorAgente(controles.firma.error.emptyagente.title);
            saveAgente("");
        }
    }

    const handleInputTexto = (e) => {
        const { name, value } = e.target;
        saveTextoLibre(value)       
        if(value != ""){
            saveErrorTexto("");
        }else if(value == ""){
            if(rangoAgentes == "0"){
                saveErrorTexto(controles.firma.error.emptytexto.title);
            }
        }
    }

    const handleCheck = (e) => {        
        if( e.target.checked ){
            saveAgente("");
            saveErrorAgente("");            
            saveFirmaSinAgente(true);
        }else{
            saveFirmaSinAgente(false);
        }
    }


    return(
        <Modal
            show={showModal}
            // onHide={ () => closeModal()} // Al estar comentada esta línea evita que se cierre el modal al hacer click fuera                        
            aria-labelledby="example-custom-modal-styling-title"                                    
            dialogClassName="modalSizeSign"                                             
        >
            <Modal.Body>
                {
                    !crewList
                    ?
                        <p className='text-center font-weight-bold'>{getDecodedCookie('idioma').toLowerCase() == "es".toLowerCase() ? lineaActual.nom1 : lineaActual.nom1_en }</p>
                    :
                        <p className='text-center font-weight-bold'>{controles.firma.txthelp.title}</p>
                }
                <div className='col-12 ' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {
                        rangoAgentes > "0" && !firmando && !crewList
                        ? 
                            <CheckIos
                                val= {firmaSinAgente}
                                name={null}
                                label={ traduction.checkios.label }
                                id={"firmaSinAgente"}
                                col={" mb-0 mt--15px"}
                                disabled={ false } 
                                change={ handleCheck }
                            />
                        :
                            <div className='form-group col-md- mb-0 mt--15px'></div>                            
                    }
                    <p className='text-right mb-0'>
                        {controles.firma.txtnumfirmas.title}
                        {
                            numFirmas == 0
                            ?                                
                                <span>{numFirmaActual}/<i className='fal fa-infinity'></i></span>
                            :
                                <span>{numFirmaActual}/{numFirmas}</span>
                        } 
                    </p>                                                                
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>                    
                    {
                        dataAgentes
                        ?
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { agente }   
                                loading=     { false }
                                id=          { controles.firma.idage.id }    
                                name=        { controles.firma.idage.name }   
                                label=       { controles.firma.idage.label }    
                                placeholder= { controles.firma.idage.placeholder }
                                col=         { controles.firma.idage.col } 
                                isClearable= { controles.firma.idage.isclearable }
                                isSearchable={ controles.firma.idage.issearchable }
                                dataType=    { dataAgentes ? "codigo" : controles.firma.idage.datatype }
                                data=        { dataAgentes }
                                required=    { controles.firma.idage.required } 
                                disabled=  { controles.firma.idage.disabled }
                                readOnly =   { firmaSinAgente ? "readonly" : controles.firma.idage.readonly }
                                disablethisid={ controles.firma.idage.disablethisid}
                                disableidsarray={ agentesFirmaron }
                                disableids={ controles.firma.idage.disableids}
                                formulario= { lineaActual }   
                                change= { handleSelectAgente }
                            />
                        :
                        rangoAgentes == "1" && !firmando
                        ?
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { agente }   
                                loading=     { false }
                                id=          { controles.firma.idage.id }    
                                name=        { controles.firma.idage.name }   
                                label=       { controles.firma.idage.label }    
                                placeholder= { controles.firma.idage.placeholder }
                                col=         { controles.firma.idage.col } 
                                isClearable= { controles.firma.idage.isclearable }
                                isSearchable={ controles.firma.idage.issearchable }
                                dataType=    { controles.firma.idage.datatype }
                                data=        { MiReplaceAll(controles.firma.idage.data, "reg_actual", lineaActual.id) }
                                required=    { controles.firma.idage.required } 
                                disabled=  { controles.firma.idage.disabled }
                                readOnly =   { firmaSinAgente ? "readonly" : controles.firma.idage.readonly }
                                disablethisid={ controles.firma.idage.disablethisid}
                                disableidsarray={ agentesFirmaron }
                                disableids={ controles.firma.idage.disableids}
                                formulario= { lineaActual }   
                                change= { handleSelectAgente }
                            />
                        :
                        rangoAgentes == "2" && !firmando
                        ?
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { agente }   
                                loading=     { false }
                                id=          { controles.firma.idage.id }    
                                name=        { controles.firma.idage.name }   
                                label=       { controles.firma.idage.label }    
                                placeholder= { controles.firma.idage.placeholder }
                                col=         { controles.firma.idage.col } 
                                isClearable= { controles.firma.idage.isclearable }
                                isSearchable={ controles.firma.idage.issearchable }
                                dataType=    { controles.firma.idage.datatype }
                                data=        { MiReplaceAll(controles.firma.idage.data2, "reg_actual", lineaActual.id) }
                                required=    { controles.firma.idage.required } 
                                disabled=  { controles.firma.idage.disabled }
                                readOnly =   { firmaSinAgente ? "readonly" : controles.firma.idage.readonly }
                                disablethisid={ controles.firma.idage.disablethisid}
                                disableidsarray={ agentesFirmaron }
                                disableids={ controles.firma.idage.disableids}
                                formulario= { lineaActual }   
                                change= { handleSelectAgente }
                            />
                        :
                        rangoAgentes == "3" && !firmando
                        ?
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { agente }   
                                loading=     { false }
                                id=          { controles.firma.idage.id }    
                                name=        { controles.firma.idage.name }   
                                label=       { controles.firma.idage.label }    
                                placeholder= { controles.firma.idage.placeholder }
                                col=         { controles.firma.idage.col } 
                                isClearable= { controles.firma.idage.isclearable }
                                isSearchable={ controles.firma.idage.issearchable }
                                dataType=    { controles.firma.idage.datatype }
                                data=        { MiReplaceAll(controles.firma.idage.data3, "reg_actual", lineaActual.id) }
                                required=    { controles.firma.idage.required } 
                                disabled=  { controles.firma.idage.disabled }
                                readOnly =   { firmaSinAgente ? "readonly" : controles.firma.idage.readonly }
                                disablethisid={ controles.firma.idage.disablethisid}
                                disableidsarray={ agentesFirmaron }
                                disableids={ controles.firma.idage.disableids}
                                formulario= { lineaActual }   
                                change= { handleSelectAgente }
                            />
                        :
                        rangoAgentes == "4" && !firmando
                        ?
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { agente }   
                                loading=     { false }
                                id=          { controles.firma.idage.id }    
                                name=        { controles.firma.idage.name }   
                                label=       { controles.firma.idage.label }    
                                placeholder= { controles.firma.idage.placeholder }
                                col=         { controles.firma.idage.col } 
                                isClearable= { controles.firma.idage.isclearable }
                                isSearchable={ controles.firma.idage.issearchable }
                                dataType=    { controles.firma.idage.datatype }
                                data=        { MiReplaceAll(controles.firma.idage.data4, "reg_actual", lineaActual.id) }
                                required=    { controles.firma.idage.required } 
                                disabled=  { controles.firma.idage.disabled }
                                readOnly =   { firmaSinAgente ? "readonly" : controles.firma.idage.readonly }
                                disablethisid={ controles.firma.idage.disablethisid}
                                disableidsarray={ agentesFirmaron }
                                disableids={ controles.firma.idage.disableids}
                                formulario= { lineaActual }   
                                change= { handleSelectAgente }
                            />
                        :
                            null
                    }                  
                    {
                        errorAgente != ""
                        ?
                        <div className="alert alert-danger" role="alert" style={{marginTop: "-15px"}}>
                            {errorAgente}
                        </div>
                        :
                            null
                    }                    
                    {
                        !firmando && !crewList
                        ?
                            <FormInput                                                        
                                estadoForm= { "" }
                                value=      { textoLibre }
                                loading=    { false }
                                id=         { controles.firma.txtlibre.id }   
                                type=       { controles.firma.txtlibre.type }  
                                name=       { controles.firma.txtlibre.name }   
                                label=      { controles.firma.txtlibre.label }                                                       
                                placeholder={ controles.firma.txtlibre.placeholder }    
                                col=        { controles.firma.txtlibre.col } 
                                required=   { controles.firma.txtlibre.required }
                                readonly=   { controles.firma.txtlibre.readonly }                                                
                                disabled=   { controles.firma.txtlibre.disabled }
                                change=     { handleInputTexto }
                                onBlur=     {null} 
                            />
                        :
                            null
                    }                    
                     {
                        errorTexto != ""
                        ?
                        <div className="alert alert-danger" role="alert" style={{marginTop: "-15px"}}>
                            {errorTexto}
                        </div>
                        :
                            null
                    }                                        
                    {
                        firmando ?
                            <div style={{ display:"flex", justifyContent:"center", alignItems:"center", flexDirection: "column", height:450, width:450, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                <Spinner animation="border" style={{color: "#28a745"}} />
                                <h5 style={{color: "#28a745"}}>{controles.firma.txtfirmando.title}</h5>
                            </div>
                        :
                            <Fragment>
                                <SignatureCanvas
                                    ref={sigCanvas}                         
                                    // backgroundColor = "rgba(0,0,0,.03)"
                                    penColor = "rgb(25,78,146)"
                                    backgroundColor = "rgb(255,255,255)"                                     
                                    canvasProps={{width: "500", height: "500", className: "canvaFirma"}}                        
                                />
                                <div className='col-12 mt-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>                                    
                                    {
                                        numFirmas == 0
                                        ?
                                        <Button 
                                            clases={ "col-3 rounded" }
                                            color={ "danger" }  
                                            type="button" 
                                            icono={ "" }   
                                            id="btnfin"     
                                            texto={ traduction.btnfin.title }   
                                            accion={ finFirma } 
                                            disabled={ false }
                                        />
                                        :
                                            null    
                                    }
                                    <Button 
                                        clases={ "col-3 rounded" }
                                        color={ "warning" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btnlimpiar"     
                                        texto={ traduction.btnlimpiar.title }   
                                        accion={ limpiarFirma } 
                                        disabled={ false }
                                    />
                                    <Button 
                                        clases={ "col-3 rounded" }
                                        color={ "success" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btnaceptar"     
                                        texto={ traduction.btnaceptar.title }   
                                        accion={ nextFirma } 
                                        disabled={ false }
                                    />
                                </div>
                            </Fragment>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Firma;