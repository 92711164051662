import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import DatePicker from 'rsuite/DatePicker';
import 'rsuite/DatePicker/styles/index.css';

import { LoginContext } from '../../hooks/context/LoginContext';


import { getAhorayyyymmdd } from '../../helpers/helperApp';
import { getAhoraddmmyyyy } from '../../helpers/helperApp';

import { getApiUrl, axiosPost, getCookieOptions, bootStrapAlertClassToColor, setEncodedCookie, getDecodedCookie, getDatePickerDaysName } from '../../helpers/helperApp';

import MiSelect from '../formulario/MiSelect';
import FormRow from '../formulario/FormRow';
import FormInput from '../formulario/FormInput';
import { alertService } from '../../services/alertservice';


function Kpi({conf, getDataTable1, saveDataTable1, saveCalendar1, getDataTable2, saveDataTable2, saveCalendar2, getDataTable3, saveDataTable3, saveCalendar3, getDataTable4, saveDataTable4, saveCalendar4, saveLoadingTable, filtro, fecIni, fecFin, idFiltro, saveIdFiltro, forceReload, idParam1, saveIdParam1, idParam2, saveIdParam2, idParam3, saveIdParam3, idParam4, saveIdParam4, noDisplay, callBackLoaded, initLoading,  customValueAction, accionesLinkKpi, readOnlyFiltro, respSimp }){

    const cookies = new Cookies();
    // Token cancelación axios
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();

    const { apiKey, confApp, reportWorkStatus } = useContext(LoginContext);

    const [ value, setValue ] = useState([]);
    const [ loading, saveLoading] = useState(false);
    const [ valSelect, saveValSelect ] = useState();
    const [ valSelectText, saveValSelectText ] = useState();
    const [ valInicio, saveValInicio ] = useState(fecIni != undefined ? fecIni : getAhorayyyymmdd );
    const [ valFin, saveValFin ] = useState(fecFin != undefined ? fecFin : getAhorayyyymmdd); 
    const [ valSelectFiltro, saveValSelectFiltro ] = useState(idFiltro);
    const [ noDisplayState, saveNoDisplayState ] = useState(noDisplay);


    useEffect( () => {                

        if( getDecodedCookie('intervalo') ){
            if( getDecodedCookie('intervalo') != "" ){                
                saveValSelect(getDecodedCookie('intervalo'));
                loadDataKpis(getDecodedCookie('intervalo'), idFiltro);
            }
        }else{
            if( getDecodedCookie('nivel') == 4 ){
                saveValSelect("3");
                loadDataKpis("3", idFiltro);
            }else{
                saveValSelect("3");
                loadDataKpis("3", idFiltro);
            }
        }

        return () => {
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
            source3.cancel();
            source4.cancel();
        };

    },[forceReload])

    const handleSelectFiltro = async (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            saveValSelectFiltro( selectedItem.value );
            loadDataKpis(valSelect, selectedItem.value);
            if( saveIdFiltro ){
                saveIdFiltro(selectedItem.value);
            }
        }else{
            saveValSelectFiltro( "" ); 
            if( saveIdFiltro ){
                saveIdFiltro("");
            }
        }
    }

    const handleSelect = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            setEncodedCookie('intervalo', selectedItem.value, getCookieOptions()); 
            saveValSelect( selectedItem.value );
            loadDataKpis(selectedItem.value, valSelectFiltro);
        }else{
            setEncodedCookie('intervalo', "", getCookieOptions()); 
            saveValSelect("");
            saveValInicio("");
            saveValFin("");  
        }
    }

    
    const handleFecha = (val, event, save, id) => {            
        let value;                               
        if(val == null){
            value = '';                
        }else{                                                                   
            const anho = val.getFullYear()
            const mes = val.getMonth()+1 < 10 ? '0'+(val.getMonth()+1) : val.getMonth()+1;
            const dia = val.getDate() < 10 ? '0'+val.getDate() : val.getDate();
            const horas = val.getHours() < 10 ? '0' + val.getHours() : val.getHours(); 
            const minutos = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
            value = `${anho}-${mes}-${dia}T${horas}:${minutos}`;
            
            if( id  == "kpiIni" ){
                setEncodedCookie('kpiIni', `${anho}-${mes}-${dia}`, getCookieOptions()); 
            }
            if( id  == "kpiFin" ){
                setEncodedCookie('kpiFin', `${anho}-${mes}-${dia}`, getCookieOptions()); 
            }
            save(value);       
        }

        setEncodedCookie('intervalo', "12", getCookieOptions());

        if(id == "kpiIni" && value <= valFin) handleSearch(value, valFin); 
        if(id == "kpiFin" && valInicio <= value) handleSearch(valInicio, value);
        
    }

    const handleSearch = async (paramIni=null, paramFin=null) => {        
        let auxInicio;
        let auxFin;
        paramIni == null ? auxInicio = valInicio : auxInicio = paramIni;
        paramFin == null ? auxFin = valFin : auxFin = paramFin;

        if(auxInicio != "" && auxFin != ""){
            if( auxInicio <= auxFin ){
                setEncodedCookie('kpiIni', auxInicio, getCookieOptions()); 
                setEncodedCookie('kpiFin', auxFin, getCookieOptions()); 
                if( auxInicio == "0" && auxFin == "1" ){
                    loadDataKpis("13", valSelectFiltro);    
                }else{
                    loadDataKpis("12", valSelectFiltro);
                }
            }
        }
    }
 
    const loadDataKpis = async (sel, idFil=null) =>{        
        if( initLoading != null ){
            initLoading();
        }

        let fechas = [];

        saveLoading(true);
        saveLoadingTable(true);
        // Comprobamos los valores del select para ver si hacemos la llamada
        if( sel != "" ){

            fechas = getFechasFromSelect(sel);
            saveValInicio(fechas["ini"]);
            saveValFin(fechas["fin"]);
            setEncodedCookie('kpiIni', fechas["ini"], getCookieOptions()); 
            setEncodedCookie('kpiFin', fechas["fin"], getCookieOptions()); 
           
        }
        // Obtener los datos de los kpis si le pasamos una url para obtenerlos. Sino la pasamos ni los muestra ni carga los datos.
        if( conf.getdata && conf.getdata != "" && ( fechas["ini"] <= fechas["fin"] ) && !customValueAction ){
            const data = new FormData();
            data.append("validacion", apiKey());
            if( fechas["ini"] != "0" && fechas["fin"] != "1" ){
                data.append("ini", fechas["ini"]+"T00:00")
                data.append("fin", fechas["fin"]+"T00:00")
            }else{
                data.append("ini", fechas["ini"])
                data.append("fin", fechas["fin"])
            }           
            if( filtro ){
                data.append("idFiltro", idFil)
            }
            // const respuesta = await axios.post(getApiUrl(window.location.origin)+conf.getdata, data);            
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+conf.getdata, data, source.token);

            setValue(respuesta.data);
        }
        
        // Cargamos los datos del table1        
        if( getDataTable1 && ( fechas["ini"] <= fechas["fin"] ) ){
            const dataTable1 = new FormData();
            dataTable1.append("validacion", apiKey());
            if( idParam1 ){
                dataTable1.append("idparam", idParam1)
                saveIdParam1(null);
                saveValSelect("");
                fechas["ini"] =  "0";
                fechas["fin"] = "1";
                saveValInicio(0);
                saveValFin(1);
                saveValSelectFiltro(null); 
            }
            if( fechas["ini"] != "0" && fechas["fin"] != "1" ){
                dataTable1.append("ini", fechas["ini"]+"T00:00")
                dataTable1.append("fin", fechas["fin"]+"T00:00")
            }else{
                dataTable1.append("ini", fechas["ini"])
                dataTable1.append("fin", fechas["fin"])
            }
            if( filtro ){
                dataTable1.append("idFiltro", idFil)
            }
            // const respuestaTable1 = await axios.post(getApiUrl(window.location.origin)+getDataTable1, dataTable1);
            const respuestaTable1 = await axiosPost(getApiUrl(window.location.origin)+getDataTable1, dataTable1, source2.token);

            saveDataTable1(respuestaTable1.data);
            // saveLoadingTable(false);

            // Si tiene calendario guardamos los datos
            if( saveCalendar1 && typeof respuestaTable1.data != "string" ){
                    let auxEventos = [];                    
                    respuestaTable1.data.map((evento, id, array) => {  
                        auxEventos.push(
                            {
                                id: id,
                                name: respuestaTable1.data[id].equipo+" - "+respuestaTable1.data[id].descripcion, 
                                startDate :  new Date(respuestaTable1.data[id].ini), 
                                endDate : new Date(respuestaTable1.data[id].fin),
                                color : bootStrapAlertClassToColor(respuestaTable1.data[id].style),
                                idDoc : respuestaTable1.data[id].id,
                                style : respuestaTable1.data[id].style,
                                id_ori : respuestaTable1.data[id].id_ori,
                                tip_ori : respuestaTable1.data[id].tip_ori,
                                id_tra : respuestaTable1.data[id].id_tra,
                                descripcion : respuestaTable1.data[id].descripcion,
                                id_responsable : respuestaTable1.data[id].id_responsable,
                                estado : respuestaTable1.data[id].estado,
                                recurrencia : respuestaTable1.data[id].recurrencia,
                                idest : respuestaTable1.data[id].idest
                            }
                        )
                    })
                saveCalendar1(auxEventos);
            }

        }
        
        // Cargamos los datos del table2
        if( getDataTable2 && ( fechas["ini"] <= fechas["fin"] ) ){
            const dataTable2 = new FormData();
            dataTable2.append("validacion", apiKey());
            if( idParam2 ){
                dataTable2.append("idparam", idParam2)
                saveIdParam2(null);
                saveValSelect("");
                fechas["ini"] =  "0";
                fechas["fin"] = "1";
                saveValInicio(0);
                saveValFin(1);
                saveValSelectFiltro(null); 
            }
            if( fechas["ini"] != "0" && fechas["fin"] != "1" ){
                dataTable2.append("ini", fechas["ini"]+"T00:00")
                dataTable2.append("fin", fechas["fin"]+"T00:00")
            }else{
                dataTable2.append("ini", fechas["ini"])
                dataTable2.append("fin", fechas["fin"])
            }
            if( filtro ){
                dataTable2.append("idFiltro", idFil)
            }
            // const respuestaTable2 = await axios.post(getApiUrl(window.location.origin)+getDataTable2, dataTable2);
            const respuestaTable2 = await axiosPost(getApiUrl(window.location.origin)+getDataTable2, dataTable2, source3.token);
            saveDataTable2(respuestaTable2.data);
            // saveLoadingTable(false);

            // Si tiene calendario guardamos los datos
            if( saveCalendar2 && typeof respuestaTable2.data != "string" ){
                    let auxEventos = [];                    
                    respuestaTable2.data.map((evento, id, array) => {  
                        auxEventos.push(
                            {
                                id: id,
                                name: respuestaTable2.data[id].equipo+" - "+respuestaTable2.data[id].descripcion, 
                                startDate :  new Date(respuestaTable2.data[id].ini), 
                                endDate : new Date(respuestaTable2.data[id].fin),
                                color : bootStrapAlertClassToColor(respuestaTable2.data[id].style),
                                idDoc : respuestaTable2.data[id].id,
                                style : respuestaTable2.data[id].style,
                                id_ori : respuestaTable2.data[id].id_ori,
                                tip_ori : respuestaTable2.data[id].tip_ori,
                                id_tra : respuestaTable2.data[id].id_tra,
                                descripcion : respuestaTable2.data[id].descripcion,
                                id_responsable : respuestaTable2.data[id].id_responsable,
                                estado : respuestaTable2.data[id].estado,
                                recurrencia : respuestaTable2.data[id].recurrencia,
                                idest : respuestaTable2.data[id].idest    
                            }
                        )
                    })
                saveCalendar2(auxEventos);
            }

        }
        
        // Cargamos los datos del table3
        if( getDataTable3 && ( fechas["ini"] <= fechas["fin"] ) ){
            const dataTable3 = new FormData();
            dataTable3.append("validacion", apiKey());
            if( idParam3 ){
                dataTable3.append("idparam", idParam3)
                saveIdParam3(null);
                saveValSelect("");
                fechas["ini"] =  "0";
                fechas["fin"] = "1";
                saveValInicio(0);
                saveValFin(1);
                saveValSelectFiltro(null); 
            }
            if( fechas["ini"] != "0" && fechas["fin"] != "1" ){
                dataTable3.append("ini", fechas["ini"]+"T00:00")
                dataTable3.append("fin", fechas["fin"]+"T00:00")
            }else{
                dataTable3.append("ini", fechas["ini"])
                dataTable3.append("fin", fechas["fin"])
            }
            if( filtro ){
                dataTable3.append("idFiltro", idFil)
            }
            // const respuestaTable3 = await axios.post(getApiUrl(window.location.origin)+getDataTable3, dataTable3);
            const respuestaTable3 = await axiosPost(getApiUrl(window.location.origin)+getDataTable3, dataTable3, source4.token);
            saveDataTable3(respuestaTable3.data);
            // saveLoadingTable(false);

            // Si tiene calendario guardamos los datos
            if( saveCalendar3 && typeof respuestaTable3.data != "string" ){
                    let auxEventos = [];
                    respuestaTable3.data.map((evento, id, array) => {
                        auxEventos.push(
                            {
                                id: id,
                                name: respuestaTable3.data[id].equipo+" - "+respuestaTable3.data[id].descripcion, 
                                startDate :  new Date(respuestaTable3.data[id].ini), 
                                endDate : new Date(respuestaTable3.data[id].fin),
                                color : bootStrapAlertClassToColor(respuestaTable3.data[id].style),  
                                idDoc : respuestaTable3.data[id].id,
                                style : respuestaTable3.data[id].style,
                                id_ori : respuestaTable3.data[id].id_ori,
                                tip_ori : respuestaTable3.data[id].tip_ori,
                                id_tra : respuestaTable3.data[id].id_tra,
                                descripcion : respuestaTable3.data[id].descripcion,
                                id_responsable : respuestaTable3.data[id].id_responsable,
                                estado : respuestaTable3.data[id].estado,
                                recurrencia : respuestaTable3.data[id].recurrencia,
                                idest : respuestaTable3.data[id].idest  
                            }
                        )
                    })
                saveCalendar3(auxEventos);
            }

        }
        // Cargamos los datos del table4
        if( getDataTable4 && ( fechas["ini"] <= fechas["fin"] ) ){
            const dataTable4 = new FormData();
            dataTable4.append("validacion", apiKey());
            if( idParam4 ){
                dataTable4.append("idparam", idParam4)
                saveIdParam4(null);
                saveValSelect("");
                fechas["ini"] =  "0";
                fechas["fin"] = "1";
                saveValInicio(0);
                saveValFin(1);
                saveValSelectFiltro(null); 
            }
            if( fechas["ini"] != "0" && fechas["fin"] != "1" ){
                dataTable4.append("ini", fechas["ini"]+"T00:00")
                dataTable4.append("fin", fechas["fin"]+"T00:00")
            }else{
                dataTable4.append("ini", fechas["ini"])
                dataTable4.append("fin", fechas["fin"])
            }
            if( filtro ){
                dataTable4.append("idFiltro", idFil)
            }
            
            const respuestaTable4 = await axiosPost(getApiUrl(window.location.origin)+getDataTable4, dataTable4, source4.token);
            saveDataTable4(respuestaTable4.data);
            // saveLoadingTable(false);

            // Si tiene calendario guardamos los datos
            if( saveCalendar4 && typeof respuestaTable4.data != "string" ){
                    let auxEventos = [];
                    respuestaTable4.data.map((evento, id, array) => {
                        auxEventos.push(
                            {
                                id: id,
                                name: respuestaTable4.data[id].equipo+" - "+respuestaTable4.data[id].descripcion, 
                                startDate :  new Date(respuestaTable4.data[id].ini), 
                                endDate : new Date(respuestaTable4.data[id].fin),
                                color : bootStrapAlertClassToColor(respuestaTable4.data[id].style),  
                                idDoc : respuestaTable4.data[id].id,
                                style : respuestaTable4.data[id].style,
                                id_ori : respuestaTable4.data[id].id_ori,
                                tip_ori : respuestaTable4.data[id].tip_ori,
                                id_tra : respuestaTable4.data[id].id_tra,
                                descripcion : respuestaTable4.data[id].descripcion,
                                id_responsable : respuestaTable4.data[id].id_responsable,
                                estado : respuestaTable4.data[id].estado,
                                recurrencia : respuestaTable4.data[id].recurrencia,
                                idest : respuestaTable4.data[id].idest  
                            }
                        )
                    })
                saveCalendar4(auxEventos);
            }

        }

        if( callBackLoaded != null ){
            callBackLoaded();
        }
        if( customValueAction ){
            customValueAction(setValue);
        }

        saveLoadingTable(false);
        saveLoading(false);
    }

    const getFechasFromSelect = (val) => {

        let fechas=[];
        let mes, dia;
        let date = new Date();
        let txtKpi = "";

        if( val != "" ){
            
            // Colocamos los label del select de la llamada en los kpi
            let option = conf.select.data.filter(option => {
                if( option.value == val ){
                    return option.label;
                }
            })
            if( option.length == 0 ){
                // Si en la cookie esta guardada la opción de todos porque venimos de prealertas y aquí no existe esa opción le ponemos la opción "hoy"
                val = "0";
                saveValSelect("0");
                txtKpi = conf.select.data[0].label;
            }else{                
                txtKpi = option[0].label;
            }
             
            switch(val) {
                case "0":
                            // Hoy
                            fechas["ini"] = getAhorayyyymmdd();
                            fechas["fin"] = getAhorayyyymmdd();
                            txtKpi = txtKpi+" - "+getAhoraddmmyyyy();
                            break;
                case "1":
                            // Ayer
                            date.setDate(date.getDate() - 1);
                            mes = date.getMonth()+1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                            dia = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
                            fechas["ini"] = date.getFullYear()+"-"+mes+"-"+dia; 
                            fechas["fin"] = date.getFullYear()+"-"+mes+"-"+dia;

                            txtKpi = txtKpi+" - "+dia+"/"+mes+"/"+date.getFullYear();
                            break;
                case "2":
                            // Mañana
                            date.setDate(date.getDate() + 1);
                            mes = date.getMonth()+1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                            dia = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
                            fechas["ini"] = date.getFullYear()+"-"+mes+"-"+dia; 
                            fechas["fin"] = date.getFullYear()+"-"+mes+"-"+dia;

                            txtKpi = txtKpi+" - "+dia+"/"+mes+"/"+date.getFullYear();
                            break;
                case "3":
                            // Esta semana
                            let first = date.getDate() - date.getDay()+1;
                            let last = first + 6;
                            
                            let firstDay = new Date(date.setDate(first));
                            let lastDay = new Date(date.setDate(last));

                            let mesFirst = firstDay.getMonth()+1 < 10 ? "0"+(firstDay.getMonth()+1) : firstDay.getMonth()+1;
                            let diaFirst = firstDay.getDate() < 10 ? "0"+firstDay.getDate() : firstDay.getDate();
                            let diaLast = lastDay.getDate() < 10 ? "0"+lastDay.getDate() : lastDay.getDate();
                            let mesLast;
                            if( diaFirst > diaLast ){
                                mesLast = lastDay.getMonth()+2 < 10 ? "0"+(lastDay.getMonth()+2) : lastDay.getMonth()+2;
                            }else{
                                mesLast = lastDay.getMonth()+1 < 10 ? "0"+(lastDay.getMonth()+1) : lastDay.getMonth()+1;
                            }


                            fechas["ini"] =  firstDay.getFullYear()+"-"+mesFirst+"-"+diaFirst;
                            fechas["fin"] = date.getFullYear()+"-"+mesLast+"-"+diaLast;

                            txtKpi = txtKpi+" - "+diaFirst+"/"+mesFirst+"/"+firstDay.getFullYear()+" - "+diaLast+"/"+mesLast+"/"+lastDay.getFullYear();
                            break;
                case "4":
                            // Semana pasada
                            // let lastWeekFirst = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (7+date.getDate()+1));
                            // let lastWeekLast = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (2+date.getDate()));

                            // let lastWeekMesFirst = lastWeekFirst.getMonth()+1 < 10 ? "0"+(lastWeekFirst.getMonth()+1) : lastWeekFirst.getMonth()+1;
                            // let lastWeekDiaFirst = lastWeekFirst.getDate() < 10 ? "0"+lastWeekFirst.getDate() : lastWeekFirst.getDate();
                            // let lastWeekDiaLast = lastWeekLast.getDate() < 10 ? "0"+lastWeekLast.getDate() : lastWeekLast.getDate();
                            // let lastWeekMesLast;
                            // if( lastWeekDiaFirst > lastWeekDiaLast ){
                            //     lastWeekMesLast = lastWeekLast.getMonth()+2 < 10 ? "0"+(lastWeekLast.getMonth()+2) : lastWeekLast.getMonth()+2;
                            // }else{
                            //     lastWeekMesLast = lastWeekLast.getMonth()+1 < 10 ? "0"+(lastWeekLast.getMonth()+1) : lastWeekLast.getMonth()+1;
                            // }

                            // fechas["ini"] =  lastWeekFirst.getFullYear()+"-"+lastWeekMesFirst+"-"+lastWeekDiaFirst;
                            // fechas["fin"] = date.getFullYear()+"-"+lastWeekMesLast+"-"+lastWeekDiaLast;
                            // txtKpi = txtKpi+" - "+lastWeekDiaFirst+"/"+lastWeekMesFirst+"/"+lastWeekFirst.getFullYear()+" - "+lastWeekDiaLast+"/"+lastWeekMesLast+"/"+lastWeekLast.getFullYear();
                            // let lastWeekfirst = date.getDate() - date.getDay()+1-7;
                            // let lastWeeklast = lastWeekfirst + 6;
                            
                            // let lastWeekfirstDay = new Date(date.setDate(lastWeekfirst));
                            // let lastWeeklastDay = new Date(date.setDate(lastWeeklast));

                            // let lastWeekmesFirst = lastWeekfirstDay.getMonth()+1 < 10 ? "0"+(lastWeekfirstDay.getMonth()+1) : lastWeekfirstDay.getMonth()+1;
                            // let lastWeekdiaFirst = lastWeekfirstDay.getDate() < 10 ? "0"+lastWeekfirstDay.getDate() : lastWeekfirstDay.getDate();
                            // let lastWeekdiaLast = lastWeeklastDay.getDate() < 10 ? "0"+lastWeeklastDay.getDate() : lastWeeklastDay.getDate();
                            // let lastWeekmesLast;
                            // if( lastWeekdiaFirst > lastWeekdiaLast ){
                            //     lastWeekmesLast = lastWeeklastDay.getMonth()+2 < 10 ? "0"+(lastWeeklastDay.getMonth()+2) : lastWeeklastDay.getMonth()+2;
                            // }else{
                            //     lastWeekmesLast = lastWeeklastDay.getMonth()+1 < 10 ? "0"+(lastWeeklastDay.getMonth()+1) : lastWeeklastDay.getMonth()+1;
                            // }

                            // Función para obtener el primer día de la semana pasada (lunes) en formato dd/mm/AAAA
                            function getFirstDayOfLastWeek(format=false) {
                                const today = new Date();
                                const firstDayOfThisWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1));
                                const firstDayOfLastWeek = new Date(firstDayOfThisWeek.setDate(firstDayOfThisWeek.getDate() - 7));
                                if(!format){
                                    return formatDate(firstDayOfLastWeek);
                                }else{
                                    return formatDateEn(firstDayOfLastWeek);
                                }
                            }
                            
                            // Función para obtener el último día de la semana pasada (domingo) en formato dd/mm/AAAA
                            function getLastDayOfLastWeek(format=false) {
                                const today = new Date();
                                const lastDayOfThisWeek = new Date(today.setDate(today.getDate() - today.getDay()));
                                const lastDayOfLastWeek = new Date(lastDayOfThisWeek.setDate(lastDayOfThisWeek.getDate()));                                
                                if(!format){
                                    return formatDate(lastDayOfLastWeek);
                                }else{
                                    return formatDateEn(lastDayOfLastWeek);
                                }
                            }
                            
                            // Función para formatear la fecha en formato dd/mm/AAAA
                            function formatDate(date) {
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                            }
                            // Función para formatear la fecha en formato AAAA-mm-dd
                            function formatDateEn(date) {
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${year}-${month}-${day}`;
                            }
                            
                            // Obtener las fechas del primer y último día de la semana pasada en formato dd/mm/AAAA
                            const firstDayOfLastWeek = getFirstDayOfLastWeek();
                            const lastDayOfLastWeek = getLastDayOfLastWeek();

                            fechas["ini"] = getFirstDayOfLastWeek(true);
                            fechas["fin"] = getLastDayOfLastWeek(true);

                            txtKpi = txtKpi+" - "+firstDayOfLastWeek+" - "+lastDayOfLastWeek;                            
                            break;
                case "5":                      
                            // Semana próxima
                            let nextWeekfirst = date.getDate() - date.getDay()+1+7;
                            let nextWeeklast = nextWeekfirst + 6;
                            
                            let nextWeekfirstDay = new Date(date.setDate(nextWeekfirst));
                            let nextWeeklastDay = new Date(date.setDate(nextWeeklast));
        
                            let nextWeekmesFirst = nextWeekfirstDay.getMonth()+1 < 10 ? "0"+(nextWeekfirstDay.getMonth()+1) : nextWeekfirstDay.getMonth()+1;
                            let nextWeekdiaFirst = nextWeekfirstDay.getDate() < 10 ? "0"+nextWeekfirstDay.getDate() : nextWeekfirstDay.getDate();
                            let nextWeekdiaLast = nextWeeklastDay.getDate() < 10 ? "0"+nextWeeklastDay.getDate() : nextWeeklastDay.getDate();
                            let nextWeekmesLast;
                            // if( nextWeekdiaFirst > nextWeekdiaLast ){
                            //     nextWeekmesLast = nextWeeklastDay.getMonth()+2 < 10 ? "0"+(nextWeeklastDay.getMonth()+2) : nextWeeklastDay.getMonth()+2;
                            // }else{
                                nextWeekmesLast = nextWeeklastDay.getMonth()+1 < 10 ? "0"+(nextWeeklastDay.getMonth()+1) : nextWeeklastDay.getMonth()+1;
                            // }
        
        
                            fechas["ini"] =  nextWeekfirstDay.getFullYear()+"-"+nextWeekmesFirst+"-"+nextWeekdiaFirst;
                            fechas["fin"] = date.getFullYear()+"-"+nextWeekmesLast+"-"+nextWeekdiaLast;
        
                            txtKpi = txtKpi+" - "+nextWeekdiaFirst+"/"+nextWeekmesFirst+"/"+nextWeekfirstDay.getFullYear()+" - "+nextWeekdiaLast+"/"+nextWeekmesLast+"/"+nextWeeklastDay.getFullYear();
                            break;
                case "6":
                            // Este mes
                            let firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                            let lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

                            let mesFirstMonth = firstDayMonth.getMonth()+1 < 10 ? "0"+(firstDayMonth.getMonth()+1) : firstDayMonth.getMonth()+1;
                            let diaFirstMonth = firstDayMonth.getDate() < 10 ? "0"+firstDayMonth.getDate() : firstDayMonth.getDate();
                            let diaLastMonth = lastDayMonth.getDate() < 10 ? "0"+lastDayMonth.getDate() : lastDayMonth.getDate();
                            let mesLastMonth;
                            if( diaFirstMonth > diaLastMonth ){
                                mesLastMonth = lastDayMonth.getMonth()+2 < 10 ? "0"+(lastDayMonth.getMonth()+2) : lastDayMonth.getMonth()+2;
                            }else{
                                mesLastMonth = lastDayMonth.getMonth()+1 < 10 ? "0"+(lastDayMonth.getMonth()+1) : lastDayMonth.getMonth()+1;
                            }


                            fechas["ini"] =  firstDayMonth.getFullYear()+"-"+mesFirstMonth+"-"+diaFirstMonth;
                            fechas["fin"] = date.getFullYear()+"-"+mesLastMonth+"-"+diaLastMonth;

                            txtKpi = txtKpi+" - "+diaFirstMonth+"/"+mesFirstMonth+"/"+firstDayMonth.getFullYear()+" - "+diaLastMonth+"/"+mesLastMonth+"/"+lastDayMonth.getFullYear();
                            break;
                case "7":
                            // Mes pasado
                            let firstDayLastMonth = new Date(date.getFullYear(), date.getMonth()-1, 1);
                            let lastDayLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);

                            let mesFirstLastMonth = firstDayLastMonth.getMonth()+1 < 10 ? "0"+(firstDayLastMonth.getMonth()+1) : firstDayLastMonth.getMonth()+1;
                            let diaFirstLastMonth = firstDayLastMonth.getDate() < 10 ? "0"+firstDayLastMonth.getDate() : firstDayLastMonth.getDate();
                            let diaLastLastMonth = lastDayLastMonth.getDate() < 10 ? "0"+lastDayLastMonth.getDate() : lastDayLastMonth.getDate();
                            let mesLastLastMonth;
                            if( diaFirstLastMonth > diaLastLastMonth ){
                                mesLastLastMonth = lastDayLastMonth.getMonth()+2 < 10 ? "0"+(lastDayLastMonth.getMonth()+2) : lastDayLastMonth.getMonth()+2;
                            }else{
                                mesLastLastMonth = lastDayLastMonth.getMonth()+1 < 10 ? "0"+(lastDayLastMonth.getMonth()+1) : lastDayLastMonth.getMonth()+1;
                            }


                            fechas["ini"] =  firstDayLastMonth.getFullYear()+"-"+mesFirstLastMonth+"-"+diaFirstLastMonth;
                            fechas["fin"] = date.getFullYear()+"-"+mesLastLastMonth+"-"+diaLastLastMonth;

                            txtKpi = txtKpi+" - "+diaFirstLastMonth+"/"+mesFirstLastMonth+"/"+firstDayLastMonth.getFullYear()+" - "+diaLastLastMonth+"/"+mesLastLastMonth+"/"+lastDayLastMonth.getFullYear();
                            break;
                case "8":
                            // Mes próximo
                            let firstDayNextMonth = new Date(date.getFullYear(), date.getMonth()+1, 1);
                            let lastDayNextMonth = new Date(date.getFullYear(), date.getMonth()+2, 0);
        
                            let mesFirstNextMonth = firstDayNextMonth.getMonth()+1 < 10 ? "0"+(firstDayNextMonth.getMonth()+1) : firstDayNextMonth.getMonth()+1;
                            let diaFirstNextMonth = firstDayNextMonth.getDate() < 10 ? "0"+firstDayNextMonth.getDate() : firstDayNextMonth.getDate();
                            let diaLastNextMonth = lastDayNextMonth.getDate() < 10 ? "0"+lastDayNextMonth.getDate() : lastDayNextMonth.getDate();
                            let mesLastNextMonth;
                            if( diaFirstNextMonth > diaLastNextMonth ){
                                mesLastNextMonth = lastDayNextMonth.getMonth()+2 < 10 ? "0"+(lastDayNextMonth.getMonth()+2) : lastDayNextMonth.getMonth()+2;
                            }else{
                                mesLastNextMonth = lastDayNextMonth.getMonth()+1 < 10 ? "0"+(lastDayNextMonth.getMonth()+1) : lastDayNextMonth.getMonth()+1;
                            }
        
        
                            fechas["ini"] =  firstDayNextMonth.getFullYear()+"-"+mesFirstNextMonth+"-"+diaFirstNextMonth;
                            fechas["fin"] = date.getFullYear()+"-"+mesLastNextMonth+"-"+diaLastNextMonth;
        
                            txtKpi = txtKpi+" - "+diaFirstNextMonth+"/"+mesFirstNextMonth+"/"+firstDayNextMonth.getFullYear()+" - "+diaLastNextMonth+"/"+mesLastNextMonth+"/"+lastDayNextMonth.getFullYear();
                            break;
                case "9":
                            // Este año
                            let firstDayThisYear = new Date(date.getFullYear(), 0, 1);
                            let lastDayThisYear = new Date(date.getFullYear(), 11, 31);     
        
                            fechas["ini"] =  (firstDayThisYear.getFullYear())+"-01-01";
                            fechas["fin"] = (firstDayThisYear.getFullYear())+"-12-31";
        
                            txtKpi = txtKpi+" - 01/01/"+(firstDayThisYear.getFullYear())+" - 31/12/"+(lastDayThisYear.getFullYear());
                            break;
                case "10":
                            // Año pasado
                            let firstDayLastYear = new Date(date.getFullYear()-1, 0, 1);
                            let lastDayLastYear = new Date(date.getFullYear()-1, 11, 31);     
        
                            fechas["ini"] =  (firstDayLastYear.getFullYear())+"-01-01";
                            fechas["fin"] = (date.getFullYear()-1)+"-12-31";
        
                            txtKpi = txtKpi+" - 01/01/"+(firstDayLastYear.getFullYear())+" - 31/12/"+(lastDayLastYear.getFullYear());
                            break;
                case "11":
                            // Año próximo
                            let firstDayNextYear = new Date(date.getFullYear()+1, 0, 1);
                            let lastDayNextYear = new Date(date.getFullYear()+1, 11, 31); 
        
                            fechas["ini"] =  (firstDayNextYear.getFullYear())+"-01-01";
                            fechas["fin"] = (date.getFullYear()+1)+"-12-31";
        
                            txtKpi = txtKpi+" - 01/01/"+(firstDayNextYear.getFullYear())+" - 31/12/"+(lastDayNextYear.getFullYear());
                            break;
                case "12":
                            // Fecha personalizada a través de los controles de fecha
                            // fechas["ini"] =  valInicio;
                            // fechas["fin"] = valFin;
                            // let auxIni = valInicio.split("-");
                            // let auxFin = valFin.split("-");
                    
                            let auxIni;
                            let auxFin;
                            
                            fechas["ini"] =  getDecodedCookie('kpiIni');  
                            saveValInicio(getDecodedCookie('kpiIni')); 
                            getDecodedCookie('kpiIni') ? auxIni = getDecodedCookie('kpiIni').split("-") : auxIni = valInicio; 
                        
                            fechas["fin"] =  getDecodedCookie('kpiFin');
                            saveValFin(getDecodedCookie('kpiFin'));
                            getDecodedCookie('kpiFin') ? auxFin = getDecodedCookie('kpiFin').split("-") : auxFin = valFin;                            
                        
                            txtKpi = txtKpi+" - "+auxIni[2]+"/"+auxIni[1]+"/"+auxIni[0]+" - "+auxFin[2]+"/"+auxFin[1]+"/"+auxFin[0];

                            saveValSelect("12");
                            break;
                case "13":
                            // Todos, se usa en la pantalla de prealertas
                            // Se pone el inicio a 0 y el fin a 1 porque en la función que cargamos los datos, para que busque, tenemos la codición de que fechas["ini"] <= fechas["fin"]
                            fechas["ini"] =  "0";
                            fechas["fin"] = "1";
                            saveValInicio(0);
                            saveValFin(1); 
        
                            txtKpi = conf.select.data[13].label;

                            break;
              }

              saveValSelectText(txtKpi);
        }




          return fechas;

    }

    const actionsKpi = (e, funcion)=>{
        switch(funcion){
            case "verCalMan":
                    accionesLinkKpi[0]();
                break;
        }
    }

    return(
        <div className={ noDisplayState ? "noDisplay" : "container-fluid" }>
            <FormRow>
                    {
                        filtro
                        ?
                            <MiSelect  
                                val=         { valSelectFiltro }   
                                loading=     { loading }
                                id=          { filtro.id }    
                                label=       { filtro.label }    
                                placeholder= { filtro.placeholder }
                                col=         { "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" }
                                readOnly=    { readOnlyFiltro == "readonly" ? readOnlyFiltro : "" } 
                                isClearable= { filtro.isclearable }
                                isSearchable={ filtro.issearchable }
                                dataType=    { filtro.datatype }
                                data=        { filtro.data }                                                       
                                formulario = { {id:""}  } 
                                change=      { handleSelectFiltro }                                                    
                            />
                        :
                            null
                    }
                    <MiSelect  
                        val=         { valSelect }   
                        loading=     { loading }
                        id=          { "kpiInt" }    
                        label=       { conf.select.label }    
                        placeholder= { conf.select.placeholder }
                        col=         { filtro ? "6 col-12 col-sm-6 col-lg-6 col-xl-3": "12 col-12 col-lg-4" } 
                        isClearable= { true }
                        isSearchable={ true }
                        dataType=    { conf.select.datatype }
                        data=        { conf.select.data }                                                       
                        formulario = { {id:""}  } 
                        change=      { handleSelect }                                                    
                    />                    
                    <div className={filtro ? "form-group col-md-6 col-12 col-sm-6 col-lg-6 col-xl-3": "form-group col-md-6 col-12 col-sm-6 col-lg-4"}>
                        <label htmlFor={"kpiIni"}>{conf.inicio.label}</label>
                        <div className="input-group mb-2">
                            <DatePicker
                                id={"kpiIni"}
                                name={""}
                                placeholder={' '}
                                format={"yyyy-MM-dd"}
                                value={ valInicio == '0000-00-00T00:00' || valInicio == '' ? null : new Date(valInicio) }
                                // onChange={ (value, event) => handleFecha(value, event, saveValInicio, "kpiIni") }                                                             
                                onOk={(value, event) => handleFecha(value, event, saveValInicio, "kpiIni")}                                
                                readOnly={ false }
                                className={ loading ? 'readonly-style' : ''}
                                disabled={ loading ? true : false } 
                                // showTime
                                block={true}
                                weekStart={1}
                                locale={getDatePickerDaysName()}
                            />
                        </div>
                    </div>
                    <div className={filtro ? "form-group col-md-6 col-12 col-sm-6 col-lg-6 col-xl-3": "form-group col-md-6 col-12 col-sm-6 col-lg-4"}>
                        <label htmlFor={"kpiIni"}>{conf.fin.label}</label>
                        <div className="input-group mb-2">
                            <DatePicker
                                id={"kpiFin"}
                                name={""}
                                placeholder={' '}
                                format={"yyyy-MM-dd"}
                                value={ valFin == '0000-00-00T00:00' || valFin == '' ? null : new Date(valFin) }
                                // onChange={ (value, event) => handleFecha(value, event, saveValFin, "kpiFin") }                                                         
                                onOk={(value, event) => handleFecha(value, event, saveValFin, "kpiFin")}                                
                                readOnly={ false }
                                className={ loading ? 'readonly-style' : ''}
                                disabled={ loading ? true : false } 
                                // showTime
                                block={true}
                                weekStart={1}
                                locale={getDatePickerDaysName()}
                            />
                        </div>
                    </div>
            </FormRow>
           {
                respSimp == "0"
                ?
                    <div className="row">
                            {
                                conf.kpi.map((k,index)  => {
                                        if( conf.getdata != "" ){
                                            return(                                    
                                                // <div key={index} className="col-sm-12 col-lg-6 mt-3">
                                                <div key={index} className="col-sm-12 col-md-12 col-lg-4 mt-3">
                                                    <div className={"small-box bg-"+k.colorfondo}>  
                                                        {
                                                            loading
                                                            ?
                                                                <div className="overlay dark">
                                                                    <i className="fas fa-3x fa-sync-alt fa-spin"></i>
                                                                </div>
                                                            :
                                                                null
                                                        }            
                                                        <div className="inner">
                                                            {/* <p className="font-italic text-right mb-2">{valSelectText}</p> */}
                                                            <h3>{value[index]} <sup style={{fontSize: "20px"}}>{k.tipounidad.title}</sup></h3>
                                                            <p>{k.title}</p>
                                                                
                                                        </div>
                                                        <div className="icon">
                                                            <i className={k.iconofondo+" mt-3"}></i>
                                                        </div>
                                                        {
                                                            k.link.url ==  "funcion"
                                                            ? 
                                                                <p className="small-box-footer" style={{cursor: "pointer"}} onClick={ e => actionsKpi(e, k.link.funcion) }>
                                                                    {k.link.title}
                                                                </p>
                                                            :
                                                            k.link.url
                                                            ?
                                                                k.link.url.includes("report.work.status")
                                                                ?
                                                                <a href={window.location.origin+reportWorkStatus+`/${valInicio}/${valFin}`} target="_blank" className="small-box-footer">
                                                                    {k.link.title} <i className="fal fa-arrow-right"></i>
                                                                </a>
                                                                :
                                                                <a href={window.location.origin+k.link.url} target="_blank" className="small-box-footer">
                                                                    {k.link.title} <i className="fal fa-arrow-right"></i>
                                                                </a>
                                                            :
                                                                <p className="small-box-footer">
                                                                    <i className="fal fa-unlink"></i>
                                                                </p>
                                                        }
                                                    </div>
                                                </div>                                    
                                            )                           
                                        }                                
                                })
                            }           
                    </div>               
                :
                    null
           }
        </div>
    )
}

export default Kpi;