import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { LoginContext } from '../../hooks/context/LoginContext';

import LoadingPage from '../../componentes/ui/LoadingPage';
import DataTableInformes from '../../componentes/formulario/informes/DataTableInformes';
import MiModal from '../../componentes/ui/MiModal';

import { MiReplaceAll, getApiUrl, axiosPost, helperSetCookiesLogin, helperDeleteCookiesLogin, stringToHtml, getDecodedCookie } from '../../helpers/helperApp';

import PermissionRefused from '../../componentes/ui/PermissionRefused';
import Loginmacros from '../macros/Loginmacros';

let configuracion;

function Runmac(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();

    // ***** Context *****
    const { apiKey, getConfApp, getValidaciones, inicioValidaciones, menuMode } = useContext(LoginContext);

    const [ loading, saveLoading ] = useState(0);
    const [ uuid, saveUuid ] = useState("");
    const [ confApp, saveConfApp ] = useState(null);
    const [ txtError, saveTxtError ] = useState("");
    const [ iconoError, saveIconoError ] = useState("");
    const [ txtOk, saveTxtOk ] = useState("");
    const [ iconoOk, saveIconoOk ] = useState("");

    // ***** State para los datos del datatable*****
    const[ datosTable, saveDatosTable ] = useState(null);
    const[ columnasTable, saveColumnasTable ] = useState(null);
    const[ nombreInf, saveNombreInf ] = useState();
    const[ sqlState, saveSqlState ] = useState(null);
    const[ macroState, saveMacroState ] = useState(null);

    // ***** State para mostrar u ocultar el modal *****
    const [ showModal, saveShowModal ] = useState(false);
    // ***** Parámetros *****
    const [param1, saveParam1] = useState();
    const [param2, saveParam2] = useState();
    const [param3, saveParam3] = useState();
    const [param4, saveParam4] = useState();
    const [param5, saveParam5] = useState();
    
    // ***** Url archivos descargables *****
    const [urlDownload, saveUrlDownload] = useState("");



    useEffect( () => {   

        if( props.match.params ){
            if( "param1" in props.match.params  ){
                saveParam1(props.match.params.param1);                
            }
            if( "param2" in props.match.params  ){
                saveParam2(props.match.params.param2);                
            }
            if( "param3" in props.match.params  ){
                saveParam3(props.match.params.param3);                
            }
            if( "param4" in props.match.params  ){
                saveParam4(props.match.params.param4);                
            }
            if( "param5" in props.match.params  ){
                saveParam5(props.match.params.param5);                
            }
            if( "uuid" in props.match.params  ){
                saveUuid(props.match.params.uuid);
                start(props.match.params.uuid);                
            }                        
        }

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 

    },[])

    const start = async (uuidMacro) =>{
        // Iniciamos las validaciones y descargamos el archivo de configuración de la aplicación
        const res = await getValidaciones();
        await inicioValidaciones(res); 
        const conf = await getConfApp();
        configuracion = conf;
        saveConfApp(conf);

        if( conf.res.toLowerCase() === "ko".toLocaleLowerCase() ){
            // No hay archivo de configuración
            
                // Mostramos mensaje
                saveLoading("ko");
                saveTxtError(conf.msg);

                // Borramos las cookies
                helperDeleteCookiesLogin();
        }else{
            // Obtenemos los datos de la macro
            const data = new FormData();
            data.append("uuid", uuidMacro);
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+"/macros/checkMacro", data, source.token);
            if( respuesta.data.error ){
                // Existe algun error. O no hay macro, o no hay usuario o el usuario no está en estado activo
                saveLoading("ko");
                saveTxtError(respuesta.data.error);

                // Borramos las cookies
                helperDeleteCookiesLogin();

            }else if( respuesta.data.macro ){
                // Existe macro
                    const usuario = respuesta.data.usuario;
                    const macro = respuesta.data.macro;
                    saveMacroState(respuesta.data.macro);

                    // Comprobamos si el usuario ya estaba logueado en la aplicación, para mostrarle o no el login. Si estaba logueado NO BORRAMOS LAS COOKIES DESPUÉS DE LAS ACCIONES
                    const data = new FormData();
                    data.append("validacion", apiKey());
                    const respuestaLogin = await axiosPost(getApiUrl(window.location.origin)+"/login/userLogged", data, source2.token);
                    let usuLogueado, estabaLogueado;
                    if( respuestaLogin.data == true ){                            
                        // Ya estaba logueado.
                        usuLogueado = {
                            id : getDecodedCookie('id'),
                            email: getDecodedCookie('email'),
                            nombre: getDecodedCookie('nombre'),
                            sesion: getDecodedCookie('sesion'),
                            nivel: getDecodedCookie('nivel'),
                            estado: getDecodedCookie('estado'),
                            idioma: getDecodedCookie('idioma').toLowerCase(),
                            imguser: getDecodedCookie('imguser'),
                            idage: getDecodedCookie('idage')
                        }
                        estabaLogueado = true;
                    }else{
                        // No estaba logueado
                        estabaLogueado = false;
                    }

                    if( usuario == "login" ){
                        if( estabaLogueado ){
                            acciones(usuLogueado, macro, false, false);
                        }else{
                            saveLoading("login");
                        }
                    }else{
                        if( estabaLogueado ){
                            acciones(usuario, macro, false, false);
                        }else{
                            acciones(usuario, macro, true, true);
                        }                       
                    }


                    
            }
        }

    }

    const acciones = async (usuario, macro, setCookies=true, deleteCookies=true) => {

        // Añadimos las cookies para hacer login del usuario
        if( setCookies ){
            helperSetCookiesLogin(usuario);
        }

        // Acciones a realizar dependiendo del tipo de macro
        switch(macro.idtip){

            case "1":
                    // Descargar adjunto
                    await downloadAdj(usuario, macro);
                    // Borramos las cookies
                    if( deleteCookies ){
                        helperDeleteCookiesLogin();   
                    }
                break;
            case "2":
                    // Ejecutar informe PDF
                    await execInfPdfPan(usuario, macro);
                    // Borramos las cookies
                    if( deleteCookies ){
                        helperDeleteCookiesLogin();   
                    }   
                break;
            case "3":
                    // Ejecutar informe POR PANTALLA
                    await execInfPdfPan(usuario, macro);
                    // Borramos las cookies
                    if( deleteCookies ){
                        helperDeleteCookiesLogin();   
                    }
                break;
            case "4":
                    // Enviar informe PDF POR MAIL
                    await execInfPdfPan(usuario, macro);
                    // Borramos las cookies
                    if( deleteCookies ){
                        helperDeleteCookiesLogin();   
                    }
                break;
            default:
                break;
        }                

    }

    // Descargar adjunto
    const downloadAdj = async (usuario, macro) => {
        // Lamamos al controler que nos devolverá la ruta del archivo
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("macro", JSON.stringify(macro));
        data.append("origen", window.location.origin+"/runmac/"+macro._uuid);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+"/macros/getAdj", data, source.token);
        if( respuesta.data.error ){
            // Existe algun error
            saveLoading("ko");
            saveTxtError(respuesta.data.error);
        }else if( respuesta.data[0].rut ){
            saveLoading("ok");
            saveTxtOk("");

            // Descargamos el archivo
            let downloadLink = document.createElement("a");
            saveUrlDownload(getApiUrl(window.location.origin)+respuesta.data[0].rut);
            downloadLink.href = getApiUrl(window.location.origin)+respuesta.data[0].rut;
            downloadLink.download = respuesta.data[0].des;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
                     
    }

    // Ejecutar informe PDF
    const execInfPdfPan = async(usuario, macro) =>{

        let resultado;
        let txtLog = "";
        let respuesta, respuestaInforme, infoInforme;

        // Nos traemos los datos del informe
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("idinf", macro.url);
        respuesta = await axios.post(getApiUrl(window.location.origin)+"/app/getDataInforme",data);

        if( typeof respuesta.data == "string" ){
            // No pasa la validación del api
            resultado = 0;
            txtLog = `KO.MACRO_EJECUTADA: {'ID':'${macro.id}','_UUID':'${macro._uuid}', 'TIPO':'${macro.idtip}','url':'${macro.url}' , 'qr': '${window.location.origin+"/runmac/"+macro._uuid}', 'RESULT':'Permiso denegado'}`;
        }else if( respuesta.data.length == 0 ){
            // No existe es id de informe
            resultado = 1;
            txtLog = `KO.MACRO_EJECUTADA: {'ID':'${macro.id}','_UUID':'${macro._uuid}', 'TIPO':'${macro.idtip}','url':'${macro.url}' , 'qr': '${window.location.origin+"/runmac/"+macro._uuid}', 'RESULT':'Informe no encontrado'}`;
        }else{
            // Llamamos a la función que genera el informe
            infoInforme = respuesta.data[0];

            // Sustituimos el valor usu_actual por el del usuario que ejecuta el informe
            if( infoInforme.sql.includes("usu_actual") ){
                infoInforme.sql = MiReplaceAll(infoInforme.sql, "usu_actual", getDecodedCookie('id'));
            }

            // Sustituimos en el sql los parámetros recibidos por url
            if( "param1" in props.match.params  ){
                infoInforme.sql = MiReplaceAll(infoInforme.sql, "param1", props.match.params.param1);                
            }
            if( "param2" in props.match.params  ){
                infoInforme.sql = MiReplaceAll(infoInforme.sql, "param2", props.match.params.param2);                
            }
            if( "param3" in props.match.params  ){
                infoInforme.sql = MiReplaceAll(infoInforme.sql, "param3", props.match.params.param3);                
            }
            if( "param4" in props.match.params  ){
                infoInforme.sql = MiReplaceAll(infoInforme.sql, "param4", props.match.params.param4);                
            }
            if( "param5" in props.match.params  ){
                infoInforme.sql = MiReplaceAll(infoInforme.sql, "param5", props.match.params.param5);                
            }

            const data2 = new FormData();
            data2.append("idinforme", macro.url);
            data2.append("validacion", apiKey());
            data2.append("sql", infoInforme.sql);
            data2.append("idregistro", 0);
            data2.append("idplantilla", infoInforme.idplantilla);
            if( macro.idtip == 4 ){
                data2.append("destinatarios", infoInforme.url);
            }
            respuestaInforme = await axios.post(getApiUrl(window.location.origin)+"/app/generateInforme",data2);        

            if( typeof respuestaInforme.data == 'string' ){
                if( macro.idtip == 2 ){
                    // Documento descargable               
                    resultado = 2;
                }else if( macro.idtip == 4 ){
                    // Enviar informe PDF POR MAIL
                    resultado = 7;
                }
                txtLog = `OK.MACRO_EJECUTADA: {'ID':'${macro.id}','_UUID':'${macro._uuid}', 'TIPO':'${macro.idtip}','url':'${macro.url}' , 'qr': '${window.location.origin+"/runmac/"+macro._uuid}', 'RESULT':'${infoInforme.nombre}'}`;
                                
            }else if( respuestaInforme.data.length == 0 ){
                // Informe vacío
                resultado = 3;
                txtLog = `KO.MACRO_EJECUTADA: {'ID':'${macro.id}','_UUID':'${macro._uuid}', 'TIPO':'${macro.idtip}','url':'${macro.url}' , 'qr': '${window.location.origin+"/runmac/"+macro._uuid}', 'RESULT':'No hay datos'}`;
            }else if( respuestaInforme.data.error ){
                // Error al generar el informe
                resultado = 4;
                txtLog = `KO.MACRO_EJECUTADA: {'ID':'${macro.id}','_UUID':'${macro._uuid}', 'TIPO':'${macro.idtip}','url':'${macro.url}' , 'qr': '${window.location.origin+"/runmac/"+macro._uuid}', 'RESULT':'${respuestaInforme.data.error}'}`;
            }else if( respuestaInforme.data.errorSesion == true ){
                // No pasó la validación en el api
                resultado = 5;
                txtLog = `KO.MACRO_EJECUTADA: {'ID':'${macro.id}','_UUID':'${macro._uuid}', 'TIPO':'${macro.idtip}','url':'${macro.url}' , 'qr': '${window.location.origin+"/runmac/"+macro._uuid}', 'RESULT':'Permiso denegado'}`;                
            }else if( respuestaInforme.data.length > 0 && macro.idtip == 3 ){
                // Informe de tipo datatable
                resultado = 6;
                txtLog = `OK.MACRO_EJECUTADA: {'ID':'${macro.id}','_UUID':'${macro._uuid}', 'TIPO':'${macro.idtip}','url':'${macro.url}' , 'qr': '${window.location.origin+"/runmac/"+macro._uuid}', 'RESULT':'${infoInforme.nombre}'}`;
                // Almacenamos el nombre del informe y el sql
                saveNombreInf(respuesta.data[0].nombre);
                saveSqlState(infoInforme.sql);
            }
        }

        const dataLog = new FormData();
        dataLog.append("validacion", apiKey());
        dataLog.append("mensaje", txtLog)
        const url = `${getApiUrl(window.location.origin)}/app/log`;
        const res = await axios.post(url, dataLog);

        switch(resultado){
            case 0:
                     // No pasa la validación del api
                        resultado = 0;
                        saveLoading("ko");
                        saveTxtError(respuesta.data);
                break;
            case 1:
                    // No existe es id de informe
                        saveLoading("ko");
                        saveIconoError("fal fa-file-search");
                        saveTxtError(configuracion.msg.config.trad.components.macros.notfound.title);
                break;
            case 2:
                    // Documento descargable               
                        var forceDown = document.createElement('a');
                        saveUrlDownload(respuestaInforme.data);
                        forceDown.setAttribute('href',respuestaInforme.data);
                        forceDown.setAttribute('download',infoInforme.nombre);
                        document.body.appendChild(forceDown);
                        forceDown.click(); 
                        forceDown.remove();
                        saveLoading("ok");
                        saveTxtOk("");
                        // Configuración chrome descarga pdf: chrome://settings/content/pdfDocuments
                   
                break;
            case 3:
                    // Informe vacío
                        saveLoading("ko");
                        saveIconoError("fal fa-empty-set");
                        saveTxtError(configuracion.msg.config.trad.components.macros.empty.title);
                break;
            case 4:
                    // Error al generar el informe
                        saveLoading("ko");
                        saveIconoError("fal fa-exclamation");
                        saveTxtError(configuracion.msg.config.trad.components.macros.errorgenerate.title);
                break;
            case 5:
                    // No pasó la validación en el api
                        saveLoading("ko");
                        saveTxtError(configuracion.msg.config.trad.components.macros.permissionrefused.title);
                break;
            case 6:
                    // Informe por pantalla
                    prepareDataTable(respuestaInforme.data);
                    saveLoading("datatable");
                    saveTxtOk("");
                break;
            case 7:
                    // Enviar informe PDF POR MAIL
                    saveLoading("okEmailPdf");
                    saveTxtOk(respuestaInforme.data);
                break;                
            default:

                break;
        }
        
    }

    const prepareDataTable = (data) => {
        const keys = data.keys();
        let cabeceras = [];
        let links = [];

        if( data.length > 0 ){
            for( let i = 0; i < data.length; i++ ){
                for (const [key, value] of Object.entries(data[i])) {
                    if( i == 0 ){
                        // Cabeceras
                        if( !key.includes("id_") && !key.includes("url_") && key != "id"){
                            let aux = { "title": key, "data": key };
                            cabeceras.push(aux);
                        }
                    }
                    // Enlaces si existe para esa columna
                    if( data[i]["url_"+key] ){  
                        data[i][key] = `<a href='${data[i]["url_"+key]}' target='_blank'>${data[i][key]}</a> `;
                    }
                }
            }
        }else{
            let aux = { "title": configuracion.trad.datatable.semptytable.title, "data": "" };
            cabeceras.push(aux);

        }
        saveColumnasTable(cabeceras);
        saveDatosTable(data);
    }

    const handleShowModal = () => {
        saveShowModal(true);
    }

    const handleCloseModal = () => {
        saveShowModal(false);
    }

    
    return(
        <Fragment>
            {
                    loading == 0
                ?
                    <LoadingPage />
                :
                    loading == "ko"
                ?
                    <div className="App wrapper"> 
                        <PermissionRefused traduction={ {"title" : txtError}} icono={ iconoError } />            
                    </div>
                :
                    loading == "ok"
                ?
                    <div className="App wrapper">
                        <div className="content-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "0", textAlign: "center" }}>
                            <p><i style={{ color: "#28a745"}} className={iconoOk != "" ? iconoOk+" fa-8x" : "fal fa-check fa-8x"}></i></p>
                            <h1>{txtOk}</h1>
                            <a href={urlDownload}>
                                <h5>{configuracion.msg.config.trad.components.runmac.downloadlink.title}</h5>
                                <p><i style={{ color: "#28a745"}} className='fal fa-download fa-6x'></i></p>
                            </a>
                        </div>
                    </div>
                :
                    loading == "datatable" && datosTable != null && columnasTable != null
                ?
                 
                        <div className="container-fluid">
                            {
                                getDecodedCookie('nivel') == "1"
                                ?
                                    <hr data-content={nombreInf} className="hr-titleinf" onClick={handleShowModal}></hr> 
                                :
                                    <hr data-content={nombreInf} className="hr-titleinf"></hr> 
                            }
                            {/* {
                                filtrossearch != ""
                                ?                                
                                    <div className="containerFiltros">{
                                            filtrossearch.map((filtro, index) => {
                                                return <p key={index} className="cardfilter mr-2"><i className="fal fa-filter mr-2"></i>{filtro.cond}</p>
                                            })
                                        }
                                    </div>
                                :
                                    null
                            } */}
                            <DataTableInformes 
                                id={"Informe"}
                                columnas={columnasTable}
                                data={datosTable}
                                buttons={true}
                                ordering={true}
                                order={[]}
                                searching={true}
                                paging={true}
                                select={true}               
                                traduction={configuracion.msg.config.trad.components.datatable}
                                pagelength={configuracion.msg.config.trad.components.datatable.pagelengthinformes}
                            />      
                        </div>
                :
                    loading == "okEmailPdf"
                ?
                    <div className="App wrapper">
                        <div className="content-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "0", textAlign: "center" }}>
                            <p><i style={{ color: "#28a745"}} className={iconoOk != "" ? iconoOk+" fa-8x" : "fal fa-check fa-8x"}></i></p>
                            {
                                txtOk.split("</p>").map((p,index) => {
                                    p = MiReplaceAll(p,"<br>","");
                                    return <p key={index}>{MiReplaceAll(p,"<p>","")}</p>                                    
                                })
                            }
                        </div>
                    </div>
                :
                    loading == "login"
                ?
                        <Loginmacros
                            confApp = {configuracion.msg}
                            saveLoading = {saveLoading}
                            saveTxtError = {saveTxtError}
                            acciones= {acciones}
                            macro = {macroState}
                        />
                :
                        null
            }
            {                
                showModal 
                ?
                    <MiModal
                        titulo={""}
                        actionhide={handleCloseModal}
                        centered={true}
                        btnclosetext={""}
                        iconoBtnDanger={"fal fa-times"}
                        footer={false}
                    >
                        <p>{sqlState}</p>
                    </MiModal>
                :
                    null 
            } 
        </Fragment>

    );

}
export default Runmac