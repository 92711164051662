import React, { useState, useContext, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../helpers/FixRequiredSelect";

import { LoginContext } from '../../../hooks/context/LoginContext';


function MiSelectListaRel(props){

    const { options, estadoForm, loading, id, label, placeholder, col, isClearable, isSearchable, required, disabled, readOnly, handleSelect, loadingSelect } = props;

    const animatedComponents = makeAnimated(); 
    
    const { apiKey, confApp } = useContext(LoginContext);    

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor:'#ced4da',
            borderColor: state.isFocused ? '#80bdff': '#ced4da',
            outline: 0,
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
          })
    }
    const nodisplay = {
        control: (base, state) => ({
            ...base,
            opacity: 0
          })
    }

    const Select = props => (
        <FixRequiredSelect
          {...props}
          SelectComponent={BaseSelect}
          options={props.options || options}
        />
    );
    
    useEffect( () => {
    },[options])

    return(
            <div className={"form-group col-md-"+col}>
                <label htmlFor={id}>{label}</label>
                    <Select 
                            options={options}
                            id={id+"aux"}
                            placeholder={ placeholder } 
                            value={ -1 }
                            onChange={ handleSelect }
                            required={required}
                            isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" || readOnly ? true :  disabled}
                            isClearable={isClearable}
                            isSearchable={isSearchable}
                            isMulti={false}
                            isLoading={false}
                            components={animatedComponents}    
                            noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title } 
                            styles={customStyles}
                            formatOptionLabel={                                
                                function(data) {
                                    return (
                                        <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                    );
                                }                                
                            }   
                    />            
            </div>           
    )
}

export default MiSelectListaRel;