import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import MiSelect from '../../componentes/formulario/MiSelect';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';
import Informes from '../../componentes/formulario/informes/Informes';

import { getTitle, getDecodedCookie } from '../../helpers/helperApp';

function Pruebas(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleClonePrin, handleCloneHijo, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{        
                    document.title = getTitle(confPantalla.config.trad, confPantalla.pruebas.title);                             
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.pruebas.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.pruebas.hijo.estadoform);                            
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.pruebas.padre.urls);
                                saveFormularioPrin(confPantalla.pruebas.padre.modelform);
                                saveFormularioHijo(confPantalla.pruebas.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.pruebas.padre.urls, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);    
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])
 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.pruebas.section.titulo } icono={ confPantalla.pruebas.section.icono } col={ confPantalla.pruebas.section.col } idPrin={ formularioPrin.id } idHijo = {formularioHijo.id} wiki={ confPantalla.pruebas.section.wiki } >
                                <Tabs defaultActiveKey="tab1" id="tab1">
                                    <Tab eventKey="tab1" title={ confPantalla.pruebas.tabs.tab1.titulo }>
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.pruebas.padre.btnform.new }
                                                    btnSave={ confPantalla.pruebas.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.pruebas.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.pruebas.padre.btnform.delete }
                                                    btnClone= { confPantalla.pruebas.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.pruebas.padre.urls, confPantalla.pruebas.padre.modelform, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.hijo.modelform, confPantalla.pruebas.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.pruebas.padre.modelform, true, confPantalla.pruebas.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.pruebas.padre.urls, confPantalla.pruebas.padre.modelform, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.title) }
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.pruebas.padre.urls, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.padreshijos, confPantalla.pruebas.hijo.modelform, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.title) } 
                                        >
                                            <Informes
                                                select =     { confPantalla.pruebas.padre.controles.informes.select }
                                                formulario = { formularioPrin }
                                                loadReg =    { loadReg ? true : false }
                                                estadoForm=  { estadoFormPrin } 
                                                traduction=  { confPantalla.config.trad.components }
                                            />
                                            <Upls
                                                estadoForm= { estadoFormPrin }
                                                loading=    { loadReg ? true : false }
                                                id={ confPantalla.pruebas.padre.controles.upl.id }
                                                value={formularioPrin.upl}
                                                save={saveFormularioPrin}                                                
                                                formulario={formularioPrin}  
                                                readonly={ confPantalla.pruebas.padre.controles.upl.readonly }
                                                traduction={confPantalla.config.trad.components.upls} 
                                            />
                                            <Upls
                                                estadoForm= { estadoFormPrin }
                                                loading=    { loadReg ? true : false }
                                                id={ confPantalla.pruebas.padre.controles.upl2.id }
                                                value={formularioPrin.upl2}  
                                                save={saveFormularioPrin}  
                                                formulario={formularioPrin}  
                                                readonly={ confPantalla.pruebas.padre.controles.upl2.readonly }
                                                traduction={confPantalla.config.trad.components.upls} 
                                            />
                                            <Upls
                                                estadoForm= { estadoFormPrin }
                                                loading=    { loadReg ? true : false }
                                                id={confPantalla.pruebas.padre.controles.upl3.id}
                                                value={formularioPrin.upl3}  
                                                save={saveFormularioPrin}                                                                                              
                                                formulario={formularioPrin}           
                                                readonly={ confPantalla.pruebas.padre.controles.upl3.readonly }  
                                                traduction={confPantalla.config.trad.components.upls}                                                                           
                                            />
                                            <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                <Tab eventKey="tab1_1" title={ confPantalla.pruebas.tabs.tab1.subtabs.tab11.titulo }>
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.text }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.pruebas.padre.controles.text.id }   
                                                            type=       { confPantalla.pruebas.padre.controles.text.type }  
                                                            name=       { confPantalla.pruebas.padre.controles.text.name }   
                                                            label=      { confPantalla.pruebas.padre.controles.text.label }                                                       
                                                            placeholder={ confPantalla.pruebas.padre.controles.text.placeholder }    
                                                            col=        { confPantalla.pruebas.padre.controles.text.col } 
                                                            required=   { confPantalla.pruebas.padre.controles.text.required }
                                                            readonly=   { confPantalla.pruebas.padre.controles.text.readonly }                                                
                                                            disabled=   { confPantalla.pruebas.padre.controles.text.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput 
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.email }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.pruebas.padre.controles.email.id }   
                                                            type=       { confPantalla.pruebas.padre.controles.email.type }  
                                                            name=       { confPantalla.pruebas.padre.controles.email.name }   
                                                            label=      { confPantalla.pruebas.padre.controles.email.label }                                                       
                                                            placeholder={ confPantalla.pruebas.padre.controles.email.placeholder }    
                                                            col=        { confPantalla.pruebas.padre.controles.email.col } 
                                                            required=   { confPantalla.pruebas.padre.controles.email.required }
                                                            readonly=   { confPantalla.pruebas.padre.controles.email.readonly }                                                
                                                            disabled=   { confPantalla.pruebas.padre.controles.email.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput 
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.tel }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.pruebas.padre.controles.tel.id }   
                                                            type=       { confPantalla.pruebas.padre.controles.tel.type }  
                                                            name=       { confPantalla.pruebas.padre.controles.tel.name }   
                                                            label=      { confPantalla.pruebas.padre.controles.tel.label }                                                       
                                                            placeholder={ confPantalla.pruebas.padre.controles.tel.placeholder }    
                                                            col=        { confPantalla.pruebas.padre.controles.tel.col } 
                                                            required=   { confPantalla.pruebas.padre.controles.tel.required }
                                                            readonly=   { confPantalla.pruebas.padre.controles.tel.readonly }                                                
                                                            disabled=   { confPantalla.pruebas.padre.controles.tel.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                         <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idpai  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.pruebas.padre.controles.idpai.id }    
                                                            name=        { confPantalla.pruebas.padre.controles.idpai.name }   
                                                            label=       { confPantalla.pruebas.padre.controles.idpai.label }    
                                                            placeholder= { confPantalla.pruebas.padre.controles.idpai.placeholder }
                                                            col=         { confPantalla.pruebas.padre.controles.idpai.col } 
                                                            isClearable= { confPantalla.pruebas.padre.controles.idpai.isclearable }
                                                            isSearchable={ confPantalla.pruebas.padre.controles.idpai.issearchable }
                                                            dataType=    { confPantalla.pruebas.padre.controles.idpai.datatype }
                                                            data=        { confPantalla.pruebas.padre.controles.idpai.data }
                                                            required=    { confPantalla.pruebas.padre.controles.idpai.required } 
                                                            disabled=    { confPantalla.pruebas.padre.controles.idpai.disabled }
                                                            readOnly =   { confPantalla.pruebas.padre.controles.idpai.readonly }
                                                            disablethisid={confPantalla.pruebas.padre.controles.idpai.disablethisid}
                                                            formulario = { formularioPrin } 
                                                            change=      { handleSelectFormPrin }                                                    
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { confPantalla.documentos.padre.estadoform }
                                                            value=      { formularioPrin.date }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.pruebas.padre.controles.date.id }   
                                                            type=       { confPantalla.pruebas.padre.controles.date.type }  
                                                            name=       { confPantalla.pruebas.padre.controles.date.name }   
                                                            label=      { confPantalla.pruebas.padre.controles.date.label }                                                       
                                                            placeholder={ confPantalla.pruebas.padre.controles.date.placeholder }    
                                                            col=        { confPantalla.pruebas.padre.controles.date.col } 
                                                            required=   { confPantalla.pruebas.padre.controles.date.required }
                                                            readonly=   { confPantalla.pruebas.padre.controles.date.readonly }                                                
                                                            disabled=   { confPantalla.pruebas.padre.controles.date.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    {/* <Informes
                                                        select =     { confPantalla.pruebas.padre.controles.informes.select }
                                                        formulario = { formularioPrin }
                                                        loadReg =    { loadReg ? true : false }
                                                        estadoForm=  { estadoFormPrin } 
                                                        traduction=  { confPantalla.config.trad.components }
                                                    /> */}
                                                </Tab>
                                                <Tab eventKey="tab1_2" title={ confPantalla.pruebas.tabs.tab1.subtabs.tab12.titulo }>
                                                    <FormRow clases="" >
                                                        <Uplm
                                                            estadoForm= { estadoFormPrin }
                                                            loading=    { loadReg ? true : false }
                                                            col={12}
                                                            id={ confPantalla.pruebas.padre.controles.uplm.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            readonly={ confPantalla.pruebas.padre.controles.uplm.readonly }
                                                            traduction={confPantalla.config.trad.components.uplm}
                                                        />
                                                    </FormRow>
                                                </Tab>
                                                <Tab eventKey="tab1_3" title={ confPantalla.pruebas.tabs.tab1.subtabs.tab13.titulo }>
                                                    <FormRow clases="" >
                                                        <Uplm
                                                            estadoForm= { estadoFormPrin }
                                                            loading=    { loadReg ? true : false }
                                                            col={12}
                                                            id={ confPantalla.pruebas.padre.controles.uplm2.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            readonly={ confPantalla.pruebas.padre.controles.uplm2.readonly }
                                                            traduction={confPantalla.config.trad.components.uplm}
                                                        />
                                                    </FormRow>
                                                </Tab>
                                            </Tabs>
                                        </Formulario>
                                        <MiDataTable 
                                            formulario={formularioPrin}
                                            estadoForm= { estadoFormPrin }
                                            id={"pruebas"}
                                            columnas={confPantalla.pruebas.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.pruebas.padre.datatable.buttons}
                                            ordering={confPantalla.pruebas.padre.datatable.ordering}
                                            order={confPantalla.pruebas.padre.datatable.order}
                                            searching={confPantalla.pruebas.padre.datatable.searching}
                                            paging={confPantalla.pruebas.padre.datatable.paging}
                                            select={confPantalla.pruebas.padre.datatable.select}
                                            pagelength={confPantalla.pruebas.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.pruebas.padre.urls, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.padreshijos, confPantalla.pruebas.hijo.modelform, confPantalla.pruebas.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.pruebas.padre.urls, confPantalla.pruebas.padre.modelform, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.pruebas.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                    </Tab>
                                    { formularioPrin.id != 0                         
                                        ? 
                                            <Tab eventKey="tab2" title={ confPantalla.pruebas.tabs.tab2.titulo }>
                                            <Formulario 
                                                 handleSubmit = { e => handleSubmitHijo(e, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.hijo.modelform, confPantalla.pruebas.hijo.tabla, confPantalla.pruebas.padre.urls, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.title2) }  
                                                formulario = { formularioHijo } 
                                                resetForm = { e => resetFormHijo(confPantalla.pruebas.hijo.modelform) } 
                                                handleDeleteItem = { e => handleDeleteItemHijo(undefined, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.hijo.modelform, confPantalla.pruebas.hijo.tabla, confPantalla.pruebas.padre.urls, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.title2) }
                                                handleCloneItem = { e => handleCloneHijo(formularioHijo.id, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.hijo.tabla, confPantalla.pruebas.padre.urls, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.title2) }                                               
                                                esHijo= { true }
                                                formularioPadre= { formularioPrin }
                                            >
                                                <input type="hidden" name="idpru" id="pruebashijo.idpru" value={ formularioPrin.id } />


                                                <Upls
                                                    estadoForm= { estadoFormHijo }
                                                    loading=    { loadReg ? true : false }
                                                    id={ confPantalla.pruebas.hijo.controles.upl.id }
                                                    value={formularioHijo.upl}
                                                    save={saveFormularioHijo}                                                
                                                    formulario={formularioHijo}         
                                                    readonly={ confPantalla.pruebas.hijo.controles.upl.readonly } 
                                                    traduction={confPantalla.config.trad.components.upls}                                                                                  
                                                />
                                                <Upls
                                                    estadoForm= { estadoFormHijo }
                                                    loading=    { loadReg ? true : false }
                                                    id={ confPantalla.pruebas.hijo.controles.upl2.id }
                                                    value={formularioHijo.upl2}
                                                    save={saveFormularioHijo}                                                
                                                    formulario={formularioHijo}         
                                                    readonly={ confPantalla.pruebas.hijo.controles.upl2.readonly }
                                                    traduction={confPantalla.config.trad.components.upls}                                                                                            
                                                />
                                                <Upls
                                                    estadoForm= { estadoFormHijo }
                                                    loading=    { loadReg ? true : false }
                                                    id={ confPantalla.pruebas.hijo.controles.upl3.id }
                                                    value={formularioHijo.upl3}
                                                    save={saveFormularioHijo}                                                
                                                    formulario={formularioHijo}         
                                                    readonly={ confPantalla.pruebas.hijo.controles.upl3.readonly }   
                                                    traduction={confPantalla.config.trad.components.upls}                                                                                      
                                                />


                                                    <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                        <Tab eventKey="tab1_1" title={ confPantalla.pruebas.tabs.tab2.subtabs.tab21.titulo }>
                                                            <FormRow clases="" >
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.text }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.pruebas.hijo.controles.text.id }   
                                                                    type=       { confPantalla.pruebas.hijo.controles.text.type }  
                                                                    name=       { confPantalla.pruebas.hijo.controles.text.name }   
                                                                    label=      { confPantalla.pruebas.hijo.controles.text.label }                                                       
                                                                    placeholder={ confPantalla.pruebas.hijo.controles.text.placeholder }    
                                                                    col=        { confPantalla.pruebas.hijo.controles.text.col } 
                                                                    required=   { confPantalla.pruebas.hijo.controles.text.required }
                                                                    readonly=   { confPantalla.pruebas.hijo.controles.text.readonly }                                                
                                                                    disabled=   { confPantalla.pruebas.hijo.controles.text.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.email }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.pruebas.hijo.controles.email.id }   
                                                                    type=       { confPantalla.pruebas.hijo.controles.email.type }  
                                                                    name=       { confPantalla.pruebas.hijo.controles.email.name }   
                                                                    label=      { confPantalla.pruebas.hijo.controles.email.label }                                                       
                                                                    placeholder={ confPantalla.pruebas.hijo.controles.email.placeholder }    
                                                                    col=        { confPantalla.pruebas.hijo.controles.email.col } 
                                                                    required=   { confPantalla.pruebas.hijo.controles.email.required }
                                                                    readonly=   { confPantalla.pruebas.hijo.controles.email.readonly }                                                
                                                                    disabled=   { confPantalla.pruebas.hijo.controles.email.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null} 
                                                                />
                                                                <FormInput 
                                                                    estadoForm= { estadoFormHijo }
                                                                    value=      { formularioHijo.tel }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.pruebas.hijo.controles.tel.id }   
                                                                    type=       { confPantalla.pruebas.hijo.controles.tel.type }  
                                                                    name=       { confPantalla.pruebas.hijo.controles.tel.name }   
                                                                    label=      { confPantalla.pruebas.hijo.controles.tel.label }                                                       
                                                                    placeholder={ confPantalla.pruebas.hijo.controles.tel.placeholder }    
                                                                    col=        { confPantalla.pruebas.hijo.controles.tel.col } 
                                                                    required=   { confPantalla.pruebas.hijo.controles.tel.required }
                                                                    readonly=   { confPantalla.pruebas.hijo.controles.tel.readonly }                                                
                                                                    disabled=   { confPantalla.pruebas.hijo.controles.tel.disabled }
                                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                                    onBlur=     {null}  
                                                                />
                                                            </FormRow>
                                                        </Tab>
                                                        <Tab eventKey="tab1_2" title={ confPantalla.pruebas.tabs.tab2.subtabs.tab22.titulo }>
                                                            <FormRow clases="" >
                                                                <Uplm
                                                                    estadoForm= { estadoFormHijo }
                                                                    loading=    { loadReg ? true : false }
                                                                    col={12}
                                                                    id={confPantalla.pruebas.hijo.controles.uplm.id}
                                                                    adjuntos={ adjuntosHijo }
                                                                    saveAdjuntos= { saveAdjuntosHijo }  
                                                                    adjuntosExistentes= { adjuntosExistentesHijo } 
                                                                    saveAdjuntosExistentes= { saveAdjuntosExistentesHijo }          
                                                                    readonly={ confPantalla.pruebas.hijo.controles.uplm.readonly }
                                                                    traduction={confPantalla.config.trad.components.uplm}
                                                                />
                                                            </FormRow>
                                                        </Tab>
                                                    </Tabs>
                                                </Formulario>
                                                <MiDataTable
                                                    formulario={formularioHijo} 
                                                    estadoForm= { estadoFormHijo }
                                                    id={"pruebasHijo"}
                                                    columnas={confPantalla.pruebas.hijo.datatable.columns}
                                                    data={registrosHijo}
                                                    buttons={confPantalla.pruebas.hijo.datatable.buttons}
                                                    ordering={confPantalla.pruebas.hijo.datatable.ordering}
                                                    order={confPantalla.pruebas.hijo.datatable.order}
                                                    searching={confPantalla.pruebas.hijo.datatable.searching}
                                                    paging={confPantalla.pruebas.hijo.datatable.paging}
                                                    select={confPantalla.pruebas.hijo.datatable.select}
                                                    pagelength={confPantalla.pruebas.hijo.datatable.pagelength}
                                                    handleEditReg= { e => handleEditRegHijo(e, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.hijo.tabla) }
                                                    handleDeleteItem={ e => handleDeleteItemHijo(e, confPantalla.pruebas.hijo.urls, confPantalla.pruebas.hijo.modelform, confPantalla.pruebas.hijo.tabla, confPantalla.pruebas.padre.urls, confPantalla.pruebas.padre.tabla, confPantalla.pruebas.title2) }
                                                    saveLoadingTable={ saveLoadingTable }
                                                    loadingTable={loadingTable}
                                                    columnactions={confPantalla.pruebas.hijo.datatable.columnactions.title}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />                              
                                            </Tab>
                                        :
                                        null
                                    }
                                    
                                </Tabs> 
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Pruebas