import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from '../ui/Button';
import MiDataTable from '../ui/MiDataTable';

import { SRLWrapper } from 'simple-react-lightbox';

import { axiosPost, getApiUrl } from '../../helpers/helperApp';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

function ShowFirmas(props){
    
    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { idPrin, getFirmas, traduction } = props;    
    
    const { apiKey, menuMode } = useContext(LoginContext);

    const [ showModal, saveShowModal ] = useState(false);
    const [ numFirmas, saveNumFirmas ] = useState(0);
    const [ firmas, saveFirmas ] = useState([]);
    const [ error, saveError ] = useState("");
    


    useEffect( () => { 

        if(getFirmas){
            getFirmasFn(true);
        }
                    
        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 
    },[idPrin])


    const getFirmasFn = async (total) => {

        if(idPrin != "" && idPrin != null && idPrin != undefined){
            const data = new FormData();
            data.append("validacion", apiKey());
            data.append("id", idPrin);
            data.append("tabla", getFirmas.tabla);
            data.append("tabrel", getFirmas.tabrel);
            data.append("total", total)        
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+getFirmas.url, data, source.token);

            if(typeof respuesta.data == "string"){
                saveError(respuesta.data);
            }else{
                saveError("");
                if(total){
                    saveNumFirmas(respuesta.data[0].total);
                }else if(!total){
                    saveShowModal(true);
                    saveFirmas(respuesta.data);
                }                
            }
        }

    }




    return(

        <Fragment>
            {
                idPrin > 0 && numFirmas > 0 && error == ""
                ?
                    <Button
                        clases={ "rounded float-right pb-2" }
                        color={ "success" }  
                        type="button" 
                        icono={ "fal fa-file-signature" }   
                        id=""     
                        texto={ " ("+numFirmas+")" }   
                        accion={ () => getFirmasFn(false) } 
                        disabled={ false }
                    />
                :
                    null                
            }
            {
                error != ""
                ?
                    <Button
                        clases={ "rounded float-right pb-2" }
                        color={ "danger" }  
                        type="button" 
                        icono={ "fal fa-exclamation-triangle" }   
                        id=""     
                        texto={ "" }   
                        accion={ () => saveShowModal(true) } 
                        disabled={ false }
                    />
                :
                    null
            }
            {
                showModal
                ?
                    <Modal
                        show={showModal}
                        onHide={ () => saveShowModal(false)} 
                        aria-labelledby="example-custom-modal-styling-title"                                    
                        dialogClassName="modal-90w"                                             
                    >
                         <Modal.Header closeButton> 
                               <h5>{traduction.firma.modal.header.title}</h5>                 
                        </Modal.Header>
                        <Modal.Body>
                            {
                                error != ""
                                ?
                                <div class="alert alert-danger text-center" role="alert">
                                    {error}
                                </div>
                                :
                                    <SRLWrapper>
                                        <MiDataTable 
                                            estadoForm= { "" }
                                            formulario={ {id:0} }
                                            id={"tablafirmas"}
                                            columnas={getFirmas.datatable.columns}
                                            data={firmas}
                                            linkColumns={getFirmas.datatable.linkColumns}
                                            buttons={getFirmas.datatable.buttons}
                                            ordering={getFirmas.datatable.ordering}
                                            order={getFirmas.datatable.order}
                                            searching={getFirmas.datatable.searching}
                                            paging={getFirmas.datatable.paging}
                                            select={getFirmas.datatable.select}
                                            pagelength={getFirmas.datatable.pagelength}
                                            loadingTable={null}
                                            columnactions={getFirmas.datatable.columnactions.title}
                                            traduction={traduction.datatable}                                        
                                        />                                        
                                    </SRLWrapper>
                            }
                            <div className='text-right'>
                                <button 
                                        className="btn btn-danger mt-1"
                                        onClick={ () => saveShowModal(false) }                                        
                                >
                                    {getFirmas.modal.btncerrar.title}
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                :
                   null
            }

        </Fragment>
    )
}

export default ShowFirmas;