import React from 'react';

function Button({clases, color, type, icono, id, accion, texto, estadoForm, loading, disabled, iconRight}){

    return(        
            iconRight
            ?
            <button 
                id={id} 
                className={ estadoForm === "readonly" || estadoForm === "disabled" ? ` noDisplay ${clases} btn btn-flat mr-1 btn-${color}` :  `${clases} btn btn-flat mr-1 btn-${color}` } 
                type={type} 
                onClick={accion !== null ? ()=> accion(): null  } 
                disabled={disabled} >
                            {texto}
                            { icono !== "" 
                                ?
                                <i className={ icono }></i>
                                :
                                null
                            }
            </button>
            :
            <button 
                id={id} 
                className={ estadoForm === "readonly" || estadoForm === "disabled" ? ` noDisplay ${clases} btn btn-flat mr-1 btn-${color}` :  `${clases} btn btn-flat mr-1 btn-${color}` } 
                type={type} 
                onClick={accion !== null ? ()=> accion(): null  } 
                disabled={disabled} >
                            { icono !== "" 
                                ?
                                <i className={ icono }></i>
                                :
                                null
                            }
                            {texto}
            </button>
    )
}

export default Button;