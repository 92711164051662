import React from 'react';

function PermissionRefused(props){

    const { traduction, icono } = props;

    return(
        <div className="content-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "0", textAlign: "center", color: "#dc3545" }}>
            <p><i className={icono != null && icono != "" ? icono+" fa-8x" : "fal fa-user-shield fa-8x"}></i></p>
            <h1>{traduction.title}</h1>
        </div>
    )
}

export default PermissionRefused;