import React, { useEffect, useState, Fragment, useContext } from 'react';
import axios from 'axios';
import { LoginContext } from '../../hooks/context/LoginContext';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import Spinner from 'react-bootstrap/Spinner'
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import MiSelect from '../../componentes/formulario/MiSelect';
import BaseSelect from "react-select";
import swal from 'sweetalert';
import { alertService } from '../../services/alertservice';

import { SRLWrapper } from 'simple-react-lightbox';

import { getApiUrl, getPantDocFromNivel, getStockOptionsSelectUbi, setStockOptionsSelectUbi, getDecodedCookie } from '../../helpers/helperApp';

require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();

var botones = [
    // {
    //     extend: 'pageLength',
    //     text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Ver número de registros'
    // },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },
    {
        extend: 'excel',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        title: hoy,
        // titleAttr: 'Exportar a excel',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    {
        extend: 'pdf',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        orientation: 'landscape', 
        title: hoy,
        // titleAttr: 'Exportar a pdf',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    // {
    //     extend: 'colvis',
    //     text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    // }
];

function MiDataTableEdiRap(props){


    const cookies = new Cookies();

    const { apiKey, confApp } = useContext(LoginContext);
    
    
    // ***** props *****
    const { id, data, saveData, columnas, formulario, optionSelectUbi, optionSelectUni, inputNum1, swaldelete } = props;
    let { buttons, ordering, order, searching, paging, select, saveLoadingTable, loadingTable, columnactions, traduction, linkColumns, functionClickTd, pagelength, columnsFunctionClickTd, selectUbi, selectUni, idFiltro } = props;
    
    const [loadingSelectUbi, saveLoadingSelectUbi] = useState(false);
    const [optionsSelectUbiState, saveOptionsSelectUbiState] = useState(optionSelectUbi);
    const [optionsSelectUbiStateBackUp, saveOptionsSelectUbiStateBackUp] = useState(optionSelectUbi);    
    
    
    useEffect(()=>{

        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);

        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        // Añadimos los enlaces a los td
        if( linkColumns != undefined ){
            for( let i = 0; i < columnas.length; i++ ){
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                    if( linkColumns[i] != undefined ){
                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('style' in rowData){
                            $(td).addClass(rowData["style"]);
                        }
                        //Creamos los enlaces
                        // Si el enlace es a la pantalla de documentos obtenemos la pantalla que le corresponde según su id
                        if( linkColumns[i].link == "/documentos" ){
                            linkColumns[i].link = getPantDocFromNivel(getDecodedCookie('nivel'));
                        }
                        if( linkColumns[i].data.includes("/") && linkColumns[i].link != "" ){
                            const links = linkColumns[i].data.split("/");
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[links[0]]+"/"+rowData[links[1]]+"' >"+cellData+"</a>");
                        }else if( linkColumns[i].link != "" ){
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[linkColumns[i].data]+"' >"+cellData+"</a>");
                        }
                    } 
                    // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                    if( 'foto' in rowData || 'img' in rowData ){
                        if( cellData ){
                            if( cellData.includes('/assets/')  ){
                                $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer;' />");
                            }
                        }else{
                            $(td).html("<span></span>");
                        }
                    }                   
                    if( functionClickTd ){
                        if( columnsFunctionClickTd ){
                            if( columnsFunctionClickTd.includes(col) ){
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }
                        }else{
                            $(td).on('click', function (e) {
                                e.preventDefault();
                                functionClickTd(e, rowData);
                            });
                        }                        
                    }                    
                }
            }
        }else{
            for( let i = 0; i < columnas.length; i++ ){
  
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('style' in rowData){
                            $(td).addClass(rowData["style"]);
                        }
                        // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                        if( 'foto' in rowData || 'img' in rowData ){
                            if( cellData ){
                                if( cellData.includes('/assets/')  ){

                                    let templateFoto = `
                                    <div>                                   
                                            <div class="containerImgTablePending">
                                                <img class="imgUplmTablePending" src=${getApiUrl(window.location.origin)+cellData} />
                                            </div>                                                                                                                                        
                                    </div>
                                    `;
                                    $(td).html(templateFoto);
                                    // $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer' />");
                                }
                            }else{
                                $(td).html("<span></span>");
                            }
                        }
                        if( functionClickTd ){
                            if( columnsFunctionClickTd ){
                                if( columnsFunctionClickTd.includes(col) ){
                                    $(td).on('click', function (e) {
                                        e.preventDefault();
                                        functionClickTd(e, rowData);
                                    });
                                }
                            }else{
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }                        
                        }                    
                }
            }
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;
        select === undefined ? select = false : select = select;

        columnas.map((columna, i) => {  

            if( i == 0){
                columna.createdCell = (td, cellData, rowData, row, col) => {  

                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-11" >
                                <BaseSelect
                                        className={rowData.id} 
                                        options={optionSelectUni}
                                        name={selectUni.name}             
                                        id={row}
                                        placeholder={ selectUni.placeholder } 
                                        // value={options.length > 0 ? options.filter(option => option.value == rowData.ubicacionactual) : null}
                                        defaultValue={ optionSelectUni.length > 0 ? optionSelectUni.filter(option => option.value == rowData.articulo) : null } 
                                        onChange={ e => handleChangeUni(e, rowData) }
                                        required={selectUni.required}
                                        isDisabled={ selectUni.disabled}
                                        isClearable={selectUni.isclearable}
                                        isSearchable={selectUni.issearchable}
                                        isMulti={false}
                                        isLoading={false}
                                        noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title }
                                        menuPlacement={"auto"}
                                        menuPortalTarget={document.body}                                              
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}                               
                                />                                
                            </div>
                            <div className={"col-1 mt-2 noDisplay spinUni_"+rowData.id}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay checkUni_"+rowData.id} style={{padding: 0, color: "#28a745"}}>
                                <i className="fal fa-check fa-2x"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay errorUni_"+rowData.id} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>
                        </div>                              
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)                                         
                }



            }
            if( i == 1 ){
                // Cantidad
                columna.createdCell = (td, cellData, rowData, row, col) => {  

                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-9" >                           
                                <input  
                                    type={ inputNum1.type } 
                                    className={ inputNum1.alignRight === true ? "form-control text-right" : "form-control"} 
                                    id={ inputNum1.id } 
                                    name={ inputNum1.name } 
                                    placeholder={ inputNum1.placeholder }
                                    // value={ rowData.cantidad }
                                    defaultValue={ rowData.cantidad }  
                                    // onChange={ e => handleChangeNum1(e, rowData) } 
                                    onBlur={ e => handleChangeNum1(e, rowData) } 
                                    required={ inputNum1.required } 
                                    disabled={ inputNum1.disabled } 
                                    readOnly={ inputNum1.readonly }                                                                         
                                />  
                            </div>
                            <div className={"col-3 mt-2 noDisplay spinNum1_"+rowData.id}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-3 mt-1 noDisplay checkNum1_"+rowData.id} style={{padding: 0, color: "#28a745"}}>
                                <i className="fal fa-check fa-2x"></i>  
                            </div>
                            <div className={"col-3 mt-1 noDisplay errorNum1_"+rowData.id} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>
                        </div>                              
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)
                } 
            }
            if( i == 2){
                // Ubicación
                columna.createdCell = async (td, cellData, rowData, row, col) => {  
                    let options = [];
                    let ubicacionesInfo = rowData.ubicacion.split(";");
                    let idSelect;

                    const newDataSelectUbiAux = await getStockOptionsSelectUbi(optionSelectUbi, rowData.articulo, saveLoadingSelectUbi);   
                    options = setStockOptionsSelectUbi(newDataSelectUbiAux);

                    // ubicacionesInfo.map((u) => {                        
                    //     let x = u.split("=");
                    //     let ubi = x[0];
                    //     let cantidad = x[1];

                    //     if( rowData.tipostock != 2 ){
                    //         // Todos los documentos que NO sean Salidas:
                    //         // Mostrar todos los almacenes. Mostrar al final entre paréntesis la cantidad.                            
                    //         optionSelectUbi.map((option) => {
                    //             if( option.value == ubi ){
                    //                 let existeOption = options.filter(option2=> option2.value == option.value )
                    //                 if( existeOption.length == 0 ){
                    //                     options.push({"value": option.value, "label": option.label+` (Un.: ${cantidad})`});
                    //                 }
                    //             }else if(  option.value == rowData.ubicacionactual ){
                    //                 let existeOption = options.filter(option2=> option2.value == option.value )
                    //                 if( existeOption.length == 0 ){
                    //                     options.push({"value": option.value, "label": option.label+` (Un.: ${rowData.stockactual})`});
                    //                 }
                    //             }else{
                    //                 let existeOption = options.filter(option2=> option2.value == option.value )
                    //                 if( existeOption.length == 0 ){
                    //                     options.push({"value": option.value, "label": option.label+` (Un.: NO_DATA)`});
                    //                 }
                    //             }
                    //         })
                    //     }else if( rowData.tipostock == 2 ){
                    //         // Todos los documentos que SI sean Salidas:
                    //         // Salidas. -Si es una salida, mostrar solo los almacenes donde hay stock (incluir el actual) y añadir paréntesis con la cantidad.
                    //             // Recorremos los option y los comparama con las ubicaciones de este row para ver que options metemos en el select. Metemos los que vienen en el sql que nos indica la función app_stkubi y metemos la ubicación actual del registro
                    //             optionSelectUbi.map((option) => {
                    //                 if( option.value == ubi ){
                    //                     let existeOption = options.filter(option2=> option2.value == option.value )
                    //                     if( existeOption.length == 0 ){
                    //                         options.push({"value": option.value, "label": option.label+` (Un.: ${cantidad})`});
                    //                     }
                    //                 }
                    //                 if(  option.value == rowData.ubicacionactual ){
                    //                     let existeOption = options.filter(option2=> option2.value == option.value )
                    //                     if( existeOption.length == 0 ){
                    //                         options.push({"value": option.value, "label": option.label+` (Un.: ${rowData.stockactual})`});
                    //                     }
                    //                 }
                    //             })
                    //     }


                    // })
                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-11" >
                                <BaseSelect
                                        className={rowData.id} 
                                        options={options}
                                        name={selectUbi.name}             
                                        id={row}
                                        placeholder={ selectUbi.placeholder } 
                                        // value={options.length > 0 ? options.filter(option => option.value == rowData.ubicacionactual) : null}
                                        defaultValue={ options.length > 0 ? options.filter(option => option.value == rowData.ubicacionactual) : null } 
                                        onChange={ e => handleChangeUbi(e, rowData) }
                                        required={selectUbi.required}
                                        isDisabled={ selectUbi.disabled}
                                        isClearable={selectUbi.isclearable}
                                        isSearchable={selectUbi.issearchable}
                                        isMulti={false}
                                        isLoading={loadingSelectUbi}
                                        noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title }
                                        menuPlacement={"auto"}
                                        menuPortalTarget={document.body}                                              
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}                              
                                />  
                            </div>
                            <div className={"col-1 mt-2 noDisplay spin_"+rowData.id}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay check_"+rowData.id} style={{padding: 0, color: "#28a745"}}>
                                <i className="fal fa-check fa-2x"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay error_"+rowData.id} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>
                        </div>                              
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)                                         
                }
            }
            if( i == 3 ){
                // Editar y Borrar
                columna.createdCell = (td, cellData, rowData, row, col) => {  
                    let templateNecesario =  ReactDOM.render(
                        <div className="flex-center">
                            <a 
                                className="btn btn-flat btn-outline-primary"
                                style={{border: "none"}} 
                                href={getPantDocFromNivel(getDecodedCookie('nivel'))+"/"+rowData["iddoc"]+"/"+rowData["id"]} 
                            >
                                <i className="fal fa-eye"></i>  
                            </a>
                            <span 
                                id={ rowData.id } 
                                className="btn btn-flat btn-outline-danger deleteRow" 
                                style={{border: 0}}
                                onClick={ e => { handleDeleteItem(e) } }>
                                    <i  
                                            id={ rowData.id }
                                            className="fal fa-trash-alt deleteRow"
                                            onClick={ e => { handleDeleteItem(e, rowData) }}
                                        >
                                    </i>
                            </span>
                            <div className={"col-1 mt-2 noDisplay spinDel_"+rowData.id}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay errorDel_"+rowData.id} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>                             
                        </div>
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)
                } 
            }
        })


        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        },
                        rowCallback : function (row, data, index){ 
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }
                        }
                    })
                }  
                // saveLoadingTable(false);       
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{               
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: null,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }         
                        },
                        rowCallback : function (row, data, index){
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }


                        }
                    })
                }
                // saveLoadingTable(false);
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({
                    mark: true,
                    rowId: function(a) {
                        return '#row-' + a.id;},
                    dom: buttons,
                    data: null,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }       
                    },
                    rowCallback : function (row, data, index){
                        // Si algún td incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                        for (let item of row.children) {
                            if( item.outerText.includes("#") ){
                                item.bgColor = item.outerText;
                                item.innerText = "";
                            }
                        }                        
                    }
                })
            }
            // saveLoadingTable(false);
            
        }
        
        saveLoadingTable(false); 
        
    }, [data, formulario.id])
    

    const handleChangeUbi = async (e, rowData) => {

        let idLinea = rowData.id;
        let idUbi;
        if( e ){
            idUbi = e.value;
        }else{
            idUbi = 0;
        }

        let spinner = document.querySelector(".spin_"+idLinea);        
        let check = document.querySelector(".check_"+idLinea);        
        let error = document.querySelector(".error_"+idLinea);        

        if( spinner ){
            spinner.classList.toggle('noDisplay');
        }
        if( check ){
            check.classList.add('noDisplay');
        }
        if( error ){
            error.classList.add('noDisplay');
        }

        const dataForm = new FormData();      
        dataForm.append("validacion", apiKey());
        dataForm.append("idlinea", idLinea);
        dataForm.append("idubi", idUbi);
        dataForm.append("iduni", rowData.articulo)
        const respuesta = await axios.post(getApiUrl(window.location.origin)+"/edirap/updateUbicacion",dataForm);
        
        spinner.classList.toggle('noDisplay');

        if(respuesta.data == idLinea){
            if( check ){
                check.classList.toggle('noDisplay');
            }
        }else if(typeof respuesta.data == "string"){    
            if( error ){
                error.classList.toggle('noDisplay');
            }
        }

        // Actulizamos los datos del state del datatable para que se refresque correctamente
        const auxData = $("."+id).DataTable().rows().data().map(aux => {                       
            if(aux.id == idLinea){
                aux.ubicacionactual = idUbi;
            }
            return aux;
        })
        setTimeout(()=>{
            saveData(auxData); 
        }, 500)

    }

    const handleChangeNum1 = async (e, rowData) => {

        let num1 = e.target.value
        let idLinea = rowData.id;

        let spinner = document.querySelector(".spinNum1_"+idLinea);        
        let check = document.querySelector(".checkNum1_"+idLinea);        
        let error = document.querySelector(".errorNum1_"+idLinea);        

        if( spinner ){
            spinner.classList.toggle('noDisplay');
        }
        if( check ){
            check.classList.add('noDisplay');
        }
        if( error ){
            error.classList.add('noDisplay');
        }

        const dataForm = new FormData();      
        dataForm.append("validacion", apiKey());
        dataForm.append("idlinea", idLinea);
        dataForm.append("num1", num1);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+"/edirap/updateNum1",dataForm);
        
        spinner.classList.toggle('noDisplay');

        if(respuesta.data == idLinea){
            if( check ){
                check.classList.toggle('noDisplay');
            }
        }else if(typeof respuesta.data == "string"){    
            if( error ){
                error.classList.toggle('noDisplay');
            }
        }

        // Actulizamos los datos del state del datatable para que se refresque correctamente
        const auxData = $("."+id).DataTable().rows().data().map(aux => {                       
            if(aux.id == idLinea){
                aux.cantidad = num1;
            }
            return aux;
        })
        setTimeout(()=>{
            saveData(auxData); 
        }, 500)

    }

    const handleChangeUni = async (e, rowData) => {

        let idLinea = rowData.id;
        let idUni;
        if( e ){
            idUni = e.value;
        }else{
            idUni = 0;
        }

        let spinner = document.querySelector(".spinUni_"+idLinea);        
        let check = document.querySelector(".checkUni_"+idLinea);        
        let error = document.querySelector(".errorUni_"+idLinea);        

        if( spinner ){
            spinner.classList.toggle('noDisplay');
        }
        if( check ){
            check.classList.add('noDisplay');
        }
        if( error ){
            error.classList.add('noDisplay');
        }

        const dataForm = new FormData();      
        dataForm.append("validacion", apiKey());
        dataForm.append("idlinea", idLinea);
        dataForm.append("iduni", idUni);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+"/edirap/updateUnidad",dataForm);
        
        spinner.classList.toggle('noDisplay');

        if(respuesta.data == idLinea){
            if( check ){
                check.classList.toggle('noDisplay');
            }
        }else if(typeof respuesta.data == "string"){    
            if( error ){
                error.classList.toggle('noDisplay');
            }
        }

        // Actulizamos los datos del state del datatable para que se refresque correctamente
        const auxData = $("."+id).DataTable().rows().data().map(aux => {                       
            if(aux.id == idLinea){
                aux.articulo = idUni;
            }
            return aux;
        })
        setTimeout(()=>{
            saveData(auxData); 
        }, 500)      
    }
    
    const handleDeleteItem = (e, rowData) => {
        e.stopPropagation();
        let idLinea = e.target.id;
        swal({
            title: swaldelete.title,
            text: swaldelete.text.title,
            icon: "warning",
            buttons: {
                cancel : {text: swaldelete.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                confirm : {text: swaldelete.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-danger btnSwalDelete'}
            } 
        }).then(confirmacion => {
            if(confirmacion){
                deleteLinea(idLinea);
            }
        })
    }

    const deleteLinea = async (idLinea) => {
        let spinner = document.querySelector(".spinDel_"+idLinea);        
        
        if( spinner ){
            spinner.classList.toggle('noDisplay');
        }
        
        const dataForm = new FormData(); 
        dataForm.append("validacion", apiKey());
        dataForm.append("id", idLinea);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+"/lineas/deletelineas",dataForm);      
        if(respuesta.data !== 0 ){
            // Borrado correcto. Borramos el tr
            const tr = document.querySelector("#\\#row-"+idLinea);
            if( tr ){
                tr.remove();
            }
            // Actulizamos los datos del state del datatable para que se refresque correctamente
            const auxData = $("."+id).DataTable().rows().data().filter(aux => {                       
                if(aux.id != idLinea){                    
                    return aux;
                }
            })
            saveData(auxData); 
 
        }else if(typeof respuesta.data == "string"){    
            // Error borrando
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});  
            if( spinner ){
                spinner.classList.toggle('noDisplay');
            }          
        }
    }
    


    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;
 
    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas





    return(
        <Fragment>
            {   loadingTable ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }
            <div style={{overflowX: "auto"}}>
                <table id={id} className={id+" table table-sm table-bordered table-striped table-hover dataTable"} style={{width:"99%"}} ></table>
            </div>   
            
        </Fragment>
    )
}

export default MiDataTableEdiRap;