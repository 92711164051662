import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import Kpi from '../../componentes/ui/Kpi';

import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';

import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getTitle, getDecodedCookie } from '../../helpers/helperApp';

function Kpis(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    // const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    // const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");
    const [ loadingTable, saveLoadingTable] = useState(true)
    const [ datosTableKpis, saveDatosTableKpis ] = useState([]);
    const [ idCen, saveIdCent ] = useState();
    const [ ini, saveIni ] = useState();
    const [ fin, saveFin ] = useState();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, saveFilterRegs, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                    
                    document.title = getTitle(confPantalla.config.trad, confPantalla.kpis.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);                                               
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                // Filtrar los registros por la opción de configuración que recibimos como parámetro
                                if( props.location.state ){ 
                                    // Recibimos centro y fechas
                                    if( "idcen" in props.location.state && "ini" in props.location.state && "fin" in props.location.state ){
                                        saveIdCent(props.location.state.idcen);
                                        saveIni(props.location.state.ini);
                                        saveFin(props.location.state.fin);
                                    }
                                    // Si solo recibimos el centro cogemos las fechas de las cookies de los kpi, sino hay cookies pillamos desde el día 1 del año en curso hasta hoy
                                    if( "idcen" in props.location.state ){
                                        saveIdCent(props.location.state.idcen);
                                        let ini = getDecodedCookie('kpiIni');
                                        let fin = getDecodedCookie('kpiFin');
                                        let date = new Date();
                                        let mesHoy = date.getMonth() + 1;
                                        let h = date.getDate();
                                        if( !ini ){
                                            ini = date.getFullYear()+"-01-01";
                                        }
                                        if( !fin ){
                                            fin = date.getFullYear()+"-"+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+"-"+(h < 10 ? h = '0'+h : h = h );
                                        }
                                        saveIni(ini);
                                        saveFin(fin);
                                    }                    
                                }
                                saveLoadingTable(true);                                                        
                            }
                        }  
                                            
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                // cookies.remove('filterRegs', {path: '/'});
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])


    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>     
            {
                confPantalla !== undefined
                ?
                    <Fragment>
                        <Menusup 
                            props = { props }
                        />
                        <Sidebar 
                            props = { props }
                        />
                    </Fragment>
                :
                null
            } 
            <div className="content-wrapper">
                <div className="content-header">
                </div>
                <section className="content">
                {
                        !tieneNivel 
                        ?
                            <PermissionRefused
                                traduction={confPantalla.config.trad.components.permissionrefused} 
                            />
                        :
                            <Fragment>
                            {
                                loadedConf === true && confPantalla !== undefined
                                ?
                                    <Section titulo={ confPantalla.kpis.section.titulo } icono={ confPantalla.kpis.section.icono } col={ confPantalla.kpis.section.col } wiki={ confPantalla.kpis.section.wiki } >

                                            <Kpi
                                                conf={ confPantalla.kpis.padre.controles.kpi }
                                                getDataTable1 = { confPantalla.kpis.padre.controles.tabla1.datatable.getdata }
                                                saveDataTable1 = { saveDatosTableKpis }
                                                saveLoadingTable = { saveLoadingTable }
                                                filtro={ confPantalla.kpis.padre.controles.centro }
                                                fecIni={ ini }
                                                fecFin={ fin } 
                                                idFiltro={ idCen }
                                            />
                                            <div className="row" >
                                                    <div className="col-12">                                    
                                                        <MiDataTable 
                                                            estadoForm= { "" }
                                                            formulario={ {id:0} }
                                                            id={"kpis"}
                                                            columnas={confPantalla.kpis.padre.controles.tabla1.datatable.columns}
                                                            data={datosTableKpis}
                                                            linkColumns={confPantalla.kpis.padre.controles.tabla1.datatable.linkColumns}
                                                            buttons={confPantalla.kpis.padre.controles.tabla1.datatable.buttons}
                                                            ordering={confPantalla.kpis.padre.controles.tabla1.datatable.ordering}
                                                            order={confPantalla.kpis.padre.controles.tabla1.datatable.order}
                                                            searching={confPantalla.kpis.padre.controles.tabla1.datatable.searching}
                                                            paging={confPantalla.kpis.padre.controles.tabla1.datatable.paging}
                                                            select={confPantalla.kpis.padre.controles.tabla1.datatable.select}
                                                            pagelength={confPantalla.kpis.padre.controles.tabla1.datatable.pagelength}
                                                            // saveLoadingTable={ saveLoadingTable }
                                                            loadingTable={loadingTable}
                                                            columnactions={confPantalla.kpis.padre.controles.tabla1.datatable.title}
                                                            traduction={confPantalla.config.trad.components.datatable}
                                                        />                          
                                                    </div>
                                                </div>
                                                   
                                    </Section>
                                :
                                    <LoadingPage />
                            }
                            </Fragment>
                }
                </section>
            </div>
        <Footer />        
        </div>

    );

}
export default Kpis