import React, { useState, useEffect, Fragment } from 'react';
import Spinner from 'react-bootstrap/Spinner';



function SpinnerFullScreen(props) {

    let {spinner, textoSpinner, respuesta, estado, saveRegenerando} = props;

    useEffect(() => {

    }, [spinner, textoSpinner, respuesta, estado]);

    return (
        <div style={{
            backgroundColor: "rgba(0,0,0,.3)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }}>
            {
                spinner != "" && estado == ""
                ?
                    <Fragment>
                        <div class="lds-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        {/* <Spinner animation="border" />                     */}
                            {
                                textoSpinner != "" && textoSpinner
                                ?
                                    <div className="alert text-center" role="alert" 
                                        style={{
                                            border : "1px solid white",
                                            backgroundColor: "rgba(0,0,0,0.2)",
                                            color : "white",
                                            fontSize : "1.5em"
                                        }}
                                    >
                                        <p style={{marginBottom : "0px"}}>{textoSpinner}</p>
                                    </div>
                                :
                                    null
                            }
                    </Fragment>
                :
                    null
            }
            {
                estado == "success" && textoSpinner == ""
                ?
                    <div className="alert alert-dismissible fade show text-center" role="alert" 
                        style={{
                            // border : "1px solid white",
                            backgroundColor: "rgba(212,237,218,0.7)",
                            // color : "white",
                            fontSize : "1.5em"
                        }}
                    >
                        <p>
                            <i className="fal fa-check mr-2 fa-2x" style={{color : "#28a745"}}></i>
                            {respuesta}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={ e => saveRegenerando(false)} style={{fontSize:"1.5em", marginTop :"12px"}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </p>
                    </div>
                :
                    null
            }
            {
                estado == "error" && textoSpinner == ""
                ?
                    <div className="alert alert-dismissible fade show text-center" role="alert"
                        style={{
                            // border : "1px solid white",
                            backgroundColor: "rgba(248,215,218,0.7)",
                            // color : "white",
                            fontSize : "1.5em"
                        }}
                    >
                        <p>
                            <i className="fal fa-times mr-2 fa-2x" style={{color : "#dc3545"}}></i>
                            {respuesta}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={ e => saveRegenerando(false)} style={{fontSize:"1.5em", marginTop :"12px"}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </p>
                    </div>
                :
                    null
            }
        </div>
    );
}
export default SpinnerFullScreen
