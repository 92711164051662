import React, { useState, useEffect, useContext } from 'react';

import UplmPendingList from './UplmPendingList';
import UplmList from './UplmList';

import { LoginContext } from '../../../hooks/context/LoginContext';


import imageCompression from "browser-image-compression";
import axios from 'axios';
import swal from 'sweetalert';


import { getApiUrl, axiosPost } from '../../../helpers/helperApp';

function Uplm(props){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { col, id, adjuntos, saveAdjuntos, adjuntosExistentes, saveAdjuntosExistentes, readonly, loading, estadoForm, traduction, dropzone } = props;

    // ***** States *****
    const [validacionesUplm, saveValidacionesUplm] = useState([]);

    // ***** Context *****
    const { apiKey } = useContext(LoginContext);

    useEffect( () => {
        // ***** Carga inicial de validaciones *****
        if( validacionesUplm.length === 0 ){
            const data = new FormData(); 
            const validacion = apiKey();
            data.append("validacion", validacion);
            const getValidacionesUplm = async () => {
                await axios.post( getApiUrl(window.location.origin)+"/adj/getValidaciones", data)
                // await axiosPost(getApiUrl(window.location.origin)+"/adj/getValidaciones", data, source.token)
                .then( response => {
                    if( typeof response.data !== "string" ){
                        saveValidacionesUplm(response.data);
                    }
                    // else{
                    //     swal("Error", `${traduction.loadvalidationserror.title}`, "error");
                    // }
                })
                .catch( error => {
                    swal("Error", `${traduction.loadvalidationserror.title}`, "error");
                })
            }

            getValidacionesUplm();
        }

        return ( () => {
            saveValidacionesUplm([]); 
            // Token cancelación axios al desmontar componentes
            source.cancel();           
        })

    },[])

    // ***** Leer los archivos del input *****
    const handleFiles = async files => {
        await actionsFiles(files);
    }
    const handleDragEnter = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };
    const handleDragLeave = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };
    const handleDragOver = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };
    const handleDrop = async e => {
        e.preventDefault();
        e.stopPropagation();
        await actionsFiles(e.dataTransfer.files);
    };

    const actionsFiles = async files => {

        let extensiones = validacionesUplm[0]["val"].split("|");
        let maxWidth = parseInt(validacionesUplm[1]["val"].split("x")[0]);
        let maxSize = validacionesUplm[2]["val"];
        const optionsCompres = {          
            maxSizeMB: validacionesUplm[2]["val"],
            maxWidthOrHeight: maxWidth,
            useWebWorker: false
        }

        
        
        for(let i = 0; i < files.length; i++){
            
            const duplicado = adjuntos.filter( adj =>  adj.name === files[i].name );

            if(duplicado.length === 0){
                let valido = true;
                //Validamos la extensión
                if( !validateExtension(files[i], extensiones) ){
                    swal("Error", `${files[i]["name"]} ${traduction.errorextension.title}`, "error");
                    valido = false;
                }
    
                if( files[i]["type"].split("/")[0] === "image" ){
                    //Imágenes                
                        //Hacemos el resize para las imágenes                    
                        let fileResize = await imageCompression(files[i], optionsCompres); 
                        let aux = await blobToFile(fileResize, files[i]);
                        
                        //Actualizamos el state
                        await saveAdjuntos( adjuntos => [...adjuntos, aux] ) 
                }else{
                    //Resto de arhivos
                    if( !validateSize(files[i]) ){                   
                        swal("Error", `${files[i]["name"]} ${traduction.errorsize.title} (${maxSize}Mb)`, "error");
                    }else{
                        //Actualizamos el state
                        if(valido){ 
                            await saveAdjuntos( adjuntos => [...adjuntos, files[i]] )  
                        } 
                    }
                }
            }else{
                swal("Error", `${duplicado[0]["name"]} ${traduction.duplicate.title}`, "error");
            }

        }
        //Borramos el value del input file multiple para manejarlos a nuestro antojo en la función que crea el FormData y envía el formulario
        document.querySelector(`#${id}`).value = "";
    }

    const validateExtension = (file, extensiones) => {
        let fileExtension = file["name"].split(".")[file["name"].split(".").length-1];
        const valido = extensiones.find(ext => fileExtension.toLowerCase() == ext.toLowerCase());
        return valido;
    }

    const validateSize = (file) => {
        let valido = true;
        let maxSize = validacionesUplm[2]["val"];

        if( (file["size"] / 1000) > (maxSize * 1000) ){
            valido = false;
        }

        return valido;
    }

    const blobToFile = (blob, file) => {
        blob.lastModifiedDate = file.lastModifiedDate;
        blob.name = file.name;
        return new File([blob], file.name,{type: "image/jpeg", lastModified: file.lastModifiedDate });     
    }

    return(
        <div className={"form-group col-md-"+col}>
                    <div className="containerCards">  
                        <UplmList 
                            id={id} 
                            adjuntosExistentes= { adjuntosExistentes } 
                            saveAdjuntosExistentes= { saveAdjuntosExistentes }
                            readonly={readonly}
                            loading={loading}
                            estadoForm={estadoForm}
                            traduction={traduction}
                        />
                    </div>
                    {
                        readonly !== "true" && readonly !== "readonly" && !loading && estadoForm !== "readonly" && estadoForm !== "disabled" 
                        ?
                        <div>                        
                            <div className="row fileupload-buttonbar">
                                <div className="col-lg-12">
                                    {
                                        dropzone != false
                                        ?
                                            <div className="well dropzone dropzone" 
                                                onDrop={e => handleDrop(e)}
                                                onDragOver={e => handleDragOver(e)}
                                                onDragEnter={e => handleDragEnter(e)}
                                                onDragLeave={e => handleDragLeave(e)}
                                            >
                                                <h3>{traduction.dropzone.title}</h3>
                                                <span><i className="fal fa-upload fa-2x"></i></span>
                                            </div>
                                        :
                                            null
                                    }
                                    
                                    <span className="btn btn-flat btn-success fileinput-button adj btn-block btn-lg " id="fileinput-buttonadj">
                                        <i className="far fa-paperclip mr-1" style={{transform : "rotate(-45deg)"}}></i>
                                        <span>{traduction.btnadd.title}</span>
                                        <input className="btn btn-success fileinput-button" id={id} type="file"  name={id+"[]"} multiple="multiple" onChange={ (e) => handleFiles(e.target.files) } />
                                    </span>
                                </div>
                            </div>
                            <div className="container-fluid" style={{marginTop:15}}>
                                <div className="containerCards">                                
                                        <UplmPendingList
                                            id={id}
                                            adjuntos={ adjuntos }
                                            saveAdjuntos= { saveAdjuntos }
                                            traduction={traduction}   
                                        />                                            
                                </div>
                            </div>
                        </div>
                        :
                        <div>                        
                            <div className="row fileupload-buttonbar" style={{display: "none"}}>
                                <div className="col-lg-12">
                                    <div className="well dropzone dropzone">
                                        <h3>{traduction.dropzone.title}</h3>
                                        <span><i className="fal fa-upload fa-2x"></i></span>
                                    </div>
                                    <span className="btn btn-flat btn-success fileinput-button adj btn-block btn-lg " id="fileinput-buttonadj">
                                        <i className="far fa-plus"></i>
                                        <span>{traduction.btnadd.title}</span>
                                        <input className="btn btn-success fileinput-button disable-click" id={id} type="file"  name={id+"[]"} multiple="multiple" />
                                    </span>
                                </div>
                            </div>
                            <div className="container-fluid" style={{marginTop:15}}>
                                <div className="containerCards">                                
                                        <UplmPendingList
                                            id={id}
                                            adjuntos={ adjuntos }
                                            saveAdjuntos= { saveAdjuntos } 
                                            traduction={traduction}  
                                        />                                            
                                </div>
                            </div>
                        </div>
                    }
                        
        </div>
    )
}

export default Uplm;