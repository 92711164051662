import React, { useState, useContext, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../helpers/FixRequiredSelect";
import axios from 'axios';
import Cookies from 'universal-cookie';


import { LoginContext } from '../../../hooks/context/LoginContext';

import { getApiUrl, axiosPost } from '../../../helpers/helperApp';

import TableMiDataTableInfo from './TableMiDataTableInfo';


function MiDataTableInfoRegActual(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();   

    const { apiKey } = useContext(LoginContext);

    const { id, loading, formulario, table, traduction } = props;

    const [options, saveOptions] = useState([]);
    const [datosTable, saveDatosTable] = useState(null);

     
    useEffect( () => {   

        // getDataDataTable(val)
        getDataDataTable()
        .then((res) => {
            saveDatosTable(res);
        });

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 

    },[ loading, formulario.id])

    const getDataDataTable = async () =>{

        const data = new FormData();
        data.append("id", formulario.id);
        // const res = await axios.post(getApiUrl(window.location.origin)+table.data.url, data);
        const res = await axiosPost(getApiUrl(window.location.origin)+table.data.url, data, source.token);

        return res.data;
    }

    return(
        <TableMiDataTableInfo 
            id={ "tableInfo-"+id }
            columnas={ table.columns }
            data={ datosTable }
            buttons={ table.buttons }
            ordering={ table.ordering }
            order={ table.order }
            searching={ table.searching }
            paging={ table.paging }
            select={ table.select }
            columnactions={ table.columnactions.title }
            traduction={ traduction }
            linkColumns = {table.linkColumns }
            pagelength = { table.pagelength }
        />                  
    )
}

export default MiDataTableInfoRegActual;