import React from 'react';
import ButtonOutline from '../ui/ButtonOutline';
import { Fragment } from 'react';

function WizardBtns(props){

    const {accionBtnExit, accionBtnPrev, accionBtnNext, disabledBtnExit, disabledBtnPrev, disabledBtnNext} = props;

    

    return(
        <div style={{paddingBottom: "85px"}}>
            <div className="float-left">
                {
                   accionBtnExit
                   ?
                        <ButtonOutline 
                            clases={ "btn-trab" }
                            color={ "danger" }  
                            type="button" 
                            icono={ "fal fa-sign-out-alt " } 
                            id=""     
                            texto={ "" }   
                            accion={ accionBtnExit } 
                            disabled={ disabledBtnExit }
                        />                                                       
                   :
                        null 
                }
            </div>
            <div className="float-right">
                {
                    accionBtnPrev
                    ?
                        <ButtonOutline 
                            clases={ "btn-trab" }
                            color={ "primary" }  
                            type="button" 
                            icono={ "fal fa-arrow-left " }  
                            id=""     
                            texto={ "" }   
                            accion={ accionBtnPrev } 
                            disabled={ disabledBtnPrev }
                        />                                     
                    :
                        null
                }
                {
                    accionBtnNext
                    ?
                        <ButtonOutline 
                            clases={ "btn-trab" }
                            color={ "primary" }  
                            type="button" 
                            icono={ "fal fa-arrow-right " } 
                            id=""     
                            texto={ "" }   
                            accion={ accionBtnNext } 
                            disabled={ disabledBtnNext }
                        />
                    :
                        null
                }
            </div>
        </div>
    )
}

export default WizardBtns;