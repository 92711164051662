import React from 'react';

function FormRow(props){

    return(
        <div className={props.clases ? "form-row "+props.clases : "form-row "}>
            { props.children }
        </div>
    )
}

export default FormRow;