import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect  from '../../componentes/formulario/MiSelect';
import Button from '../../componentes/ui/Button';
import FormChangePass from '../../componentes/formulario/forms/FormChangePass';
import MiModal from '../../componentes/ui/MiModal';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';

import useLogicForm from '../../hooks/useLogicForm';

import Spinner from 'react-bootstrap/Spinner';

import Cookies from 'universal-cookie';
import { Fragment } from 'react';
import ButtonOutline from '../../componentes/ui/ButtonOutline';

import { getTitle, getDecodedCookie } from '../../helpers/helperApp';

function LoginUsu(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);  
    
    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, handleSelectFormPrin, handleCheckEmail, handleChangePass, showChangePass, saveShowChangePass, handleSelectMultipleFormPrin } = useLogicForm();
    
    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{
                    document.title = getTitle(confPantalla.config.trad, confPantalla.loginusu.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);     
                        saveFormularioPrin(confPantalla.loginusu.padre.modelform);
                        saveFormularioHijo(confPantalla.loginusu.hijo.modelform);                           
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.loginusu.padre.urls);
                                saveFormularioPrin(confPantalla.loginusu.padre.modelform);
                                saveFormularioHijo(confPantalla.loginusu.hijo.modelform);                                
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.loginusu.padre.urls, confPantalla.loginusu.hijo.urls, confPantalla.loginusu.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                   
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])


    return(
        <div className="App wrapper"> 
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
                null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
               
                    {
                        !tieneNivel
                        ?
                            <PermissionRefused
                                traduction={confPantalla.config.trad.components.permissionrefused} 
                            />
                        :
                        <Fragment>
                            {
                                loadedConf === true && confPantalla !== undefined
                                ?
                                    <Section titulo={ confPantalla.loginusu.section.titulo } icono={ confPantalla.loginusu.section.icono } col={ confPantalla.loginusu.section.titulo } wiki={ confPantalla.loginusu.section.wiki } >
                                        <Formulario 
                                            estadoForm= { estadoFormPrin }
                                            loading=    { loadReg ? true : false }
                                            btnNew={ confPantalla.loginusu.padre.btnform.new }
                                            btnSave={ confPantalla.loginusu.padre.btnform.save }
                                            btnSaveAndNew={ confPantalla.loginusu.padre.btnform.saveandnew }
                                            btnDelete={ confPantalla.loginusu.padre.btnform.delete }
                                            btnClone={ confPantalla.loginusu.padre.btnform.clone }
                                            handleSubmit = { e => handleSubmitPrin(e, confPantalla.loginusu.padre.urls, confPantalla.loginusu.padre.modelform, confPantalla.loginusu.padre.tabla, confPantalla.loginusu.hijo.modelform, confPantalla.loginusu.title) } 
                                            formulario = { formularioPrin } 
                                            resetForm = { e => resetFormPrin(confPantalla.loginusu.padre.modelform, true, confPantalla.loginusu.padre.tabla) } 
                                            handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.loginusu.padre.urls, confPantalla.loginusu.padre.modelform, confPantalla.loginusu.padre.tabla, confPantalla.loginusu.title) } 

                                        >                               
                                            {/* <Tabs defaultActiveKey="tab1" id="tab1">
                                                <Tab eventKey="tab1" title={ confPantalla.loginusu.tabs.tab1.titulo }> */}
                                                    <FormRow clases="" >
                                                        <FormInput 
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nombre }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.loginusu.padre.controles.nombre.id }   
                                                            type=       { confPantalla.loginusu.padre.controles.nombre.type }  
                                                            name=       { confPantalla.loginusu.padre.controles.nombre.name }   
                                                            label=      { confPantalla.loginusu.padre.controles.nombre.label }                                                       
                                                            placeholder={ confPantalla.loginusu.padre.controles.nombre.placeholder }    
                                                            col=        { confPantalla.loginusu.padre.controles.nombre.col } 
                                                            required=   { confPantalla.loginusu.padre.controles.nombre.required }
                                                            readonly=   { confPantalla.loginusu.padre.controles.nombre.readonly }                                                
                                                            disabled=   { confPantalla.loginusu.padre.controles.nombre.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />                       
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idnvl  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.loginusu.padre.controles.nivel.id }    
                                                            name=        { confPantalla.loginusu.padre.controles.nivel.name }   
                                                            label=       { confPantalla.loginusu.padre.controles.nivel.label }    
                                                            placeholder= { confPantalla.loginusu.padre.controles.nivel.placeholder }
                                                            col=         { confPantalla.loginusu.padre.controles.nivel.col } 
                                                            isClearable= { confPantalla.loginusu.padre.controles.nivel.isclearable }
                                                            isSearchable={ confPantalla.loginusu.padre.controles.nivel.issearchable }
                                                            dataType=    { confPantalla.loginusu.padre.controles.nivel.datatype }
                                                            data=        { confPantalla.loginusu.padre.controles.nivel.data }
                                                            required=    { confPantalla.loginusu.padre.controles.nivel.required } 
                                                            disabled=  { confPantalla.loginusu.padre.controles.nivel.disabled }
                                                            readOnly =   { confPantalla.loginusu.padre.controles.nivel.readonly }
                                                            disablethisid={confPantalla.loginusu.padre.controles.nivel.disablethisid}
                                                            formulario = { formularioPrin }   
                                                            change={ handleSelectFormPrin }                                                    
                                                        />                                   
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <FormInput 
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.email }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.loginusu.padre.controles.email.id }   
                                                            type=       { confPantalla.loginusu.padre.controles.email.type }  
                                                            name=       { confPantalla.loginusu.padre.controles.email.name }   
                                                            label=      { confPantalla.loginusu.padre.controles.email.label }                                                       
                                                            placeholder={ confPantalla.loginusu.padre.controles.email.placeholder }    
                                                            col=        { confPantalla.loginusu.padre.controles.email.col } 
                                                            required=   { confPantalla.loginusu.padre.controles.email.required }
                                                            readonly=   { confPantalla.loginusu.padre.controles.email.readonly }                                                
                                                            disabled=   { confPantalla.loginusu.padre.controles.email.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     { handleCheckEmail } 
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.estado  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.loginusu.padre.controles.estado.id }    
                                                            name=        { confPantalla.loginusu.padre.controles.estado.name }   
                                                            label=       { confPantalla.loginusu.padre.controles.estado.label }    
                                                            placeholder= { confPantalla.loginusu.padre.controles.estado.placeholder }
                                                            col=         { confPantalla.loginusu.padre.controles.estado.col } 
                                                            isClearable= { confPantalla.loginusu.padre.controles.estado.isclearable }
                                                            isSearchable={ confPantalla.loginusu.padre.controles.estado.issearchable }
                                                            dataType=    { confPantalla.loginusu.padre.controles.estado.datatype }
                                                            data=        { confPantalla.loginusu.padre.controles.estado.data }
                                                            required=    { confPantalla.loginusu.padre.controles.estado.required } 
                                                            disabled=  { confPantalla.loginusu.padre.controles.estado.disabled }
                                                            readOnly =   { confPantalla.loginusu.padre.controles.estado.readonly }
                                                            disablethisid={confPantalla.loginusu.padre.controles.estado.disablethisid}
                                                            formulario = { formularioPrin }   
                                                            change={ handleSelectFormPrin }   
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelectMultiple  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_usu_cen  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.loginusu.padre.controles.rel_usu_cen.id }    
                                                            name=        { confPantalla.loginusu.padre.controles.rel_usu_cen.name }   
                                                            label=       { confPantalla.loginusu.padre.controles.rel_usu_cen.label }    
                                                            placeholder= { confPantalla.loginusu.padre.controles.rel_usu_cen.placeholder }
                                                            col=         { confPantalla.loginusu.padre.controles.rel_usu_cen.col } 
                                                            isClearable= { confPantalla.loginusu.padre.controles.rel_usu_cen.isclearable }
                                                            isSearchable={ confPantalla.loginusu.padre.controles.rel_usu_cen.issearchable }
                                                            dataType=    { confPantalla.loginusu.padre.controles.rel_usu_cen.datatype }
                                                            data=        { confPantalla.loginusu.padre.controles.rel_usu_cen.data }
                                                            required=    { confPantalla.loginusu.padre.controles.rel_usu_cen.required } 
                                                            disabled=  { confPantalla.loginusu.padre.controles.rel_usu_cen.disabled }
                                                            readOnly =   { confPantalla.loginusu.padre.controles.rel_usu_cen.readonly }
                                                            disablethisid={confPantalla.loginusu.padre.controles.rel_usu_cen.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectMultipleFormPrin }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelectMultiple  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_usu_gf  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.loginusu.padre.controles.rel_usu_gf.id }    
                                                            name=        { confPantalla.loginusu.padre.controles.rel_usu_gf.name }   
                                                            label=       { confPantalla.loginusu.padre.controles.rel_usu_gf.label }    
                                                            placeholder= { confPantalla.loginusu.padre.controles.rel_usu_gf.placeholder }
                                                            col=         { confPantalla.loginusu.padre.controles.rel_usu_gf.col } 
                                                            isClearable= { confPantalla.loginusu.padre.controles.rel_usu_gf.isclearable }
                                                            isSearchable={ confPantalla.loginusu.padre.controles.rel_usu_gf.issearchable }
                                                            dataType=    { confPantalla.loginusu.padre.controles.rel_usu_gf.datatype }
                                                            data=        { confPantalla.loginusu.padre.controles.rel_usu_gf.data }
                                                            required=    { confPantalla.loginusu.padre.controles.rel_usu_gf.required } 
                                                            disabled=  { confPantalla.loginusu.padre.controles.rel_usu_gf.disabled }
                                                            readOnly =   { confPantalla.loginusu.padre.controles.rel_usu_gf.readonly }
                                                            disablethisid={confPantalla.loginusu.padre.controles.rel_usu_gf.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectMultipleFormPrin }
                                                        />
                                                    </FormRow>
                                                    {
                                                         getDecodedCookie('nivel') <= 2 
                                                         ?
                                                            <FormRow>                                        
                                                                <ButtonOutline 
                                                                        clases={ "col-"+confPantalla.loginusu.padre.controles.btnchangepass.col }
                                                                        color={ confPantalla.loginusu.padre.controles.btnchangepass.color }  
                                                                        type="button" 
                                                                        icono={ "mr-1 "+confPantalla.loginusu.padre.controles.btnchangepass.icono }   
                                                                        id=""     
                                                                        texto={ confPantalla.loginusu.padre.controles.btnchangepass.text.title }   
                                                                        accion={ handleChangePass } 
                                                                        disabled={ formularioPrin.id == "" || estadoFormPrin == "disabled" || estadoFormPrin == "readonly" ? true : confPantalla.loginusu.padre.controles.btnchangepass.disabled }
                                                                    />
                                                            </FormRow>
                                                         :
                                                            null
                                                    }
                                                {/* </Tab>
                                                <Tab eventKey="tab2" title={ confPantalla.loginusu.tabs.tab2.titulo }> */}
                                                    {/* <FormRow>
                                                        <ListaRel
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_usu_cen  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.loginusu.padre.controles.rel_usu_cen.select.id }    
                                                            name=        { confPantalla.loginusu.padre.controles.rel_usu_cen.select.name }   
                                                            label=       { confPantalla.loginusu.padre.controles.rel_usu_cen.select.label }    
                                                            placeholder= { confPantalla.loginusu.padre.controles.rel_usu_cen.select.placeholder }
                                                            col=         { confPantalla.loginusu.padre.controles.rel_usu_cen.select.col } 
                                                            isClearable= { confPantalla.loginusu.padre.controles.rel_usu_cen.select.isclearable }
                                                            isSearchable={ confPantalla.loginusu.padre.controles.rel_usu_cen.select.issearchable }
                                                            dataType=    { confPantalla.loginusu.padre.controles.rel_usu_cen.select.datatype }
                                                            data=        { confPantalla.loginusu.padre.controles.rel_usu_cen.select.data }
                                                            required=    { confPantalla.loginusu.padre.controles.rel_usu_cen.select.required } 
                                                            disabled=  { confPantalla.loginusu.padre.controles.rel_usu_cen.select.disabled }
                                                            readOnly =   { confPantalla.loginusu.padre.controles.rel_usu_cen.select.readonly }
                                                            disablethisid={ confPantalla.loginusu.padre.controles.rel_usu_cen.select.disablethisid }
                                                            formulario = { formularioPrin }
                                                            saveFormulario = { saveFormularioPrin }
                                                            table = { confPantalla.loginusu.padre.controles.rel_usu_cen.table }
                                                            traduction={confPantalla.config.trad.components.datatable}
                                                        />                                                        
                                                    </FormRow> */}
                                                {/* </Tab>
                                                <Tab eventKey="tab3" title={ confPantalla.loginusu.tabs.tab3.titulo }> */}
                                                    {/* <FormRow>
                                                        <ListaRel
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_usu_gf  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.loginusu.padre.controles.rel_usu_gf.select.id }    
                                                            name=        { confPantalla.loginusu.padre.controles.rel_usu_gf.select.name }   
                                                            label=       { confPantalla.loginusu.padre.controles.rel_usu_gf.select.label }    
                                                            placeholder= { confPantalla.loginusu.padre.controles.rel_usu_gf.select.placeholder }
                                                            col=         { confPantalla.loginusu.padre.controles.rel_usu_gf.select.col } 
                                                            isClearable= { confPantalla.loginusu.padre.controles.rel_usu_gf.select.isclearable }
                                                            isSearchable={ confPantalla.loginusu.padre.controles.rel_usu_gf.select.issearchable }
                                                            dataType=    { confPantalla.loginusu.padre.controles.rel_usu_gf.select.datatype }
                                                            data=        { confPantalla.loginusu.padre.controles.rel_usu_gf.select.data }
                                                            required=    { confPantalla.loginusu.padre.controles.rel_usu_gf.select.required } 
                                                            disabled=  { confPantalla.loginusu.padre.controles.rel_usu_gf.select.disabled }
                                                            readOnly =   { confPantalla.loginusu.padre.controles.rel_usu_gf.select.readonly }
                                                            disablethisid={ confPantalla.loginusu.padre.controles.rel_usu_gf.select.disablethisid }
                                                            formulario = { formularioPrin }
                                                            saveFormulario = { saveFormularioPrin }
                                                            table = { confPantalla.loginusu.padre.controles.rel_usu_gf.table }
                                                            traduction={confPantalla.config.trad.components.datatable}
                                                        />                                                        
                                                    </FormRow> */}
                                                {/* </Tab>
                                            </Tabs> */}
                                        </Formulario>
                                        {/* <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                            {
                                                !showChangePass 
                                                ?
                                                    <Alert />
                                                :
                                                    null
                                            }
                                            {   
                                                loadReg
                                                ? 
                                                <Spinner animation="border" />
                                                :
                                                null
                                            }                                
                                        </div>                                 */}
                                        <MiDataTable 
                                            formulario={formularioPrin}
                                            estadoForm= { estadoFormPrin }
                                            id={"usuarios"}
                                            columnas={confPantalla.loginusu.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.loginusu.padre.datatable.buttons}
                                            ordering={confPantalla.loginusu.padre.datatable.ordering}
                                            order={confPantalla.loginusu.padre.datatable.order}
                                            searching={confPantalla.loginusu.padre.datatable.searching}
                                            paging={confPantalla.loginusu.padre.datatable.paging}
                                            select={confPantalla.loginusu.padre.datatable.select}
                                            pagelength={confPantalla.loginusu.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.loginusu.padre.urls, null, confPantalla.loginusu.padreshijos, null, confPantalla.loginusu.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.loginusu.padre.urls, confPantalla.loginusu.padre.modelform, confPantalla.loginusu.padre.tabla, confPantalla.loginusu.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.loginusu.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />         


                                    </Section>                                
                                :
                                    <LoadingPage />
                            }
                        </Fragment>
                    } 
            </section>
        </div>
        <Footer />        
            {
                showChangePass 
                ?
                    <MiModal
                        titulo={confPantalla.config.trad.components.changepass.modaltitle.title}
                        actionhide={saveShowChangePass}
                        centered={true}
                        btnclosetext={confPantalla.config.trad.components.changepass.modalbtnclose.title}
                    >
                        <FormChangePass
                            idUsuario={formularioPrin.id}
                            traduction={confPantalla.config.trad.components.changepass}
                            props = {props}
                        />
                    </MiModal>
                :
                    null
            }
        </div>

    );

}
export default LoginUsu