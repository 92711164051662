import React, { useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../../helpers/MenuTreeHelper';


function MenuTrabCheckList({ miNivel, activeMenuColor, confApp }){

    useEffect(() => {
        loadTree();
    },[])

    return(
              
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" data-animation-speed="600" role="menu" data-accordion="true">
                <li className="nav-item">
                    <NavLink to="/adjcentros" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.centrosadj.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.centrosadj.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>             
                <li className="nav-item">
                    <NavLink to="/checklisttrab" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.checklist.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.checklist.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>
            </ul>
        </nav>                          

       

    )
}

export default MenuTrabCheckList;