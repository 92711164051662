import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import MiDataTableInfoRegActual from '../../componentes/formulario/MiDataTableInfoRegActual/MiDataTableInfoRegActual';
import ButtonOutline from '../../componentes/ui/ButtonOutline';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { txtFalsoSql, getApiUrl, axiosPost, MiReplaceAll, getTitle, getDecodedCookie } from '../../helpers/helperApp';

import { Fragment } from 'react';

function PanInfCfg(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source(); 
    const source2 = CancelToken.source(); 
    const source3 = CancelToken.source();    
    
    const tipoVariable = [ 
        { "value" : "txt", "label" : "Texto" },
        { "value" : "number", "label" : "Número" },
        { "value" : "datetime-local", "label" : "Fecha" },
        { "value" : "cbo", "label" : "CBO" }
    ];
    const tipoDAtos = [ 
        { "value" : "sql", "label" : "SQL" },
        { "value" : "filter", "label" : "PHP" },
        { "value" : "codigo", "label" : "JSON" }
    ];
    const dataConDef = [ 
        { "value" : "igual", "label" : "Igual" },
        { "value" : "distinto", "label" : "Distinto" },
        { "value" : "contiene", "label" : "Contiene" },
        { "value" : "nocontiene", "label" : "No contiene" },
        { "value" : "todos", "label" : "Todos" }
    ];

    const dataConDefNumFec = [ 
        { "value" : "=", "label" : "=" },
        { "value" : "<=", "label" : "<=" },
        { "value" : ">=", "label" : ">=" },
        { "value" : "<", "label" : "<" },
        { "value" : ">", "label" : ">" },
        { "value" : "!=", "label" : "!=" },
        { "value" : "todos", "label" : "Todos" }
    ];

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleClonePrin, handleSelectMultipleFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {                
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                    
                    document.title = getTitle(confPantalla.config.trad, confPantalla.paninfcfg.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.paninfcfg.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.paninfcfg.hijo.estadoform);                            
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.paninfcfg.padre.urls);
                                saveFormularioPrin(confPantalla.paninfcfg.padre.modelform);
                                saveFormularioHijo(confPantalla.paninfcfg.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.paninfcfg.padre.urls, confPantalla.paninfcfg.hijo.urls, confPantalla.paninfcfg.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);   
                            }                        
                        }                      
                    }else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                   
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
        } 
        
    // },[confPantalla, formularioPrin])
       },[confPantalla])



    const addVariable = () => {
        
        const newVariable = {"idcon" : "", "tipo": "", "label" : "", "valdef" : "", "condef" : "", "origendatos" : "", "datos": "" };
        let variables;
        if(formularioPrin.jsondef != "" ){
            variables = JSON.parse(formularioPrin.jsondef);            
        }else{
            variables = [];
        }       
        variables.push(newVariable)
        if( variables.length == 1 ){
            saveFormularioPrin( 
                {...formularioPrin, 
                    ["jsondef"]: JSON.stringify(variables),
                    ["sqlinf"]: formularioPrin.sqlinf+" \r\n******FILTRO******" 
                } 
            );            
        }else{
            saveFormularioPrin( {...formularioPrin, ["jsondef"]: JSON.stringify(variables) } );
        }
        
    }

    const exportInf = async () => {

        var data = new URLSearchParams();
        data.append('id', formularioPrin.id);
        const url = `${getApiUrl(window.location.origin)}/app/exportInf`;
        // const res = await axios.post(url, data);
        const res = await axiosPost(url, data, source.token);

        var forceDown = document.createElement('a');
        forceDown.setAttribute('href',res.data);
        forceDown.setAttribute('download','');
        document.body.appendChild(forceDown);
        forceDown.click(); 
        forceDown.remove();

        data = new URLSearchParams();
        data.append('zip', res.data);
        const url2 = `${getApiUrl(window.location.origin)}/app/deleteInfZip`;
        // const res2 = await axios.post(url2, data)
        const res2 = await axiosPost(url2, data, source2.token);
        
    }

    const importInf = () => {

        var inputFile = document.createElement('input');
        // inputFile.id='inputZip';
        inputFile.setAttribute('id','inputZip');
        inputFile.setAttribute('type','file');
        inputFile.setAttribute('accept','.zip');
        inputFile.setAttribute('enctype','multipart/form-data');
        inputFile.style.display = 'none';
        document.body.appendChild(inputFile);
        inputFile.click(); 

        inputFile.addEventListener('change', handleFileSelect, false);
        
    }

    const handleFileSelect = async () => {                                  
         var inputFile = document.getElementById('inputZip');   
         var file_data = inputFile.files[0];

         var form_data = new FormData();                  
         form_data.append('file', file_data);  

         var url = `${getApiUrl(window.location.origin)}/app/importInf`;
        //  const res = await axios.post(url, form_data);    
         const res = await axiosPost(url, form_data, source3.token);

         var idInf = res.data.toString();
         url =`${window.location.origin}/paninfcfg/`+idInf;

         var forceURL = document.createElement('a');
         forceURL.setAttribute('href',url);
         document.body.appendChild(forceURL);
         forceURL.click(); 
         forceURL.remove();
         inputFile.remove();
        
    }

    const handleDeleteVariable = (indice) => {
        let variables = JSON.parse(formularioPrin.jsondef);
        const newVariables = variables.filter( (variable, index) =>  index != indice );
        if( newVariables.length == 0 ){
            saveFormularioPrin( 
                {...formularioPrin, 
                    ["jsondef"]: JSON.stringify(newVariables),
                    // ["sqlinf"]: formularioPrin.sqlinf.replace("\r\n******FILTRO******", " ")                 
                    ["sqlinf"]: MiReplaceAll(formularioPrin.sqlinf, "******FILTRO******", "")
                } 
            );
        }else{
            saveFormularioPrin( {...formularioPrin, ["jsondef"]: JSON.stringify(newVariables) } );
        }
    
    }

    const handleInputVar = (e, indice, ele) => {

        let valor;
        if( ele == "tipo" || ele == "origendatos" || ele == "condef" ){
            if( e == null ){
                valor = "";
            }else{
                valor = e.value;
            }
        }else if( ele == "idcon" || ele == "label" || ele == "valdef"  || ele == "datos" ){
            valor = e.target.value;
        }

        let variables = JSON.parse(formularioPrin.jsondef);
        const editVar = variables.filter( (variable, index) =>  index == indice );
        editVar[0][ele] = valor;
        if( (editVar[0]["tipo"] == "txt" || editVar[0]["tipo"] == "cbo") && (editVar[0]["condef"] == "=" || editVar[0]["condef"] == "<=" || editVar[0]["condef"] == ">=" || editVar[0]["condef"] == "<" || editVar[0]["condef"] == ">" || editVar[0]["condef"] == "!=" )  ){
            editVar[0]["condef"] = "";
        }
        if( (editVar[0]["tipo"] == "number" || editVar[0]["tipo"] == "datetime-local") && (editVar[0]["condef"] == "igual" || editVar[0]["condef"] == "distinto" || editVar[0]["condef"] == "contiene" || editVar[0]["condef"] == "nocontiene" )  ){
            editVar[0]["condef"] = "";
        }

        saveFormularioPrin( {...formularioPrin, ["jsondef"]: JSON.stringify(variables) } );

    }
    
    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.paninfcfg.section.titulo } icono={ confPantalla.paninfcfg.section.icono } col={ confPantalla.paninfcfg.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.paninfcfg.section.wiki } >
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.paninfcfg.padre.btnform.new }
                                                    btnSave={ confPantalla.paninfcfg.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.paninfcfg.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.paninfcfg.padre.btnform.delete }
                                                    btnClone={ confPantalla.paninfcfg.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.paninfcfg.padre.urls, confPantalla.paninfcfg.padre.modelform, confPantalla.paninfcfg.padre.tabla, confPantalla.paninfcfg.hijo.modelform, confPantalla.paninfcfg.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.paninfcfg.padre.modelform, true, confPantalla.paninfcfg.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.paninfcfg.padre.urls, confPantalla.paninfcfg.padre.modelform, confPantalla.paninfcfg.padre.tabla, confPantalla.paninfcfg.title) }
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.paninfcfg.padre.urls, confPantalla.paninfcfg.hijo.urls, confPantalla.paninfcfg.padreshijos, confPantalla.paninfcfg.hijo.modelform, confPantalla.paninfcfg.padre.tabla, confPantalla.paninfcfg.title) } 
                                        >                                        
                                            <Tabs defaultActiveKey="tab1" id="tab1">
                                                <Tab eventKey="tab1" title={ confPantalla.paninfcfg.tabs.tab1.titulo }>
                                              
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.id }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.id.id }   
                                                            type=       { confPantalla.paninfcfg.padre.controles.id.type }                                                              
                                                            label=      { confPantalla.paninfcfg.padre.controles.id.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.id.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.id.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.id.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.id.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.id.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nombre }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.nombre.id }   
                                                            type=       { confPantalla.paninfcfg.padre.controles.nombre.type }  
                                                            name=       { confPantalla.paninfcfg.padre.controles.nombre.name }   
                                                            label=      { confPantalla.paninfcfg.padre.controles.nombre.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.nombre.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.nombre.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.nombre.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.nombre.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.nombre.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.tipo  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.paninfcfg.padre.controles.tipo.id }    
                                                            name=        { confPantalla.paninfcfg.padre.controles.tipo.name }   
                                                            label=       { confPantalla.paninfcfg.padre.controles.tipo.label }    
                                                            placeholder= { confPantalla.paninfcfg.padre.controles.tipo.placeholder }
                                                            col=         { confPantalla.paninfcfg.padre.controles.tipo.col } 
                                                            isClearable= { confPantalla.paninfcfg.padre.controles.tipo.isclearable }
                                                            isSearchable={ confPantalla.paninfcfg.padre.controles.tipo.issearchable }
                                                            dataType=    { confPantalla.paninfcfg.padre.controles.tipo.datatype }
                                                            data=        { confPantalla.paninfcfg.padre.controles.tipo.data }
                                                            required=    { confPantalla.paninfcfg.padre.controles.tipo.required } 
                                                            disabled=  { confPantalla.paninfcfg.padre.controles.tipo.disabled }
                                                            readOnly =   { confPantalla.paninfcfg.padre.controles.tipo.readonly }
                                                            disablethisid={confPantalla.paninfcfg.padre.controles.tipo.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />                                                        
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.tabrel  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.paninfcfg.padre.controles.tabrel.id }    
                                                            name=        { confPantalla.paninfcfg.padre.controles.tabrel.name }   
                                                            label=       { confPantalla.paninfcfg.padre.controles.tabrel.label }    
                                                            placeholder= { confPantalla.paninfcfg.padre.controles.tabrel.placeholder }
                                                            col=         { confPantalla.paninfcfg.padre.controles.tabrel.col } 
                                                            isClearable= { confPantalla.paninfcfg.padre.controles.tabrel.isclearable }
                                                            isSearchable={ confPantalla.paninfcfg.padre.controles.tabrel.issearchable }
                                                            dataType=    { confPantalla.paninfcfg.padre.controles.tabrel.datatype }
                                                            data=        { confPantalla.paninfcfg.padre.controles.tabrel.data }
                                                            required=    { confPantalla.paninfcfg.padre.controles.tabrel.required } 
                                                            disabled=  { confPantalla.paninfcfg.padre.controles.tabrel.disabled }
                                                            readOnly =   { confPantalla.paninfcfg.padre.controles.tabrel.readonly }
                                                            disablethisid={confPantalla.paninfcfg.padre.controles.tabrel.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />                                                                    
                                                    </FormRow>                                                    
                                                    <FormRow>
                                                        <MiSelectMultiple  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_inf_loc  }                                                               
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.paninfcfg.padre.controles.rel_inf_loc.id }    
                                                            name=        { confPantalla.paninfcfg.padre.controles.rel_inf_loc.name }   
                                                            label=       { confPantalla.paninfcfg.padre.controles.rel_inf_loc.label }    
                                                            placeholder= { confPantalla.paninfcfg.padre.controles.rel_inf_loc.placeholder }
                                                            col=         { confPantalla.paninfcfg.padre.controles.rel_inf_loc.col } 
                                                            isClearable= { confPantalla.paninfcfg.padre.controles.rel_inf_loc.isclearable }
                                                            isSearchable={ confPantalla.paninfcfg.padre.controles.rel_inf_loc.issearchable }
                                                            dataType=    { confPantalla.paninfcfg.padre.controles.rel_inf_loc.datatype }
                                                            data=        { confPantalla.paninfcfg.padre.controles.rel_inf_loc.data }
                                                            required=    { confPantalla.paninfcfg.padre.controles.rel_inf_loc.required } 
                                                            disabled=  { confPantalla.paninfcfg.padre.controles.rel_inf_loc.disabled }
                                                            readOnly =   { confPantalla.paninfcfg.padre.controles.rel_inf_loc.readonly }
                                                            disablethisid={confPantalla.paninfcfg.padre.controles.rel_inf_loc.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectMultipleFormPrin }
                                                        />
                                                    </FormRow>
                                                    <FormRow> 
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.descripcion }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.descripcion.id }   
                                                            type=       { confPantalla.paninfcfg.padre.controles.descripcion.type }  
                                                            name=       { confPantalla.paninfcfg.padre.controles.descripcion.name }   
                                                            label=      { confPantalla.paninfcfg.padre.controles.descripcion.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.descripcion.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.descripcion.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.descripcion.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.descripcion.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.descripcion.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.url }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.url.id }   
                                                            type=       { confPantalla.paninfcfg.padre.controles.url.type }  
                                                            name=       { confPantalla.paninfcfg.padre.controles.url.name }   
                                                            label=      { formularioPrin.tipo == 3 ? confPantalla.paninfcfg.padre.controles.emaildestino.label : confPantalla.paninfcfg.padre.controles.url.label }                                                       
                                                            placeholder={ formularioPrin.tipo == 3 ? confPantalla.paninfcfg.padre.controles.emaildestino.placeholder : confPantalla.paninfcfg.padre.controles.url.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.url.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.url.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.url.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.url.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cont }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.cont.id }   
                                                            type=       { confPantalla.paninfcfg.padre.controles.cont.type }  
                                                            name=       { confPantalla.paninfcfg.padre.controles.cont.name }   
                                                            label=      { confPantalla.paninfcfg.padre.controles.cont.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.cont.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.cont.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.cont.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.cont.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.cont.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.lastexe }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.lastexe.id }   
                                                            type=       { confPantalla.paninfcfg.padre.controles.lastexe.type }  
                                                            name=       { confPantalla.paninfcfg.padre.controles.lastexe.name }   
                                                            label=      { confPantalla.paninfcfg.padre.controles.lastexe.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.lastexe.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.lastexe.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.lastexe.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.lastexe.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.lastexe.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    <Tabs defaultActiveKey="tab1_1" id="tab1_1">
                                                        <Tab eventKey="tab1_1" title={ confPantalla.paninfcfg.tabs.tab1.tab1_1.titulo }>
                                                            <TextArea                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.sqlinf }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.paninfcfg.padre.controles.sqlinf.id }   
                                                                name=       { confPantalla.paninfcfg.padre.controles.sqlinf.name }
                                                                rows=       { confPantalla.paninfcfg.padre.controles.sqlinf.rows }      
                                                                label=      { confPantalla.paninfcfg.padre.controles.sqlinf.label }                                                       
                                                                placeholder={ confPantalla.paninfcfg.padre.controles.sqlinf.placeholder }    
                                                                col=        { confPantalla.paninfcfg.padre.controles.sqlinf.col } 
                                                                required=   { confPantalla.paninfcfg.padre.controles.sqlinf.required }
                                                                readonly=   { confPantalla.paninfcfg.padre.controles.sqlinf.readonly }                                                
                                                                disabled=   { confPantalla.paninfcfg.padre.controles.sqlinf.disabled }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     {null} 
                                                            />                                                        
                                                        <FormRow>
                                                            <div style={{display: "none"}}>
                                                                <TextArea                                                        
                                                                    estadoForm= { estadoFormPrin }
                                                                    value=      { formularioPrin.jsondef }
                                                                    loading=    { loadReg ? true : false }
                                                                    id=         { confPantalla.paninfcfg.padre.controles.jsondef.id }   
                                                                    name=       { confPantalla.paninfcfg.padre.controles.jsondef.name }
                                                                    rows=       { confPantalla.paninfcfg.padre.controles.jsondef.rows }      
                                                                    label=      { confPantalla.paninfcfg.padre.controles.jsondef.label }                                                       
                                                                    placeholder={ confPantalla.paninfcfg.padre.controles.jsondef.placeholder }    
                                                                    col=        { confPantalla.paninfcfg.padre.controles.jsondef.col } 
                                                                    required=   { confPantalla.paninfcfg.padre.controles.jsondef.required }
                                                                    readonly=   { confPantalla.paninfcfg.padre.controles.jsondef.readonly }                                                
                                                                    disabled=   { confPantalla.paninfcfg.padre.controles.jsondef.disabled }
                                                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                    onBlur=     {null} 
                                                                /> 
                                                            </div>                                                       
                                                        </FormRow>
{
    formularioPrin.jsondef != "" && formularioPrin.jsondef != undefined
    ?
        JSON.parse(formularioPrin.jsondef).map((variable, index, arr) => {
            return(
                <Fragment key={index}>
                        <hr data-content={"Filtro "+(index+1)} className="hr-text"></hr> 
                    <FormRow>
                        <FormInput                                                        
                            estadoForm= { estadoFormPrin }
                            value=      { variable.idcon }
                            loading=    { loadReg ? true : false }
                            id=         { index }   
                            type=       { "text" }  
                            label=      { "Campo condición*" }                                                       
                            placeholder={ "TABLA.campo para la condición" }    
                            col=        { "2" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { e => handleInputVar(e, index, "idcon") }
                            onBlur=     {null} 
                        />
                        <MiSelect  
                            estadoForm=  { estadoFormPrin }
                            val=         { variable.tipo  }   
                            loading=     { loadReg ? true : false } 
                            label=       { "Tipo de filtro*" }    
                            placeholder= { "Tipo de filtro" }
                            col=         { "2" } 
                            isClearable= { false }
                            isSearchable={ true }
                            dataType=    { "codigo" }
                            data=        { tipoVariable }
                            required=    { false } 
                            disabled=  { false }
                            readOnly =   { false }
                            formulario= { formularioPrin }   
                            change=     { e => handleInputVar(e, index, "tipo") }
                        />
                        <FormInput                                                        
                            estadoForm= { estadoFormPrin }
                            value=      { variable.label }
                            loading=    { loadReg ? true : false }  
                            type=       { "text" }  
                            label=      { "Etiqueta*" }                                                       
                            placeholder={ "Etiqueta" }    
                            col=        { "2" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { e => handleInputVar(e, index, "label") }
                            onBlur=     {null} 
                        />
                        <MiSelect  
                            estadoForm=  { estadoFormPrin }
                            val=         { variable.condef  }   
                            loading=     { loadReg ? true : false } 
                            label=       { "Condición defecto" }    
                            placeholder= { "Condición defecto" }
                            col=         { "2" } 
                            isClearable= { true }
                            isSearchable={ true }
                            dataType=    { "codigo" }
                            data=        { variable.tipo == "txt" || variable.tipo == "cbo" ? dataConDef : variable.tipo == "number" || variable.tipo == "datetime-local" ?  dataConDefNumFec : [] }
                            required=    { false } 
                            disabled=  { false }
                            readOnly =   { false }
                            formulario= { formularioPrin }   
                            change=     { e => handleInputVar(e, index, "condef") }
                        />
                        <FormInput                                                        
                            estadoForm= { estadoFormPrin }
                            value=      { variable.valdef }
                            loading=    { loadReg ? true : false }  
                            type=       { "text" }  
                            label=      { "Valor defecto" }                                                       
                            placeholder={ "Name del control del form donde se ejecuta" }    
                            col=        { "2" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { e => handleInputVar(e, index, "valdef") }
                            onBlur=     {null} 
                        />                        
                        {
                            variable.tipo == "cbo" 
                            ?
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { variable.origendatos  }   
                                    loading=     { loadReg ? true : false }
                                    label=       { "Origen datos*" }    
                                    placeholder= { "" }
                                    col=         { "1" } 
                                    isClearable= { false }
                                    isSearchable={ true }
                                    dataType=    { "codigo" }
                                    data=        { tipoDAtos }
                                    required=    { false } 
                                    disabled=  { false }
                                    readOnly =   { false }
                                    formulario= { formularioPrin }   
                                    change=     { e => handleInputVar(e, index, "origendatos") }
                                    />
                            :
                            null
                        }
                        <div className={"mt-4 col-md-1"}>
                            <ButtonOutline 
                                clases={ "mt-2" }
                                color={ "danger" }  
                                type="button" 
                                icono={ "fal fa-trash-alt" }   
                                id={index}     
                                texto={ "" }   
                                accion={ e => handleDeleteVariable(index) } 
                                disabled={ false }
                            />                    
                        </div>
                    </FormRow>
                    <FormRow>
                        {
                            variable.tipo == "cbo" 
                            ?
                                <FormInput                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { variable.datos }
                                    loading=    { loadReg ? true : false }
                                    type=       { "text" }  
                                    label=      { "Datos*" }                                                       
                                    placeholder={ "Sentencia sql(id as value, x as label), llamada a función PHP o json [{'value':'','label': ''},...]" }    
                                    col=        { "12" } 
                                    required=   { false }
                                    readonly=   { false }                                                
                                    disabled=   { false }
                                    change=     { e => handleInputVar(e, index, "datos") }
                                    onBlur=     {null} 
                                />
                            :
                            null
                        }
                    </FormRow>                                      
                </Fragment>
            )
        })
    :
    null

}                                                    
                                                        <FormRow>
                                                            <ButtonOutline 
                                                                clases={ "col-12" }
                                                                color={ "success" }  
                                                                type="button" 
                                                                icono={ "mr-1 " }   
                                                                id=""     
                                                                texto={ "Añadir filtro" }   
                                                                accion={ addVariable } 
                                                                disabled={ false }
                                                            />
                                                        </FormRow>
                                                    </Tab>
                                                    <Tab eventKey="tab1_2" title={ confPantalla.paninfcfg.tabs.tab1.tab1_2.titulo }>
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.sqlsec }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.sqlsec.id }   
                                                            name=       { confPantalla.paninfcfg.padre.controles.sqlsec.name }
                                                            rows=       { confPantalla.paninfcfg.padre.controles.sqlsec.rows }      
                                                            label=      { confPantalla.paninfcfg.padre.controles.sqlsec.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.sqlsec.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.sqlsec.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.sqlsec.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.sqlsec.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.sqlsec.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </Tab>
                                                    <Tab eventKey="tab1_3" title={ confPantalla.paninfcfg.tabs.tab1.tab1_3.titulo }>
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.sqlter }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.sqlter.id }   
                                                            name=       { confPantalla.paninfcfg.padre.controles.sqlter.name }
                                                            rows=       { confPantalla.paninfcfg.padre.controles.sqlter.rows }      
                                                            label=      { confPantalla.paninfcfg.padre.controles.sqlter.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.sqlter.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.sqlter.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.sqlter.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.sqlter.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.sqlter.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </Tab>
                                                    <Tab eventKey="tab1_4" title={ confPantalla.paninfcfg.tabs.tab1.tab1_4.titulo }>
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.sqlcua }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paninfcfg.padre.controles.sqlcua.id }   
                                                            name=       { confPantalla.paninfcfg.padre.controles.sqlcua.name }
                                                            rows=       { confPantalla.paninfcfg.padre.controles.sqlcua.rows }      
                                                            label=      { confPantalla.paninfcfg.padre.controles.sqlcua.label }                                                       
                                                            placeholder={ confPantalla.paninfcfg.padre.controles.sqlcua.placeholder }    
                                                            col=        { confPantalla.paninfcfg.padre.controles.sqlcua.col } 
                                                            required=   { confPantalla.paninfcfg.padre.controles.sqlcua.required }
                                                            readonly=   { confPantalla.paninfcfg.padre.controles.sqlcua.readonly }                                                
                                                            disabled=   { confPantalla.paninfcfg.padre.controles.sqlcua.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </Tab>
                                                </Tabs>
                                                </Tab>                                                                                                                        
                                                <Tab eventKey="tab2" title={ confPantalla.paninfcfg.tabs.tab2.titulo }>
                                                    <FormRow>
                                                        <Uplm
                                                            estadoForm= { estadoFormPrin }
                                                            loading=    { loadReg ? true : false }
                                                            col={12}
                                                            id={ confPantalla.paninfcfg.padre.controles.uplm.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            readonly={ confPantalla.paninfcfg.padre.controles.uplm.readonly }
                                                            traduction={confPantalla.config.trad.components.uplm}
                                                        />
                                                    </FormRow>
                                                </Tab>
                                                <Tab eventKey="tab3" title={ confPantalla.paninfcfg.tabs.tab3.titulo }>
                                                    <FormRow>
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { txtFalsoSql(formularioPrin.sqlinf, formularioPrin.jsondef)  }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { "txtfalsosql" }   
                                                            // name=       { confPantalla.paninfcfg.padre.controles.jsondef.name }
                                                            rows=       { confPantalla.paninfcfg.padre.controles.sqlinf.rows }      
                                                            label=      { "SQL pruebas" }                                                       
                                                            placeholder={ "" }    
                                                            col=        { "12" } 
                                                            required=   { false }
                                                            readonly=   { "readonly" }                                                
                                                            disabled=   { false }
                                                            change=     { null }
                                                            onBlur=     {null} 
                                                        /> 
                                                    </FormRow>
                                                </Tab>
                                            </Tabs>
                                        </Formulario>
                                        {
                                            formularioPrin.id != ""
                                            ?
                                                //Botón exportar
                                                <ButtonOutline 
                                                    clases={ "col-12 mt-2" }
                                                    color={ "danger" }  
                                                    type="button" 
                                                    icono={ "mr-1 fal fa-file-download" }   
                                                    id=""     
                                                    texto={ "Exportar informe" }   
                                                    accion={ exportInf } 
                                                    disabled={ false }
                                                />
                                            :
                                                //Botón importar
                                                <ButtonOutline
                                                    clases={ "col-12 mt-2" }
                                                    color={ "success" } 
                                                    type="button" 
                                                    icono={ "mr-1 fal fa-file-upload" }   
                                                    id=""     
                                                    texto={ "Importar informe" }   
                                                    accion={ importInf } 
                                                    disabled={ false }
                                                />

                                        }
                                        <MiDataTable
                                            formulario={formularioPrin}                                         
                                            estadoForm= { estadoFormPrin }
                                            id={"paninfcfg"}
                                            columnas={confPantalla.paninfcfg.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.paninfcfg.padre.datatable.buttons}
                                            ordering={confPantalla.paninfcfg.padre.datatable.ordering}
                                            order={confPantalla.paninfcfg.padre.datatable.order}
                                            searching={confPantalla.paninfcfg.padre.datatable.searching}
                                            paging={confPantalla.paninfcfg.padre.datatable.paging}
                                            select={confPantalla.paninfcfg.padre.datatable.select}
                                            pagelength={confPantalla.paninfcfg.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.paninfcfg.padre.urls, confPantalla.paninfcfg.hijo.urls, confPantalla.paninfcfg.padreshijos, confPantalla.paninfcfg.hijo.modelform, confPantalla.paninfcfg.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.paninfcfg.padre.urls, confPantalla.paninfcfg.padre.modelform, confPantalla.paninfcfg.padre.tabla, confPantalla.paninfcfg.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.paninfcfg.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default PanInfCfg