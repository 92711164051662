import React, { useState, useEffect, Fragment } from 'react';

function SpinnerFloat(props) {

    return (
        <div style={{
            backgroundColor: "rgba(0,0,0,.2)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            cursor: "not-allowed"
        }}>
            <div style={{
                    backgroundColor: "rgba(0,0,0,.3)",
                    padding: "25px",
                    borderRadius: "10px"
                 }}
            >
                <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>                      
            </div>
        </div>
    );
}
export default SpinnerFloat
