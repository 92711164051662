
import React,{ useContext, useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import { LoginContext } from '../../hooks/context/LoginContext';

import 'bootstrap/dist/css/bootstrap.min.css';

import LoadingPage from '../../componentes/ui/LoadingPage';

import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import { getIndexUrl, getApiUrl, axiosPost, getDecodedCookie } from '../../helpers/helperApp';

function ChangePass(props){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
        
    // ***** State para comprobar si está cargada la configuración general de aplicación  *****
    const [ isConfApp, saveIsConfApp ] = useState(true)

    const { logo, cookies, getValidaciones, inicioValidaciones, validaPassword, formChangePass, saveFormChangePass, backgroundImg, darkLightForm, getConfApp, confApp, noConfText, menuMode, loginTxtColor } = useContext(LoginContext); 

    // ***** Custom Hook - Lógica de los formularios *****
    const { loadReg, saveLoadReg } = useLogicForm();   

    const [userPassValido, saveUserPassValid] = useState("");
    const [userRepetirPassValido, saveRepetirUserPassValid] = useState("");
    const [confirmChange, saveConfirmChange] = useState({
        estado: false, clases: "", mensaje: "", icono: ""
    });
    const [info, saveInfo] = useState("");

    // ***** Funciones que se ejecutan al terminar  de cargar el componente *****
    useEffect(() => {
                 
        if( getDecodedCookie('id') ){
            props.history.push(getIndexUrl(menuMode));
        }else{
            const validaciones = async () => {
                const res = await getValidaciones();
                await inicioValidaciones(res);                                               
                const conf = await getConfApp();
                if( conf && conf.res.toLowerCase() === "ko".toLocaleLowerCase() ){
                    saveIsConfApp(false);                        
                }
                res.map(r => {
                    if(r.cod == 'login.recoverpass.allow'){                                
                        if(r.val == '0'){
                            // Página de registro desactivada, enviar al login
                            props.history.push('/login');
                        }
                    }
                })  
            }
            validaciones();
        }
        
        // Recogemos la info que nos llega por parámetro
        saveInfo(props.match.params.info);

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 

    }, [])


    // ***** Lama a la api para comprobar username y password, almacena en las cookies la información del usuario *****
    const handleChangePass = async (e) =>{

        e.preventDefault();
        if( userPassValido === "is-valid" && userRepetirPassValido === "is-valid" ){
            saveLoadReg(true);
            const data = new FormData();
            data.append("info", info);
            data.append("pass", formChangePass.pass);            

            const url = `${getApiUrl(window.location.origin)}/login/newuserpass`;
            // const res = await axios.post(url, data);      
            const res = await axiosPost(url, data, source.token);      
            if( res.data === 0 ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.changepass.error0.title}`,{autoClose: true});
            }else if( res.data === 1 ){
                saveConfirmChange({...confirmChange, estado: true, clases: "success", mensaje: confApp.config.trad.login.changepass.success.title, icono: "fa-check-circle mr1" })                
            }else if( res.data === 2 ){
                saveConfirmChange({...confirmChange, estado: true, clases: "danger", mensaje: confApp.config.trad.login.changepass.error1.title, icono: "fal fa-times-octagon mr-1" })
            }
            saveLoadReg(false);
        }else{
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.changepass.errorform.title}`,{autoClose: true});
        }

    }


    // ***** Llama la función de validación del password y almacena en el state del form los valores de username y password *****
    const handleChange = e => {
        if( e.target.name === "pass" ){
            validaPassword(e.target.value);
        }

        const { name, value } = e.target;
        saveFormChangePass({
            ...formChangePass,
            [name]: value
        })
    }

    const handleValidaPass = async e => {

        if( e.target.value !== ""){
            const errorValidacion = validaPassword(e.target.value);
            if( errorValidacion !== false ){
                saveUserPassValid("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${errorValidacion}`,{autoClose: true});
            }else{
                saveUserPassValid("is-valid");
            }
        }else{
            saveUserPassValid("");
            saveRepetirUserPassValid("");
        }

    }

    const handleValidaRepetirPass = async e => {

        if( formChangePass.pass != "" ){
            if( formChangePass.pass !== formChangePass.repetirPassword ){
                saveUserPassValid("is-invalid");
                saveRepetirUserPassValid("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.changepass.errorpass.title}`,{autoClose: true});
            }else{
                const errorValidacion = validaPassword(formChangePass.pass);
                if( errorValidacion !== false ){
                    saveUserPassValid("is-invalid");
                    alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${errorValidacion}`,{autoClose: true});
                }else{
                    saveUserPassValid("is-valid");
                    saveRepetirUserPassValid("is-valid");
                }
            }
        }else{
            saveRepetirUserPassValid("");
        }
        if( formChangePass.repetirPassword === "" && formChangePass.pass === "" ){
            saveRepetirUserPassValid("");
            saveUserPassValid("");
        }

    }


    return(

        <Fragment>
                    {
                    confApp !== undefined
                    ?
                        <Fragment>
                            <div className="hold-transition login-page" style={{backgroundImage:`url("${backgroundImg}")`,backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                                        <div className="login-box">
                                        <Alert sinClases={true} />
                                            <div style={{ textAlign: "center"}} >
                                                {   loadReg ? 
                                                    <Spinner animation="border" className={ backgroundImg ? "text-white" : ""} />
                                                    :
                                                    null
                                                }  
                                            </div>
                                        <div className={ backgroundImg ? `card card-outline transparent-${darkLightForm}` : "card card-outline"}>
                                                    <div className="text-center">
                                                        <div className="register-logo">
                                                            <div className="h1"><img className="logo" src={logo} /></div>
                                                        </div>
                                                    </div>
                                            <div className="card-body">
                                            {
                                                        isConfApp 
                                                        ?
                                                            <div>
                                                                {
                                                                    confirmChange.estado === false
                                                                    ?
                                                                    <div>                            
                                                                        <form onSubmit={ handleChangePass }>
                                                                            <div className="input-group mb-3">
                                                                                    <input 
                                                                                        type="password" 
                                                                                        className={`form-control ${userPassValido}`} 
                                                                                        placeholder={confApp.config.trad.login.changepass.inputnewpass.placeholder} 
                                                                                        autoComplete="off"                                
                                                                                        name="pass" 
                                                                                        onChange={ handleChange }
                                                                                        onBlur={ handleValidaPass }
                                                                                        required
                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <div className="input-group-text">
                                                                                        <span className="fas fa-lock"></span>
                                                                                        </div>
                                                                                    </div>
                                                                            </div>
                                                                            <div className="input-group mb-3">
                                                                                    <input 
                                                                                        type="password" 
                                                                                        className={`form-control ${userRepetirPassValido}`} 
                                                                                        placeholder={confApp.config.trad.login.changepass.inputerepeatpass.placeholder}
                                                                                        autoComplete="off"                                
                                                                                        name="repetirPassword" 
                                                                                        onChange={ handleChange }
                                                                                        onBlur={ handleValidaRepetirPass }
                                                                                        required
                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <div className="input-group-text">
                                                                                        <span className="fas fa-lock"></span>
                                                                                        </div>
                                                                                    </div>
                                                                            </div>
                                                                            <div className="row">
                                                                            <div className="col-12">
                                                                                <button
                                                                                disabled={ userPassValido === "is-invalid" || userRepetirPassValido === "is-invalid" || formChangePass.pass == '' ||  formChangePass.repetirPassword == '' } 
                                                                                    type="submit" 
                                                                                    className="btn btn-primary btn-block">
                                                                                        {confApp.config.trad.login.changepass.btnchangepass.title}
                                                                                    </button>
                                                                            </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    :
                                                                    <div className={"alert alert-"+confirmChange.clases} role="alert">
                                                                        <h6><i className={"fal "+confirmChange.icono+" mr-1"}></i>{confirmChange.mensaje}</h6>
                                                                    </div>
                                                                }                                                                                                        
                                                                    <p className="mt-3 mb-1">
                                                                        <Link style={{ color: loginTxtColor }} to="/" className="float-left">{confApp.config.trad.login.changepass.btnlogin.title}</Link>
                                                                    </p>   
                                                            </div>

                                                        :
                                                            <div className="alert alert-danger text-center" role="alert">
                                                                {noConfText}
                                                            </div>                            
                                            }
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                        </Fragment>
                    :
                        <LoadingPage />
                    }
        </Fragment>                
    );
}

export default ChangePass