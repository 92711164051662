
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import 'bootstrap/dist/css/bootstrap.min.css';

import { emailIsValid }from '../../helpers/helperApp';
import Spinner from 'react-bootstrap/Spinner';
import LoadingPage from '../../componentes/ui/LoadingPage';

import useLogicForm from '../../hooks/useLogicForm';
import { Fragment } from 'react';

import { getIndexUrl, getApiUrl, axiosPost, getDecodedCookie } from '../../helpers/helperApp';

function Registro(props){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** State para comprobar si está cargada la configuración general de aplicación  *****
    const [ isConfApp, saveIsConfApp ] = useState(true)

    const { formRegistro, saveFormRegistro, checkUser, checkEmail, cookies, getValidaciones, inicioValidaciones, validaPassword, logo, backgroundImg, darkLightForm, getConfApp, confApp, noConfText, menuMode, loginTxtColor  } = useContext(LoginContext);
  
    const [userNameValido, saveUserNameValid] = useState("");
    const [userEmailValido, saveUserEmailValid] = useState("");
    const [userPassValido, saveUserPassValido] = useState("");
    const [userRepetirPassValido, saveRepetirUserPassValido] = useState("");
    const [confirmReg, saveConfirmReg] = useState(false);

    // ***** Custom Hook - Lógica de los formularios *****
    const { loadReg, saveLoadReg } = useLogicForm();   

    // ***** Funciones que se ejecutan al terminar  de cargar el componente *****
    useEffect(() => {
      
           
                if( getDecodedCookie('id') ){
                    props.history.push(getIndexUrl(menuMode));
                }else{
                    const validaciones = async () => {
                        const res = await getValidaciones();
                        await inicioValidaciones(res);
                        const conf = await getConfApp();                        
                        if( conf.res.toLowerCase() === "ko".toLocaleLowerCase() ){
                            saveIsConfApp(false);                        
                        }
                        res.map(r => {
                            if(r.cod == 'login.register.allow'){                                
                                if(r.val == '0'){
                                    // Página de registro desactivada, enviar al login
                                    props.history.push('/login');
                                }
                            }
                        })                                                                       
                    }
                    validaciones();
                }

                return () =>{    
                    // Token cancelación axios al desmontar componentes
                    source.cancel();
                } 

    }, [])


    // ***** Lama a la api para comprobar username y password, almacena en las cookies la información del usuario y redirige hacia el inicio *****
    const handleRegistrarse = async (e) =>{

        e.preventDefault();
        if( userNameValido === "is-valid" && userEmailValido === "is-valid" && userPassValido === "is-valid" && userRepetirPassValido === "is-valid" ){
            saveLoadReg(true);
            const data = new FormData();
            data.append("username", formRegistro.username);
            data.append("password", formRegistro.password);
            data.append("email", formRegistro.email);

            const url = `${getApiUrl(window.location.origin)}/login/register`;
            // const res = await axios.post(url, data);      
            const res = await axiosPost(url, data, source.token);      
            if( res.data === 0 ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.register.error0.title}`,{autoClose: true});
            }else if( res.data === 1 ){
                saveConfirmReg(true);
            }else if( res.data === 2 ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.register.error.title}`,{autoClose: true});
            }
            saveLoadReg(false);
        }else{
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.register.errorform.title}`,{autoClose: true});
        }

    }


    // ***** Llama la función de validación del password y almacena en el state del form los valores de username y password *****
    const handleChange = e => {
        if( e.target.name === "password" ){
            validaPassword(e.target.value);
        }

        const { name, value } = e.target;
        saveFormRegistro({
            ...formRegistro,
            [name]: value
        })
    }

    const handleCheckUser = async e => {

        if( e.target.value !== ""){
            const userValido = await checkUser(e.target.value);            
            if( userValido == 0 ){
                 saveUserNameValid("is-valid");
            }else if( userValido == 1 ){
                saveUserNameValid("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.register.errorusername.title}`,{autoClose: true});
            }
        }else{
            saveUserNameValid("");
        }
    }

    const handleCheckEmail = async e => {

        if( emailIsValid(e.target.value) ){
            const emailValido = await checkEmail(e.target.value);                        
            if( emailValido == 0 ){
                 saveUserEmailValid("is-valid");
            }else if( emailValido == 1 ){
                saveUserEmailValid("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.register.erroremail.title}`,{autoClose: true});
            }
        }else{
            if( e.target.value === "" ){
                saveUserEmailValid("");
            }else{
                saveUserEmailValid("is-invalid");
            }
        }
    }
 
    const handleValidaPass = async e => {
        
        if( e.target.value !== ""){                        
            const errorValidacion = validaPassword(e.target.value);
            if( errorValidacion !== false ){
                saveUserPassValido("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${errorValidacion}`,{autoClose: true});
            }else{
                saveUserPassValido("is-valid");
            }
        }else{
            saveUserPassValido("");
            saveRepetirUserPassValido("");
        }

    }

    const handleValidaRepetirPass = async e => {

        if( formRegistro.password != "" ){
            if( formRegistro.password !== formRegistro.repetirPassword ){
                saveUserPassValido("is-invalid");
                saveRepetirUserPassValido("is-invalid");
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.register.errorpass.title}`,{autoClose: true});
            }else{
                const errorValidacion = validaPassword(formRegistro.password);
                if( errorValidacion !== false ){
                    saveUserPassValido("is-invalid");
                    alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${errorValidacion}`,{autoClose: true});
                }else{
                    saveUserPassValido("is-valid");
                    saveRepetirUserPassValido("is-valid");
                }
            }
        }else{
            saveRepetirUserPassValido("");
        }
        if( formRegistro.repetirPassword === "" && formRegistro.password === "" ){
            saveRepetirUserPassValido("");
            saveUserPassValido("");
        }
        
    }


    return(
        <Fragment>
            {
            confApp !== undefined
            ?
                <Fragment>
                    <div className="hold-transition login-page" style={{backgroundImage:`url("${backgroundImg}")`,backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                                    <div className="register-box">
                                    <Alert sinClases={true} />
                                        <div style={{ textAlign: "center"}} >
                                            {   loadReg ? 
                                                <Spinner animation="border" className={ backgroundImg ? "text-white" : ""} />                           
                                                :
                                                null
                                            }  
                                        </div>
                                        <div className={ backgroundImg ? `card card-outline transparent-${darkLightForm}` : "card card-outline"}>
                                            <div className="text-center">
                                                <div className="register-logo">
                                                    <div className="h1"><img className="logo" src={logo} /></div>
                                                </div>
                                            </div>
                                                <div className="card-body">
                                                {
                                                    isConfApp && confApp !== "ko"
                                                    ?
                                                        <div>

                                                            <h4 style={{ color: loginTxtColor }} className="login-box-msg">{confApp.config.trad.login.register.header.title}</h4>
                                                            {
                                                                !confirmReg
                                                                ?
                                                                    <form onSubmit={ handleRegistrarse } >
                                                                        <div className="input-group mb-3">
                                                                                            <input 
                                                                                                type="text" 
                                                                                                className={`form-control ${userNameValido}`}
                                                                                                placeholder={confApp.config.trad.login.register.inputuser.placeholder}
                                                                                                autoComplete="off"                                
                                                                                                name="username" 
                                                                                                onChange={ handleChange }
                                                                                                onBlur={ handleCheckUser }
                                                                                                required
                                                                                            />
                                                                            <div className="input-group-append">
                                                                                <div className="input-group-text">
                                                                                    <span className="fas fa-user"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                                            <input 
                                                                                                type="email" 
                                                                                                className={`form-control ${userEmailValido}`} 
                                                                                                placeholder={confApp.config.trad.login.register.inputemail.placeholder} 
                                                                                                autoComplete="off"                                
                                                                                                name="email" 
                                                                                                onChange={ handleChange }
                                                                                                onBlur={ handleCheckEmail }
                                                                                                required
                                                                                            />
                                                                                <div className="input-group-append">
                                                                                    <div className="input-group-text">
                                                                                        <span className="fas fa-envelope"></span>
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                                                <input 
                                                                                                    type="password" 
                                                                                                    className={`form-control ${userPassValido}`} 
                                                                                                    placeholder={confApp.config.trad.login.register.inputpass.placeholder} 
                                                                                                    autoComplete="off"
                                                                                                    name="password" 
                                                                                                    onChange={ handleChange }
                                                                                                    onBlur={ handleValidaPass }
                                                                                                    required
                                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <div className="input-group-text">
                                                                                        <span className="fas fa-lock"></span>
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                                                <input 
                                                                                                    type="password" 
                                                                                                    className={`form-control ${userRepetirPassValido}`} 
                                                                                                    placeholder={confApp.config.trad.login.register.inputpassrepeat.placeholder}  
                                                                                                    autoComplete="off"
                                                                                                    name="repetirPassword" 
                                                                                                    onChange={ handleChange }
                                                                                                    onBlur={ handleValidaRepetirPass }
                                                                                                    required
                                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <div className="input-group-text">
                                                                                        <span className="fas fa-lock"></span>
                                                                                    </div>
                                                                                </div>
                                                                        </div>
                                                                        <div className="row">          
                                                                            <div className="col-12">
                                                                                <button
                                                                                    disabled={userNameValido === "is-invalid" || userEmailValido === "is-invalid" || userPassValido === "is-invalid" || userRepetirPassValido === "is-invalid" || formRegistro.usarname == '' || formRegistro.password == '' || formRegistro.email == '' || formRegistro.repetirPassword == '' } 
                                                                                    type="submit" 
                                                                                    className="btn btn-primary btn-block">
                                                                                    {confApp.config.trad.login.register.btnregister.title} 
                                                                                </button>
                                                                            </div>                        
                                                                        </div>
                                                                    </form>
                                                                :
                                                                    <div className="alert alert-success" role="alert">
                                                                        <h6><i className="fal fa-check-circle mr-1"></i> {confApp.config.trad.login.register.success.title} </h6>
                                                                    </div>
                                                            }

                                                                
                                                            <p className="mt-3 mb-1">
                                                                <Link to="/" style={{ color: loginTxtColor }} className="float-left">{confApp.config.trad.login.register.btnlogin.title}</Link>
                                                            </p> 
                                                            </div>                                    
                                                    :
                                                        <div className="alert alert-danger text-center" role="alert">
                                                            {noConfText}
                                                        </div>
                                                }  
                                            </div>                          
                                        </div>
                                    </div>
                                </div>
                </Fragment>
            :
                <LoadingPage />
            }  
        </Fragment>          
       
    );
}

export default Registro