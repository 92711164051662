import React, { useContext, useEffect, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import { Alert } from '../../componentes/ui/Alert';

import 'bootstrap/dist/css/bootstrap.min.css';

import LoadingPage from '../../componentes/ui/LoadingPage';

import Spinner from 'react-bootstrap/Spinner';
import { Fragment } from 'react';

import GoogleLogin from 'react-google-login';




function Loginmacros(props){

    const { confApp, saveLoading, saveTxtError, acciones, macro } = props;


    const { form, saveForm, iniciarSesion, loginGoogle, logo, backgroundImg, darkLightForm, loadLogin, saveLoadLogin, googleClientId, validacionesCargadas, loginTxtColor  } = useContext(LoginContext);   

    // ***** Lama a la api para comprobar username y password, almacena en las cookies la información del usuario y redirige hacia el inicio *****
    const handleIniciarSesion = async (e) =>{

        saveLoadLogin(true);
        let iniciar = await iniciarSesion(e, null, true);

        if( iniciar.iniciar == false ){
            saveLoading("ko");
            saveTxtError(confApp.config.trad.components.macros.erroruser.title)
        }else if( iniciar.iniciar  == true ){
            acciones(iniciar.usuario, macro, false)
        }

    }


    // ***** Llama la función de validación del password y almacena en el state del form los valores de username y password *****
    const handleChange = e => {
        const { name, value } = e.target;
        saveForm({
            ...form,
            [name]: value
        })
    }

    const responseGoogleOk = async googleData => {                

        saveLoadLogin(true);
        let iniciar = await iniciarSesion(null, googleData.profileObj.email, true);

        if( iniciar.iniciar == false ){
            saveLoading("ko");
            saveTxtError(confApp.config.trad.components.macros.erroruser.title)
        }else if( iniciar.iniciar  == true ){
            acciones(iniciar.usuario, macro, false)
        }
    }

    const responseGoogleKo = async googleData => {        
        console.log(googleData);        
    }

    return(
        <Fragment>
                    {
                    confApp !== undefined && validacionesCargadas
                    ?
                        <Fragment>
                            <div className="hold-transition login-page" style={{backgroundImage:`url("${backgroundImg}")`,backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                                <div className="login-box ">
                                    <Alert sinClases={true} />
                                    <div className={ backgroundImg ? `card card-outline transparent-${darkLightForm}` : "card card-outline"}>
                                        <div className="text-center">
                                            <div className="h1"><img className="logo" src={logo} /></div>
                                        </div>
                                        <div className="card-body">
                                            <div>
                                                <h4 style={{ color: loginTxtColor }} className="login-box-msg">{confApp.config.trad.login.login.header.title}</h4>
                                                <form onSubmit={ handleIniciarSesion }>
                                                    <div className="input-group mb-3">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder={confApp.config.trad.login.login.inputuser.placeholder} 
                                                            autoComplete="off"                                
                                                            name="username" 
                                                            onChange={ handleChange }
                                                            required
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-envelope"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input 
                                                            type="password" 
                                                            className="form-control" 
                                                            placeholder={confApp.config.trad.login.login.inputpass.placeholder} 
                                                            autoComplete="off"
                                                            name="password" 
                                                            onChange={ handleChange }
                                                            required
                                                        />
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <span className="fas fa-lock"></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-12">
                                                            <button type="submit" className="btn btn-primary btn-block mr-2">{confApp.config.trad.login.login.btnsubmit.title} 
                                                            {   
                                                                loadLogin
                                                                ? 
                                                                <Spinner as="span" style={{marginLeft: "10px"}} animation="border" size="sm" />
                                                                :
                                                                null
                                                            }      
                                                            </button>
                                                        </div>
                                                    </div>                          
                                                </form>
                                                {
                                                    loginGoogle === "1" 
                                                    ?
                                                        <div className="social-auth-links text-center mt-2 mb-3">                                                                
                                                            <GoogleLogin
                                                                clientId={googleClientId}
                                                                buttonText={confApp.config.trad.login.login.btngoogle.title} 
                                                                onSuccess={responseGoogleOk}
                                                                onFailure={responseGoogleKo}                                                                   
                                                                className='btn btn-block btn-danger text-center btnLoginGoogle'
                                                                icon={ false }
                                                            />
                                                        </div>
                                                    :
                                                        null                        
                                                } 
                                        </div>
                                    </div>
                                </div>                
                                </div>
                        </div>
                        </Fragment>
                    :
                        <LoadingPage />
                }
        </Fragment>
    );

}

export default Loginmacros