//Importamos useState para poder actualizar el valor de las variables
//Importamos useEffect para poder llamar la función para que actualice
//Importamos axios para la llamada al api
//Importamos parse para el parseador de código de html
import React, { useState, useEffect, useContext } from 'react';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';
import parse from 'html-react-parser';
import metaVersion from '../../metaversion.json';
import ReactGA from 'react-ga4';

import { getApiUrl, axiosPost } from '../../helpers/helperApp';

function Footer({full}){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { keyGA } = useContext(LoginContext);    

    //Declaramos las variables con un valor por defecto que se podra ir actualizando
    const [ footi, saveFooti ] = useState("");
    const [ footd, saveFootd ] = useState("");

    //Función que va a actualizar las variables
    useEffect( () => {        
                   
        getFootData();
                
        // Google Analytics
        if( keyGA ){
            ReactGA.initialize(keyGA);
            ReactGA.send(window.location.pathname + window.location.search);
        }

        return () => {
            // Token cancelación axios al desmontar componentes
            source.cancel();
        };

    },[window.location.pathname, window.location.search, keyGA])



    //Función que recoge los valores del footer mediante una llamada al api
    const getFootData = async ()=>{
        //Llamada al api
        const data = new FormData();      
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+'/app/footerData', data, {cancelToken: source.token});
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+'/app/footerData', data, source.token);

        //Si obtenemos respuesta actualizamos las variables utilizando el useState
        if( respuesta.data.length > 0 ){
            saveFooti(respuesta.data[0]);
            saveFootd(respuesta.data[1]);        
        }

    }


    //Resultado final en que parseamos el código html que hemos obtenido del api
    return(
        <footer className={full ? "main-footer main-footerfull" : "main-footer" }>
                {parse(footi)}
                {`- V${metaVersion.buildVersion}.${metaVersion.buildMonth}.${metaVersion.buildDay}`}
            <div className="float-right d-none d-sm-inline-block">
                {parse(footd)}
            </div>
        </footer>

    )
}
export default Footer;