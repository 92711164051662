import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';


function Calendario({id, data, locale}) {
    
    const [datos, saveDatos] = useState(null);

    useEffect(() => {
        saveDatos(data);

        // Sustituimos los iconos por unos de fontawesome
        let iconos = document.querySelectorAll("button.fc-button > span");
        if( iconos.length > 0 ){
            for( let i=0; i<iconos.length; i++ ){
                iconos[i].className = iconos[i].className.replace("fc-icon fc-icon-", "fal ");
            }
        }

    }, [datos]);

    
    const renderEventContent = (eventInfo) => {
        if( eventInfo.event.url ){
            if( eventInfo.event.url != "" && eventInfo.event.url != null && eventInfo.event.url != "null" ){
                return (
                    <>
                      <a href={eventInfo.event.url} target="_blank" className="link-calendar">{eventInfo.event.title}</a>              
                    </>
                  )
            }
        }
    }

    return (
        <Fragment>
            {
                datos != null
                ?
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ]}
                        initialView="dayGridMonth"
                        weekends={ true }
                        events={ data }
                        locale={ locale }
                        timeZone= { 'local' }
                        firstDay={ 1 }
                        headerToolbar={{
                            start: 'title', 
                            center: '',
                            end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek,today,prev,next',
                        }}
                        themeSystem={'bootstrap'}
                        buttonIcons={{
                            prev: ' fal fa-arrow-left',
                            next: ' fal fa-arrow-right',
                            prevYear: 'fa-angle-double-left',
                            nextYear: 'fa-angle-double-right',
                            dayGridMonth: 'fa-calendar-alt',
                            timeGridWeek: 'fa-calendar-week',
                            timeGridDay: 'fa-calendar-day',
                            listWeek: 'fa-list',
                            today: 'fa-map-marker'
                        }}
                        eventContent={renderEventContent}
                        listDayFormat= { false }
                        nowIndicator = { true }
                        listDaySideFormat= { false }
                        height = { "auto" }
                        weekNumers = { true }                                                             
                    /> 
                :
                    null
            }

        </Fragment>
    );
}

export default Calendario;