import React, { Fragment, useEffect } from 'react';
import FormRow from '../../componentes/formulario/FormRow';
import ButtonOutline from '../../componentes/ui/ButtonOutline';


function Formulario(props){

    
    
    const {handleSubmit, formulario, resetForm, handleDeleteItem, handleCloneItem, btnNew, btnSave, btnSaveAndNew, btnDelete, btnClone, estadoForm, loading, esHijo, btnSearch, onChangeIdPadre} = props;
    
    useEffect(() => {

        if( onChangeIdPadre ){
            onChangeIdPadre();
        }
    },[formulario.id]);
    
    return(
    <Fragment>
        <form onSubmit={ handleSubmit } style={{marginTop: 25}} >
            {
                esHijo
                ?
                <input type="hidden" className="idHijo" name="id" id={ formulario.id } value={ formulario.id } />
                :
                <input type="hidden" className="idPadre" name="id" id={ formulario.id } value={ formulario.id } />
            }

            { props.children }

            <FormRow clases="justifyRight mt-2">
            {
                btnNew !== "false"
                ?
                    <ButtonOutline clases={ !esHijo ? "btnnew formPrin" : "btnnew formhijo" }              color="success" type="button" icono="fal fa-plus"      id="btnReset"      texto=""  accion={ resetForm }  estadoForm={estadoForm} loading={loading} ></ButtonOutline>
                :
                    null
            }
            {
                btnSave !== "false"
                ?
                    <ButtonOutline clases={ !esHijo ? "btnsave formPrin" : "btnsave formhijo" }       color="primary" type="submit" icono="fal fa-save"      id="btnSave"       texto=""  accion={ null }       estadoForm={estadoForm} loading={loading} ></ButtonOutline>
                :
                    null
            }
            {
                btnSaveAndNew !== "false"
                ?
                    <ButtonOutline clases={ !esHijo ? "btnsaveAndNew formPrin" : "btnsaveAndNew formhijo" } color="primary" type="submit" icono="fal fa-save" icono2="fal fa-plus"      id="btnSaveAndNew" texto=""  accion={ null }       estadoForm={estadoForm} loading={loading} ></ButtonOutline>
                :
                    null
            }
            {
                btnDelete !== "false"
                ?
                    <ButtonOutline clases={ !esHijo ? "deleteForm formPrin" : "deleteForm formhijo" }    color="danger"  type="button" icono="fal fa-trash-alt" id="btnDelete"     texto=""  accion={ handleDeleteItem } estadoForm={estadoForm} loading={loading} ></ButtonOutline>
                :
                    null
            }
            {
                btnClone !== "false"
                ?
                    <ButtonOutline clases={ !esHijo ? "cloneForm formPrin" : "cloneForm formhijo" }    color="warning"  type="button" icono="fal fa-copy" id="btnClone"     texto=""  accion={ handleCloneItem } estadoForm={estadoForm} loading={loading} ></ButtonOutline>
                :
                    null
            }
            {
                btnSearch !== undefined && btnSearch !== "false"
                ?
                    <ButtonOutline clases={ !esHijo ? "btnSearch formPrin" : "btnSearch formhijo" }    color="primary"  type="submit" icono="fal fa-search" id="btnSearch"     texto=""  accion={ null } estadoForm={estadoForm} loading={loading} ></ButtonOutline>
                :
                    null
            }
            </FormRow>            
        </form>                                   
    </Fragment>
    )
}

export default Formulario;