import React, { useEffect, useContext, useState, Fragment } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Kpi from '../../componentes/ui/Kpi';
import Section from '../../componentes/ui/Section';
import { Alert } from '../../componentes/ui/Alert';
import { getIndexUrl, getTitle, getDecodedCookie } from '../../helpers/helperApp';


import InicioAdmin from '../../componentes/inicios/InicioAdmin';
import InicioEncargado from '../../componentes/inicios/InicioEncargado';
import InicioTrab from '../../componentes/inicios/InicioTrab';



function Inicio(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp, menuMode } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {                
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                     
                    document.title = getTitle(confPantalla.config.trad, confPantalla.index.title);             
                    saveLoadedConf(true);                    
                    if( menuMode === "checklist" ){                        
                        props.history.push(getIndexUrl(menuMode));
                    }        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          
        
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])

    return(
        <div className="App wrapper">
                <Alert fondo={true} />    
                {
                    confPantalla !== undefined
                    ?
                        <Fragment>
                            <Menusup 
                                props = { props }
                            />
                            <Sidebar 
                                props = { props }
                            />
                        </Fragment>
                    :
                    null
                } 
            <div className="content-wrapper">
                <div className="content-header"></div>
                <section className="content">
                        {
                            loadedConf === true && confPantalla !== undefined
                            ?
                                <div>
                                    {                                       
                                        getDecodedCookie('nivel') == '2' || getDecodedCookie('nivel') == '1'
                                        ?
                                            <InicioAdmin 
                                                admin={ confPantalla.index.admin  }
                                                traduction={ confPantalla.config.trad.components }
                                                propiedades={ props }
                                                confPantalla = { confPantalla }
                                            /> 
                                        :
                                            getDecodedCookie('nivel') == '3'
                                            ?
                                                <InicioEncargado 
                                                    encargado={ confPantalla.index.encargado  }
                                                    traduction={ confPantalla.config.trad.components }
                                                    propiedades={ props }
                                                    confPantalla = { confPantalla }
                                                />                                        
                                            :
                                                getDecodedCookie('nivel') == '4'
                                                ?
                                                    <InicioTrab 
                                                        trabajador={ confPantalla.index.trabajador  }
                                                        traduction={ confPantalla.config.trad.components }
                                                        propiedades={ props }
                                                        confPantalla = { confPantalla }
                                                    />   
                                                :
                                                    null
                                    }
                                </div>                             
                            :
                                <LoadingPage />
                        }
                </section>
            </div>
            <Footer />        
        </div>
    );
}

export default Inicio