import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function LoadingPage(){

    return(
        <div className="content-wrapper loadingPage">
            <Spinner animation="grow" variant="secondary" />
        </div>
    )
}

export default LoadingPage;