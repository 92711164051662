import React, { useContext } from 'react';

import { SRLWrapper } from "simple-react-lightbox";

import { getIcono, getApiUrl }from '../../../helpers/helperApp';


function UplmList(props){
    
    const { id, adjuntosExistentes, saveAdjuntosExistentes, readonly, estadoForm, loading, traduction } = props; 

    const _URL = window.URL || window.webkitURL;

    const handleDeleteAdjunto = e => {
        e.preventDefault();
        const adjCancelar = e.target.dataset.id;

        //Actualizamos el state
        saveAdjuntosExistentes( adjuntosExistentes.filter(adjunto => adjunto.id != adjCancelar) );
    }


    return(
            <SRLWrapper>                
                {adjuntosExistentes
                ?
                    adjuntosExistentes.map((adj, confIco) => {
                        adj.tab=id;
                        if( adj.type.split("/")[0] !== "image" ){
                            let ext = adj.rut.split(".")[adj.rut.split(".").length-1];
                            confIco = getIcono(ext);                        
                        }
                        return(               
                            <div key={adj.rut} className="cardUplm">
                                    <div className="containerImgUplm">
                                                <div className="row">
                                                    <div className="cardUplmImg col-12">                                   
                                                        { adj.type.split("/")[0] === "image" 
                                                                ? 
                                                                    <div className="containerImg">                                                                        
                                                                            <img className="imgUplm" src={getApiUrl(window.location.origin)+adj.rut} alt={adj.des} />                                                                        
                                                                    </div>
                                                                : 
                                                                    <div className="containerImg">
                                                                        <a  href={getApiUrl(window.location.origin)+adj.rut} target="_blank" download={getApiUrl(window.location.origin)+adj.rut} >
                                                                            <i className={`${confIco["icono"]}`} style={{color:confIco["color"]}}></i>
                                                                        </a>
                                                                    </div>
                                                        }                                       
                                                    </div>

                                        </div>
                                    </div> 
                                    <div className="infoUplm">                                        
                                            <h5>{traduction.textarea.title}</h5> 
                                            {
                                            readonly !== "true" && readonly !== "readonly" && !loading && estadoForm !== "readonly" && estadoForm !== "disabled"
                                            ?
                                            <textarea placeholder={traduction.textarea.title} className="form-control fileExist" rows="4" defaultValue={adj.des} name={"fileExist**"+adj.tab+"**"+adj.id} ></textarea>
                                            :
                                            <textarea placeholder={traduction.textarea.title} className="form-control fileExist" rows="4" defaultValue={adj.des} name={"fileExist**"+adj.tab+"**"+adj.id} readOnly={"readonly"} ></textarea>
                                        }                                                                                
                                    
                                    </div>
                                    <div className="buttonsUplm mt-2">
                                        {
                                            readonly !== "true" && readonly !== "readonly" && !loading && estadoForm !== "readonly" && estadoForm !== "disabled"
                                            ?
                                                <button className="btn btn-danger danger botonesCancelaradj" data-id={adj.id} onClick={ (e) => handleDeleteAdjunto(e) } >
                                                    <i className="fal fa-trash-alt" data-id={adj.id} style={{marginRight:5}} ></i>
                                                    <span data-id={adj.id}>{traduction.btndelete.title}</span>
                                                </button>
                                            :
                                            null
                                        }                                      
                                        <a className="btn btn-success success"  href={getApiUrl(window.location.origin)+adj.rut} target="_blank" download={getApiUrl(window.location.origin)+adj.rut} >
                                            <i className="fal fa-download" data-id={adj.id} data-rut={getApiUrl(window.location.origin)+adj.rut} style={{marginRight:5}} ></i>
                                            {traduction.btndownload.title}
                                        </a>  
                                    </div>      

                            </div>
                        ) 
                    })
                :
                    null
                } 
            </SRLWrapper>                               
    )
}

export default UplmList;