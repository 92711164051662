import React, { useEffect, useState, useContext, Fragment } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import { Alert } from '../../componentes/ui/Alert';
import ButtonOutline from '../../componentes/ui/ButtonOutline';
import FormChangePass from '../../componentes/formulario/forms/FormChangePass';
import MiSelect  from '../../componentes/formulario/MiSelect';
import MiModal from '../../componentes/ui/MiModal';
import Cookies from 'universal-cookie';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';

import useLogicForm from '../../hooks/useLogicForm';

import { getTitle, getDecodedCookie } from '../../helpers/helperApp';

function LoginUsu(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { backgroundImg, logo, editUserName, editMail, validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin,  saveFormularioHijo, handleInputForm, handleLastReg, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, showChangePass, saveShowChangePass, handleCheckUser, handleCheckEmail, handleChangePass, imgCabecera, saveImgCabecera, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{
                    document.title = getTitle(confPantalla.config.trad, confPantalla.profile.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.profile.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.profile.hijo.estadoform);                             
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.profile.padre.urls);
                                saveFormularioPrin(confPantalla.profile.padre.modelform);
                                saveFormularioHijo(confPantalla.profile.hijo.modelform);                                
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.profile.padre.urls, confPantalla.profile.hijo.urls, confPantalla.profile.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);   
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })       
            
            if( backgroundImg === ""){
                saveImgCabecera(logo); 
            }else{
                saveImgCabecera(backgroundImg);
            }

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])


    


    return(
        <div className="App wrapper"> 
            <div>
                {
                    
                    <Alert fondo={true} />

                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
            <div className="content-wrapper">
                <div className="content-header">
                </div>
                <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <div>
                                <div className="col-md-12 ">
                                    <div className="card card-widget widget-user">
                                        <div className="widget-user-header text-white"
                                            style={{ 
                                                backgroundImage: `url(${imgCabecera})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center center',
                                            }} >
                                            
                                        </div>
                                        <div className="card-footer ">
                                            <Formulario 
                                                estadoForm= { estadoFormPrin }
                                                loading=    { loadReg ? true : false }
                                                btnNew={ confPantalla.profile.padre.btnform.new }
                                                btnSave={ confPantalla.profile.padre.btnform.save }
                                                btnSaveAndNew={ confPantalla.profile.padre.btnform.saveandnew }
                                                btnDelete={ confPantalla.profile.padre.btnform.delete }
                                                btnClone={ confPantalla.profile.padre.btnform.clone }
                                                handleSubmit = { e => handleSubmitPrin(e, confPantalla.profile.padre.urls, confPantalla.profile.padre.modelform, confPantalla.profile.padre.tabla, confPantalla.profile.hijo.modelform, confPantalla.profile.title) }
                                                formulario = { formularioPrin } 
                                                resetForm = { e => resetFormPrin(confPantalla.profile.padre.modelform, true, confPantalla.profile.padre.tabla) } 
                                                handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.profile.padre.urls, confPantalla.profile.padre.modelform, confPantalla.profile.padre.tabla, confPantalla.profile.title) } 
                                            >     
                                                <div className="widget-user-image">

                                                    <Upls
                                                        estadoForm= { estadoFormPrin }
                                                        loading=    { loadReg ? true : false }
                                                        id={ confPantalla.profile.padre.controles.upl.id }
                                                        value={formularioPrin.upl}
                                                        save={saveFormularioPrin}                                                
                                                        formulario={formularioPrin}  
                                                        readonly={ confPantalla.profile.padre.controles.upl.readonly }
                                                        profile={true} 
                                                        traduction={confPantalla.config.trad.components.upls} 
                                                    />
                                                </div>
                                                <h3 className="widget-user-username text-center" style={{marginBottom:"25px"}}>{getDecodedCookie('nombre')}</h3>                                        
                                                <FormRow clases="" >
                                                    <div className="col-sm-6 border-right">
                                                        <div className="description-block">                                                    
                                                            <FormInput                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.nombre }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.profile.padre.controles.nombre.id }   
                                                                type=       { confPantalla.profile.padre.controles.nombre.type }  
                                                                name=       { confPantalla.profile.padre.controles.nombre.name }   
                                                                label=      { confPantalla.profile.padre.controles.nombre.label }                                                       
                                                                placeholder={ confPantalla.profile.padre.controles.nombre.placeholder }    
                                                                col=        { confPantalla.profile.padre.controles.nombre.col } 
                                                                required=   { confPantalla.profile.padre.controles.nombre.required }
                                                                readonly=   { confPantalla.profile.padre.controles.nombre.readonly }                                                
                                                                // disabled=   { editUserName == 1 ? "" : confPantalla.profile.padre.controles.nombre.disabled }
                                                                disabled=   { editUserName == 1 ? "" : "disabled" }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     { handleCheckUser } 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="description-block">
                                                            <FormInput                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.email }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.profile.padre.controles.email.id }   
                                                                type=       { confPantalla.profile.padre.controles.email.type }  
                                                                name=       { confPantalla.profile.padre.controles.email.name }   
                                                                label=      { confPantalla.profile.padre.controles.email.label }                                                       
                                                                placeholder={ confPantalla.profile.padre.controles.email.placeholder }    
                                                                col=        { confPantalla.profile.padre.controles.email.col } 
                                                                required=   { confPantalla.profile.padre.controles.email.required }
                                                                readonly=   { confPantalla.profile.padre.controles.email.readonly }                                                
                                                                // disabled=   { editMail == 1 ? "" : confPantalla.profile.padre.controles.email.disabled }
                                                                disabled=   { editMail == 1 ? "" : "disabled" }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     { handleCheckEmail } 
                                                            />         
                                                        </div>
                                                    </div>                                                     
                                                </FormRow>
                                                <FormRow>
                                                    <div className="col-sm-6">
                                                        <div className="description-block">
                                                            <MiSelect  
                                                                estadoForm=  { estadoFormPrin }
                                                                val=         { formularioPrin.idnvl  }   
                                                                loading=     { loadReg ? true : false }
                                                                id=          { confPantalla.profile.padre.controles.nivel.id }    
                                                                name=        { confPantalla.profile.padre.controles.nivel.name }   
                                                                label=       { confPantalla.profile.padre.controles.nivel.label }    
                                                                placeholder= { confPantalla.profile.padre.controles.nivel.placeholder }
                                                                col=         { confPantalla.profile.padre.controles.nivel.col } 
                                                                isClearable= { confPantalla.profile.padre.controles.nivel.isclearable }
                                                                isSearchable={ confPantalla.profile.padre.controles.nivel.issearchable }
                                                                dataType=    { confPantalla.profile.padre.controles.nivel.datatype }
                                                                data=        { confPantalla.profile.padre.controles.nivel.data }
                                                                required=    { confPantalla.profile.padre.controles.nivel.required } 
                                                                disabled=  { confPantalla.profile.padre.controles.nivel.disabled }
                                                                readOnly =   { getDecodedCookie('nivel') > 1 ? "readonly" : ""  }
                                                                disablethisid={confPantalla.profile.padre.controles.nivel.disablethisid}
                                                                formulario = { formularioPrin }   
                                                                change={ handleSelectFormPrin }
                                                                setTxtMode={true}                                                    
                                                            />   
                                                        </div>
                                                    </div>     
                                                    <div className="col-sm-6">
                                                        <div className="description-block">
                                                            <MiSelect  
                                                                estadoForm=  { estadoFormPrin }
                                                                val=         { formularioPrin.estado  }   
                                                                loading=     { loadReg ? true : false }
                                                                id=          { confPantalla.profile.padre.controles.estado.id }    
                                                                name=        { confPantalla.profile.padre.controles.estado.name }   
                                                                label=       { confPantalla.profile.padre.controles.estado.label }    
                                                                placeholder= { confPantalla.profile.padre.controles.estado.placeholder }
                                                                col=         { confPantalla.profile.padre.controles.estado.col } 
                                                                isClearable= { confPantalla.profile.padre.controles.estado.isclearable }
                                                                isSearchable={ confPantalla.profile.padre.controles.estado.issearchable }
                                                                dataType=    { confPantalla.profile.padre.controles.estado.datatype }
                                                                data=        { confPantalla.profile.padre.controles.estado.data }
                                                                required=    { confPantalla.profile.padre.controles.estado.required } 
                                                                disabled=  { confPantalla.profile.padre.controles.estado.disabled }
                                                                readOnly =   { confPantalla.profile.padre.controles.estado.readonly } 
                                                                disablethisid={confPantalla.profile.padre.controles.estado.disablethisid}
                                                                formulario = { formularioPrin }  
                                                                change={ handleSelectFormPrin }
                                                                setTxtMode={true}   
                                                            />
                                                        </div>
                                                    </div> 
                                                </FormRow>
                                                <FormRow>
                                                    <div className="col-sm-12">
                                                        <div className="description-block">
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idioma  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.profile.padre.controles.idioma.id }    
                                                            name=        { confPantalla.profile.padre.controles.idioma.name }   
                                                            label=       { confPantalla.profile.padre.controles.idioma.label }    
                                                            placeholder= { confPantalla.profile.padre.controles.idioma.placeholder }
                                                            col=         { confPantalla.profile.padre.controles.idioma.col } 
                                                            isClearable= { confPantalla.profile.padre.controles.idioma.isclearable }
                                                            isSearchable={ confPantalla.profile.padre.controles.idioma.issearchable }
                                                            dataType=    { confPantalla.profile.padre.controles.idioma.datatype }
                                                            data=        { confPantalla.profile.padre.controles.idioma.data }
                                                            required=    { confPantalla.profile.padre.controles.idioma.required } 
                                                            disabled=  { confPantalla.profile.padre.controles.idioma.disabled }
                                                            readOnly =   { confPantalla.profile.padre.controles.idioma.readonly }
                                                            disablethisid={confPantalla.profile.padre.controles.idioma.disablethisid}
                                                            formulario = { formularioPrin }   
                                                            change={ handleSelectFormPrin }   
                                                        />
                                                        </div>
                                                    </div> 
                                                </FormRow>
                                                <FormRow clases="mt-4 mb-4">
                                                    <ButtonOutline 
                                                        clases={ "col-"+confPantalla.profile.padre.controles.btnchangepass.col }
                                                        color={ confPantalla.profile.padre.controles.btnchangepass.color }  
                                                        type="button" 
                                                        icono={ "mr-1 "+confPantalla.profile.padre.controles.btnchangepass.icono }   
                                                        id=""     
                                                        texto={ confPantalla.profile.padre.controles.btnchangepass.text.title }   
                                                        accion={ handleChangePass } 
                                                        disabled={ formularioPrin.id !== "" ? confPantalla.profile.padre.controles.btnchangepass.disabled : true }
                                                    />
                                                </FormRow>
                                            </Formulario>                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        <LoadingPage />
                    }                        
                    </Fragment>
                }
                </section>                    
                {/* <div style={{height:30, marginTop:0, marginBottom:5, textAlign: "center"}} >
                    {
                        !showChangePass 
                        ?
                            <Alert />
                        :
                            null
                    }                                                         
                </div> */}
            </div>
            <Footer />        
                {
                    showChangePass 
                    ?
                        <MiModal
                            titulo={confPantalla.config.trad.components.changepass.modaltitle.title}
                            actionhide={saveShowChangePass}
                            centered={true}
                            btnclosetext={confPantalla.config.trad.components.changepass.modalbtnclose.title}
                        >
                            <FormChangePass
                                idUsuario={formularioPrin.id}
                                traduction={confPantalla.config.trad.components.changepass}
                                props={props} 
                            />
                        </MiModal>
                    :
                        null
                }
        </div>

    );

}
export default LoginUsu