import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { LoginContext } from '../../hooks/context/LoginContext';

import { alertService } from '../../services/alertservice';

import Section from '../ui/Section';
import MiDataTable from '../ui/MiDataTable';
import swal from 'sweetalert';
import Kpi from '../ui/Kpi';
import Modal from 'react-bootstrap/Modal';
import MiSelect from '../formulario/MiSelect';
import FormInput from '../formulario/FormInput';
import Informes from '../formulario/informes/Informes';
import Spinner from 'react-bootstrap/Spinner';




import { getApiUrl, axiosPost, getAhorayyyymmdd, getNumFromData, getDecodedCookie } from '../../helpers/helperApp';

function InicioTrab(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();

    const{ trabajador, traduction, propiedades, confPantalla } = props;

    const { apiKey } = useContext(LoginContext);

    const [ reloadKpi, saveReloadKpi ] = useState(0);

    const [ loadingTable, saveLoadingTable] = useState(true);
    const [ datosTabableOts, saveDatosTableOts ] = useState([]);
    const [ plantillasCorrectivos, savePlantillasCorrectivos ] = useState([]);
    const [ loadingPlantillasCorrectivos, saveLoadingPlantillasCorrectivos ] = useState(false);    
    const [ valFec1, saveValFec1 ] = useState(getAhorayyyymmdd());
    const [ valTrabajador, saveValTrabajador ] = useState(getDecodedCookie('idage'));
    const[ isPlanPreventivo, saveIsPlanPreventivo] = useState(false);
    const [ idMantenimientosCorrectivos, saveIdMantenimientosCorrectivos ] = useState(0);
    const [ descripcionDocu, saveDescripcionDocu ] = useState("")
    const [ showModalMatenimientosCorrectivos, saveShowModalMantenimientosCorrectivos ] = useState(false);
    const [ showModalMatenimientosPendientes, saveShowModalMantenimientosPendientes ] = useState(false);
    const [ idMantenimientos, saveIdMantenimientos ] = useState(0);
    const[ checkear, saveCheckear ] = useState(false);
    const[ checkeando, saveCheckeando ] = useState(false);
    const[ errorCheckeando, saveErrorCheckeando ] = useState(false);
    const[ checkCentro, saveCheckCentro ] = useState(null);
    const[ checkCentroNom, saveCheckCentroNom ] = useState(null);
    const[ checkEstado, saveCheckEstado ] = useState(null);
    const[ checkEstadoNom, saveCheckEstadoNom ] = useState(null);
    const[ checkFecha, saveCheckFecha ] = useState(null);
    const[ checkFechaNom, saveCheckFechaNom ] = useState(null);
    const[ checkRealizado, saveCheckRealizado ] = useState(null);
    const[ checkRealizadoNom, saveCheckRealizadoNom ] = useState(null);




    useEffect( () => { 
        
        loadPlantillasCorrectivos();
                        
        return () =>{               
            document.querySelector("body").classList.remove("sidebar-open");
            
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
            source3.cancel();
            source4.cancel();    
        } 
        
    },[])

    const loadPlantillasCorrectivos = async() => {

        saveLoadingPlantillasCorrectivos(true);
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("initrab", true);       
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+trabajador.controles.createcorrectivo.datatable.getdata, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+trabajador.controles.createcorrectivo.datatable.getdata, data, source3.token);

        savePlantillasCorrectivos(respuesta.data);
        saveLoadingPlantillasCorrectivos(false);

    }

    const clickCorrectivo = async (e, rowData) => {

        saveCheckear(false);
        saveErrorCheckeando(false);

        // Reseteamos el control de fecha y ponemos por defecto el agente asociado al usuario que está logueado
        saveValFec1(getAhorayyyymmdd());  
        saveValTrabajador(getDecodedCookie('idage'));

        if( rowData.idtip == 10 ){
            saveIsPlanPreventivo(true);
        }else{
            saveIsPlanPreventivo(false);
        }

        saveIdMantenimientosCorrectivos(rowData.id);
        saveDescripcionDocu(rowData.equipo+" - "+rowData.descripcion);    
        saveShowModalMantenimientosCorrectivos(true);
    }

    const actionsModalMantenimientosCorrectivos = (action) => {            

        switch(action) {
            case 0:
                    // Cancelar
                    saveShowModalMantenimientosCorrectivos(false);              
              break;
            case 1:
                    // Creamos el correctivo
                    createCorrectivo(idMantenimientosCorrectivos);
              break;
            case 2:
                    // Creamos el preventivo
                    createDoc(idMantenimientosCorrectivos, trabajador.controles.plantillas.plantilla.createot, trabajador.controles.plantillas.plantilla.swalerror.text.title, false, trabajador.controles.plantillas.plantilla.createsuccess.title);
              break;
            case 3:
                    // Ver plantilla en solo lectura
                    window.open("/documentosenc/"+idMantenimientosCorrectivos);
              break;             
            case 4:
                    // Chekear documento
                    checkearDocumento();
              break; 
          }

    }

    const createCorrectivo = async(id)=>{
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", id )
        data.append("fec1", valFec1 );
        data.append("idage", getDecodedCookie('idage') );
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+trabajador.controles.createcorrectivo.createcorrectivo, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+trabajador.controles.createcorrectivo.createcorrectivo, data, source2.token);

        if( typeof respuesta.data.errorot == "string" ){
            // Ya existe una ot
            alertService.error(`<a href='${window.location.origin}/documentosenc/${respuesta.data.idot}'>${respuesta.data.errorot}</a>`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            // Así lo mantenemos en la misma pantalla y no habría que actualizar la tabla
            // propiedades.history.push("/documentosenc/"+respuesta.data.idClon); 
            saveReloadKpi(reloadKpi => reloadKpi+1)
    
        }else if( typeof respuesta.data == "string" ){
            alertService.error(`${trabajador.controles.createcorrectivo.swalerror.text.title}`, {autoClose: false});             
        }     

        saveShowModalMantenimientosCorrectivos(false);    
    }

    const checkearDocumento = async () => {
        saveCheckear(false);
        saveErrorCheckeando(false);
        saveCheckeando(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idMantenimientosCorrectivos );
        data.append("fecini", getDecodedCookie('kpiIni') );
        data.append("fecfin", getDecodedCookie('kpiFin') );
        data.append("idage", valTrabajador );        
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+trabajador.controles.createcorrectivo.checkear.getdata, data, source4.token);
        
        saveCheckeando(false);

        if( typeof respuesta.data[0] == "string" ){
            saveErrorCheckeando(respuesta.data);
        }else{
            saveCheckear(true);
            saveCheckCentro(respuesta.data[0].centro);
            saveCheckCentroNom(respuesta.data[0].centronom)
            saveCheckEstado(respuesta.data[0].estado);
            saveCheckEstadoNom(respuesta.data[0].estadonom);
            saveCheckFecha(respuesta.data[0].fecha);
            saveCheckFechaNom(respuesta.data[0].fechanom);
            saveCheckRealizado(respuesta.data[0].realizado);
            saveCheckRealizadoNom(respuesta.data[0].realizadonom);
        }


    }

    const checkOt = (e, rowData) => {
        
        const aux = trabajador.controles.ots;   
        switch (rowData.style) {
            case "alert-warning":
            case "alert-primary":
            case "alert-secondary":
            case "":
                    // Pregunta trabajador: Iniciar trabajo (NOM1 DEL SELECT) -> SI/NO -> LA PREGUNTA SE HACE:
                    swal({
                        title: aux.iniciar.swal.title,
                        text: rowData.descripcion,
                        content: {
                            element : "p",
                            attributes : {
                                innerHTML : aux.iniciar.swal.responsabilidad.title
                            }
                        },
                        icon: "warning",
                        buttons: {
                            cancel : {text: aux.iniciar.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: aux.iniciar.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}                            
                        } 
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            if( rowData.id_tra != "0" ){
                                // Si tiene trabajo lanzamos el asistente                                
                                propiedades.history.push("/documentostrab/"+rowData.id_tra); 
                            }else if( rowData.id_tra = "0" ){ 
                                // Si es una OT y aun no tiene trabajo, creamos el trabajo y lanzamos el asistente con el id que recibimos tras crear el trabajo
                                const res = createDoc(rowData.id, aux.createtrab, aux.swalerror.title, aux.swalerror.text.title, aux.swalerror.btncancel.title);                                                            
                            }                            
                        }
                    })
                break
            case "alert-danger":
            case "alert-success":
                // DANGER CLICK -> NADA DE NADA(MENSAJE "NO se puede EDITAR este TRABAJO")
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${trabajador.controles.ots.revision.title}`, {autoClose: true});
            break
        }        
    }
    
    const createDoc = async(idplantilla, url, textError, goToTrabajo=false, txtConfirmacion="") => {

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idplantilla );
        data.append("fec1", valFec1 );
        data.append("idage", valTrabajador );
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+url, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+url, data, source.token);

        if( typeof respuesta.data.errorot == "string" ){
            // Ya existe una ot
            alertService.error(`<a href='${window.location.origin}/documentosenc/${respuesta.data.idot}'>${respuesta.data.errorot}</a>`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            if( goToTrabajo ){
                // Crear trabajo y ejecutar
                // Así lo mantenemos en la misma pestaña y no habría que actualizar la tabla
                // propiedades.history.push("/documentosenc/"+respuesta.data.idClon); 
                // Así ejecutamos el wizard
                propiedades.history.push("/documentostrab/"+respuesta.data.idClon); 
    
                // Así abrimos la ot en otra pestaña y recargamos la tabla para que se actualice el campo que indica si hay ot abierta
                // window.open("/documentosenc/"+respuesta.data.idClon);
                // loadDatosTable();   
            }else{
                // Crear trabajo/ot depende desde donde se llame              
                alertService.success(`<i class="fal fa-check-circle mr-1"></i>${txtConfirmacion}`, {autoClose: true});
                saveReloadKpi(reloadKpi => reloadKpi+1)
            }
        }else if( typeof respuesta.data == "string" ){
            alertService.error(`${textError}`, {autoClose: false});
        }

        saveShowModalMantenimientosPendientes(false);
        saveShowModalMantenimientosCorrectivos(false);

    }

    const handleFec1 = (e) => {
        const { name, value } = e.target;
        saveValFec1(value)        
    }
    const handleSelectTrabajador = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            saveValTrabajador(selectedItem.value);  
        }else{
            saveValTrabajador(0);
        }

    }

    return(
        <Fragment>
            <Kpi
                conf={ trabajador.controles.kpi }
                getDataTable1 = { trabajador.controles.ots.datatable.getdata }
                saveDataTable1 = { saveDatosTableOts }
                saveLoadingTable = { saveLoadingTable }
                forceReload={ reloadKpi }
            />
            <Section titulo={ trabajador.controles.ots.section.titulo } icono={ trabajador.controles.ots.section.icono } col={ trabajador.controles.ots.section.col } wiki={ trabajador.controles.ots.section.wiki }>
                {
                    datosTabableOts != null
                    ?
                        <Fragment>
                            <div className="row" >
                                <div className="col-12">
                                    <div className="row justify-content-md-center"> 
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-primary alertHelp" role="alert">
                                                {getNumFromData(datosTabableOts, 'alert-primary')}{trabajador.controles.ots.help.primary.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-warning alertHelp" role="alert">
                                                {getNumFromData(datosTabableOts, 'alert-warning')}{trabajador.controles.ots.help.warning.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-danger alertHelp" role="alert">
                                                {getNumFromData(datosTabableOts, 'alert-danger')}{trabajador.controles.ots.help.danger.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-secondary alertHelp" role="alert" >
                                                {getNumFromData(datosTabableOts, 'alert-secondary')}{trabajador.controles.ots.help.light.title}
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-2">
                                            <div className="alert alert-success alertHelp" role="alert">
                                                {getNumFromData(datosTabableOts, 'alert-success')}{trabajador.controles.ots.help.success.title}
                                            </div>
                                        </div>
                                    </div>
                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"otsTrabajador"}
                                    columnas={trabajador.controles.ots.datatable.columns}
                                    data={datosTabableOts}
                                    linkColumns={trabajador.controles.ots.datatable.linkColumns}
                                    buttons={trabajador.controles.ots.datatable.buttons}
                                    ordering={trabajador.controles.ots.datatable.ordering}
                                    order={trabajador.controles.ots.datatable.order}
                                    searching={trabajador.controles.ots.datatable.searching}
                                    paging={trabajador.controles.ots.datatable.paging}
                                    select={trabajador.controles.ots.datatable.select}
                                    pagelength={trabajador.controles.ots.datatable.pagelength}
                                    // saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={trabajador.controles.ots.datatable.columnactions.title}
                                    traduction={traduction.datatable}
                                    functionClickTd={ checkOt }
                                />                          

                                </div>
                            </div>
                        </Fragment>
                    :
                        null
                }
            </Section>
            <Section titulo={trabajador.controles.createcorrectivo.section.title} icono={trabajador.controles.createcorrectivo.section.icono} >
                <MiDataTable 
                    estadoForm= { "" }
                    formulario={ {id:0} }
                    id={"createcorrectivo"}
                    columnas={trabajador.controles.createcorrectivo.datatable.columns}
                    data={plantillasCorrectivos}
                    linkColumns={trabajador.controles.createcorrectivo.datatable.linkColumns}
                    buttons={trabajador.controles.createcorrectivo.datatable.buttons}
                    ordering={trabajador.controles.createcorrectivo.datatable.ordering}
                    order={trabajador.controles.createcorrectivo.datatable.order}
                    searching={trabajador.controles.createcorrectivo.datatable.searching}
                    paging={trabajador.controles.createcorrectivo.datatable.paging}
                    select={trabajador.controles.createcorrectivo.datatable.select}
                    pagelength={trabajador.controles.createcorrectivo.datatable.pagelength}
                    saveLoadingTable={ saveLoadingPlantillasCorrectivos }
                    loadingTable={ loadingPlantillasCorrectivos }
                    columnactions={trabajador.controles.createcorrectivo.datatable.columnactions.title}
                    traduction={traduction.datatable}
                    functionClickTd={ clickCorrectivo }
                />    
            </Section>
            {/* ********* MODAL PARA CLICK EN LA TABLA MANTENIMIENTOS CORRECTIVOS ********* */}
        {
            showModalMatenimientosCorrectivos
            ?
                <Modal
                    size="lg"
                    show={true}
                    onHide={ () => saveShowModalMantenimientosCorrectivos(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}
                    contentClassName="likeSw"
                    backdropClassName="backLikeSw"                                              
                >                          
                    <Modal.Body>
                        <div className="swal-icon swal-icon--warning">
                            <span className="swal-icon--warning__body">
                            <span className="swal-icon--warning__dot"></span>
                            </span>
                        </div>                        
                        <div className="swal-text">{trabajador.controles.createcorrectivo.swal.text.title}</div>
                        <h6 className='text-center' dangerouslySetInnerHTML={{ __html: descripcionDocu }}></h6>
                        <MiSelect  
                            estadoForm=  { "" }
                            val=         { valTrabajador }   
                            loading=     { false }
                            id=          { confPantalla.documentos.padre.controles.idage2.id }    
                            name=        { confPantalla.documentos.padre.controles.idage2.name }   
                            label=       { confPantalla.documentos.padre.controles.idage2.label }    
                            placeholder= { confPantalla.documentos.padre.controles.idage2.placeholder }
                            col=         { 12 } 
                            isClearable= { confPantalla.documentos.padre.controles.idage2.isclearable }
                            isSearchable={ confPantalla.documentos.padre.controles.idage2.issearchable }
                            dataType=    { confPantalla.documentos.padre.controles.idage2.datatype }
                            data=        { confPantalla.documentos.padre.controles.idage2.data }
                            required=    { false } 
                            disabled=    { false }
                            readOnly =   { false }
                            disablethisid={false}
                            disableids=   {false}
                            formulario= { {id:0} }   
                            change= { handleSelectTrabajador }
                        />
                        <FormInput                                                        
                            estadoForm= { "" }
                            value=      { valFec1 }
                            loading=    { false }
                            id=         { confPantalla.documentos.padre.controles.fec1.id }    
                            type=       { "date" }  
                            name=       { confPantalla.documentos.padre.controles.fec1.name }   
                            label=      { confPantalla.documentos.padre.controles.fec1.label }                                                       
                            placeholder={ confPantalla.documentos.padre.controles.fec1.placeholder }    
                            col=        { "12" } 
                            required=   { false }
                            readonly=   { false }                                                
                            disabled=   { false }
                            change=     { handleFec1 }
                            onBlur=     {null} 
                        />
                        {/* <div className='form-group col-md-12'>
                            <Informes
                                select =     { trabajador.controles.createcorrectivo.informes.select }
                                formulario = { {id: idMantenimientosCorrectivos} }
                                getRegistro = { trabajador.controles.createcorrectivo.informes.getregistro }
                                loadReg =    { false }
                                estadoForm=  { "" } 
                                traduction=  { confPantalla.config.trad.components }
                                btnGenerar= { trabajador.controles.createcorrectivo.informes.btngenerar.title }
                            />
                        </div> */}
                        <div className='text-right'>
                            <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosCorrectivos(0) }                                        
                            >
                                {trabajador.controles.createcorrectivo.swal.btncancel.title}
                            </button>
                            {/* <button 
                                className="swal-button swal-button--cancel btn btn-flat mr-1 mt-1 btn-outline-secondary"
                                onClick={ e => actionsModalMantenimientosCorrectivos(4) }
                            >
                                {trabajador.controles.createcorrectivo.swal.comprobar.title}
                            </button> */}
                            <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ valTrabajador == "0" || valTrabajador == null || valTrabajador == "null" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosCorrectivos(1) }
                            >
                                {trabajador.controles.createcorrectivo.swal.btnconfirm.title}
                            </button>
                            {/* <button
                                className="swal-button swal-button--confirm btn btn-flat mr-1 mt-1 btn-outline-success btnSwalSuccess"
                                disabled={ !isPlanPreventivo || valTrabajador == "0" || valTrabajador == null || valTrabajador == "null" || valFec1 == "" ? true : false }
                                onClick={ e => actionsModalMantenimientosCorrectivos(2) }
                            >
                                {trabajador.controles.createcorrectivo.swal.btncreateprev.title}
                            </button>
                            <button 
                                className="swal-button swal-button--verplant btn btn-flat mr-1 mt-1 btn-outline-warning btnSwalWarning"
                                onClick={ e => actionsModalMantenimientosCorrectivos(3) }
                            >
                                {trabajador.controles.createcorrectivo.swal.btnverplant.title}
                            </button> */}
                            {
                                checkeando == true
                                ?
                                    <div className='mt-2 alert alert-primary'  role='alert' style={{display: "flex", justifyContent: "space-around"}}>
                                        <Spinner animation="border" />                                    
                                    </div>
                                :
                                    null
                            }
                            {
                                checkear == true
                                ?
                                    <div className='mt-2 alert alert-primary text-left'  role='alert' /*style={{display: "flex", justifyContent: "space-around"}}*/ >
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkEstado == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkEstado == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {trabajador.controles.createcorrectivo.checkear.estado.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkEstadoNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkFecha == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkFecha == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {trabajador.controles.createcorrectivo.checkear.fecha.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkFechaNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkCentro == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkCentro == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            } 
                                            {trabajador.controles.createcorrectivo.checkear.centro.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkCentroNom}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{fontStyle: "italic"}}>
                                            {
                                                checkRealizado == true 
                                                ?
                                                    <i className="fal fa-check checksuccess" style={{minWidth: "28px"}}></i>
                                                : 
                                                    checkRealizado == false
                                                ?
                                                    <i className="fal fa-times checkerror" style={{minWidth: "28px"}}></i>
                                                :
                                                    null
                                            }                                                 
                                            {trabajador.controles.createcorrectivo.checkear.realizado.title}: 
                                                <span className='pl-1' style={{fontSize : "1.2em", fontStyle : "normal", fontWeight : "500"}}>{checkRealizadoNom}</span>
                                            </p>
                                        </div>
                                    </div>                                                                                          
                                :
                                    null
                            }
                              {
                                errorCheckeando != false
                                ?
                                    <div className='mt-2 alert alert-danger'  role='alert' style={{display: "flex", justifyContent: "space-around"}}>
                                        <div>
                                            <h5>{errorCheckeando}</h5>
                                        </div>                                     
                                    </div>                                                                                          
                                :
                                    null
                            }
                        </div>
                    </Modal.Body>                            
                </Modal>                
            :
                null
        }             
        </Fragment>
    )
}

export default InicioTrab;