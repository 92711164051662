import React, { useState, useEffect, Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios';

import { LoginContext } from '../../../hooks/context/LoginContext';

import ButtonOutline from '../../ui/ButtonOutline';
import FormInput from '../../formulario/FormInput';
import MiSelect from '../../formulario/MiSelect';
import FormRow from '../../formulario/FormRow';
import TextArea from '../../formulario/TextArea';
import Uplm from '../../formulario/Uplm/Uplm';
import CheckIos from '../../ui/CheckIos';
import { Spinner } from 'react-bootstrap';
import { alertService } from '../../../services/alertservice';
import swal from 'sweetalert';
import { Accordion, Card } from "react-bootstrap";
import MiDataTable from '../../ui/MiDataTable';
import MiDataTableCanPos from './MiDataTableCanPos';
import Modal from 'react-bootstrap/Modal';
import Button from '../../ui/Button';
import WizardBtns from '../WizardBtns';
import Firma from '../Firma';


import Cookies from 'universal-cookie';

import { MiReplaceAll, getFechaHoraActualSql, getIndexUrl, getApiUrl, axiosPost, setStockOptionsSelectUbi, getStockOptionsSelectUbi, getDecodedCookie } from '../../../helpers/helperApp';

let lineasCanPosDataTable, backUpOptionsUbi;

function WizardMaterialesStepCanPos(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();

    const { lineasCanPos, cabecera, paso, pasos, saveCurrentStep, controles, confPantalla, saveLoadReg, propiedades, saveRegAnterior, resumenHerramientas, resumenMateriales, loadingForms, saveLoadingForms, soloHerramientas, adjuntosCabecera, updateCanPos, nomEquipo, lineasFirmas } = props;

    const [ fecDocIn, saveFecDocIn ] = useState(getFechaHoraActualSql());
    const [ acordeon, saveAcordeon ] = useState(null);  

    const [ showModal, saveShowModal ] = useState(false);
    const [ fec1, saveFec1 ] = useState("");
    const [ fec3, saveFec3 ] = useState("");
    const [ estableciendoFechas, saveEstableciendoFechas ] = useState();
    const [ btnAceptar, saveBtnAceptar ] = useState(false);
    const [ btnRevisar, saveBtnRevisar ] = useState(false);
    const [ docFinalizado, saveDocFinalizado ] = useState(false);
    const [ errorFec, saveErrorFec ] = useState(false);
    const [ showFirmas, saveShowFirmas ] = useState();

    
    saveCurrentStep(props.currentStep-1);

    const { apiKey, menuMode, btnWizPos } = useContext(LoginContext);

    useEffect( () => {         
            saveLoadingForms(false);                

            return () =>{    
                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
            } 
    },[])

    
    // Preparamos los datos para el datatable de edición 
    lineasCanPosDataTable = lineasCanPos.map(canPos => {

        canPos.linea["respuesta"] = canPos.respuesta;
        let nombre = "";
        if( getDecodedCookie("idioma") == "es" ){
            nombre = canPos.linea.nom1;
        }else if( getDecodedCookie("idioma") == "en" ){
            nombre = canPos.linea.nom1_en;
        }else{
            nombre = canPos.linea.nom1+" - "+canPos.linea.nom1_en; 
        }
        canPos.linea["descripcion"] = nombre;

        return canPos.linea;
    })
    

    const next = async () => { 

        saveLoadReg(true);

        let formValido = true;
        const data = new FormData();
        const inputsCant = document.querySelectorAll(".input-cant");
        const inputsListaRef = document.querySelectorAll(".input-listaref");

        
        for(let i=0; i<inputsCant.length; i++ ){
            if( inputsCant[i].required && inputsCant[i].value == "" ){
                inputsCant[i].classList.add("errorinput");
                inputsCant[i].focus();
                formValido = false;
                break
            }else{
                // Obtenemos la configuración de la línea para enviar por post
                const linea = lineasCanPosDataTable.filter((canPos) => canPos.id == inputsCant[i].name )
                inputsCant[i].classList.remove("errorinput");                
                let aux = { "valueCantidad" : inputsCant[i].value, "valuePosicion" : inputsListaRef[i].value, "confLinea" : linea[0].respuesta.data[0].val }
                data.append(inputsCant[i].name, JSON.stringify(aux));
            }
        }              
        if( formValido ){
            data.append("validacion", apiKey());  
            data.append("idPadre", lineasCanPos[0].linea.iddoc);    
            data.append("fecIn", fecDocIn);
            data.append("fecOut", getFechaHoraActualSql());
            data.append("cantidadPosicion", true);        
            // if( paso == pasos.length ){
            if( paso == pasos ){
                data.append("ultimoPaso", true);
            }

            // enviamos la petición
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+updateCanPos, data, source.token);            

            if( typeof respuesta.data === "string" ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.config.trad.components.wizard.saveerror.title}`, {autoClose: true});
            }else{
                // Guardamos la fecha de salida que es la de entrada del siguiente paso
                let aux = { "fec3wizard" : getFechaHoraActualSql() }
                saveRegAnterior(aux)

                // Pasamos a la siguiente línea
                if(paso < pasos){
                    props.nextStep();
                }
    
                // Si completó todos los pasos lo redirigimos al inicio
                // if( paso == pasos.length ){
                if( paso == pasos){
                     // Modificación 14-12-2022: Al finalizar el wizard preguntar las fechas de inicio y fin
                     saveFec1("");
                     saveFec3("");
                     saveBtnAceptar(false);
                     saveBtnRevisar(false);
                     saveDocFinalizado(false);
                     saveShowModal(true);                                     
                }
            }
    
            saveLoadReg(false);

        }
        saveLoadReg(false);
    }

    const actionsAfterSign = () => {
        propiedades.history.push(getIndexUrl(menuMode));
    }

    const accionModal = async() => {

        let fecha1 = fec1;
        let fecha3 = fec3;
        let sinFechas = false;
        let continuar = false;

        if( fecha3 != "" && fecha3 < fecha1 ){
            saveErrorFec(true);
        }else{
            saveErrorFec(false);
            if( fecha1 == "" && fecha3 == "" ){
                sinFechas = true;
            }
            if( sinFechas == false ){
                if( fecha1 == "" && fecha3 != "" ){
                    fecha1 = fecha3;
                }
                if( fecha3 == "" && fecha1 != "" ){
                    fecha3 = fecha1;
                }
            }
            saveEstableciendoFechas(true);
    
            if( sinFechas == false ){
                const data = new FormData();
                data.append("validacion", apiKey());
                data.append("iddoc", cabecera.id);
                data.append("fec1", fecha1);
                data.append("fec3", fecha3);
                const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.documentostrab.hijo.controles.modalwizard.action, data, source2.token);
                if( respuesta.data == cabecera.id ){
                    continuar = true;
                }else if(typeof respuesta.data == "string"){
                    alertService.error(`${respuesta.data}`, {autoClose: false});
                }
            }
    
            saveEstableciendoFechas(false);
            // saveShowModal(false);
    
            if( sinFechas == true || continuar == true ){
                saveBtnAceptar(true);
                saveBtnRevisar(true);
                saveDocFinalizado(true);            
            }
        }

    }

    const accionBtnRevisarModal = (e) => {
        saveShowModal(false);
    }

    const accionBtnAceptarModal = (e) => {
        if( lineasFirmas.length > 0 ){
            saveShowModal(false);
            saveShowFirmas(true);        
        }else{
            propiedades.history.push(getIndexUrl(menuMode));
        }
    }
    
    const handleInputFecModal = (e, saveFec) => {
        const { name, value } = e.target;
        saveFec(value)       
    }

    const exit = (e) => {
        propiedades.history.push(getIndexUrl(menuMode));
    }

    const previous = async () => {        
        props.previousStep();
    }

    const toogleAcordeon = (id) => {

        if( acordeon == id ){
            saveAcordeon(null);
        }else{
            saveAcordeon(id);
        }                
    }

    return(
        <div
            currentStep={paso}                        
        >
        {
            loadingForms 
            ?
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            :
             null
        }
            {
                lineasCanPos.length > 0
                ?
                    <Fragment>
                        {
                            btnWizPos == "top" || btnWizPos == "both" 
                            ?
                                <WizardBtns
                                    accionBtnExit={exit} 
                                    accionBtnPrev={previous}
                                    accionBtnNext={next}
                                    disabledBtnExit={false}
                                    disabledBtnPrev={props.currentStep == 1 ? true : false}
                                    disabledBtnNext={false}
                                />
                            :
                                null
                        }
                        {
                            !soloHerramientas
                            ?
                                <div className="alert alert-dark text-center" style={{borderColor: "#ccc"}} role="alert">
                                    <h5>{ controles.wizard.acordeon.cantidadposicion.title }</h5> 
                                    <h5>{ nomEquipo }</h5>                                  
                                </div>
                            :
                                null
                        }
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0')} className={ acordeon == "0" ? "activeAcordeon" : "" } >
                                    {
                                        getDecodedCookie("idioma") == "es"
                                        ?
                                                cabecera.nom1
                                            :
                                                getDecodedCookie("idioma") == "en"
                                                ?
                                                    cabecera.nom1_en
                                                :
                                                    cabecera.nom1.label+" - "+cabecera.nom1_en.label
                                    }
                                    {
                                        acordeon == "0"
                                        ?
                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                        :
                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                    }                                            
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                    <Card.Body>
                                        {
                                            getDecodedCookie("idioma") == "es"
                                            ?
                                                <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas}</div>
                                                :
                                                    getDecodedCookie("idioma") == "en"
                                                    ?
                                                        <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas_en}</div>
                                                    :
                                                        <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas} <br/> {cabecera.notas_en}</div>
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toogleAcordeon('1')} className={ acordeon == "1" ? "activeAcordeon" : "" } >
                                            { controles.wizard.acordeon.herramientas.title } - {"("+resumenHerramientas.length+")"}
                                            {
                                                acordeon == "1"
                                                ?
                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                :
                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                            }                                            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1" className={"slowAcordeon"} >
                                            <Card.Body>                                               
                                                <MiDataTable 
                                                    formulario={{id:0}}
                                                    estadoForm= { "" }
                                                    id={"herramientas"+paso}
                                                    columnas={[{"title":controles.wizardmateriales.datatableherramientas.columnas.title, "data": "html"}]}
                                                    data={resumenHerramientas}
                                                    buttons={true}
                                                    ordering={true}
                                                    order={[0,"asc"]}
                                                    searching={true}
                                                    paging={true}
                                                    select={false}
                                                    pagelength={25}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>                     
                                <div className="col-lg-4 col-md-12">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => toogleAcordeon('2')} className={ acordeon == "2" ? "activeAcordeon" : "" } >
                                        { controles.wizard.acordeon.materiales.title } - {"("+resumenMateriales.length+")"}
                                        {
                                            acordeon == "2"
                                            ?
                                                <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                            :
                                                <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                        }
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2" className={"slowAcordeon"} >
                                            <Card.Body>
                                                <MiDataTable 
                                                    formulario={{id:0}}
                                                    estadoForm= { "" }
                                                    id={"materiales"+paso}
                                                    columnas={[{"title":controles.wizardmateriales.datatablemateriales.columnas.title, "data": "html"}]}
                                                    data={resumenMateriales}
                                                    buttons={true}
                                                    ordering={true}
                                                    order={[0,"asc"]}
                                                    searching={true}
                                                    paging={true}
                                                    select={false}
                                                    pagelength={25}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => toogleAcordeon('3')} className={ acordeon == "3" ? "activeAcordeon" : "" } >
                                            { controles.wizard.acordeon.adjuntos.title } - {"("+adjuntosCabecera.length+")"}
                                            {
                                                acordeon == "3"
                                                ?
                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                :
                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                            }                                            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="3" className={"slowAcordeon"} >
                                            <Card.Body>                                               
                                                <MiDataTable 
                                                    formulario={{id:0}}
                                                    estadoForm= { "" }
                                                    id={"adjuntos"+paso}
                                                    columnas={[{"title":controles.wizardmateriales.datatableadjuntos.columnas.title, "data": "html"}]}
                                                    data={adjuntosCabecera}
                                                    buttons={true}
                                                    ordering={true}
                                                    order={[0,"asc"]}
                                                    searching={true}
                                                    paging={true}
                                                    select={false}
                                                    pagelength={25}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                            </div>
                        </Accordion>
                    </Fragment>
                :
                    null
            } 
            {
                soloHerramientas
                ?
                    <h1 className="text-center">{controles.wizard.nolinescheck.title}</h1>
                :
                    null
            }
            {
                lineasCanPos.length > 0
                ?
                    <Fragment>
                        {
                            <div className="">                                                      
                                <MiDataTableCanPos 
                                    formulario={{id:0}}
                                    estadoForm= { "" }
                                    id={"inputcanpos"+paso}
                                    columnas={controles.wizardmateriales.datatableedicanpos.columns}
                                    datos={lineasCanPosDataTable}
                                    buttons={controles.wizardmateriales.datatableedicanpos.buttons}
                                    ordering={controles.wizardmateriales.datatableedicanpos.ordering}
                                    order={controles.wizardmateriales.datatableedicanpos.order}
                                    searching={controles.wizardmateriales.datatableedicanpos.searching}
                                    paging={controles.wizardmateriales.datatableedicanpos.paging}
                                    select={controles.wizardmateriales.datatableedicanpos.select}
                                    pagelength={controles.wizardmateriales.datatableedicanpos.pagelength}
                                    traduction={confPantalla.config.trad.components.datatable}
                                    inputCantidad={confPantalla.documentostrab.hijo.controles.num1}
                                    inputListaRef={confPantalla.documentostrab.hijo.controles.txt2}
                                />
                            </div>
                            
                        }                       
                        {
                            btnWizPos == "bottom" || btnWizPos == "both" 
                            ?
                                <WizardBtns
                                    accionBtnExit={exit} 
                                    accionBtnPrev={previous}
                                    accionBtnNext={next}
                                    disabledBtnExit={false}
                                    disabledBtnPrev={props.currentStep == 1 ? true : false}
                                    disabledBtnNext={false}
                                />
                            :
                                null
                        }
                    </Fragment>
                :
                    btnWizPos == "bottom" || btnWizPos == "both" 
                    ?
                        <WizardBtns
                            accionBtnExit={exit} 
                            accionBtnPrev={previous}
                            accionBtnNext={next}
                            disabledBtnExit={false}
                            disabledBtnPrev={props.currentStep == 1 ? true : false}
                            disabledBtnNext={false}
                        />
                    :
                        null
            }
            {
                showFirmas && lineasFirmas.length > 0
                ?
                    <Firma
                        cabecera={cabecera}
                        lineasFirmas={lineasFirmas}
                        controles={ controles.wizardmateriales }
                        show={showFirmas}
                        saveShow={saveShowFirmas}
                        traduction={confPantalla.config.trad.components.firma}
                        functionAfter={actionsAfterSign}
                    />
                :
                    null
            }
            <Modal
                show={showModal}
                onHide={ () => saveShowModal(false)}                            
                aria-labelledby="example-custom-modal-styling-title"
                centered={true}                    
                dialogClassName="modal-90w"                                             
            > 
                <Modal.Header closeButton>
                    {
                        !docFinalizado
                        ?
                            <h4>{confPantalla.documentostrab.hijo.controles.modalwizard.titulo.title}</h4>
                        :
                            null
                    }
                </Modal.Header>                         
                <Modal.Body>
                    <FormRow> 
                        {
                            !docFinalizado
                            ?
                                <Fragment>

                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { fec1 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec1.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.required }
                                        readonly=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.readonly }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.disabled }
                                        change=     { e => handleInputFecModal(e,saveFec1) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { cabecera.fec2 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec2.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.required }
                                        readonly=   { true }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.disabled }
                                        change=     {null}
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { fec3 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec3.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.required }
                                        readonly=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.readonly }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.disabled }
                                        change=     { e => handleInputFecModal(e,saveFec3) }
                                        onBlur=     {null} 
                                    />
                                </Fragment>
                            :
                                null
                        }                     
                    </FormRow>
                    {
                        errorFec
                        ?
                            <div class="alert alert-danger text-center" role="alert">
                                {confPantalla.config.trad.components.wizard.fecerror.title}
                            </div>
                        :
                            null
                    }
                    {
                        estableciendoFechas
                        ?
                            <div className="text-center">
                                <Spinner animation="border" />
                            </div>                    
                        :
                            null
                    }
                    {
                        !docFinalizado
                        ?
                            <Button 
                                clases={ "col-12 mt-2" }
                                color={ "success" }  
                                type="button" 
                                icono={ "" }   
                                id="btnsetmodal"     
                                texto={ confPantalla.documentostrab.hijo.controles.modalwizard.btntitle.title }   
                                accion={ accionModal } 
                                disabled={ false }
                            />
                        :
                            null
                    }
                    {
                        docFinalizado
                        ?
                            <div className='flex-center' style={{flexDirection: "column", alignItems: "center"}}>
                                <p>
                                    <i className="fal fa-check-circle fa-4x" style={{color : "#28a745"}}></i>
                                </p>
                                <h3 style={{fontWeight: "lighter", fontStyle: "italic"}}>
                                    {confPantalla.documentostrab.hijo.controles.modalwizard.txtfin.title}
                                </h3>
                            </div>
                        :
                            null
                    }
                    <div className="text-right"> 
                    {
                        btnRevisar
                        ?
                            <button 
                                    className="swal-button swal-button--danger btn btn-flat mr-1 mt-1 btn-outline-danger"
                                    onClick={ e => accionBtnRevisarModal(e) }                                        
                            >
                                {confPantalla.documentostrab.hijo.controles.modalwizard.btnRevisar.title}
                            </button>
                        :
                            null
                    }
                    {
                        btnAceptar
                        ?
                            <button 
                                    className="swal-button swal-button--success btn btn-flat mr-1 mt-1 btn-outline-success"
                                    onClick={ e => accionBtnAceptarModal(e) }                                        
                            >
                                {confPantalla.documentostrab.hijo.controles.modalwizard.btnAceptar.title}
                            </button>                              
                        :
                            null
                    }                      
                    </div>                                                                        
                </Modal.Body>                            
            </Modal>          
        </div>
    )
}

export default WizardMaterialesStepCanPos;