import React, { Fragment } from 'react';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import DatePicker from 'rsuite/DatePicker';
import 'rsuite/DatePicker/styles/index.css';
import { getDatePickerDaysName } from '../../helpers/helperApp';


function FormInput(props){

    const { id, type, name, label, placeholder, col, estadoForm, required, readonly, disabled, onBlur, change, loading, autoComplete, alignRight, title, keyUp, linkRight, linkLeft, linkColor, linkIco, clasesInput } = props;

    let { value } = props;    
    if( value == null ){
        value = "";
    }

    $(`input[name='${name}']`).on('change invalid', function() {
        var textfield = $(this).get(0);
        
        // 'setCustomValidity not only sets the message, but also marks
        // the field as invalid. In order to see whether the field really is
        // invalid, we have to remove the message first
        if( title ){
            textfield.setCustomValidity('');
            
            if (!textfield.validity.valid) {
              textfield.setCustomValidity(title);  
            }
        }
    });

    const handleInputFormDatePicker = (val, event, name) => {                   
        let value;                        
        if(val == null){
            value = '';                
        }else{                                                                   
            const anho = val.getFullYear()
            const mes = val.getMonth()+1 < 10 ? '0'+(val.getMonth()+1) : val.getMonth()+1;
            const dia = val.getDate() < 10 ? '0'+val.getDate() : val.getDate();
            const horas = val.getHours() < 10 ? '0' + val.getHours() : val.getHours(); 
            const minutos = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
            value = `${anho}-${mes}-${dia}T${horas}:${minutos}`;
        }

        // Llamar a handleInputForm con el valor y el name 
        change({ target: { value, name } });           
    }

    const handleInputFormDatePickerOnBlur = (val, event, name) => {        
        handleInputFormDatePicker(val, event, name);        
        if(onBlur !== null){
            let value;                        
            if(val == null){
                value = '';                
            }else{                                                                   
                const anho = val.getFullYear()
                const mes = val.getMonth()+1 < 10 ? '0'+(val.getMonth()+1) : val.getMonth()+1;
                const dia = val.getDate() < 10 ? '0'+val.getDate() : val.getDate();
                const horas = val.getHours() < 10 ? '0' + val.getHours() : val.getHours(); 
                const minutos = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
                value = `${anho}-${mes}-${dia}T${horas}:${minutos}`;
            }
            onBlur({ target: { value, name } });
        } 
    }
    

    return(
        <div className={"form-group col-md-"+col}>
            {
                type != "hidden"
                ?
                    <label htmlFor={id}>{label}</label>
                :
                null
            }
            <div className="input-group mb-2">
                {
                    linkLeft
                    ?
                        <div clclassNameass="input-group-prepend">
                            <NavLink to={linkLeft} className={"btn btn-"+linkColor}><i className={linkIco}></i></NavLink>
                        </div>
                    :
                        null
                }
                {
                    type == 'datetime-local' || type == 'datetime' ||type == 'date'
                    ?
                        <Fragment>
                            <DatePicker
                                id={id}
                                name={name}
                                placeholder={' '}
                                format={type == 'datetime-local' || type == 'datetime' ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
                                value={ value == '0000-00-00T00:00' || value == '' ? null : new Date(value) }
                                onChange={ (value, event) => handleInputFormDatePicker(value, event, name) }
                                onOk={onBlur !== null ? (value, event) => handleInputFormDatePickerOnBlur(value, event, name) : null}                                
                                readOnly={ estadoForm === "readonly" || loading ? true : readonly }
                                className={ estadoForm === "readonly" || estadoForm === 'disabled' || loading ? 'readonly-style' : ''}
                                disabled={ estadoForm === "disabled" ? true : disabled } 
                                showTime
                                block={true}
                                weekStart={1}
                                locale={getDatePickerDaysName()}
                            />
                            <input type="hidden" name={name} value={value == '0000-00-00T00:00' || value == '' ? '' : value} />
                        </Fragment>  
                    :                    
                        <input  type={ type } 
                            className={ alignRight === true ? "form-control text-right "+clasesInput : "form-control "+clasesInput} 
                            id={ id } 
                            name={ name } 
                            placeholder={ placeholder }
                            value={ value } 
                            onChange={ change } 
                            required={ required } 
                            disabled={ estadoForm === "disabled" ? true : disabled } 
                            readOnly={ estadoForm === "readonly" || loading ? true : readonly } 
                            autoComplete={ autoComplete === undefined ? "off" : autoComplete } 
                            onBlur={ onBlur !== null ? onBlur : null } 
                            title = {placeholder}
                            onKeyUp={ keyUp !== null ? keyUp : null }
                        />
                }
                {
                    linkRight
                    ?
                        <div className="input-group-prepend" style={{marginLeft: "-1px"}}>
                            <NavLink to={linkRight} className={"btn btn-"+linkColor}  style={{borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem"}}><i className={linkIco}></i></NavLink>
                        </div>
                    :
                        null
                }
            </div>
        </div>
    )
}

export default FormInput;