import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Fragment } from 'react';
import Button from './ui/Button';
import Modal from 'react-bootstrap/Modal';
import MiDataTable from './ui/MiDataTable';

import { getApiUrl, axiosPost } from '../helpers/helperApp';

import { LoginContext } from '../hooks/context/LoginContext';

function CheckAppCfg(props){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
  
    const { confApp, apiKey } = useContext(LoginContext);    

    const [ showModal, saveShowModal ] = useState(false);
    const [ loading, saveLoading ] = useState(false);
    const [ datosTable, saveDatosTable ] = useState([]);
    const [ error, saveError ] = useState("");

    const getAppCfg = async () => {
        saveDatosTable([]);
        saveError("");
        saveLoading(true);
        saveShowModal(true);

        const data = new FormData();
        data.append("validacion", apiKey());            
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+props.config.getdata, data, source.token);

        if(typeof respuesta.data == "string"){
            saveError(respuesta.data);
        }else{
            saveDatosTable(respuesta.data);         
        }

        saveLoading(false);
    }
    return(
        <Fragment>
            <Button 
                clases={ "btn mb-1" }
                color={ "success" }  
                type="button" 
                icono={ "fal fa-tools pl-1" }   
                id={"btncheckappcfg"}     
                texto={ confApp.config.trad.components.checkappcfg.btn.title }   
                accion={ e => getAppCfg() } 
                disabled={ false }
                iconRight={true}
            />
            {
                showModal
                ?
                    <Modal
                        show={showModal}
                        onHide={ () => saveShowModal(false)} 
                        aria-labelledby="example-custom-modal-styling-title"                                    
                        dialogClassName="modal-90w"                                             
                    >
                        <Modal.Header closeButton> 
                               <h5>{confApp.config.trad.components.checkappcfg.modal.headertitle.title}</h5>                 
                        </Modal.Header>
                        <Modal.Body>
                            {
                                error != ""
                                ?
                                    <div class="alert alert-danger text-center" role="alert">
                                        {error}
                                    </div>
                                :
                                    <MiDataTable 
                                        estadoForm= { "" }
                                        formulario={ {id:0} }
                                        id={"tablafirmas"}
                                        columnas={props.config.datatable.columns}
                                        data={datosTable}
                                        linkColumns={props.config.datatable.linkColumns}
                                        buttons={props.config.datatable.buttons}
                                        ordering={props.config.datatable.ordering}
                                        order={props.config.datatable.order}
                                        searching={props.config.datatable.searching}
                                        paging={props.config.datatable.paging}
                                        select={props.config.datatable.select}
                                        pagelength={props.config.datatable.pagelength}
                                        loadingTable={loading}
                                        columnactions={props.config.datatable.columnactions.title}
                                        traduction={confApp.config.trad.components.datatable}                                        
                                    />                                        

                            }                            
                            <div className='text-right'>
                                <button 
                                        className="btn btn-danger mt-1"
                                        onClick={ () => saveShowModal(false) }                                        
                                >
                                    {confApp.config.trad.components.checkappcfg.modal.btncerrar.title}
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                :
                    null
            }                    
        </Fragment>
)

}
export default CheckAppCfg;