import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import Informes from '../../componentes/formulario/informes/Informes';
import UplmPlus2 from '../../componentes/formulario/UpmlPlus2/UplmPlus2';


import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getApiUrl, axiosPost, getTitle, getDecodedCookie } from '../../helpers/helperApp';

function Agentes(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectMultipleFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.agentes.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true); 
                        saveEstadoFormPrin(confPantalla.agentes.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.agentes.hijo.estadoForm)                               
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.agentes.padre.urls);
                                saveFormularioPrin(confPantalla.agentes.padre.modelform);
                                saveFormularioHijo(confPantalla.agentes.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.agentes.padre.urls, confPantalla.agentes.hijo.urls, confPantalla.agentes.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo); 
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();    
        } 
        
    },[confPantalla])
    
    const getUserInfoHandleSelect = async (selectedItem, nameOfComponent) => {

        // Guardamos el valor del select en el state
        // handleSelectFormPrin(selectedItem, nameOfComponent);

        // Hacemos una llamada para obtener el tipo(barco/instalación) del tipo de centro
        if( selectedItem ){
            const data = new FormData();
            data.append("id", selectedItem.value);
            const url = `${getApiUrl(window.location.origin)}/agentes/getInfoUsu`;
            // const res = await axios.post(url, data);
            const res = await axiosPost(url, data, source.token);

            saveFormularioPrin(
                {
                    ... formularioPrin, 
                    ["nom"] : res.data[0].nombre,
                    ["ema"] : res.data[0].email,
                    ["idusu"] : selectedItem.value
                }
            )
        }else{
            saveFormularioPrin(
                {
                    ... formularioPrin, 
                    ["nom"] : "",
                    ["ema"] : "",
                    ["idusu"] : ""
                }
            )
        }
       

    }

 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.agentes.section.titulo } icono={ confPantalla.agentes.section.icono } col={ confPantalla.agentes.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.agentes.section.wiki } >
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.agentes.padre.btnform.new }
                                                    btnSave={ confPantalla.agentes.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.agentes.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.agentes.padre.btnform.delete }
                                                    btnClone={ confPantalla.agentes.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.agentes.padre.urls, confPantalla.agentes.padre.modelform, confPantalla.agentes.padre.tabla, confPantalla.agentes.hijo.modelform, confPantalla.agentes.title) }
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.agentes.padre.modelform, true, confPantalla.agentes.padre.tabla) }  
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.agentes.padre.urls, confPantalla.agentes.padre.modelform, confPantalla.agentes.padre.tabla, confPantalla.agentes.title) } 
                                        >  
                                            <Informes
                                                select =     { confPantalla.agentes.padre.controles.informes.select }
                                                formulario = { formularioPrin }
                                                loadReg =    { loadReg ? true : false }
                                                estadoForm=  { estadoFormPrin } 
                                                traduction=  { confPantalla.config.trad.components }
                                            />
                                            <hr className="separator" />
                                            <Tabs defaultActiveKey="tab1" id="tab1">
                                                <Tab eventKey="tab1" title={ confPantalla.agentes.tabs.tab1.title }>
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.nom.id }   
                                                            type=       { confPantalla.agentes.padre.controles.nom.type }  
                                                            name=       { confPantalla.agentes.padre.controles.nom.name }   
                                                            label=      { confPantalla.agentes.padre.controles.nom.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.nom.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.nom.col } 
                                                            required=   { confPantalla.agentes.padre.controles.nom.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.nom.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.nom.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        /> 
                                                        <MiSelect  
                                                            estadoForm=  { confPantalla.agentes.padre.estadoform }
                                                            val=         { formularioPrin.idusu  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.agentes.padre.controles.idusu.id }    
                                                            name=        { confPantalla.agentes.padre.controles.idusu.name }   
                                                            label=       { confPantalla.agentes.padre.controles.idusu.label }    
                                                            placeholder= { confPantalla.agentes.padre.controles.idusu.placeholder }
                                                            col=         { confPantalla.agentes.padre.controles.idusu.col } 
                                                            isClearable= { confPantalla.agentes.padre.controles.idusu.isclearable }
                                                            isSearchable={ confPantalla.agentes.padre.controles.idusu.issearchable }
                                                            dataType=    { confPantalla.agentes.padre.controles.idusu.datatype }
                                                            data=        { confPantalla.agentes.padre.controles.idusu.data }
                                                            required=    { confPantalla.agentes.padre.controles.idusu.required } 
                                                            disabled=  { confPantalla.agentes.padre.controles.idusu.disabled }
                                                            readOnly =   { confPantalla.agentes.padre.controles.idusu.readonly }
                                                            disablethisid={confPantalla.agentes.padre.controles.idusu.disablethisid}
                                                            disableids={confPantalla.agentes.padre.controles.idusu.disableids}
                                                            formulario= { formularioPrin }   
                                                            change= { getUserInfoHandleSelect }
                                                        />  
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.raz }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.raz.id }   
                                                            type=       { confPantalla.agentes.padre.controles.raz.type }  
                                                            name=       { confPantalla.agentes.padre.controles.raz.name }   
                                                            label=      { confPantalla.agentes.padre.controles.raz.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.raz.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.raz.col } 
                                                            required=   { confPantalla.agentes.padre.controles.raz.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.raz.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.raz.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                            /> 
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.xif }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.xif.id }   
                                                            type=       { confPantalla.agentes.padre.controles.xif.type }  
                                                            name=       { confPantalla.agentes.padre.controles.xif.name }   
                                                            label=      { confPantalla.agentes.padre.controles.xif.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.xif.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.xif.col } 
                                                            required=   { confPantalla.agentes.padre.controles.xif.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.xif.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.xif.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                            />
                                                        <MiSelectMultiple  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_agentes_tip  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.agentes.padre.controles.rel_agentes_tip.id }    
                                                            name=        { confPantalla.agentes.padre.controles.rel_agentes_tip.name }   
                                                            label=       { confPantalla.agentes.padre.controles.rel_agentes_tip.label }    
                                                            placeholder= { confPantalla.agentes.padre.controles.rel_agentes_tip.placeholder }
                                                            col=         { confPantalla.agentes.padre.controles.rel_agentes_tip.col } 
                                                            isClearable= { confPantalla.agentes.padre.controles.rel_agentes_tip.isclearable }
                                                            isSearchable={ confPantalla.agentes.padre.controles.rel_agentes_tip.issearchable }
                                                            dataType=    { confPantalla.agentes.padre.controles.rel_agentes_tip.datatype }
                                                            data=        { confPantalla.agentes.padre.controles.rel_agentes_tip.data }
                                                            required=    { confPantalla.agentes.padre.controles.rel_agentes_tip.required } 
                                                            disabled=  { confPantalla.agentes.padre.controles.rel_agentes_tip.disabled }
                                                            readOnly =   { confPantalla.agentes.padre.controles.rel_agentes_tip.readonly }
                                                            disablethisid={confPantalla.agentes.padre.controles.rel_agentes_tip.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectMultipleFormPrin }
                                                        />
                                                        <MiSelectMultiple  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_agentes_cen  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.agentes.padre.controles.rel_agentes_cen.id }    
                                                            name=        { confPantalla.agentes.padre.controles.rel_agentes_cen.name }   
                                                            label=       { confPantalla.agentes.padre.controles.rel_agentes_cen.label }    
                                                            placeholder= { confPantalla.agentes.padre.controles.rel_agentes_cen.placeholder }
                                                            col=         { confPantalla.agentes.padre.controles.rel_agentes_cen.col } 
                                                            isClearable= { confPantalla.agentes.padre.controles.rel_agentes_cen.isclearable }
                                                            isSearchable={ confPantalla.agentes.padre.controles.rel_agentes_cen.issearchable }
                                                            dataType=    { confPantalla.agentes.padre.controles.rel_agentes_cen.datatype }
                                                            data=        { confPantalla.agentes.padre.controles.rel_agentes_cen.data }
                                                            required=    { confPantalla.agentes.padre.controles.rel_agentes_cen.required } 
                                                            disabled=  { confPantalla.agentes.padre.controles.rel_agentes_cen.disabled }
                                                            readOnly =   { confPantalla.agentes.padre.controles.rel_agentes_cen.readonly }
                                                            disablethisid={confPantalla.agentes.padre.controles.rel_agentes_cen.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectMultipleFormPrin }
                                                        />
                                                        <MiSelectMultiple  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_agentes_gf  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.agentes.padre.controles.rel_agentes_gf.id }    
                                                            name=        { confPantalla.agentes.padre.controles.rel_agentes_gf.name }   
                                                            label=       { confPantalla.agentes.padre.controles.rel_agentes_gf.label }    
                                                            placeholder= { confPantalla.agentes.padre.controles.rel_agentes_gf.placeholder }
                                                            col=         { confPantalla.agentes.padre.controles.rel_agentes_gf.col } 
                                                            isClearable= { confPantalla.agentes.padre.controles.rel_agentes_gf.isclearable }
                                                            isSearchable={ confPantalla.agentes.padre.controles.rel_agentes_gf.issearchable }
                                                            dataType=    { confPantalla.agentes.padre.controles.rel_agentes_gf.datatype }
                                                            data=        { confPantalla.agentes.padre.controles.rel_agentes_gf.data }
                                                            required=    { confPantalla.agentes.padre.controles.rel_agentes_gf.required } 
                                                            disabled=  { confPantalla.agentes.padre.controles.rel_agentes_gf.disabled }
                                                            readOnly =   { confPantalla.agentes.padre.controles.rel_agentes_gf.readonly }
                                                            disablethisid={confPantalla.agentes.padre.controles.rel_agentes_gf.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectMultipleFormPrin }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.dir }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.dir.id }   
                                                            type=       { confPantalla.agentes.padre.controles.dir.type }  
                                                            name=       { confPantalla.agentes.padre.controles.dir.name }   
                                                            label=      { confPantalla.agentes.padre.controles.dir.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.dir.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.dir.col } 
                                                            required=   { confPantalla.agentes.padre.controles.dir.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.dir.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.dir.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cp }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.cp.id }   
                                                            type=       { confPantalla.agentes.padre.controles.cp.type }  
                                                            name=       { confPantalla.agentes.padre.controles.cp.name }   
                                                            label=      { confPantalla.agentes.padre.controles.cp.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.cp.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.cp.col } 
                                                            required=   { confPantalla.agentes.padre.controles.cp.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.cp.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.cp.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.pob }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.pob.id }   
                                                            type=       { confPantalla.agentes.padre.controles.pob.type }  
                                                            name=       { confPantalla.agentes.padre.controles.pob.name }   
                                                            label=      { confPantalla.agentes.padre.controles.pob.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.pob.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.pob.col } 
                                                            required=   { confPantalla.agentes.padre.controles.pob.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.pob.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.pob.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.pro }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.pro.id }   
                                                            type=       { confPantalla.agentes.padre.controles.pro.type }  
                                                            name=       { confPantalla.agentes.padre.controles.pro.name }   
                                                            label=      { confPantalla.agentes.padre.controles.pro.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.pro.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.pro.col } 
                                                            required=   { confPantalla.agentes.padre.controles.pro.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.pro.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.pro.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idpais  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.agentes.padre.controles.pais.id }    
                                                            name=        { confPantalla.agentes.padre.controles.pais.name }   
                                                            label=       { confPantalla.agentes.padre.controles.pais.label }    
                                                            placeholder= { confPantalla.agentes.padre.controles.pais.placeholder }
                                                            col=         { confPantalla.agentes.padre.controles.pais.col } 
                                                            isClearable= { confPantalla.agentes.padre.controles.pais.isclearable }
                                                            isSearchable={ confPantalla.agentes.padre.controles.pais.issearchable }
                                                            dataType=    { confPantalla.agentes.padre.controles.pais.datatype }
                                                            data=        { confPantalla.agentes.padre.controles.pais.data }
                                                            required=    { confPantalla.agentes.padre.controles.pais.required } 
                                                            disabled=  { confPantalla.agentes.padre.controles.pais.disabled }
                                                            readOnly =   { confPantalla.agentes.padre.controles.pais.readonly }
                                                            disablethisid={confPantalla.agentes.padre.controles.pais.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />  
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.tel }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.tel.id }   
                                                            type=       { confPantalla.agentes.padre.controles.tel.type }  
                                                            name=       { confPantalla.agentes.padre.controles.tel.name }   
                                                            label=      { confPantalla.agentes.padre.controles.tel.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.tel.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.tel.col } 
                                                            required=   { confPantalla.agentes.padre.controles.tel.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.tel.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.tel.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.ema }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.ema.id }   
                                                            type=       { confPantalla.agentes.padre.controles.ema.type }  
                                                            name=       { confPantalla.agentes.padre.controles.ema.name }   
                                                            label=      { confPantalla.agentes.padre.controles.ema.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.ema.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.ema.col } 
                                                            required=   { confPantalla.agentes.padre.controles.ema.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.ema.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.ema.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.web }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.agentes.padre.controles.web.id }   
                                                            type=       { confPantalla.agentes.padre.controles.web.type }  
                                                            name=       { confPantalla.agentes.padre.controles.web.name }   
                                                            label=      { confPantalla.agentes.padre.controles.web.label }                                                       
                                                            placeholder={ confPantalla.agentes.padre.controles.web.placeholder }    
                                                            col=        { confPantalla.agentes.padre.controles.web.col } 
                                                            required=   { confPantalla.agentes.padre.controles.web.required }
                                                            readonly=   { confPantalla.agentes.padre.controles.web.readonly }                                                
                                                            disabled=   { confPantalla.agentes.padre.controles.web.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                </Tab>
                                                <Tab eventKey="tab2" title={ confPantalla.agentes.tabs.tab2.title }>
                                                    <TextArea                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.notas }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.agentes.padre.controles.notas.id }   
                                                        name=       { confPantalla.agentes.padre.controles.notas.name }
                                                        rows=       { confPantalla.agentes.padre.controles.notas.rows }      
                                                        label=      { confPantalla.agentes.padre.controles.notas.label }                                                       
                                                        placeholder={ confPantalla.agentes.padre.controles.notas.placeholder }    
                                                        col=        { confPantalla.agentes.padre.controles.notas.col } 
                                                        required=   { confPantalla.agentes.padre.controles.notas.required }
                                                        readonly=   { confPantalla.agentes.padre.controles.notas.readonly }                                                
                                                        disabled=   { confPantalla.agentes.padre.controles.notas.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                    <TextArea                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.notas_en }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.agentes.padre.controles.notas_en.id }   
                                                        name=       { confPantalla.agentes.padre.controles.notas_en.name }
                                                        rows=       { confPantalla.agentes.padre.controles.notas_en.rows }      
                                                        label=      { confPantalla.agentes.padre.controles.notas_en.label }                                                       
                                                        placeholder={ confPantalla.agentes.padre.controles.notas_en.placeholder }    
                                                        col=        { confPantalla.agentes.padre.controles.notas_en.col } 
                                                        required=   { confPantalla.agentes.padre.controles.notas_en.required }
                                                        readonly=   { confPantalla.agentes.padre.controles.notas_en.readonly }                                                
                                                        disabled=   { confPantalla.agentes.padre.controles.notas_en.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                </Tab>
                                                <Tab eventKey="tab3" title={ confPantalla.agentes.tabs.tab3.title }>
                                                    {/* <Uplm
                                                        estadoForm= { estadoFormPrin }
                                                        loading=    { loadReg ? true : false }
                                                        col={12}
                                                        id={ confPantalla.agentes.padre.controles.uplm.id }
                                                        adjuntos={ adjuntosPrin }
                                                        saveAdjuntos= { saveAdjuntosPrin }  
                                                        adjuntosExistentes= { adjuntosExistentesPrin } 
                                                        saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                        readonly={ confPantalla.agentes.padre.controles.uplm.readonly }
                                                        traduction={confPantalla.config.trad.components.uplm}
                                                    /> */}
                                                    <UplmPlus2
                                                        id={ confPantalla.agentes.padre.controles.uplmplus.id }
                                                        adjuntos={ adjuntosPrin }
                                                        saveAdjuntos= { saveAdjuntosPrin }  
                                                        adjuntosExistentes= { adjuntosExistentesPrin } 
                                                        saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                        estadoForm= { estadoFormPrin }
                                                        loading= { loadReg ? true : false }
                                                        col={12}
                                                        readonly={ confPantalla.agentes.padre.controles.uplmplus.readonly }
                                                        traduction={confPantalla.config.trad.components}                                                            
                                                        datatable={ confPantalla.agentes.padre.controles.uplmplus.datatable }
                                                        select={ confPantalla.agentes.padre.controles.uplmplus.select }
                                                    />
                                                </Tab>
                                                {/* <Tab eventKey="tab4" title={ confPantalla.agentes.tabs.tab4.title }>
                                                    <Informes
                                                        select =     { confPantalla.agentes.padre.controles.informes.select }
                                                        formulario = { formularioPrin }
                                                        loadReg =    { loadReg ? true : false }
                                                        estadoForm=  { estadoFormPrin } 
                                                        traduction=  { confPantalla.config.trad.components }
                                                    />
                                                </Tab>                                                                                                                 */}
                                            </Tabs>

                                        </Formulario>
                                        <MiDataTable
                                            formulario={formularioPrin} 
                                            estadoForm= { estadoFormPrin }
                                            id={"agentes"}
                                            columnas={confPantalla.agentes.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.agentes.padre.datatable.buttons}
                                            ordering={confPantalla.agentes.padre.datatable.ordering}
                                            order={confPantalla.agentes.padre.datatable.order}
                                            searching={confPantalla.agentes.padre.datatable.searching}
                                            paging={confPantalla.agentes.padre.datatable.paging}
                                            select={confPantalla.agentes.padre.datatable.select}
                                            pagelength={confPantalla.agentes.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.agentes.padre.urls, confPantalla.agentes.hijo.urls, confPantalla.agentes.padreshijos, confPantalla.agentes.hijo.modelform, confPantalla.agentes.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.agentes.padre.urls, confPantalla.agentes.padre.modelform, confPantalla.agentes.padre.tabla, confPantalla.agentes.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.agentes.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Agentes