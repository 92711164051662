import React,{ useContext, useEffect, useState } from 'react';
import FormRow from '../../formulario/FormRow';
import MiSelect from '../../formulario/MiSelect';
import FormInput from '../../formulario/FormInput';

import { dateSqlToLocal, getAhoraddmmyyyy, diffDatesToString }from '../../../helpers/helperApp';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Fragment } from 'react';

function Recurrencia(props){

    const {estadoForm, loading, formulario, changeSelect, saveFormulario, handleInput, traduction, noDisplay, pr } = props

    const dataCalculable = [
        { "value" : 0, "label" : traduction.cbocalculable.no.title },
        { "value" : 1, "label" : traduction.cbocalculable.si.title }
    ]
    let dataTipo;
    if(pr){
        dataTipo = [
            { "value" : 0, "label" : traduction.cbotipo.sinrecurrencia.title },
            { "value" : 1, "label" : traduction.cbotipo.finalizado.title },
            { "value" : 2, "label" : traduction.cbotipo.fecha.title }
        ]
    }else{
        dataTipo = [
            { "value" : 0, "label" : traduction.cbotipo.sinrecurrencia.title },
            { "value" : 1, "label" : traduction.cbotipo.finalizado.title },
            { "value" : 2, "label" : traduction.cbotipo.fecha.title },
            { "value" : 3, "label" : traduction.cbotipo.expresion.title },
            { "value" : 4, "label" : traduction.cbotipo.fechayexpresion.title }
        ]
    }
    const dataMetodoF = [
        { "value" : 0, "label" : traduction.cbometodof.dias.title },
        { "value" : 1, "label" : traduction.cbometodof.semanas.title },
        { "value" : 2, "label" : traduction.cbometodof.meses.title },
        { "value" : 3, "label" : traduction.cbometodof.anhos.title }
    ]

     // ***** Funciones que se ejecutan al terminar  de cargar el componente *****
    useEffect(() => {
                

    }, [formulario])

    return(
      <div>
            <FormRow>              
                    <MiSelect  
                        estadoForm=   { estadoForm }
                        val=          { formulario["recurrencia.tipo"]  }   
                        loading=      { loading }
                        id=           "recurrencia.tipo"    
                        name=         "recurrencia.tipo"   
                        label=        { traduction.tipo.title }    
                        placeholder=  { traduction.tipo.title }
                        col=          "3" 
                        isClearable=  "false"
                        isSearchable= "true"
                        dataType=     "codigo"
                        data=         {dataTipo}
                        required=     { false } 
                        disabled=     { false }
                        readOnly =    { estadoForm }
                        disablethisid={null}
                        disableids=   {null}
                        formulario=   { formulario }   
                        change=       { changeSelect }
                    />
                    {
                        formulario["recurrencia.tipo"] != 0
                        ?
                            <FormInput                                                        
                                estadoForm= { estadoForm }
                                value=      { formulario["recurrencia.preaviso"] }
                                loading=    { loading }
                                id=         "recurrencia.preaviso"   
                                type=       "number"
                                name=       "recurrencia.preaviso"   
                                label=      { traduction.preaviso.title }                                                       
                                placeholder=""    
                                col=        "2" 
                                required=   { false }
                                readonly=   { formulario["recurrencia.tipo"] == 1 ? "readonly" : estadoForm }                                                
                                disabled=   { null }
                                change=     { e => handleInput(e, formulario, saveFormulario) }
                                onBlur=     {null} 
                            />
                        :
                            null
                    }
            </FormRow>
            {
                formulario["recurrencia.tipo"] != 0
                    ?
                    <Fragment>
                        <FormRow>
                                    <FormInput                                                        
                                        estadoForm= { estadoForm }
                                        value=      { formulario["recurrencia.fecha_evento"] }
                                        loading=    { loading }
                                        id=         "recurrencia.fecha_evento"   
                                        type=       "datetime-local"
                                        name=       "recurrencia.fecha_evento"   
                                        label=      { traduction.fecha_evento.title }                                                       
                                        placeholder={ traduction.fecha_evento.title }    
                                        col=        "3" 
                                        required=   { false }
                                        readonly=   { "readonly" }   
                                        disabled=   { null }
                                        change=     { e => handleInput(e, formulario, saveFormulario) }
                                        onBlur=     {null} 
                                    />
                                    <MiSelect  
                                        estadoForm=   { estadoForm }
                                        // val=          { formulario["recurrencia.calculable"]  }  
                                        val = { formulario["recurrencia.tipo"] == 3 || formulario["recurrencia.tipo"] == 4 ? 1 : 0} 
                                        loading=      { loading }
                                        id=           "recurrencia.calculable"    
                                        name=         "recurrencia.calculable"   
                                        label=        { traduction.calculable.title }    
                                        placeholder=  { traduction.calculable.title }
                                        col=          "2" 
                                        isClearable=  "false"
                                        isSearchable= "true"
                                        dataType=     "codigo"
                                        data=         {dataCalculable}
                                        required=     { false } 
                                        disabled=     { false }
                                        readOnly =    { "readonly" }
                                        disablethisid={null}
                                        disableids=   {null}
                                        formulario=   { formulario }   
                                        change=       { changeSelect }
                                    />
                                    <FormInput                                                        
                                        estadoForm= { estadoForm }
                                        value=      { formulario["recurrencia.inicio_f"] }
                                        loading=    { loading }
                                        id=         "recurrencia.inicio_f"   
                                        type=       "datetime-local"
                                        name=       "recurrencia.inicio_f"   
                                        label=      { traduction.inicio_f.title }                                                       
                                        placeholder={ traduction.inicio_f.title }    
                                        col=        "3" 
                                        required=   { noDisplay ? false : formulario["recurrencia.tipo"] == 2 ? true : false }
                                        readonly=   { formulario["recurrencia.tipo"] == 1 ? "readonly" : estadoForm }                                                
                                        disabled=   { null }
                                        change=     { e => handleInput(e, formulario, saveFormulario) }
                                        onBlur=     {null} 
                                    />
                                    <div className={"form-group col-md-4"}>
                                        <label htmlFor="dif">{ traduction.diferencia.title }</label>
                                                {
                                                    diffDatesToString(formulario["recurrencia.fecha_evento"], formulario["recurrencia.inicio_f"], traduction, true, false) === "future" 
                                                    ?
                                                        <div className="alert alert-warning" role="alert" style={{height: "38px", paddingTop: "6px"}}>
                                                            {diffDatesToString(formulario["recurrencia.fecha_evento"], formulario["recurrencia.inicio_f"], traduction, true, true)}
                                                        </div>                                                 
                                                    :
                                                        diffDatesToString(formulario["recurrencia.fecha_evento"], formulario["recurrencia.inicio_f"], traduction, true, false) === "past"
                                                        ?
                                                        <div className="alert alert-danger" role="alert" style={{height: "38px", paddingTop: "6px"}}>
                                                            {diffDatesToString(formulario["recurrencia.fecha_evento"], formulario["recurrencia.inicio_f"], traduction, true, true)}
                                                        </div>  
                                                        :
                                                        diffDatesToString(formulario["recurrencia.fecha_evento"], formulario["recurrencia.inicio_f"], traduction, true, false) === "now"
                                                            ?
                                                            <div className="alert alert-success" role="alert" style={{height: "38px", paddingTop: "6px"}}>
                                                                {diffDatesToString(formulario["recurrencia.fecha_evento"], formulario["recurrencia.inicio_f"], traduction, true, true)}
                                                            </div> 
                                                            :
                                                            null

                                                }
                                    </div>
                            </FormRow>
                        </Fragment>
                    :
                    null
            }

            {
                formulario["recurrencia.tipo"] == 2 || formulario["recurrencia.tipo"] == 4
                ?
                    <FormRow>
                            <FormInput                                                        
                                estadoForm= { estadoForm }
                                value=      { formulario["recurrencia.cantidad_f"] }
                                loading=    { loading }
                                id=         "recurrencia.cantidad_f"   
                                type=       "number"
                                name=       "recurrencia.cantidad_f"   
                                label=      { traduction.cantidad_f.title }                                                       
                                placeholder=""    
                                col=        "2" 
                                required=   { noDisplay ? false : formulario["recurrencia.tipo"] == 2 ? true : false }
                                readonly=   { estadoForm }                                                
                                disabled=   { null }
                                change=     { e => handleInput(e, formulario, saveFormulario) }
                                onBlur=     {null} 
                            />
                            <MiSelect  
                                estadoForm=   { estadoForm }
                                val=          { formulario["recurrencia.metodo_f"]  }   
                                loading=      { loading }
                                id=           "recurrencia.metodo_f"    
                                name=         "recurrencia.metodo_f"   
                                label=        { traduction.metodo_f.title }   
                                placeholder=  ""
                                col=          "2" 
                                isClearable=  "false"
                                isSearchable= "true"
                                dataType=     "codigo"
                                data=         { dataMetodoF }
                                required=     { noDisplay ? false : formulario["recurrencia.tipo"] == 2 ? true : false } 
                                disabled=     { false }
                                readOnly =    { estadoForm }
                                disablethisid={null}
                                disableids=   {null}
                                formulario=   { formulario }   
                                change=       { changeSelect }
                            />  
                    </FormRow>
                :
                null
            }
            {
                formulario["recurrencia.tipo"] == 3 || formulario["recurrencia.tipo"] == 4
                ?
                    <Fragment>
                        <FormRow>
                                <FormInput                                                        
                                    estadoForm= { estadoForm }
                                    value=      { formulario["recurrencia.cantidad_e"] }
                                    loading=    { loading }
                                    id=         "recurrencia.cantidad_e"   
                                    type=       "number"
                                    name=       "recurrencia.cantidad_e"   
                                    label=      { traduction.cantidad_e.title }                                                        
                                    placeholder=""    
                                    col=        "4" 
                                    required=   { noDisplay ? false : formulario["recurrencia.tipo"] == 3 || formulario["recurrencia.tipo"] == 4 ? true : false }
                                    readonly=   { estadoForm }                                                
                                    disabled=   { null }
                                    change=     { e => handleInput(e, formulario, saveFormulario) }
                                    onBlur=     {null} 
                                />
                        </FormRow>                
                                <FormRow>
                                    <FormInput                                                        
                                        estadoForm= { estadoForm }
                                        value=      { formulario["recurrencia.inicio_e"] }
                                        loading=    { loading }
                                        id=         "recurrencia.inicio_e"   
                                        type=       "number"
                                        name=       "recurrencia.inicio_e"   
                                        label=      {formulario["recurencia.inicio_f"] != "" ?  traduction.inicio_e.title +" "+dateSqlToLocal(formulario["recurrencia.inicio_f"]) : traduction.inicio_e.title +" "+formulario["recurrencia.inicio_f"]}
                                        placeholder=""    
                                        col=        "4" 
                                        required=   { noDisplay ? false : formulario["recurrencia.tipo"] == 3 || formulario["recurrencia.tipo"] == 4 ? true : false }
                                        readonly=   { estadoForm }                                                
                                        disabled=   { null }
                                        change=     { e => handleInput(e, formulario, saveFormulario) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { estadoForm }
                                        value=      { formulario["recurrencia.actual_e"] }
                                        loading=    { loading }
                                        id=         "recurrencia.actual_e"   
                                        type=       "number"
                                        name=       "recurrencia.actual_e"   
                                        label=      {formulario["recurencia.actual_e"] != "" ?  traduction.actual_e.title +getAhoraddmmyyyy() : traduction.actual_e.title +formulario["recurrencia.actual_e"]}
                                        placeholder=""    
                                        col=        "4" 
                                        required=   { noDisplay ? false : formulario["recurrencia.tipo"] == 3 || formulario["recurrencia.tipo"] == 4 ? true : false }
                                        readonly=   { estadoForm }                                                
                                        disabled=   { null }
                                        change=     { e => handleInput(e, formulario, saveFormulario) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { estadoForm }
                                        value=      { formulario["recurrencia.media_e"] }
                                        loading=    { loading }
                                        id=         "recurrencia.media_e"   
                                        type=       "number"
                                        name=       "recurrencia.media_e"   
                                        label=      { traduction.media_e.title }
                                        placeholder=""    
                                        col=        "4" 
                                        required=   { null }
                                        readonly=   { "readonly" }                                                
                                        disabled=   { null }
                                        change=     { e => handleInput(e, formulario, saveFormulario) }
                                        onBlur=     {null} 
                                    />
                                </FormRow>                       
                    </Fragment>

                :
                null
            }



      </div>
    )
}

export default Recurrencia;