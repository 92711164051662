import React, { useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../helpers/MenuTreeHelper';

const url = window.location;

function MenuEnc({ miNivel, activeMenuColor, confApp }){

    const [ actual, saveActual ] = useState(0);
    const [ open1, saveOpen1 ] = useState(false);
    const [ open2, saveOpen2 ] = useState(false); 
    const [ open9, saveOpen9 ] = useState(false);
    const [ open10, saveOpen10 ] = useState(false); 
 

    useEffect(() => {
        loadTree();

        if( url.pathname.includes("/stockubi") || url.pathname.includes("/stockmov") || url.pathname.includes("/stockreu") ){
            saveActual(2);
        }
        if( url.pathname.includes("/impstoart") ){
            saveActual(9);
        }
        if( url.pathname.includes("/edirap") || url.pathname.includes("/articulos") ){
            saveActual(10);
        }        

    },[])

    const handleOpen = (e, open, saveOpen, indice) => {

        if( open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('openMenu');  
                subMenu.classList.add('closeMenu');             
            }
        }else if( !open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('closeMenu');  
                subMenu.classList.add('openMenu');               
            }
        }
        saveOpen(!open);
    }

    return(
              
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" data-animation-speed="600" role="menu" data-accordion="true">           
                <li className="nav-item">
                    <NavLink to="/inicio" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                        <i className={confApp.documentostrab.sidebarmenu.icono} style={{marginRight:5}} />
                        <p>{confApp.documentostrab.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>             
                <li className="nav-item">
                    <NavLink to="/checklist" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                        <i className={confApp.checklist.sidebarmenu.icono} style={{marginRight:5}} />
                        <p>{confApp.checklist.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/adjcentros" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft : "10px"}} >
                        <i className={confApp.centrosadj.sidebarmenu.icono} />
                        <p>{confApp.centrosadj.sidebarmenu.titulo}</p>
                    </NavLink>
                </li> 
                <li className={ open10 === true || actual === 10  ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open10, saveOpen10, 10) } >
                    <a href="#" className={ actual == 10  ? "nav-link2 active" : "nav-link2"}  >
                        <i className={confApp.menuencargado.nivelessup.stock.icono}></i>
                        <p>
                            {confApp.menuencargado.nivelessup.stock.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub10" className="nav nav-treeview sub">
                        <li className="nav-item">
                            <NavLink to="/runmac/bd0789da-d7ea-11ed-ab36-005056011ce4" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.menuencargado.stock.icono} style={{marginRight:5}} />
                                <p  style={{paddingLeft:5}}>{confApp.menuencargado.stock.titulo}</p>
                            </NavLink>
                        </li>  
                        <li className="nav-item">
                            <NavLink to="/edirapinv/14" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.edirap.sidebarmenu.icono_inv} style={{marginRight:5}} />
                                <p>{confApp.edirap.sidebarmenu.titulo_inv}</p>
                            </NavLink>
                        </li>  

                        <li className="nav-item">
                            <NavLink to="/edirapcomp/11" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.edirap.sidebarmenu.icono_comp} style={{marginRight:5}} />
                                <p>{confApp.edirap.sidebarmenu.titulo_comp}</p>
                            </NavLink>
                        </li>  

                        <li className="nav-item">
                        <NavLink to="/articulos" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                            <i className={confApp.articulos.sidebarmenu.icono} style={{marginRight:5}} />
                            <p>{confApp.articulos.sidebarmenu.titulo}</p>
                        </NavLink>
                        </li>                                                              
                    </ul>
                </li>
                <li className="nav-item">
                    <NavLink to="/equipos" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                        <i className={confApp.equipos.sidebarmenu.icono} style={{marginRight:5}} />
                        <p>{confApp.equipos.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
                <li className="nav-item">
                    <NavLink to="/supply" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                        <i className={confApp.supply.sidebarmenu.icono} style={{marginRight:5}} />
                        <p>{confApp.supply.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>  
            </ul>
        </nav>                                 
    )
}

export default MenuEnc;