import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { LoginContext } from '../../../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import { MiReplaceAll, getApiUrl, axiosPost } from '../../../../helpers/helperApp';
import { Fragment } from 'react';
import MiDataTable from '../../../ui/MiDataTable';
import Button from '../../../ui/Button';
import Section from '../../../ui/Section';
import ButtonOutline from '../../../ui/ButtonOutline';
import FormRow from '../../FormRow';
import FormInput from '../../FormInput';
import MiSelect from '../../MiSelect';
import { alertService } from '../../../../services/alertservice';
import { Accordion, Card } from "react-bootstrap";


function ConnectionsGetData({index, confPantalla, connection}){

    const cookies = new Cookies();
    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();
    // const source2 = CancelToken.source();
    // const source3 = CancelToken.source();

    const { apiKey, confApp } = useContext(LoginContext); 

    const [ token, saveToken ] = useState(null);
    const [ erroCallToken, saveErroCallToken ] = useState(false);
    // const [ loadingToken, saveLoadingToken ] = useState(true);
    const [ loadingToken, saveLoadingToken ] = useState(false);
    const [ loadingData, saveLoadingData ] = useState(false);
    const [ data, saveData ] = useState([]);
    const [ filtros, saveFiltros ] = useState("");    
    const [ maxRegs, saveMaxRegs ] = useState(0);
    const [ acordeon, saveAcordeon ] = useState(null);


    useEffect( () => {

        // connection.urltok != "" ? loadToken() : saveLoadingToken(false);
            
                                                    
        return () =>{    
            // Token cancelación axios al desmontar componentes
            // source.cancel();
        } 

    },[])

    const loadToken = async() => {         
        
        let data;

        saveLoadingToken(true);
        saveLoadingData(true);

        let datosLlamada = {};
        let respuesta;

        Object.entries(JSON.parse(connection.datatok)).forEach(([key, value]) => {
            datosLlamada[value.key] = value.value;
        });

        if( connection.typtok == "0" ){
            // GET            
            let config = {};
            config.headers = datosLlamada;
            respuesta = await axios.get(connection.urltok, config)
                        .catch(function (error){console.log(error)});
        }else if( connection.typtok == "1" ){
            // POST
            respuesta = await axios.post(connection.urltok, datosLlamada)
                        .catch(function (error){console.log(error)});
        }

        if(respuesta){
            if(respuesta.status == 200 && respuesta.data[connection.nomgettok]){
                // Ok obteniendo el Token
                saveToken(respuesta.data[connection.nomgettok])           
                saveLoadingToken(false);
                data = respuesta.data[connection.nomgettok];
            }
        }else{
            // error de comunicación
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.connections.padre.controles.errorcalltoken.title}`, {autoClose: true});
            saveErroCallToken(true);
            data = false;
        }
        saveLoadingData(false);
        return data;

    }

    const loadDataEndPoint = async() => {

        const tokenVar = await loadToken();
        if( tokenVar != false ){
            saveLoadingData(true);
    
            let datosLlamada = {};
            let respuesta;
    
            if(connection.nomposttok != ""){
                datosLlamada[connection.nomposttok] = tokenVar;
            }
    
            Object.entries(JSON.parse(connection.datadat)).forEach(([key, value]) => {
                datosLlamada[value.key] = value.value;
            });
    
            // Filtros 
            if( filtros != "" || maxRegs > 0 ){
                let filtroMax = "";
                let filtrosUsuario = "";
                if( maxRegs > 0 ){
                    filtroMax = `max=${maxRegs}`
                }
                if( filtros != "" ){
                    Object.entries(JSON.parse(filtros)).forEach(([key, value]) => {
                        if( value.filter != "" && value.value != "" ){
                            if(filtroMax == ""){                        
                                if(key == 0){
                                    filtrosUsuario = `contains=${value.filter} %${value.value}%`;
                                }else{
                                    filtrosUsuario += `&contains=${value.filter} %${value.value}%`;
                                }
                            }else{
                                filtrosUsuario += `&contains=${value.filter} %${value.value}%`;
                            }
                        }
                    });
                }           
                datosLlamada["Filters"] = filtroMax+filtrosUsuario;
            }        
    
    
            if(datosLlamada["Filters"] == ""){
                delete datosLlamada["Filters"];
            }
    
            if( connection.typdat == "0" ){
                // GET
                let config = {};
                config.headers = datosLlamada;
                console.log(config)
                respuesta = await axios.get(connection.urldat, config)
                            .catch(function (error){console.log(error)});
            }else if( connection.typdat == "1" ){
                // POST
                respuesta = await axios.post(connection.urldat, datosLlamada)
                            .catch(function (error){console.log(error)});
            }
            console.log("loadDataEndPoint")
            console.log(respuesta)
    
            if(respuesta){
                if(respuesta.status == 200){
                    saveData(respuesta.data[Object.keys(respuesta.data)[0]]) 
                }
            }else{
                // error de comunicación
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.connections.padre.controles.errorcalldata.title}`, {autoClose: true});
            }
            
            saveLoadingData(false);
        }
        
    }

    const prepareColumns = () => {

        let data = [];

        Object.entries(JSON.parse(connection.datatab)).forEach(([key, value]) => {
            if(value.title != ""){
                data.push(value);
            }
        });        
        
        return data;
    }

    const handleAddColumn = (e) => {

        let variables;

        const newVariable = {"filter" : "", "value": "" };

        if(filtros != "" ){
            variables = JSON.parse(filtros);            
        }else{
            variables = [];
        }       
        variables.push(newVariable)
        saveFiltros(JSON.stringify(variables));
    }

    const handleInputValue = (e, indice) => {

        const valor = e.target.value;

        let variables = JSON.parse(filtros);
        const editVar = variables.filter( (variable, index) =>  index == indice );
        editVar[0]["value"] = valor;

        saveFiltros(JSON.stringify(variables));
    }

    const handleSelectFiltro = (selectedItem, nameOfComponent) => {

        let variables = JSON.parse(filtros);
        const editVar = variables.filter( (variable, index) =>  index == nameOfComponent.name );
        
        if( selectedItem ){
            editVar[0]["filter"] = selectedItem.value;
        }else{            
            editVar[0]["filter"] = "";
        }

        saveFiltros(JSON.stringify(variables));
    }

    const handleDeleteFiltro = (indice) => {
        let variables = JSON.parse(filtros);
        const newVariables = variables.filter( (variable, index) =>  index != indice );
        saveFiltros(JSON.stringify(newVariables));
    }

    const prepareDataSelect = () => {

        let data = [];

        Object.entries(JSON.parse(connection.datatab)).forEach(([key, value]) => {
            const aux = { value : value.data , label : value.data }
            data.push(aux);
        });

        return data        
    }

    const handleInputMaxRegs = (e) => {
        saveMaxRegs(e.target.value)        
    }


    const toogleAcordeon = (id) => {

        if( acordeon == id ){
            saveAcordeon(null);
        }else{
            saveAcordeon(id);
        }                
    }


    return(                  
        <Accordion>
            <Card>
                <Accordion.Toggle as={Card.Header} eventKey={index} onClick={() => toogleAcordeon(index)} className={ acordeon == index ? "activeAcordeon" : "" } >
                        {connection.nom}
                        {
                            acordeon == index
                            ?
                                <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                            :
                                <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                        }                                       
                </Accordion.Toggle>                            
                <Accordion.Collapse eventKey={index} className={"slowAcordeon"} >
                    <Card.Body>
                        {/* <Section titulo={ connection.nom } > */}
                            <ButtonOutline 
                                clases={ "btn-block mb-2" }
                                color={ "success" }  
                                type="button" 
                                icono={ "fal fa-plus" }   
                                id=""     
                                texto={ confPantalla.connections.padre.controles.btnaddfiltro.title }   
                                accion={ e => handleAddColumn(e) } 
                                disabled={ loadingData ? true : false }
                            />
                            <FormRow>
                                <FormInput                                                        
                                    estadoForm= { "" }
                                    value=      { maxRegs }
                                    loading=    { loadingData ? true : false }
                                    id=         { "maxregs" }   
                                    type=       { "number" }  
                                    name=       { "" }   
                                    label=      { confPantalla.connections.padre.controles.maxregs.label }                                                       
                                    placeholder={ confPantalla.connections.padre.controles.maxregs.placeholder }    
                                    col=        { 3 } 
                                    required=   { false }
                                    readonly=   { false }                                                
                                    disabled=   { false }
                                    change=     { e => handleInputMaxRegs(e) }
                                    onBlur=     {null} 
                                />
                            </FormRow>
                            {
                                filtros != "" 
                                ?
                                JSON.parse(filtros).map((variable, index, arr) => {                                                    
                                    return(
                                        <Fragment key={index}>                                    
                                            <FormRow key={index}>
                                                <MiSelect  
                                                    estadoForm=  { "" }
                                                    val=         { variable.filter  }   
                                                    loading=     { loadingData ? true : false }
                                                    id=          { "fil-"+index }    
                                                    name=        { index }   
                                                    label=       { confPantalla.connections.padre.controles.filtros.filtro.title }    
                                                    placeholder= { "" }
                                                    col=         { "5" } 
                                                    isClearable= { false }
                                                    isSearchable={ true }
                                                    dataType=    { "codigo" }
                                                    data=        { prepareDataSelect() }
                                                    required=    { false } 
                                                    disabled=  { false }
                                                    readOnly =   { false }
                                                    disablethisid={false}
                                                    formulario= { {id:0} }   
                                                    change= { handleSelectFiltro }
                                                />
                                                <FormInput                                                        
                                                    estadoForm= { "" }
                                                    value=      { variable.value }
                                                    loading=    { loadingData ? true : false }
                                                    id=         { "val-"+index }   
                                                    type=       { "text" }  
                                                    label=      { confPantalla.connections.padre.controles.filtros.valor.title }
                                                    placeholder={ "" }    
                                                    col=        { "5" } 
                                                    required=   { false }
                                                    readonly=   { false }                                                
                                                    disabled=   { false }
                                                    change=     { e => handleInputValue(e, index) }
                                                    onBlur=     {null} 
                                                />                                      
                                                <div className={"mt-4 col-md-2"}>
                                                    <Button 
                                                        clases={ "btn-block mt-2" }
                                                        color={ "danger" }  
                                                        type="button" 
                                                        icono={ "fal fa-trash-alt" }   
                                                        id={index}     
                                                        texto={ "" }   
                                                        accion={ e => handleDeleteFiltro(index) } 
                                                        disabled={ false }
                                                    />                    
                                                </div>
                                            </FormRow>
                                        </Fragment>
                                    )
                                    })
                                :
                                    null
                            } 
                            <Button 
                                clases={ "btn-block mb-2" }
                                color={ "primary" }  
                                type="button" 
                                icono={ "fal fa-search" }   
                                id=""     
                                texto={ "" }   
                                accion={ loadDataEndPoint } 
                                disabled={ loadingData ? true : false }
                            />
                                {
                                    loadingToken && !erroCallToken
                                    ?
                                        <div class="alert alert-warning text-center" role="alert">
                                            {confPantalla.connections.padre.controles.loadtoken.title}
                                        </div>
                                    :
                                    loadingData
                                    ?
                                        <div class="alert alert-warning text-center" role="alert">
                                            {confPantalla.connections.padre.controles.loaddata.title}
                                        </div>
                                    :
                                        null
                                }
                            <MiDataTable
                                formulario={{id:0}} 
                                estadoForm= { "" }
                                id={connection.id}
                                columnas={prepareColumns()}
                                data={data}
                                buttons={true}
                                ordering={true}
                                order={[[0,"asc"]]}
                                searching={true}
                                paging={true}
                                select={false}
                                pagelength={25}                        
                                saveLoadingTable={ saveLoadingData }
                                loadingTable={loadingData}
                                // columnactions={}
                                traduction={confPantalla.config.trad.components.datatable}
                            />
                        {/* </Section> */}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default ConnectionsGetData;