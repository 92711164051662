import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import UplmPlus from '../../componentes/formulario/UplmPlus/UplmPlus';
import UplmPlus2 from '../../componentes/formulario/UpmlPlus2/UplmPlus2';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import MiDataTableInfoRegActual from '../../componentes/formulario/MiDataTableInfoRegActual/MiDataTableInfoRegActual';
import Informes from '../../componentes/formulario/informes/Informes';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { SRLWrapper } from 'simple-react-lightbox';

import { getApiUrl, axiosPost, getTitle, getDecodedCookie } from '../../helpers/helperApp';

function Centros(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectMultipleFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {                
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                    
                    document.title = getTitle(confPantalla.config.trad, confPantalla.centros.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.centros.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.centros.hijo.estadoform);                             
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.centros.padre.urls);
                                saveFormularioPrin(confPantalla.centros.padre.modelform);
                                saveFormularioHijo(confPantalla.centros.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.centros.padre.urls, confPantalla.centros.hijo.urls, confPantalla.centros.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);   
                            }                        
                        }                      
                    }else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 
        
    },[confPantalla])
    
    const getTipoTipAndHandleSelect = async (selectedItem, nameOfComponent) => {

        // Guardamos el valor del select en el state
        handleSelectFormPrin(selectedItem, nameOfComponent);

        // Hacemos una llamada para obtener el tipo(barco/instalación) del tipo de centro
        if( selectedItem ){
            const data = new FormData();
            data.append("id", selectedItem.value);
            const url = `${getApiUrl(window.location.origin)}/centros/gettipotip`;
            // const res = await axios.post(url, data);
            const res = await axiosPost(url, data, source.token);

            saveFormularioPrin(
                {
                    ... formularioPrin, 
                    ["idtipotip"] : res.data[0].idtipotip,
                    ["idtipo"] : res.data[0].idtipo
                }
            )
        }else{
            saveFormularioPrin(
                {
                    ... formularioPrin, 
                    ["idtipotip"] : "",
                    ["idtipo"] : ""
                }
            )
        }

    }

    const reset = () => {
        resetFormPrin(confPantalla.centros.padre.modelform, true, confPantalla.centros.padre.tabla);
    }

 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <SRLWrapper>
                        <Fragment>
                        {
                            loadedConf === true && confPantalla !== undefined
                            ?
                            <Section titulo={ confPantalla.centros.section.titulo } icono={ confPantalla.centros.section.icono } col={ confPantalla.centros.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.centros.section.wiki } >
                                            <Formulario 
                                                        estadoForm= { estadoFormPrin }
                                                        loading=    { loadReg ? true : false }
                                                        btnNew={ getDecodedCookie('nivel') == 1 ? confPantalla.centros.padre.btnform.new : "false" }
                                                        btnSave={ confPantalla.centros.padre.btnform.save }
                                                        btnSaveAndNew={ getDecodedCookie('nivel') == 1 ? confPantalla.centros.padre.btnform.saveandnew : "false" }
                                                        btnDelete={ getDecodedCookie('nivel') == 1 ? confPantalla.centros.padre.btnform.delete : "false" }
                                                        btnClone={ confPantalla.centros.padre.btnform.clone }
                                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.centros.padre.urls, confPantalla.centros.padre.modelform, confPantalla.centros.padre.tabla, confPantalla.centros.hijo.modelform, confPantalla.centros.title) } 
                                                        formulario = { formularioPrin } 
                                                        resetForm = { reset } 
                                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.centros.padre.urls, confPantalla.centros.padre.modelform, confPantalla.centros.padre.tabla, confPantalla.centros.title) }
                                            >
                                                <Informes
                                                    select =     { confPantalla.centros.padre.controles.informes.select }
                                                    formulario = { formularioPrin }
                                                    loadReg =    { loadReg ? true : false }
                                                    estadoForm=  { estadoFormPrin } 
                                                    traduction=  { confPantalla.config.trad.components }
                                                />
                                                <hr className="separator" />
                                                <Upls
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    id={ confPantalla.centros.padre.controles.upl.id }
                                                    value={formularioPrin.img}
                                                    save={saveFormularioPrin}                                                
                                                    formulario={formularioPrin}  
                                                    readonly={ confPantalla.centros.padre.controles.upl.readonly }
                                                    traduction={confPantalla.config.trad.components.upls} 
                                                />                                         
                                                <Tabs defaultActiveKey="tab1" id="tab1">
                                                    <Tab eventKey="tab1" title={ confPantalla.centros.tabs.tab1.titulo }>
                                                        <FormRow clases="" >
                                                            <FormInput                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.nombre }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.centros.padre.controles.nombre.id }   
                                                                type=       { confPantalla.centros.padre.controles.nombre.type }  
                                                                name=       { confPantalla.centros.padre.controles.nombre.name }   
                                                                label=      { confPantalla.centros.padre.controles.nombre.label }                                                       
                                                                placeholder={ confPantalla.centros.padre.controles.nombre.placeholder }    
                                                                col=        { confPantalla.centros.padre.controles.nombre.col } 
                                                                required=   { confPantalla.centros.padre.controles.nombre.required }
                                                                readonly=   { confPantalla.centros.padre.controles.nombre.readonly }                                                
                                                                disabled=   { confPantalla.centros.padre.controles.nombre.disabled }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     {null} 
                                                            />
                                                            <FormInput                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.tipini }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.centros.padre.controles.empresa.id }   
                                                                type=       { confPantalla.centros.padre.controles.empresa.type }  
                                                                name=       { confPantalla.centros.padre.controles.empresa.name }   
                                                                label=      { confPantalla.centros.padre.controles.empresa.label }                                                       
                                                                placeholder={ confPantalla.centros.padre.controles.empresa.placeholder }    
                                                                col=        { confPantalla.centros.padre.controles.empresa.col } 
                                                                required=   { confPantalla.centros.padre.controles.empresa.required }
                                                                readonly=   { confPantalla.centros.padre.controles.empresa.readonly }                                                
                                                                disabled=   { confPantalla.centros.padre.controles.empresa.disabled }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     {null} 
                                                            />
                                                        </FormRow>
                                                        <FormRow clases="" >
                                                            <MiSelect  
                                                                estadoForm=  { estadoFormPrin }
                                                                val=         { formularioPrin.idtipo  }   
                                                                loading=     { loadReg ? true : false }
                                                                id=          { confPantalla.centros.padre.controles.tipocen.id }    
                                                                name=        { confPantalla.centros.padre.controles.tipocen.name }   
                                                                label=       { confPantalla.centros.padre.controles.tipocen.label }    
                                                                placeholder= { confPantalla.centros.padre.controles.tipocen.placeholder }
                                                                col=         { confPantalla.centros.padre.controles.tipocen.col } 
                                                                isClearable= { confPantalla.centros.padre.controles.tipocen.isclearable }
                                                                isSearchable={ confPantalla.centros.padre.controles.tipocen.issearchable }
                                                                dataType=    { confPantalla.centros.padre.controles.tipocen.datatype }
                                                                data=        { confPantalla.centros.padre.controles.tipocen.data }
                                                                required=    { confPantalla.centros.padre.controles.tipocen.required } 
                                                                disabled=  { confPantalla.centros.padre.controles.tipocen.disabled }
                                                                readOnly =   { confPantalla.centros.padre.controles.tipocen.readonly }
                                                                disablethisid={confPantalla.centros.padre.controles.tipocen.disablethisid}
                                                                formulario= { formularioPrin }   
                                                                change= { getTipoTipAndHandleSelect }
                                                            />                                                          
                                                        </FormRow>
                                                        {
                                                            formularioPrin.idtipotip === "0"
                                                            ?
                                                            <Fragment>
                                                                <FormRow>
                                                                    <MiSelect  
                                                                        estadoForm=  { estadoFormPrin }
                                                                        val=         { formularioPrin.idpais  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.centros.padre.controles.bandera.id }    
                                                                        name=        { confPantalla.centros.padre.controles.bandera.name }   
                                                                        label=       { confPantalla.centros.padre.controles.bandera.label }    
                                                                        placeholder= { confPantalla.centros.padre.controles.bandera.placeholder }
                                                                        col=         { confPantalla.centros.padre.controles.bandera.col } 
                                                                        isClearable= { confPantalla.centros.padre.controles.bandera.isclearable }
                                                                        isSearchable={ confPantalla.centros.padre.controles.bandera.issearchable }
                                                                        dataType=    { confPantalla.centros.padre.controles.bandera.datatype }
                                                                        data=        { confPantalla.centros.padre.controles.bandera.data }
                                                                        required=    { confPantalla.centros.padre.controles.bandera.required } 
                                                                        disabled=  { confPantalla.centros.padre.controles.bandera.disabled }
                                                                        readOnly =   { confPantalla.centros.padre.controles.bandera.readonly }
                                                                        disablethisid={confPantalla.centros.padre.controles.bandera.disablethisid}
                                                                        formulario= { formularioPrin }   
                                                                        change= { handleSelectFormPrin }
                                                                    />    
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.txt1 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.imo.id }   
                                                                        type=       { confPantalla.centros.padre.controles.imo.type }  
                                                                        name=       { confPantalla.centros.padre.controles.imo.name }   
                                                                        label=      { confPantalla.centros.padre.controles.imo.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.imo.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.imo.col } 
                                                                        required=   { confPantalla.centros.padre.controles.imo.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.imo.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.imo.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.txt2 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.mmsi.id }   
                                                                        type=       { confPantalla.centros.padre.controles.mmsi.type }  
                                                                        name=       { confPantalla.centros.padre.controles.mmsi.name }   
                                                                        label=      { confPantalla.centros.padre.controles.mmsi.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.mmsi.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.mmsi.col } 
                                                                        required=   { confPantalla.centros.padre.controles.mmsi.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.mmsi.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.mmsi.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                </FormRow>
                                                                <FormRow>
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.num1 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.eslora.id }   
                                                                        type=       { confPantalla.centros.padre.controles.eslora.type }  
                                                                        name=       { confPantalla.centros.padre.controles.eslora.name }   
                                                                        label=      { confPantalla.centros.padre.controles.eslora.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.eslora.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.eslora.col } 
                                                                        required=   { confPantalla.centros.padre.controles.eslora.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.eslora.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.eslora.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.num2 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.ton.id }   
                                                                        type=       { confPantalla.centros.padre.controles.ton.type }  
                                                                        name=       { confPantalla.centros.padre.controles.ton.name }   
                                                                        label=      { confPantalla.centros.padre.controles.ton.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.ton.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.ton.col } 
                                                                        required=   { confPantalla.centros.padre.controles.ton.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.ton.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.ton.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.num3 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.ton2.id }   
                                                                        type=       { confPantalla.centros.padre.controles.ton2.type }  
                                                                        name=       { confPantalla.centros.padre.controles.ton2.name }   
                                                                        label=      { confPantalla.centros.padre.controles.ton2.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.ton2.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.ton2.col } 
                                                                        required=   { confPantalla.centros.padre.controles.ton2.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.ton2.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.ton2.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                </FormRow>
                                                                

                                                            </Fragment> 
                                                            :
                                                            null
                                                        }
                                                        {
                                                            formularioPrin.idtipotip === "1"
                                                            ?
                                                                <FormRow>
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.txt1 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.dir.id }   
                                                                        type=       { confPantalla.centros.padre.controles.dir.type }  
                                                                        name=       { confPantalla.centros.padre.controles.dir.name }   
                                                                        label=      { confPantalla.centros.padre.controles.dir.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.dir.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.dir.col } 
                                                                        required=   { confPantalla.centros.padre.controles.dir.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.dir.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.dir.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.txt3 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.cp.id }   
                                                                        type=       { confPantalla.centros.padre.controles.cp.type }  
                                                                        name=       { confPantalla.centros.padre.controles.cp.name }   
                                                                        label=      { confPantalla.centros.padre.controles.cp.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.cp.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.cp.col } 
                                                                        required=   { confPantalla.centros.padre.controles.cp.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.cp.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.cp.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.txt2 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.pob.id }   
                                                                        type=       { confPantalla.centros.padre.controles.pob.type }  
                                                                        name=       { confPantalla.centros.padre.controles.pob.name }   
                                                                        label=      { confPantalla.centros.padre.controles.pob.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.pob.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.pob.col } 
                                                                        required=   { confPantalla.centros.padre.controles.pob.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.pob.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.pob.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                    <FormInput                                                        
                                                                        estadoForm= { estadoFormPrin }
                                                                        value=      { formularioPrin.num1 }
                                                                        loading=    { loadReg ? true : false }
                                                                        id=         { confPantalla.centros.padre.controles.pro.id }   
                                                                        type=       { confPantalla.centros.padre.controles.pro.type }  
                                                                        name=       { confPantalla.centros.padre.controles.pro.name }   
                                                                        label=      { confPantalla.centros.padre.controles.pro.label }                                                       
                                                                        placeholder={ confPantalla.centros.padre.controles.pro.placeholder }    
                                                                        col=        { confPantalla.centros.padre.controles.pro.col } 
                                                                        required=   { confPantalla.centros.padre.controles.pro.required }
                                                                        readonly=   { confPantalla.centros.padre.controles.pro.readonly }                                                
                                                                        disabled=   { confPantalla.centros.padre.controles.pro.disabled }
                                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                        onBlur=     {null} 
                                                                    />
                                                                    <MiSelect  
                                                                        estadoForm=  { estadoFormPrin }
                                                                        val=         { formularioPrin.idpais  }   
                                                                        loading=     { loadReg ? true : false }
                                                                        id=          { confPantalla.centros.padre.controles.pais.id }    
                                                                        name=        { confPantalla.centros.padre.controles.pais.name }   
                                                                        label=       { confPantalla.centros.padre.controles.pais.label }    
                                                                        placeholder= { confPantalla.centros.padre.controles.pais.placeholder }
                                                                        col=         { confPantalla.centros.padre.controles.pais.col } 
                                                                        isClearable= { confPantalla.centros.padre.controles.pais.isclearable }
                                                                        isSearchable={ confPantalla.centros.padre.controles.pais.issearchable }
                                                                        dataType=    { confPantalla.centros.padre.controles.pais.datatype }
                                                                        data=        { confPantalla.centros.padre.controles.pais.data }
                                                                        required=    { confPantalla.centros.padre.controles.pais.required } 
                                                                        disabled=  { confPantalla.centros.padre.controles.pais.disabled }
                                                                        readOnly =   { confPantalla.centros.padre.controles.pais.readonly }
                                                                        disablethisid={confPantalla.centros.padre.controles.pais.disablethisid}
                                                                        formulario= { formularioPrin }   
                                                                        change= { handleSelectFormPrin }
                                                                    />    
                                                                </FormRow>
                                                            :
                                                            null
                                                        }
                                                    </Tab>                                                                                                                        
                                                    <Tab eventKey="tab2" title={ confPantalla.centros.tabs.tab2.titulo }>
                                                        <FormRow>
                                                            {/* <FormInput                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.motaux }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.centros.padre.controles.motaux.id }   
                                                                type=       { confPantalla.centros.padre.controles.motaux.type }  
                                                                name=       { confPantalla.centros.padre.controles.motaux.name }   
                                                                label=      { confPantalla.centros.padre.controles.motaux.label }                                                       
                                                                placeholder={ confPantalla.centros.padre.controles.motaux.placeholder }    
                                                                col=        { confPantalla.centros.padre.controles.motaux.col } 
                                                                required=   { confPantalla.centros.padre.controles.motaux.required }
                                                                readonly=   { confPantalla.centros.padre.controles.motaux.readonly }                                                
                                                                disabled=   { confPantalla.centros.padre.controles.motaux.disabled }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     {null} 
                                                            /> */}                                                            
                                                            <TextArea                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.notas }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.centros.padre.controles.notas.id }   
                                                                name=       { confPantalla.centros.padre.controles.notas.name }
                                                                rows=       { confPantalla.centros.padre.controles.notas.rows }      
                                                                label=      { confPantalla.centros.padre.controles.notas.label }                                                       
                                                                placeholder={ confPantalla.centros.padre.controles.notas.placeholder }    
                                                                col=        { confPantalla.centros.padre.controles.notas.col } 
                                                                required=   { confPantalla.centros.padre.controles.notas.required }
                                                                readonly=   { confPantalla.centros.padre.controles.notas.readonly }                                                
                                                                disabled=   { confPantalla.centros.padre.controles.notas.disabled }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     {null} 
                                                            />
                                                        </FormRow>
                                                    </Tab>
                                                    <Tab eventKey="tab7" title={ confPantalla.centros.tabs.tab7.titulo }>
                                                        <FormRow>
                                                            <MiDataTableInfoRegActual
                                                                id =         { confPantalla.centros.padre.controles.tableequipos.id }     
                                                                loading=     { loadReg ? true : false }
                                                                formulario = { formularioPrin }
                                                                table =      { confPantalla.centros.padre.controles.tableequipos }
                                                                traduction=  { confPantalla.config.trad.components.datatable }
                                                            />
                                                        </FormRow>
                                                    </Tab>
                                                    <Tab eventKey="tab3" title={ confPantalla.centros.tabs.tab3.titulo }>                                                        
                                                        <ListaRel
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.rel_usu_cen  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.centros.padre.controles.rel_usu_cen.select.id }    
                                                            name=        { confPantalla.centros.padre.controles.rel_usu_cen.select.name }   
                                                            label=       { confPantalla.centros.padre.controles.rel_usu_cen.select.label }    
                                                            placeholder= { confPantalla.centros.padre.controles.rel_usu_cen.select.placeholder }
                                                            col=         { confPantalla.centros.padre.controles.rel_usu_cen.select.col } 
                                                            isClearable= { confPantalla.centros.padre.controles.rel_usu_cen.select.isclearable }
                                                            isSearchable={ confPantalla.centros.padre.controles.rel_usu_cen.select.issearchable }
                                                            dataType=    { confPantalla.centros.padre.controles.rel_usu_cen.select.datatype }
                                                            data=        { confPantalla.centros.padre.controles.rel_usu_cen.select.data }
                                                            required=    { confPantalla.centros.padre.controles.rel_usu_cen.select.required } 
                                                            disabled=  { confPantalla.centros.padre.controles.rel_usu_cen.select.disabled }
                                                            readOnly =   { confPantalla.centros.padre.controles.rel_usu_cen.select.readonly }
                                                            disablethisid={ confPantalla.centros.padre.controles.rel_usu_cen.select.disablethisid }
                                                            formulario = { formularioPrin }
                                                            saveFormulario = { saveFormularioPrin }
                                                            table = { confPantalla.centros.padre.controles.rel_usu_cen.table }
                                                            traduction={confPantalla.config.trad.components.datatable}
                                                            selectSonUsuarios={true}
                                                        />       
                                                    </Tab>                                                                                                                        
                                                    <Tab eventKey="tab4" title={ confPantalla.centros.tabs.tab4.titulo }>
                                                        <FormRow clases="" >
                                                            {/* <Uplm
                                                                estadoForm= { estadoFormPrin }
                                                                loading=    { loadReg ? true : false }
                                                                col={12}
                                                                id={ confPantalla.centros.padre.controles.uplm.id }
                                                                adjuntos={ adjuntosPrin }
                                                                saveAdjuntos= { saveAdjuntosPrin }  
                                                                adjuntosExistentes= { adjuntosExistentesPrin } 
                                                                saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                                readonly={ confPantalla.centros.padre.controles.uplm.readonly }
                                                                traduction={confPantalla.config.trad.components.uplm}
                                                            /> */}
                                                            <UplmPlus2
                                                                id={ confPantalla.centros.padre.controles.uplmplus.id }
                                                                adjuntos={ adjuntosPrin }
                                                                saveAdjuntos= { saveAdjuntosPrin }  
                                                                adjuntosExistentes= { adjuntosExistentesPrin } 
                                                                saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                                estadoForm= { estadoFormPrin }
                                                                loading= { loadReg ? true : false }
                                                                col={12}
                                                                readonly={ confPantalla.centros.padre.controles.uplmplus.readonly }
                                                                traduction={confPantalla.config.trad.components}                                                            
                                                                datatable={ confPantalla.centros.padre.controles.uplmplus.datatable }
                                                                select={ confPantalla.centros.padre.controles.uplmplus.select }
                                                            />
                                                        </FormRow>

                                                    </Tab>                                                                                                                        
                                                    {/* <Tab eventKey="tab5" title={ confPantalla.centros.tabs.tab5.titulo }>
                                                        <Informes
                                                            select =     { confPantalla.centros.padre.controles.informes.select }
                                                            formulario = { formularioPrin }
                                                            loadReg =    { loadReg ? true : false }
                                                            estadoForm=  { estadoFormPrin } 
                                                            traduction=  { confPantalla.config.trad.components }
                                                        /> 
                                                    </Tab>*/}
                                                    {/* <Tab eventKey="tab6" title={ confPantalla.centros.tabs.tab6.titulo }>
                                                        <FormRow>
                                                            <FormInput                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.id }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.centros.padre.controles.id.id }   
                                                                type=       { confPantalla.centros.padre.controles.id.type }  
                                                                name=       { confPantalla.centros.padre.controles.id.name }   
                                                                label=      { confPantalla.centros.padre.controles.id.label }                                                       
                                                                placeholder={ confPantalla.centros.padre.controles.id.placeholder }    
                                                                col=        { confPantalla.centros.padre.controles.id.col } 
                                                                required=   { confPantalla.centros.padre.controles.id.required }
                                                                readonly=   { confPantalla.centros.padre.controles.id.readonly }                                                
                                                                disabled=   { confPantalla.centros.padre.controles.id.disabled }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     {null} 
                                                            />
                                                            <FormInput                                                        
                                                                estadoForm= { estadoFormPrin }
                                                                value=      { formularioPrin.api }
                                                                loading=    { loadReg ? true : false }
                                                                id=         { confPantalla.centros.padre.controles.api.id }   
                                                                type=       { confPantalla.centros.padre.controles.api.type }  
                                                                name=       { confPantalla.centros.padre.controles.api.name }   
                                                                label=      { confPantalla.centros.padre.controles.api.label }                                                       
                                                                placeholder={ confPantalla.centros.padre.controles.api.placeholder }    
                                                                col=        { confPantalla.centros.padre.controles.api.col } 
                                                                required=   { confPantalla.centros.padre.controles.api.required }
                                                                readonly=   { confPantalla.centros.padre.controles.api.readonly }                                                
                                                                disabled=   { confPantalla.centros.padre.controles.api.disabled }
                                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                                onBlur=     {null} 
                                                            />
                                                        </FormRow>
                                                    </Tab>                     */}
                                                </Tabs>


                                            </Formulario>
                                            <MiDataTable
                                                formulario={formularioPrin} 
                                                estadoForm= { getDecodedCookie('nivel') == 1 ? estadoFormPrin : "readonly" }
                                                id={"centros"}
                                                columnas={confPantalla.centros.padre.datatable.columns}
                                                data={registrosPrin}
                                                buttons={confPantalla.centros.padre.datatable.buttons}
                                                ordering={confPantalla.centros.padre.datatable.ordering}
                                                order={confPantalla.centros.padre.datatable.order}
                                                searching={confPantalla.centros.padre.datatable.searching}
                                                paging={confPantalla.centros.padre.datatable.paging}
                                                select={confPantalla.centros.padre.datatable.select}
                                                pagelength={confPantalla.centros.padre.datatable.pagelength}
                                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.centros.padre.urls, confPantalla.centros.hijo.urls, confPantalla.centros.padreshijos, confPantalla.centros.hijo.modelform, confPantalla.centros.padre.tabla) }
                                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.centros.padre.urls, confPantalla.centros.padre.modelform, confPantalla.centros.padre.tabla, confPantalla.centros.title) }
                                                saveLoadingTable={ saveLoadingTable }
                                                loadingTable={loadingTable}
                                                columnactions={confPantalla.centros.padre.datatable.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable}
                                            />                                             
                                                                    
                                </Section>
                            :
                                <LoadingPage />
                            }
                        </Fragment>
                    </SRLWrapper>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Centros