import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import MiModal from './MiModal';

import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.es';
import 'rc-year-calendar/locales/rc-year-calendar.uk';

import { bootStrapAlertClassToColor } from '../../helpers/helperApp';




function CalendarioYear({id, data, locale, onDayClick, colorTitleEvent, functionClickTitle}) {
    
    const [ datos, saveDatos ] = useState(null);
    const [ showModal, saveShowModal ] = useState(false);
    const [ eventsModal, saveEventsModal ] = useState([]);

    useEffect(() => {

        saveDatos(data);
    }, [datos, data]);

    
    const handleDayClick = (e)=>{        

        saveEventsModal([]);

        if( e.events.length > 0 ){
            for(let i = 0; i < e.events.length; i++){               
                const aux = { 
                    title: e.events[i].name, 
                    startDate : e.events[i].startDate.toLocaleDateString(locale), 
                    endDate : e.events[i].endDate.toLocaleDateString(locale), 
                    url :  e.events[i].url,
                    color : bootStrapAlertClassToColor(e.events[i].style),
                    idDoc :  e.events[i].idDoc,
                    style : e.events[i].style,
                    id_ori : e.events[i].id_ori,
                    tip_ori : e.events[i].tip_ori,
                    id_tra : e.events[i].id_tra,
                    descripcion : e.events[i].descripcion,
                    id_responsable : e.events[i].id_responsable,
                    estado : e.events[i].estado,
                    recurrencia : e.events[i].recurrencia,
                    idest : e.events[i].idest  
                }
                saveEventsModal(eventsModal => [...eventsModal, aux]);
            }
            saveShowModal(true);
        }

    }

    const handleCloseModal = () => {
        saveShowModal(false);
    }


    return (
        <Fragment>
            {
                datos != null
                ?
                <Calendar 
                    dataSource = {datos}
                    onDayClick = { onDayClick ? handleDayClick : null }
                    style = 'border'
                    alwaysHalfDay = { true }
                    displayWeekNumber = { true }
                    language = { locale }
                />
                :
                    null
            }
            {
                showModal 
                ?
                    <MiModal
                        titulo={""}
                        actionhide={handleCloseModal}
                        centered={true}
                        btnclosetext={""}
                        iconoBtnDanger={"fal fa-times"}
                        footer={false}
                    >
                        {
                            eventsModal.map( (event) => {
                                return(
                                    <Fragment>
                                        <hr data-content={`${event.startDate} - ${event.endDate}`} className="hr-text"></hr> 
                                        <div className="row" style={{margin : "0px 0px"}}>
                                            {
                                                colorTitleEvent 
                                                ?
                                                    <span 
                                                        onClick={ functionClickTitle != null ? functionClickTitle : null }
                                                        className={event.url ? "col-10 "+event.style : "col-12 "+event.style} 
                                                        // style={functionClickTitle != null ? {fontWeight: "500", cursor : "pointer", color: event.color, padding: "5px 5px"} : {fontWeight: "500", color: event.color, padding: "5px 5px"}}
                                                        style={functionClickTitle != null ? {fontWeight: "500", padding: "5px 5px", cursor : "pointer"} : {fontWeight: "500", padding: "5px 5px"}}
                                                        data-iddoc = { event.idDoc }
                                                        data-style = { event.style }
                                                        data-id_ori = { event.id_ori }
                                                        data-tip_ori = { event.tip_ori }
                                                        data-id_tra = { event.id_tra }
                                                        data-descripcion = { event.descripcion }
                                                        data-id_responsable = { event.id_responsable }
                                                        data-title = { event.title }
                                                        data-estado = { event.estado }
                                                        data-recurrencia = { event.recurrencia }
                                                        data-idest = { event.idest }
                                                    >
                                                        {event.title}
                                                    </span> 
                                                :
                                                    <span 
                                                        onClick={ functionClickTitle != null ? functionClickTitle : null }
                                                        className={event.url ? "col-10" : "col-12"} 
                                                        // style={functionClickTitle != null ? {fontWeight: "500", cursor : "pointer", padding: "5px 5px", color: event.color} : {fontWeight: "500", color: event.color, padding: "5px 5px"}}
                                                        style={functionClickTitle != null ? {fontWeight: "500", cursor : "pointer", padding: "5px 5px"} : {fontWeight: "500", padding: "5px 5px"}}
                                                        data-iddoc = { event.idDoc }
                                                        data-style = { event.style }
                                                        data-id_ori = { event.id_ori }
                                                        data-tip_ori = { event.tip_ori }
                                                        data-id_tra = { event.id_tra }
                                                        data-descripcion = { event.descripcion }
                                                        data-id_responsable = { event.id_responsable }
                                                        data-title = { event.title }
                                                        data-estado = { event.estado }
                                                        data-recurrencia = { event.recurrencia }
                                                        data-idest = { event.idest }
                                                    >
                                                        {event.title}
                                                    </span> 
                                            }
                                            {
                                                event.url
                                                ?
                                                    <span >
                                                        <a href={event.url} className="btn btn-success float-right mt-1" target="_blank"><i className="fal fa-arrow-right"></i></a>
                                                    </span>
                                                :
                                                    null
                                            }                       
                                        </div>
                                        
                                    </Fragment>
                                )
                            } )
                        }
                    </MiModal>
                :
                    null 
            }
        </Fragment>
    );
}

export default CalendarioYear;