import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import UplmPlus from '../../componentes/formulario/UplmPlus/UplmPlus';
import UplmPlus2 from '../../componentes/formulario/UpmlPlus2/UplmPlus2';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';
import Informes from '../../componentes/formulario/informes/Informes';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getTitle, getDecodedCookie } from '../../helpers/helperApp';

import { Fragment } from 'react';

function Equipos(props){

    const cookies = new Cookies();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{  
                    document.title = getTitle(confPantalla.config.trad, confPantalla.equipos.title);                                      
                    if( respuesta == 1 ){
                        saveTieneNivel(true);   
                        saveEstadoFormPrin(confPantalla.equipos.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.equipos.hijo.estadoform);                         
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.equipos.padre.urls);
                                saveFormularioPrin(confPantalla.equipos.padre.modelform);
                                saveFormularioHijo(confPantalla.equipos.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.equipos.padre.urls, confPantalla.equipos.hijo.urls, confPantalla.equipos.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 
        
    },[confPantalla])
    


 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.equipos.section.titulo } icono={ confPantalla.equipos.section.icono } col={ confPantalla.equipos.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.equipos.section.wiki } >
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.equipos.padre.btnform.new }
                                                    btnSave={ confPantalla.equipos.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.equipos.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.equipos.padre.btnform.delete }
                                                    btnClone={ confPantalla.equipos.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.equipos.padre.urls, confPantalla.equipos.padre.modelform, confPantalla.equipos.padre.tabla, confPantalla.equipos.hijo.modelform, confPantalla.equipos.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.equipos.padre.modelform, true, confPantalla.equipos.padre.tabla) }  
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.equipos.padre.urls, confPantalla.equipos.padre.modelform, confPantalla.equipos.padre.tabla, confPantalla.equipos.title) }
                                        >
                                            <Informes
                                                select =     { confPantalla.equipos.padre.controles.informes.select }
                                                formulario = { formularioPrin }
                                                loadReg =    { loadReg ? true : false }
                                                estadoForm=  { estadoFormPrin } 
                                                traduction=  { confPantalla.config.trad.components }
                                            /> 
                                            <hr className="separator" />                                       
                                            <Tabs defaultActiveKey="tab1" id="tab1">
                                                <Tab eventKey="tab1" title={ confPantalla.equipos.tabs.tab1.titulo }>
                                                <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod3 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.cod3.id }   
                                                            type=       { confPantalla.equipos.padre.controles.cod3.type }  
                                                            name=       { confPantalla.equipos.padre.controles.cod3.name }   
                                                            label=      { confPantalla.equipos.padre.controles.cod3.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.cod3.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.cod3.col } 
                                                            required=   { confPantalla.equipos.padre.controles.cod3.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.cod3.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.cod3.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom3 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.nom3.id }   
                                                            type=       { confPantalla.equipos.padre.controles.nom3.type }  
                                                            name=       { confPantalla.equipos.padre.controles.nom3.name }   
                                                            label=      { confPantalla.equipos.padre.controles.nom3.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.nom3.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.nom3.col } 
                                                            required=   { confPantalla.equipos.padre.controles.nom3.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.nom3.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.nom3.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom1 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.nombre.id }   
                                                            type=       { confPantalla.equipos.padre.controles.nombre.type }  
                                                            name=       { confPantalla.equipos.padre.controles.nombre.name }   
                                                            label=      { confPantalla.equipos.padre.controles.nombre.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.nombre.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.nombre.col } 
                                                            required=   { confPantalla.equipos.padre.controles.nombre.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.nombre.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.nombre.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom1_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.nombre_en.id }   
                                                            type=       { confPantalla.equipos.padre.controles.nombre_en.type }  
                                                            name=       { confPantalla.equipos.padre.controles.nombre_en.name }   
                                                            label=      { confPantalla.equipos.padre.controles.nombre_en.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.nombre_en.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.nombre_en.col } 
                                                            required=   { confPantalla.equipos.padre.controles.nombre_en.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.nombre_en.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.nombre_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.cla2  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.equipos.padre.controles.tipoequi.id }    
                                                            name=        { confPantalla.equipos.padre.controles.tipoequi.name }   
                                                            label=       { confPantalla.equipos.padre.controles.tipoequi.label }    
                                                            placeholder= { confPantalla.equipos.padre.controles.tipoequi.placeholder }
                                                            col=         { confPantalla.equipos.padre.controles.tipoequi.col } 
                                                            isClearable= { confPantalla.equipos.padre.controles.tipoequi.isclearable }
                                                            isSearchable={ confPantalla.equipos.padre.controles.tipoequi.issearchable }
                                                            dataType=    { confPantalla.equipos.padre.controles.tipoequi.datatype }
                                                            data=        { confPantalla.equipos.padre.controles.tipoequi.data }
                                                            required=    { confPantalla.equipos.padre.controles.tipoequi.required } 
                                                            disabled=  { confPantalla.equipos.padre.controles.tipoequi.disabled }
                                                            readOnly =   { confPantalla.equipos.padre.controles.tipoequi.readonly }
                                                            disablethisid={confPantalla.equipos.padre.controles.tipoequi.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        /> 
                                                    </FormRow>
                                                    <FormRow>
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.cla1  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.equipos.padre.controles.centro.id }    
                                                            name=        { confPantalla.equipos.padre.controles.centro.name }   
                                                            label=       { confPantalla.equipos.padre.controles.centro.label }    
                                                            placeholder= { confPantalla.equipos.padre.controles.centro.placeholder }
                                                            col=         { confPantalla.equipos.padre.controles.centro.col } 
                                                            isClearable= { confPantalla.equipos.padre.controles.centro.isclearable }
                                                            isSearchable={ confPantalla.equipos.padre.controles.centro.issearchable }
                                                            dataType=    { confPantalla.equipos.padre.controles.centro.datatype }
                                                            data=        { confPantalla.equipos.padre.controles.centro.data }
                                                            required=    { confPantalla.equipos.padre.controles.centro.required } 
                                                            disabled=  { confPantalla.equipos.padre.controles.centro.disabled }
                                                            readOnly =   { confPantalla.equipos.padre.controles.centro.readonly }
                                                            disablethisid={confPantalla.equipos.padre.controles.centro.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />
                                                        <MiSelect  
                                                            estadoForm=  { estadoFormPrin }
                                                            val=         { formularioPrin.idapa  }   
                                                            loading=     { loadReg ? true : false }
                                                            id=          { confPantalla.equipos.padre.controles.familia.id }    
                                                            name=        { confPantalla.equipos.padre.controles.familia.name }   
                                                            label=       { confPantalla.equipos.padre.controles.familia.label }    
                                                            placeholder= { confPantalla.equipos.padre.controles.familia.placeholder }
                                                            col=         { confPantalla.equipos.padre.controles.familia.col } 
                                                            isClearable= { confPantalla.equipos.padre.controles.familia.isclearable }
                                                            isSearchable={ confPantalla.equipos.padre.controles.familia.issearchable }
                                                            dataType=    { confPantalla.equipos.padre.controles.familia.datatype }
                                                            data=        { confPantalla.equipos.padre.controles.familia.data }
                                                            required=    { confPantalla.equipos.padre.controles.familia.required } 
                                                            disabled=  { confPantalla.equipos.padre.controles.familia.disabled }
                                                            readOnly =   { confPantalla.equipos.padre.controles.familia.readonly }
                                                            disablethisid={confPantalla.equipos.padre.controles.familia.disablethisid}
                                                            formulario= { formularioPrin }   
                                                            change= { handleSelectFormPrin }
                                                        />
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom2 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.fabricante.id }   
                                                            type=       { confPantalla.equipos.padre.controles.fabricante.type }  
                                                            name=       { confPantalla.equipos.padre.controles.fabricante.name }   
                                                            label=      { confPantalla.equipos.padre.controles.fabricante.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.fabricante.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.fabricante.col } 
                                                            required=   { confPantalla.equipos.padre.controles.fabricante.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.fabricante.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.fabricante.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod1 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.modelo.id }   
                                                            type=       { confPantalla.equipos.padre.controles.modelo.type }  
                                                            name=       { confPantalla.equipos.padre.controles.modelo.name }   
                                                            label=      { confPantalla.equipos.padre.controles.modelo.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.modelo.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.modelo.col } 
                                                            required=   { confPantalla.equipos.padre.controles.modelo.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.modelo.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.modelo.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow> 
                                                    <FormRow>
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod2 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.nserie.id }   
                                                            type=       { confPantalla.equipos.padre.controles.nserie.type }  
                                                            name=       { confPantalla.equipos.padre.controles.nserie.name }   
                                                            label=      { confPantalla.equipos.padre.controles.nserie.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.nserie.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.nserie.col } 
                                                            required=   { confPantalla.equipos.padre.controles.nserie.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.nserie.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.nserie.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.num3 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.anhoinst.id }   
                                                            type=       { confPantalla.equipos.padre.controles.anhoinst.type }  
                                                            name=       { confPantalla.equipos.padre.controles.anhoinst.name }   
                                                            label=      { confPantalla.equipos.padre.controles.anhoinst.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.anhoinst.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.anhoinst.col } 
                                                            required=   { confPantalla.equipos.padre.controles.anhoinst.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.anhoinst.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.anhoinst.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.num1 }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.contador.id }   
                                                            type=       { confPantalla.equipos.padre.controles.contador.type }  
                                                            name=       { confPantalla.equipos.padre.controles.contador.name }   
                                                            label=      { confPantalla.equipos.padre.controles.contador.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.contador.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.contador.col } 
                                                            required=   { confPantalla.equipos.padre.controles.contador.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.contador.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.contador.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>                                                                                                       
                                                </Tab>                                                                                                                        
                                                <Tab eventKey="tab2" title={ confPantalla.equipos.tabs.tab2.titulo }>
                                                    <FormRow>
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.notas }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.notas.id }   
                                                            name=       { confPantalla.equipos.padre.controles.notas.name }
                                                            rows=       { confPantalla.equipos.padre.controles.notas.rows }      
                                                            label=      { confPantalla.equipos.padre.controles.notas.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.notas.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.notas.col } 
                                                            required=   { confPantalla.equipos.padre.controles.notas.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.notas.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.notas.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <TextArea                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.notas_en }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.equipos.padre.controles.notas_en.id }   
                                                            name=       { confPantalla.equipos.padre.controles.notas_en.name }
                                                            rows=       { confPantalla.equipos.padre.controles.notas_en.rows }      
                                                            label=      { confPantalla.equipos.padre.controles.notas_en.label }                                                       
                                                            placeholder={ confPantalla.equipos.padre.controles.notas_en.placeholder }    
                                                            col=        { confPantalla.equipos.padre.controles.notas_en.col } 
                                                            required=   { confPantalla.equipos.padre.controles.notas_en.required }
                                                            readonly=   { confPantalla.equipos.padre.controles.notas_en.readonly }                                                
                                                            disabled=   { confPantalla.equipos.padre.controles.notas_en.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>
                                                </Tab>                                                                                                                        
                                                <Tab eventKey="tab3" title={ confPantalla.equipos.tabs.tab3.titulo }>
                                                    <FormRow clases="" >
                                                        {/* <Uplm
                                                            estadoForm= { estadoFormPrin }
                                                            loading=    { loadReg ? true : false }
                                                            col={12}
                                                            id={ confPantalla.equipos.padre.controles.uplm.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            readonly={ confPantalla.equipos.padre.controles.uplm.readonly }
                                                            traduction={confPantalla.config.trad.components.uplm}
                                                        /> */}
                                                        <UplmPlus2
                                                            id={ confPantalla.equipos.padre.controles.uplm.id }
                                                            adjuntos={ adjuntosPrin }
                                                            saveAdjuntos= { saveAdjuntosPrin }  
                                                            adjuntosExistentes= { adjuntosExistentesPrin } 
                                                            saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                                            estadoForm= { estadoFormPrin }
                                                            loading= { loadReg ? true : false }
                                                            col={12}
                                                            readonly={ confPantalla.equipos.padre.controles.uplm.readonly }
                                                            traduction={confPantalla.config.trad.components}                                                                                                                        
                                                            datatable={ confPantalla.equipos.padre.controles.uplmplus.datatable }
                                                            select={ confPantalla.equipos.padre.controles.uplmplus.select }
                                                        />
                                                    </FormRow>
                                                </Tab>                                                                                                                        
                                                {/* <Tab eventKey="tab4" title={ confPantalla.equipos.tabs.tab4.titulo }>
                                                    <Informes
                                                        select =     { confPantalla.equipos.padre.controles.informes.select }
                                                        formulario = { formularioPrin }
                                                        loadReg =    { loadReg ? true : false }
                                                        estadoForm=  { estadoFormPrin } 
                                                        traduction=  { confPantalla.config.trad.components }
                                                    />
                                                </Tab>                                                                                                                                 */}
                                            </Tabs>

                                        </Formulario>
                                        <MiDataTable
                                            formulario={formularioPrin} 
                                            estadoForm= { estadoFormPrin }
                                            id={"equipos"}
                                            columnas={confPantalla.equipos.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.equipos.padre.datatable.buttons}
                                            ordering={confPantalla.equipos.padre.datatable.ordering}
                                            order={confPantalla.equipos.padre.datatable.order}
                                            searching={confPantalla.equipos.padre.datatable.searching}
                                            paging={confPantalla.equipos.padre.datatable.paging}
                                            select={confPantalla.equipos.padre.datatable.select}
                                            pagelength={confPantalla.equipos.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.equipos.padre.urls, confPantalla.equipos.hijo.urls, confPantalla.equipos.padreshijos, confPantalla.equipos.hijo.modelform, confPantalla.equipos.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.equipos.padre.urls, confPantalla.equipos.padre.modelform, confPantalla.equipos.padre.tabla, confPantalla.equipos.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.equipos.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Equipos