import React from 'react';
import $ from 'jquery';


function TextArea(props){

    const { id, name, rows, label, placeholder, col, estadoForm, required, readonly, disabled, onBlur, change, loading, title } = props;
    let { value } = props;

    if( value == null ){
        value = "";
    }

    $(`textarea[name='${name}']`).on('change invalid', function() {
        var textfield = $(this).get(0);
        
        // 'setCustomValidity not only sets the message, but also marks
        // the field as invalid. In order to see whether the field really is
        // invalid, we have to remove the message first
        if( title ){
            textfield.setCustomValidity('');
            
            if (!textfield.validity.valid) {
              textfield.setCustomValidity(title);  
            }
        }
    });

    return(
        <div className={"form-group col-md-"+col}>
            <label htmlFor={id}>{label}</label>
            <textarea 
                    className="form-control" 
                    id={ id } 
                    name={ name }
                    rows={ rows } 
                    placeholder={ placeholder }
                    value={ value } 
                    onChange={ change } 
                    required={ required } 
                    disabled={ estadoForm === "disabled" ? true : disabled } 
                    readOnly={ estadoForm === "readonly" || loading ? true : readonly } 
                    autoComplete="off" 
                    onBlur={ onBlur !== null ? onBlur : null } 
                    title={title}
                    >
            </textarea> 
        </div>
    )
}

export default TextArea;