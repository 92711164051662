import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import Informes from '../../componentes/formulario/informes/Informes';
import { Alert } from '../../componentes/ui/Alert';
import { SRLWrapper } from 'simple-react-lightbox';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiDataTable from '../../componentes/ui/MiDataTable';
import Button from '../../componentes/ui/Button';
import { Modal } from 'react-bootstrap';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import CheckIos from '../../componentes/ui/CheckIos';
import Firma from '../../componentes/formulario/Firma';
import { Spinner } from 'react-bootstrap';

import { alertService } from '../../services/alertservice';

import { getTitle, getApiUrl, MiReplaceAll, getAhorayyyymmdd, getAhoraInputDateTimeLocal, encryptData, getDecodedCookie } from '../../helpers/helperApp';
import FormRow from '../../componentes/formulario/FormRow';

let idAgeParamUrl = null;

function LogBook(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp, centroActivo, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ optionsAgentes, saveOptionsAgentes ] = useState([]);
    const [ loadingAgentes, saveLoadingAgentes ] = useState(false);
    const [ centroActual, saveCentroAtual ] = useState(centroActivo);
    const [ agente, saveAgente ] = useState(0);
    const [ agenteNom, saveAgenteNom ] = useState('');
    const [ btnFilCen, saveBtnFilCen ] = useState(null);
    const [ dataTableLogBook, saveDataTableLogBook ] = useState([]);
    const [ loadingTable, saveLoadingTable ] = useState(false);
    const [ showModal, saveShowModal ] = useState(false);
    const [ valModalCentro, saveValModalCentro ] = useState(0);
    const [ valModalRegistro, saveValModalRegistro ] = useState(0);
    const [ valModalFecha, saveValModalFecha ] = useState(getAhoraInputDateTimeLocal());
    const [ valModalCantidad, saveValModalCantidad ] = useState(0);
    const [ valModalNotas, saveValModalNotas ] = useState('');
    const [ valFirma, saveValFirma ] = useState(false);
    const [ creandoRegistro, saveCreandoRegistro ] = useState(false);
    const [ backUpCentrosModal, saveBackUpCentrosModal ] = useState([]);
    const [ showFirma, saveShowFirma ] = useState(false);
    const [ idDiarioRegistroActual, saveIdDiarioRegistroActual ] = useState(0);
    const [ idLineaCreada, saveIdLineaCreada ] = useState(0);

    useEffect( () => {                
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.logbook.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        // Seleccionar los parámetros que nos llegan por la url
                        let openModal = false;
                        if( props.location.state ){
                            if( "idage" in props.location.state){                                 
                                saveAgente(props.location.state.idage);
                                openModal = true;
                                idAgeParamUrl = props.location.state.idage;
                                loadDatosTableParamUrl(props.location.state.idage);                              
                            }
                            if( "idcen" in props.location.state && "idage" in props.location.state){                                 
                                saveValModalCentro(props.location.state.idcen);
                                openModal = true;                     
                            }
                            if( "iduni" in props.location.state && "idage" in props.location.state){                                 
                                saveValModalRegistro(props.location.state.iduni);
                                openModal = true;
                            }                        
                        }
                        init();
                        if(openModal){
                            saveShowModal(true);
                        }                                                                               
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                                
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })                  
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla, centroActivo])

    const init = async() => {       
        const select = confPantalla.logbook.controles.agentes;        
        await loadOptionsCbo(select.data, optionsAgentes, saveOptionsAgentes, saveLoadingAgentes);
    }

    const loadDatosTableParamUrl = async(idAge) => {
        await loadDatosTable(idAge);
    }

    const loadOptionsCbo = async (dataSql, options, saveOptions, saveLoading, mostrarTodos=false) =>{
        saveLoading(true);        
        const data = new FormData();
        data.append("validacion", apiKey());
        let sql = '';        
        if(centroActivo == ""){
            // Todos
            sql = dataSql.todos;
            await saveBtnFilCen("todos");
        }else if(centroActivo == "0"){
            // Sin centro
            sql = dataSql.sincentro;
            await saveBtnFilCen("filtrando");
        }else{
            // Hay centro seleccionado
            sql = dataSql.concentro;
            await saveBtnFilCen("filtrando");
        }

        if(mostrarTodos){
            // Todos
            sql = dataSql.todos;
            await saveBtnFilCen("todos");
        }
        
        const idCookie = await encryptData(cookies.get('id'));
        data.append('ua', idCookie);
        const nivelCookie = await encryptData(cookies.get('nivel'));
        data.append('na', nivelCookie);
                    
        // Encriptamos el tag sql. 
        sql = await encryptData(sql);
        data.append('sql', sql); 

        let url = getApiUrl(window.location.origin)+'/app/getDataCbo';       
        const respuesta = await axios.post(url, data);        
        if(idAgeParamUrl != 0){
            let auxAgeNom = respuesta.data.filter(age => age.value == idAgeParamUrl);
            if(auxAgeNom[0]){
                saveAgenteNom(auxAgeNom[0].label);
            }
        }        
        saveOptions(respuesta.data);        

        saveLoading(false);
    }

    const clickBtnFilCen = async (mostrarTodos) => {
        saveAgente(0);
        saveAgenteNom('');       
        const select = confPantalla.logbook.controles.agentes;
        if(mostrarTodos){            
            await loadOptionsCbo(select.data, optionsAgentes, saveOptionsAgentes, saveLoadingAgentes, true);
            await saveBtnFilCen("todos");
        }else{
            await loadOptionsCbo(select.data, optionsAgentes, saveOptionsAgentes, saveLoadingAgentes, false);
            await saveBtnFilCen("filtrando");
        }

    }

    const handleSelectAgente = async (selectedItem, nameOfComponent) => {        
        if( selectedItem ){
            saveAgente(selectedItem.value);
            saveAgenteNom(selectedItem.label);            
            await loadDatosTable(selectedItem.value);
        }else{
            saveAgente(0);
            saveAgenteNom('');            
            saveDataTableLogBook([]);
        }
    }

    const loadDatosTable = async (idage) => {
        saveLoadingTable(true);        
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idage", idage);        
        data.append("centroActivo", centroActivo);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/logbook/getLineasDiario', data);   
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
            saveDataTableLogBook([]);
        }else{
            saveDataTableLogBook(respuesta.data);                  
        }

        saveLoadingTable(false);
    }

    const clickBtnCrear = () => {
        let valDefectoCentro = 0;
        // Si tenemos centro seleccionado en el select del menú lo establecemos
        if(centroActivo != '' && centroActivo != '0'){
            valDefectoCentro = centroActivo;
        }
        // Si solo hay un centro en el select del modal lo seleccionamos por defecto                
        if(backUpCentrosModal.length == 1){            
            valDefectoCentro = backUpCentrosModal[0].value;
        }        

        saveValModalCentro(valDefectoCentro);
        saveValModalFecha(getAhoraInputDateTimeLocal());
        saveValModalRegistro(0);
        saveValModalCantidad(0);
        saveValModalNotas('');
        saveValFirma(false);
        saveShowModal(true);
    }

    const crearRegistroModal = async () => {
        saveCreandoRegistro(true);               

        const data = new FormData();             
        data.append("validacion", apiKey()); 
        data.append("idcen", valModalCentro);       
        data.append("iduni", valModalRegistro);
        data.append("fec", MiReplaceAll(valModalFecha,'T', ' ')+':00');
        data.append("num1", valModalCantidad);
        data.append("notas", valModalNotas);
        data.append("idageselect", agente);        
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/logbook/actionsLogBook', data); 
             console.log(respuesta);
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
        }else{
            // Guardar aqui el id de linea creada y el id del documento que hace falta para la firma. Traerlo de vuelta del api
            saveIdDiarioRegistroActual(respuesta.data.iddoc);
            saveIdLineaCreada(respuesta.data.idlin);
        }

        saveCreandoRegistro(false);

        saveShowModal(false);

        if(valFirma){
            saveShowFirma(true);
        }

        await loadDatosTable(agente);

    }

    const handleSelectModal= (selectedItem, nameOfComponent, saveData) => {
        if( selectedItem ){
            saveData(selectedItem.value) 
        }else{
            saveData(0)
        }
    }

    const handleInputModal = (e, saveData) => {
        const { name, value } = e.target;
        saveData(value)       
    }

    const handleCheckFirmaModal = (e) => {
        if( e.target.checked ){            
            saveValFirma(true);
        }else{        
            saveValFirma(false);
        }
    }

    return(
    <SRLWrapper>
        <div className="App wrapper">
            <Alert fondo={true} />    
            {
                confPantalla !== undefined
                ?
                    <Fragment>
                        <Menusup 
                            props = { props }
                        />
                        <Sidebar 
                            props = { props }
                        />
                    </Fragment>
                :
                null
            } 
            <div className="content-wrapper">
                <div className="content-header"></div>
                <section className="content">
                {
                    !tieneNivel 
                ?
                    <PermissionRefused
                        traduction={confPantalla.config.trad.components.permissionrefused} 
                    />
                :
                    <Fragment>
                    {
                    loadedConf === true && confPantalla !== undefined
                    ?
                    <Fragment>
                        <Section titulo={ confPantalla.logbook.sectioninf.titulo } icono={ confPantalla.logbook.sectioninf.icono } col={ confPantalla.logbook.sectioninf.col } wiki={ confPantalla.logbook.sectioninf.wiki } >
                            <Informes
                                select =     { confPantalla.logbook.controles.informes.select }
                                formulario = { {id:0} }
                                loadReg =    { false }
                                estadoForm=  { "" } 
                                traduction=  { confPantalla.config.trad.components }
                                btnfilubi=   { confPantalla.logbook.controles.informes.btnfilubi }
                            />
                        </Section>
                        <Section titulo={ confPantalla.logbook.sectionadj.titulo } icono={ confPantalla.logbook.sectionadj.icono } col={ confPantalla.logbook.sectionadj.col } wiki={ confPantalla.logbook.sectionadj.wiki } >
                        <div className='container-fluid'>
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { "" }
                                    val=         { agente }   
                                    loading=     { loadingAgentes }
                                    id=          { 'confPantalla.logbook.controles.agentes.id' }    
                                    name=        { confPantalla.logbook.controles.agentes.name }   
                                    label=       { confPantalla.logbook.controles.agentes.label }    
                                    placeholder= { confPantalla.logbook.controles.agentes.placeholder }
                                    col=         { confPantalla.logbook.controles.agentes.col } 
                                    isClearable= { confPantalla.logbook.controles.agentes.isclearable }
                                    isSearchable={ confPantalla.logbook.controles.agentes.issearchable }                                                                            
                                    dataType=    { "codigo" }
                                    data=        { optionsAgentes }
                                    required=    { confPantalla.logbook.controles.agentes.required } 
                                    disabled=  { confPantalla.logbook.controles.agentes.disabled }
                                    readOnly =   { confPantalla.logbook.controles.agentes.readonly }
                                    disablethisid={confPantalla.logbook.controles.agentes.disablethisid}
                                    disableids={confPantalla.logbook.controles.agentes.disableids}
                                    formulario={{id:0}} 
                                    change= { handleSelectAgente }
                                    btnFilCen={ centroActivo != '' ? btnFilCen : null }
                                    clickBtnFilCen={ clickBtnFilCen }
                                    saveBtnFilCen={ saveBtnFilCen }
                                />                                
                                <button 
                                    style={{marginTop: "32px"}} 
                                    id="btncrear"
                                    className={"btn btn-success col-md-2 col-sm-12 mb-4" } 
                                    type="button" 
                                    onClick={ () => clickBtnCrear() } 
                                    disabled={ agente == 0 ? true : false }
                                >
                                    <i className="fal fa-plus pr-1"></i>
                                    {confPantalla.logbook.controles.btncrear.title}
                                </button> 
                            </FormRow>
                            <MiDataTable 
                                estadoForm= { "" }
                                formulario={ {id:0} }
                                id={"lineaslogboook"}
                                columnas={confPantalla.logbook.controles.datatable.columns}
                                data={dataTableLogBook}
                                buttons={confPantalla.logbook.controles.datatable.buttons}
                                ordering={confPantalla.logbook.controles.datatable.ordering}
                                order={confPantalla.logbook.controles.datatable.order}
                                searching={confPantalla.logbook.controles.datatable.searching}
                                paging={confPantalla.logbook.controles.datatable.paging}
                                select={confPantalla.logbook.controles.datatable.select}
                                pagelength={confPantalla.logbook.controles.datatable.pagelength}
                                saveLoadingTable={ saveLoadingTable }
                                loadingTable={loadingTable}
                                columnactions={confPantalla.logbook.controles.datatable.columnactions.title}
                                linkColumns={confPantalla.logbook.controles.datatable.linkColumns}
                                traduction={confPantalla.config.trad.components.datatable}                                        
                            />
                        </div>
                        </Section>   
                    </Fragment>
                    :
                    <LoadingPage />
                    }
                    </Fragment>
                }
                </section>
            </div>
            <Footer />
            {
            showModal
            ?
                <Modal
                    show={showModal}
                    onHide={ () => saveShowModal(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w"                                             
                > 
                    <Modal.Header closeButton>
                        <h4 dangerouslySetInnerHTML={{ __html: agenteNom }}></h4>
                    </Modal.Header>                         
                    <Modal.Body>
                        <FormRow>
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { valModalCentro }   
                                loading=     { creandoRegistro ? true : false }
                                id=          { confPantalla.logbook.controles.modal.centro.id }    
                                name=        { confPantalla.logbook.controles.modal.centro.name }   
                                label=       { confPantalla.logbook.controles.modal.centro.label }    
                                placeholder= { confPantalla.logbook.controles.modal.centro.placeholder }
                                col=         { confPantalla.logbook.controles.modal.centro.col } 
                                isClearable= { confPantalla.logbook.controles.modal.centro.isclearable }
                                isSearchable={ confPantalla.logbook.controles.modal.centro.issearchable }
                                dataType=    { confPantalla.logbook.controles.modal.centro.datatype }
                                data=        { confPantalla.logbook.controles.modal.centro.data }
                                required=    { confPantalla.logbook.controles.modal.centro.required } 
                                disabled=    { confPantalla.logbook.controles.modal.centro.disabled }
                                readOnly =   { confPantalla.logbook.controles.modal.centro.readonly }
                                disablethisid={confPantalla.logbook.controles.modal.centro.disablethisid}
                                extradata=    {confPantalla.logbook.controles.modal.centro.extradata}
                                formulario= { {id:0} }   
                                change= { (selectedItem, nameOfComponent) => handleSelectModal(selectedItem, nameOfComponent, saveValModalCentro) }
                                saveBackUpOptions={saveBackUpCentrosModal}
                            />
                            <FormInput                                                        
                                estadoForm= { "" }
                                value=      { valModalFecha }
                                loading=    { creandoRegistro ? true : false }
                                id=         { confPantalla.logbook.controles.modal.fec.id }   
                                type=       { confPantalla.logbook.controles.modal.fec.type }  
                                name=       { confPantalla.logbook.controles.modal.fec.name }   
                                label=      { confPantalla.logbook.controles.modal.fec.label }                                                       
                                placeholder={ confPantalla.logbook.controles.modal.fec.placeholder }    
                                col=        { confPantalla.logbook.controles.modal.fec.col } 
                                required=   { confPantalla.logbook.controles.modal.fec.required }
                                readonly=   { confPantalla.logbook.controles.modal.fec.readonly }                                                
                                disabled=   { confPantalla.logbook.controles.modal.fec.disabled }
                                change=     { e => handleInputModal(e,saveValModalFecha) }                                 
                            />                            
                        </FormRow>
                        <FormRow>                            
                            <MiSelect  
                                estadoForm=  { "" }
                                val=         { valModalRegistro }   
                                loading=     { creandoRegistro ? true : false }
                                id=          { confPantalla.logbook.controles.modal.iduni.id }    
                                name=        { confPantalla.logbook.controles.modal.iduni.name }   
                                label=       { confPantalla.logbook.controles.modal.iduni.label }    
                                placeholder= { confPantalla.logbook.controles.modal.iduni.placeholder }
                                col=         { confPantalla.logbook.controles.modal.iduni.col } 
                                isClearable= { confPantalla.logbook.controles.modal.iduni.isclearable }
                                isSearchable={ confPantalla.logbook.controles.modal.iduni.issearchable }
                                dataType=    { confPantalla.logbook.controles.modal.iduni.datatype }
                                data=        { confPantalla.logbook.controles.modal.iduni.data }
                                required=    { confPantalla.logbook.controles.modal.iduni.required } 
                                disabled=    { confPantalla.logbook.controles.modal.iduni.disabled }
                                readOnly =   { confPantalla.logbook.controles.modal.iduni.readonly }
                                disablethisid={confPantalla.logbook.controles.modal.iduni.disablethisid}
                                extradata=    {confPantalla.logbook.controles.modal.iduni.extradata}
                                formulario= { {id:0} }   
                                change= { (selectedItem, nameOfComponent) => handleSelectModal(selectedItem, nameOfComponent, saveValModalRegistro) }
                            /> 
                            <FormInput 
                                estadoForm= { "" }
                                value=      { valModalCantidad }
                                loading=    { creandoRegistro ? true : false }
                                id=         { confPantalla.logbook.controles.modal.num1.id }   
                                type=       { confPantalla.logbook.controles.modal.num1.type }  
                                name=       { confPantalla.logbook.controles.modal.num1.name }   
                                label=      { confPantalla.logbook.controles.modal.num1.label }                                                       
                                placeholder={ confPantalla.logbook.controles.modal.num1.placeholder }    
                                col=        { confPantalla.logbook.controles.modal.num1.col } 
                                required=   { confPantalla.logbook.controles.modal.num1.required }
                                readonly=   { confPantalla.logbook.controles.modal.num1.readonly }                                                
                                disabled=   { confPantalla.logbook.controles.modal.num1.disabled }
                                change=     { e => handleInputModal(e, saveValModalCantidad) }                                
                            />
                        </FormRow>
                        <FormRow>
                            <TextArea                                                        
                                estadoForm= { "" }
                                value=      { valModalNotas }
                                loading=    { creandoRegistro ? true : false }
                                id=         { confPantalla.logbook.controles.modal.notas.id }   
                                name=       { confPantalla.logbook.controles.modal.notas.name }
                                rows=       { confPantalla.logbook.controles.modal.notas.rows }      
                                label=      { confPantalla.logbook.controles.modal.notas.label }                                                       
                                placeholder={ confPantalla.logbook.controles.modal.notas.placeholder }    
                                col=        { confPantalla.logbook.controles.modal.notas.col } 
                                required=   { confPantalla.logbook.controles.modal.notas.required }
                                readonly=   { confPantalla.logbook.controles.modal.notas.readonly }                                                
                                disabled=   { confPantalla.logbook.controles.modal.notas.disabled }
                                change=     { e => handleInputModal(e, saveValModalNotas) }                                
                            />
                        </FormRow>
                        <FormRow clases={' text-right '}>
                            <CheckIos                                
                                val= { valFirma }
                                name={null}
                                label={ confPantalla.logbook.controles.modal.firma.title }                                
                                id={'checkfirma'}
                                col={"12"}
                                change={ handleCheckFirmaModal }
                                required={ false }
                                disabled={ false }                               
                            />
                        </FormRow>                        
                        {
                            creandoRegistro 
                            ?
                                <div className="text-center">
                                    <Spinner animation="border" />
                                </div>
                            :
                            null
                        }
                        <p className="font-italic text-center">{ confPantalla.logbook.controles.modal.required.title }</p> 
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='text-right'>                                                                   
                            <Button 
                                clases={ "" }
                                color={ "success" }  
                                type="button" 
                                icono={ "" }   
                                id=""     
                                texto={confPantalla.logbook.controles.modal.btncrearmodal.title}   
                                accion={ () => crearRegistroModal() } 
                                disabled={ creandoRegistro || valModalCentro == 0 || valModalRegistro == 0 || valModalFecha == '' ? true : false }
                            /> 
                            <Button 
                                clases={ "" }
                                color={ "danger" }  
                                type="button" 
                                icono={ "" }   
                                id=""     
                                texto={confPantalla.logbook.controles.modal.btncerrarmodal.title}   
                                accion={ () => saveShowModal(false) } 
                                disabled={ false }
                            />                                    
                        </div>
                    </Modal.Footer>                            
                </Modal>
            :
                null 
            }
            {
                showFirma? 
                    <Firma
                        cabecera={{id: idDiarioRegistroActual}}
                        lineasFirmas={[{idlinea: idLineaCreada, id: idLineaCreada, num1: 0}]}
                        controles={  confPantalla.logbook.controles }
                        show={showFirma}
                        saveShow={saveShowFirma}
                        traduction={confPantalla.config.trad.components.firma}
                        functionAfter={null}
                        crewList={true}
                        numFirmasProp={1}ZZZ
                        rangoAgentesProp={1}
                        dataAgentes={[{value: agente, label: agenteNom, selected: true}]}
                        defaultFirmante={agente}
                    />                                   
                :
                    null
            }       
        </div>
    </SRLWrapper>
    );
}

export default LogBook