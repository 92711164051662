import React, { useEffect, useState, useContext } from 'react';
import StepWizard from "react-step-wizard";
import WizardStep from './WizardStep';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import axios from 'axios';

import { LoginContext } from '../../../hooks/context/LoginContext';

import { getApiUrl, getIndexUrl, axiosPost } from '../../../helpers/helperApp';

import Cookies from 'universal-cookie';
import { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import ButtonOutline from '../../ui/ButtonOutline';
import Informes from '../informes/Informes';
import WizardBtns from '../WizardBtns';


function Wizard(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { titulo, cabecera, registrosHijo, controles, estadoForm, adjuntos, adjuntosExistentes, confPantalla, saveLoadReg, propiedades, adjuntosCabecera, informeSelect, informeLoadReg, informeEstadoForm, informeTraduction, loadedHijos } = props;

    const { apiKey, menuMode, btnWizPos } = useContext(LoginContext);

    const [ lineas, saveLineas ] = useState([]);
    const [ currentStep, saveCurrentStep] = useState(0);
    const [ regAnterior, saveRegAnterior ] = useState();
    const [ resumenHerramientas, saveResumenHerramientas ] = useState([]);
    const [ resumenMateriales, saveResumenMateriales ] = useState([]);
    const [ loadedSteps, saveLoadedSteps ] = useState(false);
    const [ loadingForms, saveLoadingForms ] = useState(true);
    const [ trabajoSinLineas, saveTrabajoSinLineas ] = useState(false); 
    const [ lineaSoloHerramientas, saveLineaSoloHerramientas ] = useState([]);
    const [ nomEquipo, saveNomEquipo ] = useState("");
    const [ loadedHijosState, saveLoadedHijosState ]=useState(loadedHijos);

           
    useEffect( () => { 



        // Cargamos la configuración de todas las líneas y guardamos las que no sean herramientas en el state para pasarle al stepwizard
        init(registrosHijo); 
        
        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        }

    },[registrosHijo])

    // Preparamos los adjuntos de la cabecera para el datatable    
    adjuntosCabecera.map(adj => {
        adj["html"] = `<a 
                        href='${getApiUrl(window.location.origin)+adj.rut}'
                        target='_blank'
                        download=${getApiUrl(window.location.origin)+adj.rut}
                        class='btn btn-success'
                        style='color: white !important'
                        >
                            <i 
                                class='fal fa-download'
                                data-rut=${getApiUrl(window.location.origin)+adj.rut}
                                style='margin-right:5'
                            >
                            </i>
                        </a>
                        ${adj.des}
                    `; 

    })

    const init = async (registrosHijo) => {

        await loadConfWizard(registrosHijo)
       
    }



    // const loadConfWizard = async(registro, i) => {
    const loadConfWizard = async(registrosHijo) => {

        const data = new FormData();
        data.append("validacion", apiKey());

        let regsIdUniIdTip = []
        registrosHijo.map( reg => {
            let aux = { idreg : reg.id, iduni : reg.iduni, idtip : reg.tipuni }
            regsIdUniIdTip.push(aux);
        } )

        data.append("getconf", JSON.stringify(regsIdUniIdTip));
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+controles.wizard.getconfuni, data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+controles.wizard.getconfuni, data, source.token);
        let auxResumenHerramientas = [];
        let auxResumenMateriales = [];
        let todasLineasSinConfiguracion = true;
        if( Object.keys(respuesta.data).length > 0 ){
            for( let i=0; i<Object.keys(respuesta.data).length; i++ ){
                for (const [key, configuracionLinea] of Object.entries(respuesta.data[i])) {
                    let esHerramienta = false;
                    
                    // Filtro el registro que estoy iterando
                    const registro = registrosHijo.filter( reg => reg.id == key )[0];

                    // Guardamos el nombre del equipo en el state
                    saveNomEquipo(registro.equipo);          
                    
                    if( configuracionLinea ){
                        todasLineasSinConfiguracion = false;
                        configuracionLinea.split(";").map((e) => {
                            if( e == 11 ){
                                // Si es una herramienta la línea no va a stepper y no va a ser un paso
                                // 11. RESUMEN HERRAMIENTAS                                
                                if( auxResumenHerramientas.filter(herr => herr.html == registro.nomart).length == 0) { 
                                    let auxHerr = [];
                                    auxHerr["html"] = registro.nomart;                          
                                    auxResumenHerramientas.push(auxHerr);
                                }
                                esHerramienta=true; 
                            }
                            if( e == 12 ){
                                // 12. RESUMEN MATERIALES
                                if( auxResumenMateriales.filter(mat => mat.mat == registro.nomart).length == 0 ){ 
                                    let auxMat = [];
                                    auxMat["mat"] = registro.nomart;
                                    auxMat["html"] = `<a 
                                                      href='${confPantalla.articulos.padre.controles.stoact.linkright}/${registro.iduni}'
                                                      target='_blank'
                                                      class='btn btn-${confPantalla.articulos.padre.controles.stoact.linkcolor}'
                                                      style='color: white !important'
                                                      >
                                                      <i class='${confPantalla.articulos.padre.controles.stoact.linkico}'></i>
                                                      </a>
                                                      ${registro.nomart}
                                                    `;
                                    auxResumenMateriales.push(auxMat);                                                                        
                                }                         
                            }                
                        })
            
                        // Almacenamos en el state que pasamos al stepwizar las lineas que no son herramientas para crear solo estos pasos.
                        if( !esHerramienta ){
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }
                            saveLineas(lineas => [...lineas, aux]);
                        }
                        if( esHerramienta ){
                            // En el caso de ser herramientas las guardamos en otro state pero sobreescribiendo el registro para que solo acumule 1, con lo que al final tendremos en este state solo un registro. Este state es el que pasamos al wizard en el caso de que todas las lineas sean de herramientas para que al darle a siguiente en este único paso el backend ejecute todas las acciones necesarias que se realizan en la última línea del wizard
                            let data = [];
                            data[0] = {val : configuracionLinea};
                            let aux = {
                                "linea"     : registro,
                                "respuesta" : {data : data}
                            }
                            saveLineaSoloHerramientas([...lineaSoloHerramientas, aux]);
                        }          
                    }
    
                }
            }
            saveResumenHerramientas(auxResumenHerramientas)
            saveResumenMateriales(auxResumenMateriales)
 
            
            // Modificación corrección mensaje trabajo no disponble mientras no llegan las lineas
            saveTrabajoSinLineas(false);
            if( todasLineasSinConfiguracion ){
                saveTrabajoSinLineas(true);
            }
            
        }else if( respuesta.data.length == 0 ){
            // Si i es igual a 0 no hay lineas
            saveTrabajoSinLineas(true);
            saveLoadedSteps(true);
        }
        
        saveLoadedSteps(true);

    }

    const steps = []
    for (let i = 1 ; i <= lineas.length; i++) {        
            steps.push(
                <Step>
                    <StepLabel></StepLabel>
                </Step>
            )
    }

    const noLinesNext = () => {
        propiedades.history.push(getIndexUrl(menuMode));
    } 

    return(
        <div>
            {
                lineas.length > 0 && loadedSteps 
                ?
                <Fragment>
                    <Informes
                        select =     { informeSelect }
                        formulario = { cabecera }
                        loadReg =    { informeLoadReg ? true : false }
                        estadoForm=  { informeEstadoForm } 
                        traduction=  { informeTraduction }
                    />   
                    <div className="row">
                        <hr data-content={currentStep+1+"/"+lineas.length} className="hr-text-xl"></hr>
                    </div>
                    <div className="row" style={{overflowY: "auto"}}>
                        <div className="col-md-12 stepsContainer">
                            <Stepper activeStep={currentStep}>
                                {steps}                      
                            </Stepper>
                        </div>
                    </div>
                    <StepWizard>
                        {
                            lineas.map((linea, index, lineas) => {
                                return(                                    
                                    <WizardStep                                    
                                        key={ index }
                                        cabecera={ cabecera }
                                        paso={ index+1 }
                                        pasos={ lineas }
                                        linea={ linea.linea }
                                        respuesta={ linea.respuesta }
                                        saveCurrentStep={ saveCurrentStep }
                                        controles={ controles } 
                                        estadoForm={ estadoForm }
                                        adjuntos={ adjuntos }
                                        adjuntosExistentes={ adjuntosExistentes }
                                        confPantalla={ confPantalla }
                                        saveLoadReg={ saveLoadReg }
                                        propiedades={ propiedades }
                                        regAnterior={ regAnterior }
                                        saveRegAnterior={ saveRegAnterior }
                                        resumenHerramientas={ resumenHerramientas }
                                        resumenMateriales={ resumenMateriales }
                                        loadingForms={ loadingForms }
                                        saveLoadingForms={ saveLoadingForms }
                                        soloHerramientas={ false }
                                        adjuntosCabecera={ adjuntosCabecera }
                                        nomEquipo={ nomEquipo }
                                        />
                                )
                             
                            })
                        }                
                    </StepWizard>                    
                </Fragment>
                :
                    lineaSoloHerramientas.length > 0 && loadedSteps && !trabajoSinLineas
                    ?
                        <Fragment>
                            <Informes
                                select =     { informeSelect }
                                formulario = { cabecera }
                                loadReg =    { informeLoadReg ? true : false }
                                estadoForm=  { informeEstadoForm } 
                                traduction=  { informeTraduction }
                            />   
                            <div className="row">
                                <hr data-content={currentStep+1+"/1"} className="hr-text-xl"></hr>
                            </div>
                            <div className="row" style={{overflowY: "auto"}}>
                                <div className="col-md-12 stepsContainer">
                                    <Stepper activeStep={currentStep}>
                                        <Step>
                                            <StepLabel></StepLabel>
                                        </Step>                      
                                    </Stepper>
                                </div>
                            </div>
                            
                            <StepWizard>
                                {
                                    lineaSoloHerramientas.map((linea, index, lineas) => {
                                        return(                                    
                                            <WizardStep                                    
                                                key={ index }
                                                cabecera={ cabecera }
                                                paso={ index+1 }
                                                pasos={ lineas }
                                                linea={ linea.linea }
                                                respuesta={ linea.respuesta }
                                                saveCurrentStep={ saveCurrentStep }
                                                controles={ controles } 
                                                estadoForm={ estadoForm }
                                                adjuntos={ adjuntos }
                                                adjuntosExistentes={ adjuntosExistentes }
                                                confPantalla={ confPantalla }
                                                saveLoadReg={ saveLoadReg }
                                                propiedades={ propiedades }
                                                regAnterior={ regAnterior }
                                                saveRegAnterior={ saveRegAnterior }
                                                resumenHerramientas={ resumenHerramientas }
                                                resumenMateriales={ resumenMateriales }
                                                loadingForms={ loadingForms }
                                                saveLoadingForms={ saveLoadingForms }
                                                soloHerramientas={ true }
                                                adjuntosCabecera={ adjuntosCabecera }
                                                nomEquipo={ nomEquipo }
                                            />
                                        )
                                    
                                    })
                                }                
                            </StepWizard>                            
                        </Fragment>
                    :
                        trabajoSinLineas && loadedSteps && loadedHijosState
                        ?
                            <Fragment>
                                <h1 className="text-center">{controles.wizard.nolines.title}</h1>                                
                                {
                                    btnWizPos == "bottom" || btnWizPos == "both" 
                                    ?
                                        <WizardBtns 
                                            accionBtnExit={noLinesNext}
                                            accionBtnPrev={null}
                                            accionBtnNext={noLinesNext}
                                            disabledBtnExit={false}
                                            disabledBtnPrev={null}
                                            disabledBtnNext={false}
                                        />
                                    :
                                        null
                                }
                            </Fragment>
                        : 
                            <div className="text-center">
                                <Spinner animation="border" />
                            </div>
            }
        </div>
    )
}

export default Wizard;