import React, { useEffect, Fragment, useContext } from 'react';
import { LoginContext } from '../../hooks/context/LoginContext';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import axios from 'axios';
import InformesBtn from '../../componentes/formulario/informes/informesBtn/InformesBtn';

import { alertService } from '../../services/alertservice';

import { getIconoTable, dateSqlToLocal, getApiUrl, getPantDocFromNivel, getDecodedCookie } from '../../helpers/helperApp';


require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');
require('datatables.net-rowgroup')

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();
let collapsedGroups = {};
let categorias = [];


var botones = [
    {
        extend: 'pageLength',
        text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        // titleAttr: 'Ver número de registros'
    },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },
    {
        extend: 'excel',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        title: hoy,
        // titleAttr: 'Exportar a excel',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    {
        extend: 'pdf',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        orientation: 'landscape', 
        title: hoy,
        // titleAttr: 'Exportar a pdf',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    {
        extend: 'colvis',
        text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
    }
];

function MiDataTableCrewList(props){

    const cookies = new Cookies();

    const { apiKey, confApp, logo } = useContext(LoginContext);

    // ***** props *****
    const { id, columnas, data, columnactions, traduction, adjuntosplegados, functionAfterSelect, linkColumns, functionClickTd, columnsFunctionClickTd, saveLoading, confPantalla, getDocsCrewList, selectAndGoDataTablesReg, getLinesCrewList, editCabecera, clonarDoc } = props;
    let { buttons, ordering, order, searching, paging, select, grouping, pagelength, loading } = props;
    
    useEffect(()=>{

        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);


        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        select === undefined ? select = false : select = select;
        grouping === undefined ? grouping = false : grouping = grouping;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;

        

        columnas.map((columna, i) => {            
            if( i == 6 ){
                // Acciones
                columna.createdCell = (td, cellData, rowData, row, col) => {
                    let templateNecesario =  ReactDOM.render(
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            {
                                rowData.idest == 11
                                ?
                                <div className={"text-center mr-1"} onClick={e => {editarDoc(e, rowData)}}>                               
                                    <span className="btn btn-primary"><i className="fal fa-edit" onClick={e => {editarDoc(e, rowData)}}></i></span>
                                </div>
                            :
                                <div className={" mr-1"} style={{width: "44px"}}>
                                </div>
                            }
                            {
                                getDecodedCookie('nivel') < 2
                                ?
                                    <div className='text-center mr-1'>
                                        <a className='btn btn-outline-primary' href={"/documentospr/"+rowData.id}>
                                            <i className='fal fa-eye'></i>
                                        </a>
                                    </div>
                                :
                                    null
                            }                            
                            <div className={"text-center mr-1"} onClick={e => {clonDoc(e, rowData)}}>                               
                                <span className="btn btn-outline-warning"><i className="fal fa-copy" onClick={e => {clonDoc(e, rowData)}}></i></span>
                            </div>
                            <InformesBtn
                                select =     { confPantalla.crewlist.controles.crewlist.informes.select }
                                formulario = { {id: rowData.id} }
                                getRegistro = { confPantalla.crewlist.controles.crewlist.informes.getregistro }
                                loadReg =    { false }
                                estadoForm=  { "" } 
                                traduction=  { confPantalla.config.trad.components }
                                btnGenerar= { confPantalla.crewlist.controles.crewlist.informes.btngenerar.title }
                                logoContext={logo}
                                apiKeyContext={apiKey}
                                confAppContext={confApp}
                            />                             
                        </div>                        
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)                                        
                }
            }
        })

        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({                      
                        mark: true,                         
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        }                                       
                    })                    
                }  
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{    
                    $("."+id).DataTable({                      
                        mark: true,                         
                        rowId: function(a) {
                            return '#row-' + a.id;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        }                                             
                    })                                 
                }
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({                      
                    mark: true,                     
                    rowId: function(a) {
                        return '#row-' + a.id;},
                    dom: buttons,
                    data: data,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }        
                    }                                         
                })    
            }
        }

         // Código para llamar a una función tras seleccionar una fila
         if( functionAfterSelect != null  ){
            let tabla = $('#'+id).DataTable();
            tabla.on( 'click', 'tr', function ( e, dt, type, indexes ) {
                if(e.currentTarget.id != ""){
                    setTimeout(()=> {
                        let idRow = tabla.row( this ).id();         
                        const datos = $('#'+id).DataTable().rows().data();
                        const selectedRows = $('#'+id).DataTable().rows( { selected: true } ).data();
                        functionAfterSelect(idRow, id, datos, selectedRows);                                                
                    }, 500)
                }
            } );            
        }

    }, [data])

    const editarDoc = async (e, rowData) =>{
        e.stopPropagation();
        editCabecera(rowData)        
    }

    const clonDoc = async (e, rowData) =>{
        e.stopPropagation();
        clonarDoc(rowData)        
    }
      
    const expandCollapse = (e, group) => {
        collapsedGroups[group] = !collapsedGroups[group];
        let table = $("."+id).DataTable();
        table.draw(false);
    }

   // Busqueda en los input, select de la tabla
   $.fn.dataTableExt.ofnSearch['html-input'] = function(value) {    
    if(/^(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d)$/.test(value) || /^(\d{4})-(\d\d)-(\d\d)$/.test(value) ){            
        return dateSqlToLocal(value+"T00:00", getDecodedCookie('idioma'));
    }else{                           
        return value;
    }   
};

    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;

    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas


    return(
        <Fragment>
            {   loading ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }
            <div className="mb-2" style={{overflowX: "auto"}}>
                <table id={id} className={id+" table table-sm table-bordered table-hover dataTable"} style={{width:"100%"}}>                            
                </table>
            </div>   
                        
        </Fragment>
    )
}

export default MiDataTableCrewList;