import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getTitle, getDecodedCookie } from '../../helpers/helperApp';

function TipObj(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo , handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                        
                    document.title = getTitle(confPantalla.config.trad, confPantalla.tipobj.title);                                   
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        saveEstadoFormPrin(confPantalla.tipobj.padre.estadoform);
                        saveEstadoFormHijo(confPantalla.tipobj.hijo.estadoform);                             
                        // ***** Carga inicial de registros *****
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){
                                saveLoadingTable(true);
                                regIniciales(saveRegistrosPrin, confPantalla.tipobj.padre.urls);
                                saveFormularioPrin(confPantalla.tipobj.padre.modelform);
                                saveFormularioHijo(confPantalla.tipobj.hijo.modelform);     
                            }
                        }  
                        // Seleccionar el id que nos llega como parámetro por la url
                        if( props.location.state ){
                            if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.tipobj.padre.urls, confPantalla.tipobj.hijo.urls, confPantalla.tipobj.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);    
                            }                        
                        }                      
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])
 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.tipobj.section.titulo } icono={ confPantalla.tipobj.section.icono } col={ confPantalla.tipobj.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.tipobj.section.wiki } >
                            <Formulario 
                                        estadoForm= { estadoFormPrin }
                                        loading=    { loadReg ? true : false }
                                        btnNew={ confPantalla.tipobj.padre.btnform.new }
                                        btnSave={ confPantalla.tipobj.padre.btnform.save }
                                        btnSaveAndNew={ confPantalla.tipobj.padre.btnform.saveandnew }
                                        btnDelete={ confPantalla.tipobj.padre.btnform.delete }
                                        btnClone={ confPantalla.tipobj.padre.btnform.clone }
                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.tipobj.padre.urls, confPantalla.tipobj.padre.modelform, confPantalla.tipobj.padre.tabla, confPantalla.tipobj.hijo.modelform, confPantalla.tipobj.title) }
                                        formulario = { formularioPrin } 
                                        resetForm = { e => resetFormPrin(confPantalla.tipobj.padre.modelform, true, confPantalla.tipobj.padre.tabla) } 
                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.tipobj.padre.urls, confPantalla.tipobj.padre.modelform, confPantalla.tipobj.padre.tabla, confPantalla.tipobj.title) } 
                            >         
                            <h4>{ formularioPrin.ruta }</h4>   
                                <FormRow clases="" >
                                    <MiSelect  
                                        estadoForm=  { estadoFormPrin }
                                        val=         { formularioPrin.pad  }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.tipobj.padre.controles.pad.id }    
                                        name=        { confPantalla.tipobj.padre.controles.pad.name }   
                                        label=       { confPantalla.tipobj.padre.controles.pad.label }    
                                        placeholder= { confPantalla.tipobj.padre.controles.pad.placeholder }
                                        col=         { confPantalla.tipobj.padre.controles.pad.col } 
                                        isClearable= { confPantalla.tipobj.padre.controles.pad.isclearable }
                                        isSearchable={ confPantalla.tipobj.padre.controles.pad.issearchable }
                                        dataType=    { confPantalla.tipobj.padre.controles.pad.datatype }
                                        data=        { confPantalla.tipobj.padre.controles.pad.data }
                                        required=    { confPantalla.tipobj.padre.controles.pad.required } 
                                        disabled=    { confPantalla.tipobj.padre.controles.pad.disabled }
                                        readOnly =   { confPantalla.tipobj.padre.controles.pad.readonly }
                                        disablethisid={confPantalla.tipobj.padre.controles.pad.disablethisid}
                                        formulario = { formularioPrin } 
                                        change=      { handleSelectFormPrin }                                                    
                                    />                                    
                                    <FormInput                                                        
                                        estadoForm= { estadoFormPrin }
                                        value=      { formularioPrin.nom }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.tipobj.padre.controles.nom.id }   
                                        type=       { confPantalla.tipobj.padre.controles.nom.type }  
                                        name=       { confPantalla.tipobj.padre.controles.nom.name }   
                                        label=      { confPantalla.tipobj.padre.controles.nom.label }                                                       
                                        placeholder={ confPantalla.tipobj.padre.controles.nom.placeholder }    
                                        col=        { confPantalla.tipobj.padre.controles.nom.col } 
                                        required=   { confPantalla.tipobj.padre.controles.nom.required }
                                        readonly=   { confPantalla.tipobj.padre.controles.nom.readonly }                                                
                                        disabled=   { confPantalla.tipobj.padre.controles.nom.disabled }
                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                        onBlur=     {null} 
                                    /> 
                                    <FormInput                                                        
                                        estadoForm= { estadoFormPrin }
                                        value=      { formularioPrin.nom_en }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.tipobj.padre.controles.nom_en.id }   
                                        type=       { confPantalla.tipobj.padre.controles.nom_en.type }  
                                        name=       { confPantalla.tipobj.padre.controles.nom_en.name }   
                                        label=      { confPantalla.tipobj.padre.controles.nom_en.label }                                                       
                                        placeholder={ confPantalla.tipobj.padre.controles.nom_en.placeholder }    
                                        col=        { confPantalla.tipobj.padre.controles.nom_en.col } 
                                        required=   { confPantalla.tipobj.padre.controles.nom_en.required }
                                        readonly=   { confPantalla.tipobj.padre.controles.nom_en.readonly }                                                
                                        disabled=   { confPantalla.tipobj.padre.controles.nom_en.disabled }
                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                        onBlur=     {null} 
                                    /> 
                                </FormRow>                            
                            </Formulario>
                            <MiDataTable 
                                formulario={formularioPrin}
                                estadoForm= { estadoFormPrin }
                                id={"tipobj"}
                                columnas={confPantalla.tipobj.padre.datatable.columns}
                                data={registrosPrin}
                                buttons={confPantalla.tipobj.padre.datatable.buttons}
                                ordering={confPantalla.tipobj.padre.datatable.ordering}
                                order={confPantalla.tipobj.padre.datatable.order}
                                searching={confPantalla.tipobj.padre.datatable.searching}
                                paging={confPantalla.tipobj.padre.datatable.paging}
                                select={confPantalla.tipobj.padre.datatable.select}
                                pagelength={confPantalla.tipobj.padre.datatable.pagelength}
                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.tipobj.padre.urls, confPantalla.tipobj.hijo.urls, confPantalla.tipobj.padreshijos, confPantalla.tipobj.hijo.modelform, confPantalla.tipobj.padre.tabla) }
                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.tipobj.padre.urls, confPantalla.tipobj.padre.modelform, confPantalla.tipobj.padre.tabla, confPantalla.tipobj.title) }
                                saveLoadingTable={ saveLoadingTable }
                                loadingTable={loadingTable}
                                columnactions={confPantalla.tipobj.padre.datatable.columnactions.title}
                                traduction={confPantalla.config.trad.components.datatable}
                            />                                                                                                             
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default TipObj