import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';
import MiDataTableBrowserPr from './MiDataTableBrowserPr';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';

import { alertService } from '../../services/alertservice';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Spinner } from 'react-bootstrap';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, getAhorayyyymmdd, axiosPost, getTitle, getDecodedCookie} from '../../helpers/helperApp';

import { Fragment } from 'react';

function BrowserPr(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ idParam, saveIdParam ] = useState(null);
    const [ tipoParam, saveTipoParam ] = useState(null);
    const [ fecIniParam, saveFecIniParam ] = useState(null);
    const [ fecFinParam, saveFecFinParam ] = useState(null);
    const [ datosTabla, saveDatosTabla ] = useState([]);
    const [ loading, saveLoading ] = useState(false);
    const [ txtHeader, saveTxtHeader ] = useState("");
    const [ showModal, saveShowModal ] = useState(false);
    const [ datosTableAgentes, saveDatosTableAgentes ] = useState([]);
    const [ loadingAgentes, saveLoadingAgentes ] = useState(false);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                     
                    document.title = getTitle(confPantalla.config.trad, confPantalla.browserpr.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        // Seleccionar los parámetros que nos llegan por la url
                        if( props.location.state ){
                            let idAux = null;
                            let tipoAux = null;                            
                            let fecIniAux = null;
                            let fecFinAux = null;                            
                            if( "id" in props.location.state){
                                saveIdParam(props.location.state.id);
                                idAux = props.location.state.id;
                            }
                            if( "tipo" in props.location.state){
                                saveTipoParam(props.location.state.tipo);
                                tipoAux = props.location.state.tipo;
                            }                            
                            if( "fecini" in props.location.state){
                                saveFecIniParam(props.location.state.fecini);
                                fecIniAux = props.location.state.fecini;
                            }
                            if( "fecfin" in props.location.state){
                                saveFecFinParam(props.location.state.fecfin);
                                fecFinAux = props.location.state.fecfin;
                            }                            
                            handleGetData(idAux, tipoAux, fecIniAux, fecFinAux);
                        }                                                                               
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla, formularioHijo.id])


    const handleGetData = async (id, tipo, fecIni, fecFin) => {
        
        saveLoading(true);       
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("id", id);
        data.append("tipo", tipo);
        data.append("fecini", fecIni);
        data.append("fecfin", fecFin);              
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.browserpr.controles.datatable.getdata, data);

        if(typeof respuesta.data == "string"){
            // Error
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            saveDatosTabla(respuesta.data);
            if(respuesta.data.length > 0){
                saveTxtHeader(respuesta.data[0].txtheader)
            }
        }                
        saveLoading(false);
    }

    // Ejecutar
    const ejecutar = (rowData, idTabla) => {       
        swal({
            title: confPantalla.checklist.controles.swal.ejecutar.titulo.title,
            text: confPantalla.checklist.controles.swal.ejecutar.texto.title,
            icon: "warning",
            buttons: {
                cancel : {text: confPantalla.checklist.controles.swal.ejecutar.cancelar.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                confirm : {text: confPantalla.checklist.controles.swal.ejecutar.confirmar.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}                
            } 
        }).then(confirmacion => {
            if( confirmacion == true ){
                ejecutarDoc(rowData);            
            }
        })
    }

    const ejecutarDoc = async(rowData) => {
        saveLoading(true);       
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("id", rowData.id_poe);
        data.append("id_centro", rowData.id_centro);
        data.append("id_linea", rowData.id);
              
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.checklist.controles.planificados.createdocplantrapr, data);

        if(typeof respuesta.data == "string"){
            // Error
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
           // Lanzamos el wizardchecklist
           // propiedades.history.push("/checklistwiz/"+respuesta.data);
           window.open("/checklistwiz/"+respuesta.data);       
        }        
        saveLoading(false);
    }

    // Agentes Modal
    const getAgentes = async (rowData) => {
        saveShowModal(true);
        saveLoadingAgentes(true);
        saveDatosTableAgentes([]);
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("id_linea", rowData.id);
        data.append("id_roe", rowData.id_roe);
                
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.checklist.controles.planificados.modal.getagentesmodal, data);
        if(typeof respuesta.data == "string"){
            // Error
            saveShowModal(false);
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            saveDatosTableAgentes(respuesta.data);       
        }
    }
    

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                            titulo={ confPantalla.browserpr.section.titulo } 
                            icono={ confPantalla.browserpr.section.icono } 
                            col={ confPantalla.browserpr.section.col } 
                            idPrin={ formularioPrin.id }
                            idHijo={ formularioHijo.id }
                        >
                            {
                                datosTabla.length > 0
                                ?
                                    <div class="alert alert-secondary text-center" role="alert">
                                        <h3>{txtHeader}</h3>
                                        <h4>{fecIniParam} / {fecFinParam}</h4>
                                    </div>
                                :
                                    null
                            }
                            <MiDataTableBrowserPr
                                estadoForm= { "" }
                                formulario={ {id:0} }
                                id={"tablabrowserpr"}
                                columnas={confPantalla.browserpr.controles.datatable.columns}
                                data={datosTabla}
                                linkColumns={confPantalla.browserpr.controles.datatable.linkColumns}
                                buttons={confPantalla.browserpr.controles.datatable.buttons}                                                        
                                ordering={confPantalla.browserpr.controles.datatable.ordering}
                                order={confPantalla.browserpr.controles.datatable.order}
                                searching={confPantalla.browserpr.controles.datatable.searching}
                                paging={confPantalla.browserpr.controles.datatable.paging}
                                select={confPantalla.browserpr.controles.datatable.select}
                                pagelength={confPantalla.browserpr.controles.datatable.pagelength}                                                           
                                columnactions={confPantalla.browserpr.controles.datatable.columnactions.title}
                                traduction={confPantalla.config.trad.components.datatable} 
                                loadingTable={loading}
                                saveLoading={saveLoading}
                                confPantalla={confPantalla}
                                propiedades={props}
                                ejecutar={ejecutar}
                                getAgentes={getAgentes}                                                
                            /> 
                      
                        </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
            {
            showModal
            ?
                <Modal
                    show={showModal}
                    onHide={ () => saveShowModal(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w"                                             
                > 
                        <Modal.Header closeButton>
                            {
                                datosTableAgentes.length > 0
                                ?
                                    <h4>{confPantalla.checklist.controles.planificados.modal.header.title} {datosTableAgentes[0]["tot_fir"]}/{datosTableAgentes[0]["tot_pre"]}</h4>
                                :
                                    <h4>{confPantalla.checklist.controles.planificados.modal.header.title}</h4>

                            }
                        </Modal.Header>
                        <Modal.Body>
                            <MiDataTable 
                                estadoForm= { "" }
                                formulario={ {id:0} }
                                id={"tablaAgentes"}
                                columnas={confPantalla.checklist.controles.planificados.modal.tabla.columns}
                                data={datosTableAgentes}
                                linkColumns={confPantalla.checklist.controles.planificados.modal.tabla.linkColumns}
                                buttons={confPantalla.checklist.controles.planificados.modal.tabla.buttons}
                                ordering={confPantalla.checklist.controles.planificados.modal.tabla.ordering}
                                order={confPantalla.checklist.controles.planificados.modal.tabla.order}
                                searching={confPantalla.checklist.controles.planificados.modal.tabla.searching}
                                paging={confPantalla.checklist.controles.planificados.modal.tabla.paging}
                                select={confPantalla.checklist.controles.planificados.modal.tabla.select}
                                pagelength={confPantalla.checklist.controles.planificados.modal.tabla.pagelength}
                                saveLoadingTable={ saveLoadingAgentes }
                                loadingTable={loading}
                                columnactions={confPantalla.checklist.controles.planificados.modal.tabla.columnactions.title}
                                traduction={confPantalla.config.trad.components.datatable}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='text-right'>
                                <button 
                                        className="btn btn-danger mt-1"
                                        onClick={ () => saveShowModal(false) }                                        
                                >
                                    {confPantalla.checklist.controles.planificados.modal.btncerrar.title}
                                </button>
                            </div>
                        </Modal.Footer>
                </Modal>
            :
                null
            }
        </div>
        <Footer />        
        </div>

    );

}
export default BrowserPr