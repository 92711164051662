import React, { useEffect, useContext, useState } from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import LoadingPage from '../componentes/ui/LoadingPage';

import { LoginContext } from '../hooks/context/LoginContext';

function NotFound(props){

  
    const { validateSesion, confApp, getConfApp, saveConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp);
    const [ loadedConf, saveLoadedConf ] = useState(false);


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{
                    saveLoadedConf(true);   
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          
       
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])

    return(
        <Fragment>
            {
                loadedConf === true && confPantalla !== undefined
                ?
                    <div className="not-found" >
                            <section className="content">
                                <div className="error-page">
                                    <h2 className="headline text-warning"> 404</h2>    
                                    <div className="error-content">
                                        <h3><i className="fas fa-exclamation-triangle text-warning"></i>{confPantalla.config.trad.components.notfound.opps.title}</h3>        
                                        <p>{confPantalla.config.trad.components.notfound.text.title}
                                             <Link to={confPantalla.config.trad.components.notfound.urllink.link} className="mt-3"> {confPantalla.config.trad.components.notfound.textlink.title}</Link>
                                        </p>                 
                                    </div>
                                </div>
                            </section>
                    </div>
                :
                    <LoadingPage />
            }

        </Fragment>


    );

}
export default NotFound