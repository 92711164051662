import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';

import { alertService } from '../../services/alertservice';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, getTitle, getDecodedCookie } from '../../helpers/helperApp';

import { Fragment } from 'react';

function StockUbi(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{                     
                    document.title = getTitle(confPantalla.config.trad, confPantalla.stockubi.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        if( registrosPrin ){
                            if( registrosPrin.length === 0 ){                                    
                                saveFormularioPrin(confPantalla.stockubi.padre.modelform);
                            }
                        }                                                                            
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla, formularioHijo.id])


    const handleSearchStock = async(e, urls) => {

        e.preventDefault();
        saveLoadingTable(true);
        // preparamos los datos del formulario para enviar
        const data = new FormData(e.target);      
        const validacion = apiKey();
        data.append("validacion", validacion);

        // enviamos la petición
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+urls.submit,data);   
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+urls.submit, data, source.token);     
        if( typeof respuesta.data !== "string" ){
            saveRegistrosPrin(respuesta.data);

        }else if( typeof respuesta.data === "string" ){            
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
        } 
        saveLoadingTable(false);
    }

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                                titulo={ confPantalla.stockubi.section.titulo } 
                                icono={ confPantalla.stockubi.section.icono } 
                                col={ confPantalla.stockubi.section.col } 
                                idPrin={ formularioPrin.id }
                                idHijo={ formularioHijo.id }
                        >

                                        <Formulario 
                                                    estadoForm= { confPantalla.stockubi.padre.estadoform }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.stockubi.padre.btnform.new }
                                                    btnSave={ confPantalla.stockubi.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.stockubi.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.stockubi.padre.btnform.delete }
                                                    btnClone={ confPantalla.stockubi.padre.btnform.clone }
                                                    btnSearch={ confPantalla.stockubi.padre.btnform.search }
                                                    handleSubmit = { e => handleSearchStock(e, confPantalla.stockubi.padre.urls) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.stockubi.padre.modelform, true, confPantalla.stockubi.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.stockubi.padre.urls, confPantalla.stockubi.padre.modelform, confPantalla.stockubi.padre.tabla, confPantalla.stockubi.title) } 
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.stockubi.padre.urls, confPantalla.stockubi.hijo.urls, confPantalla.stockubi.padreshijos, confPantalla.stockubi.hijo.modelform, confPantalla.stockubi.padre.tabla, confPantalla.stockubi.title) } 

                                        >
                                  
                                        <FormRow clases="" >                                                                 
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockubi.padre.estadoform }
                                                val=         { formularioPrin.idubi  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockubi.padre.controles.idubi.id }    
                                                name=        { confPantalla.stockubi.padre.controles.idubi.name }   
                                                label=       { confPantalla.stockubi.padre.controles.idubi.label }    
                                                placeholder= { confPantalla.stockubi.padre.controles.idubi.placeholder }
                                                col=         { confPantalla.stockubi.padre.controles.idubi.col } 
                                                isClearable= { confPantalla.stockubi.padre.controles.idubi.isclearable }
                                                isSearchable={ confPantalla.stockubi.padre.controles.idubi.issearchable }
                                                dataType=    { confPantalla.stockubi.padre.controles.idubi.datatype }
                                                data=        { confPantalla.stockubi.padre.controles.idubi.data }
                                                required=    { confPantalla.stockubi.padre.controles.idubi.required } 
                                                disabled=  { confPantalla.stockubi.padre.controles.idubi.disabled }
                                                readOnly =   { confPantalla.stockubi.padre.controles.idubi.readonly }
                                                disablethisid={confPantalla.stockubi.padre.controles.idubi.disablethisid}
                                                disableids={confPantalla.stockubi.padre.controles.idubi.disableids}
                                                depends={ confPantalla.stockubi.padre.controles.idubi.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockubi.padre.estadoform }
                                                val=         { formularioPrin.filtro  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockubi.padre.controles.filtro.id }    
                                                name=        { confPantalla.stockubi.padre.controles.filtro.name }   
                                                label=       { confPantalla.stockubi.padre.controles.filtro.label }    
                                                placeholder= { confPantalla.stockubi.padre.controles.filtro.placeholder }
                                                col=         { confPantalla.stockubi.padre.controles.filtro.col } 
                                                isClearable= { confPantalla.stockubi.padre.controles.filtro.isclearable }
                                                isSearchable={ confPantalla.stockubi.padre.controles.filtro.issearchable }
                                                dataType=    { confPantalla.stockubi.padre.controles.filtro.datatype }
                                                data=        { confPantalla.stockubi.padre.controles.filtro.data }
                                                required=    { confPantalla.stockubi.padre.controles.filtro.required } 
                                                disabled=  { confPantalla.stockubi.padre.controles.filtro.disabled }
                                                readOnly =   { confPantalla.stockubi.padre.controles.filtro.readonly }
                                                disablethisid={confPantalla.stockubi.padre.controles.filtro.disablethisid}
                                                disableids={confPantalla.stockubi.padre.controles.filtro.disableids}
                                                depends={ confPantalla.stockubi.padre.controles.filtro.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />
                                        </FormRow>                                                  
                                                                                           
                                    </Formulario>
                                    <div style={{height:70, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                        {   loadReg ? 
                                            <Spinner animation="border" />
                                            :
                                            null
                                        }                                
                                        <Alert />
                                    </div>
                                    <MiDataTable 
                                        estadoForm= { confPantalla.stockubi.padre.estadoform }
                                        formulario={ {id:0} }
                                        id={"stockubi"}
                                        columnas={confPantalla.stockubi.padre.datatable.columns}
                                        data={registrosPrin}
                                        buttons={confPantalla.stockubi.padre.datatable.buttons}
                                        ordering={confPantalla.stockubi.padre.datatable.ordering}
                                        order={confPantalla.stockubi.padre.datatable.order}
                                        searching={confPantalla.stockubi.padre.datatable.searching}
                                        paging={confPantalla.stockubi.padre.datatable.paging}
                                        select={confPantalla.stockubi.padre.datatable.select}
                                        pagelength={confPantalla.stockubi.padre.datatable.pagelength}
                                        handleEditReg= { e => handleEditRegPrin(e, confPantalla.stockubi.padre.urls, confPantalla.stockubi.hijo.urls, confPantalla.stockubi.padreshijos, confPantalla.stockubi.hijo.modelform, confPantalla.stockubi.padre.tabla) }
                                        handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.stockubi.padre.urls, confPantalla.stockubi.padre.modelform, confPantalla.stockubi.padre.tabla, confPantalla.stockubi.title) }
                                        saveLoadingTable={ saveLoadingTable }
                                        loadingTable={loadingTable}
                                        columnactions={confPantalla.stockubi.padre.datatable.columnactions.title}
                                        linkColumns={confPantalla.stockubi.padre.datatable.linkColumns}
                                        traduction={confPantalla.config.trad.components.datatable}                                        
                                    />                                             

                                              
                                    
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default StockUbi