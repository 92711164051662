import React, { useState, useEffect, Fragment, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import axios from 'axios';

import { LoginContext } from '../../../hooks/context/LoginContext';

import ButtonOutline from '../../ui/ButtonOutline';
import FormInput from '../../formulario/FormInput';
import MiSelect from '../../formulario/MiSelect';
import FormRow from '../../formulario/FormRow';
import TextArea from '../../formulario/TextArea';
import Uplm from '../../formulario/Uplm/Uplm';
import CheckIos from '../../ui/CheckIos';
import { Spinner } from 'react-bootstrap';
import { alertService } from '../../../services/alertservice';
import swal from 'sweetalert';
import { Accordion, Card } from "react-bootstrap";
import MiDataTableMateriales from './MiDataTableMateriales';
import MiDataTable from '../../ui/MiDataTable';
import Modal from 'react-bootstrap/Modal';
import Button from '../../ui/Button';
import WizardBtns from '../WizardBtns';
import Firma from '../Firma';


import Cookies from 'universal-cookie';

import { MiReplaceAll, getFechaHoraActualSql, getIndexUrl, getApiUrl, axiosPost, setStockOptionsSelectUbi, getStockOptionsSelectUbi, encryptData, getDecodedCookie } from '../../../helpers/helperApp';

let materialesDataTable, backUpOptionsUbi;

function WizardMaterialesStepMateriales(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();

    const { materiales, saveMateriales, cabecera, paso, pasos, saveCurrentStep, controles, estadoForm, confPantalla, saveLoadReg, propiedades, saveRegAnterior, resumenHerramientas, resumenMateriales, loadingForms, saveLoadingForms, soloHerramientas, adjuntosCabecera, updatemateriales, nomEquipo, lineasFirmas, bbdd, btnfilubi } = props;

    const [ fecDocIn, saveFecDocIn ] = useState(getFechaHoraActualSql());
    const [ acordeon, saveAcordeon ] = useState(null);  
    const [ optionsUbi, saveOptionsUbi ] = useState([]); 
    const [ loadedSelect, saveLoadedSelect ] = useState(false);
    const [ showModalDividir, saveShowModalDividir ] = useState(false);
    const [ unidadModal, saveUnidadModal ] = useState(0); 
    const [ cantidadModal, saveCantidadModal ] = useState(0);
    const [ ubicacionModal, saveUbicacionModal ] = useState(0);
    const [ idLineaDividir, saveIdLineaDividir ] = useState(0);
    const [ accionCrearDividir, saveAccionCrearDividir ] = useState(null);
    const [ showSpinnerModal, saveShowSpinnerModal ] = useState(false);

    const [ showModal, saveShowModal ] = useState(false);
    const [ fec1, saveFec1 ] = useState("");
    const [ fec3, saveFec3 ] = useState("");
    const [ estableciendoFechas, saveEstableciendoFechas ] = useState();
    const [ btnAceptar, saveBtnAceptar ] = useState(false);
    const [ btnRevisar, saveBtnRevisar ] = useState(false);
    const [ docFinalizado, saveDocFinalizado ] = useState(false);
    const [ errorFec, saveErrorFec ] = useState(false);
    const [ showFirmas, saveShowFirmas ] = useState();

    const [ loadingSelectUbi ,saveLoadingSelectUbi ] = useState(false);
    
    saveCurrentStep(props.currentStep-1);

    const { apiKey, menuMode, btnWizPos } = useContext(LoginContext);

    useEffect( () => {         
            saveLoadingForms(false);
            
            initOptionsSelect();
            
            return () =>{    
                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
            } 
    },[])

    const initOptionsSelect = async() => {

        // Combo ubicaciones para la tabla de edicion
        const ubi = controles.wizardmateriales.idubi;
        await loadOptionsCbo(ubi.datatype,ubi.data, optionsUbi, saveOptionsUbi);
    }

    const loadOptionsCbo = async (fuente, opcion, options, saveOptions) =>{
        const data = new FormData();
        data.append("validacion", apiKey());            
            
        if( bbdd ){
            bbdd = await encryptData(bbdd);
            data.append('bd', bbdd);
        }
        const idCookie = await encryptData(cookies.get('id'));
        data.append('ua', idCookie);
        const nivelCookie = await encryptData(cookies.get('nivel'));
        data.append('na', nivelCookie);
                    
        // Encriptamos el tag sql. 
        opcion = await encryptData(opcion);
        data.append(fuente, opcion);                   

        if( options.length == 0 ){
            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }
            const respuesta = await axios.post(url, data);                    
            saveOptions(respuesta.data);
            backUpOptionsUbi = respuesta.data;
        }

        saveLoadedSelect(true);
    }
    
    // Preparamos los datos para el datatable de edición de los materiales
    materialesDataTable = materiales.map(mat => {

        mat.linea["respuesta"] = mat.respuesta;
        let nombre = "";
        if( getDecodedCookie("idioma") == "es" ){
            mat.linea.txt1 == "" ? nombre = mat.linea.nom1 : nombre = mat.linea.txt1+" - "+mat.linea.nom1;
        }else if( getDecodedCookie("idioma") == "en" ){
            mat.linea.txt1 == "" ? nombre = mat.linea.nom1_en : nombre = mat.linea.txt1+" - "+mat.linea.nom1_en;
        }else{
            mat.linea.txt1 == "" ? nombre = mat.linea.nom1+" - "+mat.linea.nom1_en : nombre = mat.linea.txt1+" - "+mat.linea.nom1+" - "+mat.linea.nom1_en; 
        }
        mat.linea["repuesto"] = nombre;

        return mat.linea;
    })
    
    const crearYDividir = async (e, rowData, accion) => {
        // accion = 0(dividir); accion = 1(crear)
        saveIdLineaDividir(rowData.id);
        saveAccionCrearDividir(accion);
        // accion == 0 ? saveUnidadModal(rowData.iduni) : saveUnidadModal(0);
        if( accion == 0 ){
            saveUnidadModal(rowData.iduni)
            const respuesta = await getStockOptionsSelectUbi(backUpOptionsUbi, rowData.iduni, saveLoadingSelectUbi);
            saveOptionsUbi(respuesta);    
        }else{
            saveOptionsUbi(backUpOptionsUbi); 
            saveUnidadModal(0)
        }        
        saveCantidadModal(0);
        saveUbicacionModal(0);
        saveShowModalDividir(true);

    }

    const actionAceptarDividir = async () => {

        saveShowSpinnerModal(true);

        const data = new FormData();
        data.append("validacion", apiKey());  
        data.append("id", idLineaDividir);
        data.append("accionwizardmateriales", accionCrearDividir);
        data.append("iduniwizard", unidadModal);
        data.append("cantidadwizard", cantidadModal);
        data.append("idubiwizard", ubicacionModal);  
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+"/lineastrab/cloneLineasTrab", data, source2.token);
        
        if(respuesta.data.id == idLineaDividir){
            const data2 = new FormData();
            data2.append("validacion", apiKey());  
            data2.append("idLinea", respuesta.data.idClon);
            const respuesta2 = await axiosPost(getApiUrl(window.location.origin)+"/lineastrab/getDataNewLine", data2, source3.token);            
            // Clonado correcto            
            const auxMateriales = respuesta2.data[0];
            let auxStateMateriales = {
                "linea"     : auxMateriales,
                "respuesta" : {data : [{val:auxMateriales.respuesta.data.val[0]}]}
            }
            saveMateriales(materiales => [...materiales, auxStateMateriales]);
            materialesDataTable.push(auxMateriales)
    
            let tabla = $('#inputmateriales'+paso).DataTable();
            tabla.row.add(auxMateriales).draw();        
        }else{
            // Error
        }

        saveShowModalDividir(false)
        saveShowSpinnerModal(false);

    }

    const checkDisabledBtnAceptar = () => {
        let disabled;
        if( accionCrearDividir == 0 ){
            // Dividir
            if( ubicacionModal == 0 ){
                disabled = true;
            }
        }else if( accionCrearDividir == 1 ){
            // Crear
            if( unidadModal == 0 ||ubicacionModal == 0 ){
                disabled = true;
            }
        }

        return disabled;
    }

    const handleSelectUnidadModal = async (selectedItem, nameOfComponent) => {
        // Añadimos la cantidad a los option del select de ubicaciones del modal
        let iduni = selectedItem ? selectedItem.value : 0;
        const respuesta = await getStockOptionsSelectUbi(backUpOptionsUbi, iduni, saveLoadingSelectUbi);
        saveOptionsUbi(respuesta);

        if( selectedItem ){
            saveUnidadModal(selectedItem.value);
            iduni = selectedItem.value;
        }else{
            saveUnidadModal(0);
            iduni = 0;
        }
                
    }

    const handleInputCantidadModal = (e) => {
        const { name, value } = e.target;
            saveCantidadModal(value);
    }    

    const handleSelectUbicacionModal = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveUbicacionModal(selectedItem.value);  
        }else{
            saveUbicacionModal(0);
        }
    }
    

    const next = async () => { 

        saveLoadReg(true);

        let formValido = true;
        const data = new FormData();
        const inputs = document.querySelectorAll(".input-mat");
        const selects = document.querySelectorAll(".select-mat");        

        for (var input of inputs) {  
            if( input.required && input.value == "" ){
                input.classList.add("errorinput");
                input.focus();
                formValido = false;
                break
            }else{
                // Obtenemos la configuración de la línea para enviar por post
                // const material = materiales.filter((mat) => mat.linea.id == input.name )
                const material = materialesDataTable.filter((mat) => mat.id == input.name )
                input.classList.remove("errorinput");
                // let aux = { "value" : input.value, "confLinea" : material[0].respuesta.data[0].val }
                let aux = { "value" : input.value, "confLinea" : material[0].respuesta.data[0].val }
                data.append(input.name, JSON.stringify(aux));
            }
        }
        let contador = 0;
        for (var select of selects) {
            // Si no existe esta propiedad quiere decir que los select están en SOLO LECTURA y no tenemos acceso a sus propiedades. No los enviamos por post y controlamos en el backend que no se borre/modifique ningún dato de estos select QUE NO ENVIAMOS POR POST.
            if( select.children[2] ){
            // Si existe esta propiedad quiere decir que los select NO ESTAN EN SOLO LECTURA                
                // if( select.children[2].required && select.children[2].value == "" ){
                if( select.classList.contains("required") && select.children[2].value == "" ){
                    select.classList.add("errorinput");
                    // El select al focus no le hace caso con lo cual nos posicionamos en el input de la misma línea para que el usuario vea el select en rojo si hay algún error.
                    inputs[contador].focus();
                    // select.focus();
                    formValido = false;
                    break
                }else{
                    // Obtenemos la configuración de la línea para enviar por post
                    // const material = materiales.filter((mat) => mat.linea.id == select.children[2].name )
                    const material = materialesDataTable.filter((mat) => mat.id == select.children[2].name )
                    select.classList.remove("errorinput");
                    let aux = { "value" : select.children[2].value, "confLinea" : material[0].respuesta.data[0].val }
                    data.append(select.children[2].name+"-idubi", JSON.stringify(aux));
                }
            }
            contador++;
        }
        if( formValido ){
            data.append("validacion", apiKey());  
            data.append("idPadre", materiales[0].linea.iddoc);    
            data.append("fecIn", fecDocIn);
            data.append("fecOut", getFechaHoraActualSql());        
            // if( paso == pasos.length ){
            if( paso == pasos ){
                data.append("ultimoPaso", true);
            }

            // enviamos la petición
            // const respuesta = await axios.post(getApiUrl(window.location.origin)+updatemateriales,data);
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+updatemateriales, data, source.token);            

            if( typeof respuesta.data === "string" ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.config.trad.components.wizard.saveerror.title}`, {autoClose: true});
            }else{
                // Guardamos la fecha de salida que es la de entrada del siguiente paso
                let aux = { "fec3wizard" : getFechaHoraActualSql() }
                saveRegAnterior(aux)

                // Pasamos a la siguiente línea
                if(paso < pasos){
                    props.nextStep();
                }
    
                // Si completó todos los pasos lo redirigimos al inicio
                // if( paso == pasos.length ){
                if( paso == pasos){
                     // Modificación 14-12-2022: Al finalizar el wizard preguntar las fechas de inicio y fin
                     saveFec1("");
                     saveFec3("");
                     saveBtnAceptar(false);
                     saveBtnRevisar(false);
                     saveDocFinalizado(false);
                     saveShowModal(true);                                     
                }
            }
    
            saveLoadReg(false);

        }
        saveLoadReg(false);
    }

    const actionsAfterSign = () => {
        propiedades.history.push(getIndexUrl(menuMode));
    }

    const accionModal = async() => {

        let fecha1 = fec1;
        let fecha3 = fec3;
        let sinFechas = false;
        let continuar = false;

        if( fecha3 != "" && fecha3 < fecha1 ){
            saveErrorFec(true);
        }else{
            if( fecha1 == "" && fecha3 == "" ){
                sinFechas = true;
            }
            if( sinFechas == false ){
                if( fecha1 == "" && fecha3 != "" ){
                    fecha1 = fecha3;
                }
                if( fecha3 == "" && fecha1 != "" ){
                    fecha3 = fecha1;
                }
            }
            saveEstableciendoFechas(true);
    
            if( sinFechas == false ){
                const data = new FormData();
                data.append("validacion", apiKey());
                data.append("iddoc", cabecera.id);
                data.append("fec1", fecha1);
                data.append("fec3", fecha3);
                const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.documentostrab.hijo.controles.modalwizard.action, data, source2.token);
                if( respuesta.data == cabecera.id ){
                    continuar = true;
                }else if(typeof respuesta.data == "string"){
                    alertService.error(`${respuesta.data}`, {autoClose: false});
                }
            }
    
            saveEstableciendoFechas(false);
            // saveShowModal(false);
    
            if( sinFechas == true || continuar == true ){
                saveBtnAceptar(true);
                saveBtnRevisar(true);
                saveDocFinalizado(true);            
            }
        }


    }

    const accionBtnRevisarModal = (e) => {
        saveShowModal(false);
    }

    const accionBtnAceptarModal = (e) => {
        if( lineasFirmas.length > 0 ){
            saveShowModal(false);
            saveShowFirmas(true);        
        }else{
            propiedades.history.push(getIndexUrl(menuMode));
        }
    }

    const handleInputFecModal = (e, saveFec) => {
        const { name, value } = e.target;
        saveFec(value)       
    }

    const previous = async () => {        
        props.previousStep();
    }

    const toogleAcordeon = (id) => {

        if( acordeon == id ){
            saveAcordeon(null);
        }else{
            saveAcordeon(id);
        }                
    }

    return(
        <div
            currentStep={paso}                        
        >
        {
            loadingForms 
            ?
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            :
             null
        }
            {
                materiales.length > 0
                ?
                    <Fragment>
                        {
                            btnWizPos == "top" || btnWizPos == "both" 
                            ?
                                <WizardBtns 
                                    accionBtnExit={accionBtnAceptarModal}
                                    accionBtnPrev={previous}
                                    accionBtnNext={next}
                                    disabledBtnExit={false}
                                    disabledBtnPrev={props.currentStep == 1 ? true : false}
                                    disabledBtnNext={false}
                                />
                            :
                                null
                        }
                        {
                            !soloHerramientas
                            ?
                                <div className="alert alert-dark text-center" style={{borderColor: "#ccc"}} role="alert">
                                    <h5>{ controles.wizard.acordeon.materiales.title }</h5> 
                                    <h5>{ nomEquipo }</h5>                                  
                                </div>
                            :
                                null
                        }
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0')} className={ acordeon == "0" ? "activeAcordeon" : "" } >
                                    {
                                        getDecodedCookie("idioma") == "es"
                                        ?
                                                cabecera.nom1
                                            :
                                                getDecodedCookie("idioma") == "en"
                                                ?
                                                    cabecera.nom1_en
                                                :
                                                    cabecera.nom1.label+" - "+cabecera.nom1_en.label
                                    }
                                    {
                                        acordeon == "0"
                                        ?
                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                        :
                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                    }                                            
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                    <Card.Body>
                                        {
                                            getDecodedCookie("idioma") == "es"
                                            ?
                                                <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas}</div>
                                                :
                                                    getDecodedCookie("idioma") == "en"
                                                    ?
                                                        <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas_en}</div>
                                                    :
                                                        <div style={{whiteSpace: "pre-wrap"}}>{cabecera.notas} <br/> {cabecera.notas_en}</div>
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toogleAcordeon('1')} className={ acordeon == "1" ? "activeAcordeon" : "" } >
                                            { controles.wizard.acordeon.herramientas.title } - {"("+resumenHerramientas.length+")"}
                                            {
                                                acordeon == "1"
                                                ?
                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                :
                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                            }                                            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1" className={"slowAcordeon"} >
                                            <Card.Body>                                               
                                                <MiDataTable 
                                                    formulario={{id:0}}
                                                    estadoForm= { "" }
                                                    id={"herramientas"+paso}
                                                    columnas={[{"title":controles.wizardmateriales.datatableherramientas.columnas.title, "data": "html"}]}
                                                    data={resumenHerramientas}
                                                    buttons={true}
                                                    ordering={true}
                                                    order={[0,"asc"]}
                                                    searching={true}
                                                    paging={true}
                                                    select={false}
                                                    pagelength={25}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>                     
                                <div className="col-lg-4 col-md-12">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => toogleAcordeon('2')} className={ acordeon == "2" ? "activeAcordeon" : "" } >
                                        { controles.wizard.acordeon.materiales.title } - {"("+resumenMateriales.length+")"}
                                        {
                                            acordeon == "2"
                                            ?
                                                <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                            :
                                                <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                        }
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2" className={"slowAcordeon"} >
                                            <Card.Body>
                                                <MiDataTable 
                                                    formulario={{id:0}}
                                                    estadoForm= { "" }
                                                    id={"materiales"+paso}
                                                    columnas={[{"title":controles.wizardmateriales.datatablemateriales.columnas.title, "data": "html"}]}
                                                    data={resumenMateriales}
                                                    buttons={true}
                                                    ordering={true}
                                                    order={[0,"asc"]}
                                                    searching={true}
                                                    paging={true}
                                                    select={false}
                                                    pagelength={25}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="3" onClick={() => toogleAcordeon('3')} className={ acordeon == "3" ? "activeAcordeon" : "" } >
                                            { controles.wizard.acordeon.adjuntos.title } - {"("+adjuntosCabecera.length+")"}
                                            {
                                                acordeon == "3"
                                                ?
                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                :
                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                            }                                            
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="3" className={"slowAcordeon"} >
                                            <Card.Body>                                               
                                                <MiDataTable 
                                                    formulario={{id:0}}
                                                    estadoForm= { "" }
                                                    id={"adjuntos"+paso}
                                                    columnas={[{"title":controles.wizardmateriales.datatableadjuntos.columnas.title, "data": "html"}]}
                                                    data={adjuntosCabecera}
                                                    buttons={true}
                                                    ordering={true}
                                                    order={[0,"asc"]}
                                                    searching={true}
                                                    paging={true}
                                                    select={false}
                                                    pagelength={25}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </div>
                            </div>
                        </Accordion>
                    </Fragment>
                :
                    null
            } 
            {
                soloHerramientas
                ?
                    <h1 className="text-center">{controles.wizard.nolinescheck.title}</h1>
                :
                    null
            }
            {
                materiales.length > 0
                ?
                    <Fragment>
                        {
                            <div className="">                            
                            {
                                loadedSelect == true
                                ?
                                    <MiDataTableMateriales 
                                        formulario={{id:0}}
                                        estadoForm= { "" }
                                        id={"inputmateriales"+paso}
                                        columnas={controles.wizardmateriales.datatableedimateriales.columns}
                                        datos={materialesDataTable}
                                        buttons={controles.wizardmateriales.datatableedimateriales.buttons}
                                        ordering={controles.wizardmateriales.datatableedimateriales.ordering}
                                        order={controles.wizardmateriales.datatableedimateriales.order}
                                        searching={controles.wizardmateriales.datatableedimateriales.searching}
                                        paging={controles.wizardmateriales.datatableedimateriales.paging}
                                        select={controles.wizardmateriales.datatableedimateriales.select}
                                        pagelength={controles.wizardmateriales.datatableedimateriales.pagelength}
                                        traduction={confPantalla.config.trad.components.datatable}
                                        inputCantidad={confPantalla.documentostrab.hijo.controles.num1}
                                        selectubi={confPantalla.documentostrab.hijo.controles.idubi}
                                        optionselectubi={optionsUbi}
                                        crearYDividir={crearYDividir}
                                        getStockOptionsSelectUbi={getStockOptionsSelectUbi} 
                                    />
                                :
                                    <div className="text-center">
                                        <Spinner animation="border" />
                                    </div>
                            }
                            </div>
                            
                        }                       
                        {
                            btnWizPos == "bottom" || btnWizPos == "both" 
                            ?
                                <WizardBtns 
                                    accionBtnExit={accionBtnAceptarModal}
                                    accionBtnPrev={previous}
                                    accionBtnNext={next}
                                    disabledBtnExit={false}
                                    disabledBtnPrev={props.currentStep == 1 ? true : false}
                                    disabledBtnNext={false}
                                />
                            :
                                null
                        }
                    </Fragment>
                :
                    btnWizPos == "bottom" || btnWizPos == "both" 
                    ?
                        <WizardBtns 
                            accionBtnExit={accionBtnAceptarModal}
                            accionBtnPrev={previous}
                            accionBtnNext={next}
                            disabledBtnExit={false}
                            disabledBtnPrev={props.currentStep == 1 ? true : false}
                            disabledBtnNext={false}
                        />
                    :
                        null
            }
            {
                showFirmas && lineasFirmas.length > 0
                ?
                    <Firma
                        cabecera={cabecera}
                        lineasFirmas={lineasFirmas}
                        controles={ controles.wizardmateriales }
                        show={showFirmas}
                        saveShow={saveShowFirmas}
                        traduction={confPantalla.config.trad.components.firma}
                        functionAfter={actionsAfterSign}
                    />
                :
                    null
            }
            <Modal
                    show={showModalDividir}
                    onHide={ () => saveShowModalDividir(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w"                                             
            >
            <Modal.Header closeButton>
                {
                    accionCrearDividir == 0
                    ?
                        <h3>{controles.wizardmateriales.modal.dividir.title}</h3>
                    :
                    accionCrearDividir == 1
                    ?
                        <h3>{controles.wizardmateriales.modal.crear.title}</h3>
                    :
                        null
                }                    
            </Modal.Header> 
            <Modal.Body>
                <FormRow>
                    <MiSelect  
                        estadoForm=  { "" }
                        val=         { unidadModal }   
                        loading=     { false }
                        id=          { controles.wizardmateriales.iduni.id }    
                        name=        { controles.wizardmateriales.iduni.name }   
                        label=       { controles.wizardmateriales.iduni.label }    
                        placeholder= { controles.wizardmateriales.iduni.placeholder }
                        col=         { 5 } 
                        isClearable= { controles.wizardmateriales.iduni.isclearable }
                        isSearchable={ controles.wizardmateriales.iduni.issearchable }
                        dataType=    { controles.wizardmateriales.iduni.datatype }
                        data=        { controles.wizardmateriales.iduni.data }
                        required=    { false } 
                        disabled=  { false }
                        readOnly =   { accionCrearDividir == 0 ? "readonly" : false }                        
                        formulario= { {id:0} }   
                        change= { (selectedItem, nameOfComponent) => handleSelectUnidadModal(selectedItem, nameOfComponent) }
                    />
                    <FormInput                                                        
                        estadoForm= { "" }
                        value=      { cantidadModal }
                        loading=    { false }
                        id=         { confPantalla.documentostrab.hijo.controles.num1.id }   
                        type=       { confPantalla.documentostrab.hijo.controles.num1.type }  
                        name=       { confPantalla.documentostrab.hijo.controles.num1.name }   
                        label=      { confPantalla.documentostrab.hijo.controles.num1.label }                                                       
                        placeholder={ confPantalla.documentostrab.hijo.controles.num1.placeholder }    
                        col=        { 2 } 
                        required=   { false }
                        readonly=   { false }                                                
                        disabled=   { false }
                        change=     { e => handleInputCantidadModal(e) }
                        onBlur=     {null}
                        alignRight= { true } 
                    />
                    <MiSelect  
                        estadoForm=  { "" }
                        val=         { ubicacionModal }   
                        loading=     { loadingSelectUbi }
                        id=          { controles.wizardmateriales.idubi.id }    
                        name=        { controles.wizardmateriales.idubi.name }   
                        label=       { controles.wizardmateriales.idubi.label }    
                        placeholder= { controles.wizardmateriales.idubi.placeholder }
                        col=         { 5 } 
                        isClearable= { controles.wizardmateriales.idubi.isclearable }
                        isSearchable={ controles.wizardmateriales.idubi.issearchable }
                        dataType=    { "codigo" }
                        data=        { optionsUbi }
                        required=    { false } 
                        disabled=  { loadingSelectUbi ? true : false }
                        readOnly =   { false }                        
                        formulario= { {id:0} }   
                        change= { (selectedItem, nameOfComponent) => handleSelectUbicacionModal(selectedItem, nameOfComponent) }
                        modifyOptions = { setStockOptionsSelectUbi }
                        selectinformes={true}
                    />
                </FormRow>
                {
                    showSpinnerModal == true
                    ?
                        <div className="text-center">
                            <Spinner animation="border" />
                        </div>
                    :
                        null
                }
                <div className='text-right'>
                            <button 
                                className="btn btn-success"
                                onClick={ e => actionAceptarDividir() }
                                disabled={checkDisabledBtnAceptar()}                                        
                            >
                                {controles.wizardmateriales.modal.btnaceptar.title}
                            </button>                                                                                         
                        </div>
            </Modal.Body>
        </Modal>                      
        <Modal
                show={showModal}
                onHide={ () => saveShowModal(false)}                            
                aria-labelledby="example-custom-modal-styling-title"
                centered={true}                    
                dialogClassName="modal-90w"                                             
            > 
                <Modal.Header closeButton>
                    {
                        !docFinalizado
                        ?
                            <h4>{confPantalla.documentostrab.hijo.controles.modalwizard.titulo.title}</h4>
                        :
                            null
                    }
                </Modal.Header>                         
                <Modal.Body>
                    <FormRow> 
                        {
                            !docFinalizado
                            ?
                                <Fragment>

                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { fec1 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec1.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.required }
                                        readonly=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.readonly }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec1.disabled }
                                        change=     { e => handleInputFecModal(e,saveFec1) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { cabecera.fec2 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec2.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.required }
                                        readonly=   { true }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec2.disabled }
                                        change=     {null}
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { fec3 }
                                        loading=    { false }
                                        id=         { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.id }   
                                        type=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.type }  
                                        name=       { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.name }   
                                        label=      { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.label }                                                       
                                        placeholder={ confPantalla.documentostrab.hijo.controles.modalwizard.fec3.placeholder }    
                                        col=        { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.col } 
                                        required=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.required }
                                        readonly=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.readonly }                                                
                                        disabled=   { confPantalla.documentostrab.hijo.controles.modalwizard.fec3.disabled }
                                        change=     { e => handleInputFecModal(e,saveFec3) }
                                        onBlur=     {null} 
                                    />
                                </Fragment>
                            :
                                null
                        }                     
                    </FormRow>
                    {
                        errorFec
                        ?
                            <div class="alert alert-danger text-center" role="alert">
                                {confPantalla.config.trad.components.wizard.fecerror.title}
                            </div>
                        :
                            null
                    }
                    {
                        estableciendoFechas
                        ?
                            <div className="text-center">
                                <Spinner animation="border" />
                            </div>                    
                        :
                            null
                    }
                    {
                        !docFinalizado
                        ?
                            <Button 
                                clases={ "col-12 mt-2" }
                                color={ "success" }  
                                type="button" 
                                icono={ "" }   
                                id="btnsetmodal"     
                                texto={ confPantalla.documentostrab.hijo.controles.modalwizard.btntitle.title }   
                                accion={ accionModal } 
                                disabled={ false }
                            />
                        :
                            null
                    }
                    {
                        docFinalizado
                        ?
                            <div className='flex-center' style={{flexDirection: "column", alignItems: "center"}}>
                                <p>
                                    <i className="fal fa-check-circle fa-4x" style={{color : "#28a745"}}></i>
                                </p>
                                <h3 style={{fontWeight: "lighter", fontStyle: "italic"}}>
                                    {confPantalla.documentostrab.hijo.controles.modalwizard.txtfin.title}
                                </h3>
                            </div>
                        :
                            null
                    }
                    <div className="text-right"> 
                    {
                        btnRevisar
                        ?
                            <button 
                                    className="swal-button swal-button--danger btn btn-flat mr-1 mt-1 btn-outline-danger"
                                    onClick={ e => accionBtnRevisarModal(e) }                                        
                            >
                                {confPantalla.documentostrab.hijo.controles.modalwizard.btnRevisar.title}
                            </button>
                        :
                            null
                    }
                    {
                        btnAceptar
                        ?
                            <button 
                                    className="swal-button swal-button--success btn btn-flat mr-1 mt-1 btn-outline-success"
                                    onClick={ e => accionBtnAceptarModal(e) }                                        
                            >
                                {confPantalla.documentostrab.hijo.controles.modalwizard.btnAceptar.title}
                            </button>                              
                        :
                            null
                    }                      
                    </div>                                                                        
                </Modal.Body>                            
            </Modal>
        </div>
    )
}

export default WizardMaterialesStepMateriales;