import React, { useState, useContext, useEffect, Fragment } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../helpers/FixRequiredSelect";
import axios from 'axios';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import DataTable from 'datatables.net';
import FormInput from '../FormInput';
import MiSelect from '../MiSelect';
import ButtonOutline from '../../ui/ButtonOutline';
import Button from '../../ui/Button';
import { alertService } from '../../../services/alertservice';


import { LoginContext } from '../../../hooks/context/LoginContext';

import { getApiUrl, axiosPost, getDecodedCookie } from '../../../helpers/helperApp';

import MiDataTableUplmInfoSearch from './MiDataTableUplmInfoSearch';
import FormRow from '../FormRow';



function UplmInfoSearch(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const { apiKey } = useContext(LoginContext);

    const { id, uplminfo, traduction, filter } = props;

    const [datosTable, saveDatosTable] = useState([]);
    const [loading, saveLoading] = useState(false);
    const [descripcion, saveDescripcion] = useState("");
    const [tipAdj, saveTipAdj] = useState("");
    const [tipOpe, saveTipOpe] = useState("-1");

     
    useEffect( () => {   

        // Filtramos en la tabla de documentos
        let tablaDocus = $('#uplmInfo-'+id).DataTable();
        tablaDocus.search(filter).draw();
        
        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        }        

    },[])


    const getDataDataTable = async () =>{

        
        if( descripcion == "" && tipAdj == "" && getDecodedCookie('nivel') > 2 ){
            // Si NO eres administrador tienes que rellenar el campo búsqueda o el campo tipo de adjunto
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${traduction.uplminfosearch.errorfiltros.title}`, {autoClose: true});
        }else{
            saveLoading(true);

            const data = new FormData();
            data.append("validacion", apiKey());
            data.append("descripcion", descripcion);
            data.append("tipAdj", tipAdj);
            data.append("tipOpe", tipOpe);
            data.append("nivelUsu", getDecodedCookie('nivel'));
            data.append("tablas", JSON.stringify(uplminfo.tablas));
    
            // const res = await axios.post(getApiUrl(window.location.origin)+uplminfo.data.url, data);
            const res = await axiosPost(getApiUrl(window.location.origin)+uplminfo.data.url, data, source.token);
            
            saveLoading(false);
            
            saveDatosTable(res.data);
        }

  
    }

    const handleSearch = () => {
        getDataDataTable();
    }

    const handleInputDesc = (e) => {
        saveDescripcion(e.target.value)        
    }

    const handleSelectTipAdj = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveTipAdj(selectedItem.value)  
        }else{
            saveTipAdj("")
        }
    }

    const handleSelectTipOpe = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveTipOpe(selectedItem.value)  
        }else{
            saveTipOpe("")
        }
    }

    return(

        <Fragment>
            <FormRow>
                <FormInput                                                        
                    estadoForm= { "" }
                    value=      { descripcion }
                    loading=    { loading ? true : false }
                    id=         { uplminfo.descripcion.id }   
                    type=       { uplminfo.descripcion.type }  
                    name=       { uplminfo.descripcion.name }   
                    label=      { uplminfo.descripcion.label }                                                       
                    placeholder={ uplminfo.descripcion.placeholder }    
                    col=        { uplminfo.descripcion.col } 
                    required=   { uplminfo.descripcion.required }
                    readonly=   { uplminfo.descripcion.readonly }                                                
                    disabled=   { uplminfo.descripcion.disabled }
                    change=     { e => handleInputDesc(e) }
                    onBlur=     {null} 
                />
            </FormRow>
            <FormRow>
                <MiSelect  
                    estadoForm=  { "" }
                    val=         { tipAdj  }   
                    loading=     { loading ? true : false }
                    id=          { uplminfo.tipadj.id }    
                    name=        { uplminfo.tipadj.name }   
                    label=       { uplminfo.tipadj.label }    
                    placeholder= { uplminfo.tipadj.placeholder }
                    col=         { uplminfo.tipadj.col } 
                    isClearable= { uplminfo.tipadj.isclearable }
                    isSearchable={ uplminfo.tipadj.issearchable }
                    dataType=    { uplminfo.tipadj.datatype }
                    data=        { uplminfo.tipadj.data }                    
                    required=    { uplminfo.tipadj.required } 
                    disabled=    { uplminfo.tipadj.disabled }
                    readOnly =   { uplminfo.tipadj.readonly }
                    disablethisid={uplminfo.tipadj.disablethisid}
                    formulario = { {id:0}} 
                    change=      { handleSelectTipAdj }                                                    
                />
                <MiSelect  
                    estadoForm=  { "" }
                    val=         { tipOpe  }   
                    loading=     { loading ? true : false }
                    id=          { uplminfo.tipope.id }    
                    name=        { uplminfo.tipope.name }   
                    label=       { uplminfo.tipope.label }    
                    placeholder= { uplminfo.tipope.placeholder }
                    col=         { uplminfo.tipope.col } 
                    isClearable= { uplminfo.tipope.isclearable }
                    isSearchable={ uplminfo.tipope.issearchable }
                    dataType=    { uplminfo.tipope.datatype }
                    data=        { uplminfo.tipope.data }
                    extradata=   { uplminfo.tipope.extradata }
                    required=    { uplminfo.tipope.required } 
                    disabled=    { uplminfo.tipope.disabled }
                    readOnly =   { uplminfo.tipope.readonly }
                    disablethisid={uplminfo.tipope.disablethisid}
                    formulario = { {id:0}} 
                    change=      { handleSelectTipOpe }                                                    
                />
            </FormRow>
            {/* <ButtonOutline 
                clases={ "btn-block mb-2" }
                color={ "primary" }  
                type="button" 
                icono={ "fal fa-search" }   
                id=""     
                texto={ "" }   
                accion={ handleSearch } 
                disabled={ loading ? true : false }
            /> */}
            <Button 
                clases={ "btn-block mb-2" }
                color={ "primary" }  
                type="button" 
                icono={ "fal fa-search" }   
                id=""     
                texto={ "" }   
                accion={ handleSearch } 
                disabled={ loading ? true : false }
            />
            <MiDataTableUplmInfoSearch 
                id={ "uplmInfo-"+id }
                columnas={ uplminfo.tabla.columns }
                data={ datosTable }
                buttons={ uplminfo.tabla.buttons }
                ordering={ uplminfo.tabla.ordering }
                order={ uplminfo.tabla.order }
                searching={ uplminfo.tabla.searching }
                paging={ uplminfo.tabla.paging }
                select={ uplminfo.tabla.select }
                columnactions={ uplminfo.tabla.columnactions.title }
                traduction={ traduction.datatable }
                linkColumns = {uplminfo.tabla.linkColumns }
                pagelength = { uplminfo.tabla.pagelength }
                grouping={ uplminfo.tabla.grouping}
                loading={ loading }
                adjuntosplegados={ uplminfo.tabla.adjuntosplegados }
            />                 
        </Fragment>
    )
}

export default UplmInfoSearch;