import React from 'react';

import { SRLWrapper } from "simple-react-lightbox";

import { getIcono }from '../../../helpers/helperApp';


function UplmPendingList(props){
    
    const { id, adjuntos, saveAdjuntos, traduction } = props; 
    
    const _URL = window.URL || window.webkitURL;

    const handleDeleteTmpAdjunto = e => {
        e.preventDefault();
        const adjCancelar = e.target.dataset.id;

        //Actualizamos el state
        saveAdjuntos( adjuntos.filter(adjunto => adjunto.name != adjCancelar) );

    }

    return(
            <SRLWrapper>
                {
                    adjuntos.map((adj, confIco) => {
                        adj.id=id;
                        if( adj["type"].split("/")[0] === "image" ){
                            adj.src = _URL.createObjectURL(adj);
                            adj.url = adj.src.replace("blob:","");
                        }else{
                            let ext = adj["name"].split(".")[adj["name"].split(".").length-1];
                            confIco = getIcono(ext);
                        }
                        return(               
                            <div key={adj.name} className="cardUplm">
                                    <div className="containerImgUplm">
                                        <div className="row">
                                            <div className="cardUplmImg col-12">                                   
                                                { adj.src 
                                                        ? <div className="containerImg"><img className="imgUplm" src={adj.src} alt={adj.name} /></div>
                                                        : <div className="containerImg">
                                                                <i className={`${confIco["icono"]}`} style={{color:confIco["color"]}}></i>
                                                        </div>
                                                }                                       
                                            </div>

                                        </div>
                                    </div> 
                                    <div className="infoUplm">                                        
                                            <h5>{traduction.textarea.title}</h5>                                  
                                            <textarea placeholder={traduction.textarea.title} className="form-control fileNew" rows="4" defaultValue={adj.name} name={"fileNew**"+adj.name} ></textarea>
                                    
                                    </div>
                                    <div className="buttonsUplm">
                                        <button className="btn btn-warning cancel botonesCancelaradj" data-id={adj.name} onClick={ (e) => handleDeleteTmpAdjunto(e) } >
                                            <i className="fal fa-ban" data-id={adj.name} style={{marginRight:5}} ></i>
                                            <span data-id={adj.name}>{traduction.btncancel.title}</span>
                                        </button>
                                    </div>                                   
                                </div>
                        ) 
                    })
                } 
            </SRLWrapper>                               
    )
}

export default UplmPendingList;