import React, { useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../../helpers/MenuTreeHelper';


function MenuEncCheckList({ miNivel, activeMenuColor, confApp }){

    useEffect(() => {
        loadTree();
    },[])

    return(
              
        <nav className="mt-2">
                <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" data-animation-speed="600" role="menu" data-accordion="true">                               
                <li className="nav-item">
                    <NavLink to="/adjcentros" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.centrosadj.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.centrosadj.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/checklist" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.checklist.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.checklist.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>
                {/* <li className="nav-item">
                    <NavLink to="/documentosenc" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                    <i className={confApp.documentosenc.sidebarmenu.icono} style={{marginRight:5}} />
                    <p>{confApp.documentosenc.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/tipadj" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                        <i className={confApp.tipadj.sidebarmenu.icono} style={{marginRight:5}} />
                        <p>{confApp.tipadj.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>   */}
            </ul>
        </nav>                          

       

    )
}

export default MenuEncCheckList;