
import React,{ useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import { LoginContext } from '../../hooks/context/LoginContext';

import 'bootstrap/dist/css/bootstrap.min.css';

import Spinner from 'react-bootstrap/Spinner';
import LoadingPage from '../../componentes/ui/LoadingPage';


import useLogicForm from '../../hooks/useLogicForm';
import { Fragment } from 'react';

import { getIndexUrl, getApiUrl, axiosPost, getDecodedCookie } from '../../helpers/helperApp';

function ForgotPass(props){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** State para comprobar si está cargada la configuración general de aplicación  *****
    const [ isConfApp, saveIsConfApp ] = useState(true)

    const { logo, cookies, formResetPass, saveFormResetPass, getValidaciones, inicioValidaciones, backgroundImg, darkLightForm, getConfApp, confApp, noConfText, menuMode, loginTxtColor } = useContext(LoginContext); 

    // ***** Custom Hook - Lógica de los formularios *****
    const { loadReg, saveLoadReg } = useLogicForm();   


    const [okEnvioMail, saveOkEnvioMail] = useState(false);
    

    // ***** Funciones que se ejecutan al terminar  de cargar el componente *****
    useEffect(() => {
        // Si estamos logueado redirigimos al inicio
        if( getDecodedCookie('id') ){
            props.history.push(getIndexUrl(menuMode));
        }else{
            const validaciones = async () => {
                const res = await getValidaciones();
                await inicioValidaciones(res);                                               
                const conf = await getConfApp();
                if( conf.res.toLowerCase() === "ko".toLocaleLowerCase() ){
                    saveIsConfApp(false);                        
                }
                res.map(r => {
                    if(r.cod == 'login.recoverpass.allow'){                                
                        if(r.val == '0'){
                            // Página de registro desactivada, enviar al login
                            props.history.push('/login');
                        }
                    }
                })  
            }
            validaciones();
        }

        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
        } 
        

    }, [])

    const handleChange = e => {

        const { name, value } = e.target;
        saveFormResetPass({
            ...formResetPass,
            [name]: value
        })
    }

    
    const handleRecoveryPass = async e => {
        e.preventDefault();

        if( formResetPass.email != "" ){
            saveLoadReg(true);
            const data = new FormData();
            data.append("email", formResetPass.email);
            data.append("baseUrl", window.location.origin);

            const url = `${getApiUrl(window.location.origin)}/login/recoveryPass`;
            // const res = await axios.post(url, data);
            const res = await axiosPost(url, data, source.token);    
            if( res.data === 0 ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.forgotpass.errorusername.title}`,{autoClose: true});
            }else if( res.data === 1){
                saveOkEnvioMail(true);
            }else if( res.data === 0 ){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confApp.config.trad.login.forgotpass.error0.title}`,{autoClose: true});
            }
            saveLoadReg(false);
        }
    }

    return(
    
        <Fragment>
        {
            confApp !== undefined
            ?
                <Fragment>
                    <div className="hold-transition login-page" style={{backgroundImage:`url("${backgroundImg}")`,backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}} >
                                <div className="login-box">
                                <Alert sinClases={true} />
                                    <div style={{ textAlign: "center"}} >
                                        {   loadReg ? 
                                            <Spinner animation="border" className={ backgroundImg ? "text-white" : ""} />
                                            :
                                            null
                                        }   
                                    </div>
                                <div className={ backgroundImg ? `card card-outline transparent-${darkLightForm}` : "card card-outline"}>
                                            <div className="text-center">
                                                <div className="register-logo">
                                                    <div className="h1"><img className="logo" src={logo} /></div>
                                                </div>
                                            </div>
                                    <div className="card-body">
                                    {
                                                isConfApp && confApp !== "ko"
                                                ?
                                                    <div>
                                                            {
                                                                    okEnvioMail === false
                                                                    ?
                                                                    <div>
                                                                        <p style={{ color: loginTxtColor }} className="login-box-msg">{confApp.config.trad.login.forgotpass.recovery.title}</p>
                                                                        
                                                                        <form onSubmit={ handleRecoveryPass }>
                                                                            <div className="input-group mb-3">
                                                                                                <input 
                                                                                                    type="email" 
                                                                                                    className={`form-control`} 
                                                                                                    placeholder={confApp.config.trad.login.forgotpass.inputemail.placeholder}
                                                                                                    autoComplete="off"                                
                                                                                                    name="email" 
                                                                                                    onChange={ handleChange }
                                                                                                    required
                                                                                                />
                                                                            <div className="input-group-append">
                                                                                <div className="input-group-text">
                                                                                <span className="fas fa-envelope"></span>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                            <div className="row">
                                                                            <div className="col-12">
                                                                                <button type="submit" className="btn btn-primary btn-block">{confApp.config.trad.login.forgotpass.btnrecovery.title}</button>
                                                                            </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    :
                                                                    <div className="alert alert-success" role="alert">
                                                                        <h6><i className="fal fa-check-circle mr-1"></i>{confApp.config.trad.login.forgotpass.success.title} </h6>
                                                                    </div>
                                                                }
                                                                
                                                        
                                                            <p className="mt-3 mb-1">
                                                                <Link to="/" style={{ color: loginTxtColor }} className="float-left ">{confApp.config.trad.login.forgotpass.btnlogin.title}</Link>
                                                            </p>               
                                                            </div>

                                        :
                                            <div className="alert alert-danger text-center" role="alert">
                                                {noConfText}
                                            </div>
                                        }                                        
                                        </div>
                                    </div>
                                </div>
                            </div> 
                </Fragment>
            :
            <LoadingPage />
        }
        </Fragment>

        
    );
}

export default ForgotPass